import React from 'react';

import {getLocalizedStrings} from '../../services/localization/localizationService';
import Button from '../Button/Button';
import ButtonDark from '../Button/ButtonDark/ButtonDark';
import PopupError from '../Popup/PopupError';
import styles from './FWUFailedPopup.module.scss';

const FWUFailedPopup = ({
    headerKey,
    descriptionKey,
    okBtnKey,
    cancelBtnKey,
    onClose,
    onTryAgain,
    isClosePopupFeaturesDisable,
}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupError onClose={isClosePopupFeaturesDisable ? undefined : onClose}>
            <h2 data-testid='PopupErrorHeader' className={styles.Title}>
                {localizedStrings[headerKey]}
            </h2>
            <p className={`ica--txt-regular`}>{localizedStrings[descriptionKey]}</p>
            {okBtnKey && (
                <ButtonDark onClick={onTryAgain} showArrow>
                    {localizedStrings[okBtnKey]}
                </ButtonDark>
            )}
            <Button className={styles.ButtonSecondary} onClick={onClose} showUnderline>
                {localizedStrings[cancelBtnKey]}
            </Button>
        </PopupError>
    );
};

export default FWUFailedPopup;
