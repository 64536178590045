import React from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import FloatButton from '../Button/FloatButton/FloatButton';

const SkipButton = (props) => {
    return <FloatButton {...props}>{getLocalizedStrings()[localizationKeys.PAIR_FAIL_SKIP_BUTTON]}</FloatButton>;
};

export default SkipButton;
