import React, {createRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../components/BackButton/BackButton';
import Container from '../../../components/Layout/Container/Container';
import PopupFullPage from '../../../components/Popup/PopupFullPage/PopupFullPage';
import tncService from '../../../services/tncService';
import {removeLastTncPopupCode} from '../../../state/ducks/tnc';
import {makeSelectTncLegals} from '../../../state/selectors/consumer';
import {makeSelectTncPopupCodesList} from '../../../state/selectors/tnc';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from '../TermsOfUsePage/TermsOfUsePage.module.scss';

const mapStateToProps = createStructuredSelector({
    legals: makeSelectTncLegals(),
    tncPopupCodesList: makeSelectTncPopupCodesList(),
});

const TermsOfUsePopup = (props) => {
    const {dispatch, tncPopupCodesList, legals} = props;
    const popupRef = createRef();

    let legalData = tncService.getTncLegalByTermsCode(legals, tncPopupCodesList.slice(-1)[0]);
    if (!legalData) {
        dispatch(removeLastTncPopupCode());
        legalData = {};
    }
    const {title, text} = legalData;

    const onClose = () => {
        dispatch(removeLastTncPopupCode());
    };

    useEffect(() => {
        popupRef.current.scrollTo(0, 0);
        window.scrollTo(0, 0);
    }, [tncPopupCodesList]);

    return (
        <PopupFullPage ref={popupRef} containerClassName={styles.FixedContainer}>
            <div className={styles.PopupCommonContainer}>
                <Container isFloatHeight>
                    <BackButton onClick={onClose} />
                </Container>
                <section className={styles.PopupContainer}>
                    <section>
                        <h2>{title}</h2>
                    </section>
                    <section>
                        <TermsOfUseDescription innerHtml={text} className={styles.DescriptionContainer} />
                    </section>
                </section>
            </div>
        </PopupFullPage>
    );
};

export default connect(mapStateToProps)(TermsOfUsePopup);
