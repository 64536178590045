import {useEffect, useState} from 'react';

import iotMessageService from '../../services/iotMessageService';

const useParentModeChanged = (iotMessage) => {
    const [isParentModeFinished, setIsParentModeFinished] = useState(false);
    const isParentModeFlowFinished = iotMessageService.isParentModeFlowFinished(iotMessage);

    const startParentMode = () => {
        setIsParentModeFinished(false);
    };

    useEffect(() => {
        if (isParentModeFlowFinished) {
            setIsParentModeFinished(true);
        }
    }, [isParentModeFlowFinished]);

    return [isParentModeFinished, startParentMode];
};

export default useParentModeChanged;
