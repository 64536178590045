import React from 'react';

import DeviceImage from '../../../containers/DeviceImage/DeviceImage';
import deviceFWUProgressStyles from '../../../containers/UpdateFWPage/components/DeviceFWUProgress/DeviceFWUProgress.module.scss';
import styles from './DeviceAnimatedLoader.module.css';

const DeviceAnimatedLoader = (props) => {
    const {iotDevice, pageLoader, imgClassName, isActive} = props;
    const {device, holder} = iotDevice;

    return (
        <section className={`${styles.DeviceLoaderContainer} ${!isActive && styles.DeviceAnimatedLoaderDisabled}`}>
            {pageLoader}
            <DeviceImage
                device={device}
                holder={holder}
                imgClassName={imgClassName}
                className={deviceFWUProgressStyles.ImageDeviceContainer}
            />
        </section>
    );
};

export default DeviceAnimatedLoader;
