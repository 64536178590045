import DEVICE_TYPES from '../device/deviceTypes';
import PRODUCT_COLORS from './productColors';
import PRODUCT_COLOR_TYPES from './productColorTypes';

const PRODUCT_COLORS_MAPPING = {
    [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
        [PRODUCT_COLOR_TYPES.GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.COPPER_ROSE]: PRODUCT_COLORS.COPPER,
        [PRODUCT_COLOR_TYPES.VELVET_GREY]: PRODUCT_COLORS.GRAPHITE,
        [PRODUCT_COLOR_TYPES.MARINE_BLUE]: PRODUCT_COLORS.BLUE_METALLIC,
        [PRODUCT_COLOR_TYPES.LUCID_TEAL]: PRODUCT_COLORS.LUCID_TEAL,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
        [PRODUCT_COLOR_TYPES.GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.COPPER]: PRODUCT_COLORS.COPPER,
        [PRODUCT_COLOR_TYPES.GREY]: PRODUCT_COLORS.GRAPHITE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.BLUE_METALLIC,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
        [PRODUCT_COLOR_TYPES.GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.GREY]: PRODUCT_COLORS.GRAPHITE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.BLUE_METALLIC,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.RADIENT_RED,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
        [PRODUCT_COLOR_TYPES.GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.GREY]: PRODUCT_COLORS.GRAPHITE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.BLUE_METALLIC,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.RADIENT_RED,
        [PRODUCT_COLOR_TYPES.WHITE]: PRODUCT_COLORS.WARM_WHITE,
    },
    [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
        [PRODUCT_COLOR_TYPES.GOLD]: PRODUCT_COLORS.MELLOW_GOLD,
        [PRODUCT_COLOR_TYPES.MOTOR_SPORTS]: PRODUCT_COLORS.GRAPHITE,
        [PRODUCT_COLOR_TYPES.BLUE]: PRODUCT_COLORS.BLUE_METALLIC,
        [PRODUCT_COLOR_TYPES.NIGO]: PRODUCT_COLORS.LUCID_TEAL,
        [PRODUCT_COLOR_TYPES.RED]: PRODUCT_COLORS.RADIENT_RED,
        [PRODUCT_COLOR_TYPES.NAVY]: PRODUCT_COLORS.SLATE,
        [PRODUCT_COLOR_TYPES.PINK]: PRODUCT_COLORS.WARM_WHITE,
    },
};

export default PRODUCT_COLORS_MAPPING;
