let logState = [];

const setToLogSate = (...log) => {
    logState.push({
        log,
    });
};

const getLogState = () => logState;

const clearLogState = () => {
    logState = [];
};

export default {
    clearLogState,
    getLogState,
    setToLogSate,
};
