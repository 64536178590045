import {useEffect, useState} from 'react';

import backendService from '../../services/app/backendService';
import getDeviceMessageRequestService from '../../services/deviceMessageRequestService';
import {hideLoader, setNotificationSuccess, showLoader} from '../../state/ducks/global';
import {setMessage} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import useHideLoader from './useHideLoader';
import useParentModeChanged from './useParentModeChanged';

const LOADER_NAME = 'LOCK_LOADER';

const useDeviceLockUnlock = (iotMessage, isConnected, currentDeviceParentMode) => {
    const [isParentModeFinished, startParentMode] = useParentModeChanged(iotMessage);
    const [deviceLockUnlockData, setDeviceLockUnlockData] = useState(null);
    const {isLock} = deviceLockUnlockData || {};
    const isGamBackend = backendService.isGamBackend();

    useEffect(() => {
        if (deviceLockUnlockData) {
            if (!isGamBackend) {
                dispatch(showLoader({name: LOADER_NAME}));
            }

            startParentMode();

            if (isLock) {
                getDeviceMessageRequestService().publishParentProtectionEnableMessage();
            } else {
                getDeviceMessageRequestService().publishParentProtectionDisableMessage();
            }
        }
    }, [deviceLockUnlockData]);

    useEffect(() => {
        if (isParentModeFinished) {
            const messageDescription = deviceLockUnlockData?.messageDescription;
            const isParentModeChangeSuccessful = currentDeviceParentMode === isLock;

            dispatch(hideLoader(LOADER_NAME));
            dispatch(setMessage(null));

            if (messageDescription && isParentModeChangeSuccessful) {
                dispatch(setNotificationSuccess(messageDescription));
            }
        }
    }, [isParentModeFinished]);

    useHideLoader(!isConnected, LOADER_NAME);

    return [isParentModeFinished, setDeviceLockUnlockData];
};

export default useDeviceLockUnlock;
