import React from 'react';

import ExpansionPanel from '../../../../../components/ExpansionPanel/ExpansionPanel';
import SwitchToggle from '../../../../../components/SwitchToggle/SwitchToggle';
import styles from './DCExpansionPanel.module.css';

const DCExpansionPanel = (props) => {
    const {index, title, innerText, panelState, onChange, isActive} = props;

    return (
        <ExpansionPanel innerText={innerText} state={panelState} title={title}>
            <SwitchToggle className={styles.Toggle} index={index} isActive={isActive} onChange={onChange} />
        </ExpansionPanel>
    );
};

export default DCExpansionPanel;
