import {setNotificationFailed} from '../../state/ducks/global';
import {setIsConnected, setIsDisconnected} from '../../state/ducks/iotDevice/actions';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../../state/ducks/mwDevice';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import backendService from '../app/backendService';
import connectivityService from '../device/connectivityService';
import framesProcessingService from '../device/framesProcessingService';
import DeviceRequestTimeoutError from '../errors/deviceRequestTimeoutError';
import DisconnectEventEmitter from '../events/disconnectEventEmitter';
import gamClientService from '../gam/gamClientService';
import log from '../logger/log';
import pairingService from '../pairingService';
import iotService from '../scpCloud/iotService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import uiIotMessageResponseChecker from '../scpCloud/uiIotMessageResponseChecker';
import devicePingService from './devicePingService';
import IqosBleClient from './iqosBleClient';

const DEVICE_REQUEST_TIMEOUT_MS = 10 * 1000;

const connect = async ({isNewDevice, onConnect, onError}) => {
    try {
        let deviceSelectTimeout;

        const options = {
            onDeviceConnect: () => {
                pairingService.addBleAvailabilityListener();
                if (backendService.isGamBackend()) {
                    framesProcessingService.createNew();
                    dispatch(setIsConnected());
                }
            },
            onDisconnected: async (isDisconnectedByUser) => {
                const disconnectEventEmitter = new DisconnectEventEmitter();

                if (isDisconnectedByUser) {
                    dispatch(setMwDeviceIsDisconnectedWithNoReconnect(true));
                }

                disconnectEventEmitter.emitDeviceDisconnect();
                disconnectEventEmitter.removeAllDisconnectListeners();

                if (connectivityService.isBle()) {
                    devicePingService.stopDevicePing();
                }
                framesProcessingService.disable();

                if (backendService.isGamBackend()) {
                    dispatch(setIsDisconnected());
                } else {
                    iotService.unsubscribeFromDeviceTopic();
                    uiIotMessageResponseChecker.clearResponsesTimeouts();
                    mwIotMessageRequestService.publishDisconnectedEvent();
                }
            },
            onForcedDisconnect: () => {
                pairingService.disconnectAndForwardToMyDevicesPage();
            },
            onDeviceReconnectSuccess: () => {
                if (backendService.isGamBackend()) {
                    gamClientService.synchronizeGamDevice();
                } else {
                    iotService.initIoT();
                }
            },
            onReconnectFail: async () => {
                dispatch(setNotificationFailed(`Couldn't reconnect to your device`));
            },
            onDeviceSelect: () => {
                const deviceSelectPromise = new Promise((res, rej) => {
                    deviceSelectTimeout = setTimeout(() => {
                        rej(new DeviceRequestTimeoutError());
                    }, DEVICE_REQUEST_TIMEOUT_MS);
                });

                deviceSelectPromise.catch((e) => {
                    helpers.runFunction(onError, [e]);
                });
            },
            onFinishDeviceSelect: () => clearTimeout(deviceSelectTimeout),
        };

        const iqosBleClient = new IqosBleClient(options);

        await iqosBleClient.connect({isNewDevice, onConnect, onError});
    } catch (e) {
        log.debug(`iqosBleService: device connection failed, error: ${e}`);
        helpers.runFunction(onError, [e]);
        throw e;
    }
};

const disconnect = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        iqosBleClient.disconnect();
    } catch (e) {
        log.info(`iqosBleService: disconnect() error: ${e}`);
    }
};

const isDeviceConnected = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        return iqosBleClient.isDeviceConnected();
    } catch (e) {
        log.info(`iqosBleService: isDeviceConnected() error: ${e}`);

        return false;
    }
};

const unmount = () => {
    try {
        const iqosBleClient = new IqosBleClient();

        iqosBleClient.unmount();
    } catch (e) {
        log.info(`iqosBleService: unmount() error: ${e}`);
    }
};

export default {
    connect,
    disconnect,
    isDeviceConnected,
    unmount,
};
