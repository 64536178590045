import classNames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import deviceFWUProgressStyles from '../../containers/UpdateFWPage/components/DeviceFWUProgress/DeviceFWUProgress.module.scss';
import layoutService from '../../services/layoutService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import LoaderScreen from './LoaderScreen';
import PageLoader from './PageLoader/PageLoader';
import styles from './PageLoader/PageLoader.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const CommonLoaderScreen = (props) => {
    const {
        iotDevice,
        header,
        description,
        layout,
        isBig,
        isMedium,
        isSmall,
        isAnimated = true,
        isActive = true,
        customImgClassName,
        headerTestId,
    } = props;
    const {device} = iotDevice || {};
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const isBigLoader = !isMobileLayout && isBig;
    const isMediumLoader = isMedium;
    const isSmallLoader = !isMobileLayout && isSmall;

    const classNameContainer = classNames({
        [styles.PageLoaderBigContainer]: isBigLoader,
        [styles.PageLoaderMediumContainer]: isMediumLoader,
        [styles.PageLoaderSmallContainer]: isSmallLoader,
        [styles.PageLoaderContainer]: device,
        [styles.EmptyPageLoader]: !device,
    });

    const className = classNames({
        [styles.PageLoaderStopAnimation]: !isAnimated || !isActive,
        [styles.PageLoaderBig]: isBigLoader,
        [styles.PageLoaderMedium]: isMediumLoader,
        [styles.PageLoaderSmall]: isSmallLoader,
    });

    const imgClassName = classNames({
        customImgClassName,
        [deviceFWUProgressStyles.ImageDeviceContainerBig]: isBigLoader,
        [deviceFWUProgressStyles.ImageDeviceContainerMedium]: isMediumLoader,
        [deviceFWUProgressStyles.ImageDeviceContainerSmall]: isSmallLoader,
    });

    const pageLoader = <PageLoader classNameContainer={classNameContainer} className={className} />;

    return device ? (
        <LoaderScreen
            description={description}
            header={header}
            headerTestId={headerTestId}
            imgClassName={imgClassName}
            iotDevice={iotDevice}
            isActive={isActive}
            pageLoader={pageLoader}
        />
    ) : (
        pageLoader
    );
};

export default connect(mapStateToProps)(CommonLoaderScreen);
