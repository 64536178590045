import defaultImage_DEV_TYPE_P1V30_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_CHARGER.png';
import defaultImage_DEV_TYPE_P1V30_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V30_HOLDER.png';
import defaultImage_DEV_TYPE_P1V30M from '../../../assets/images/devices/default/DEV_TYPE_P1V30M.png';
import defaultImage_DEV_TYPE_P1V31_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_CHARGER.png';
import defaultImage_DEV_TYPE_P1V31_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_P1V31_HOLDER.png';
import defaultImage_DEV_TYPE_P4_M3_G2 from '../../../assets/images/devices/default/DEV_TYPE_P4_M3_G2.png';
import defaultImage_DEV_TYPE_V24_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24_CHARGER.png';
import defaultImage_DEV_TYPE_V24_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24_HOLDER.png';
import defaultImage_DEV_TYPE_V24P_CHARGER from '../../../assets/images/devices/default/DEV_TYPE_V24P_CHARGER.png';
import defaultImage_DEV_TYPE_V24P_HOLDER from '../../../assets/images/devices/default/DEV_TYPE_V24P_HOLDER.png';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import * as localizationKeys from '../app/localizationKeys';
import DEVICE_TYPES from './deviceTypes';

let data;

const getDeviceTypesData = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            [DEVICE_TYPES.DEV_TYPE_UNKNOWN]: {},
            [DEVICE_TYPES.DEV_TYPE_V24_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_CHARGER,
                description: 'IQOS 2.4 Charger',
                name: 'IQOS 2.4 Charger',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x01',
            },
            [DEVICE_TYPES.DEV_TYPE_V24_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24_HOLDER,
                description: 'IQOS 2.4 Holder',
                isHolder: true,
                name: 'IQOS 2.4 Holder',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x02',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_CHARGER,
                description: 'IQOS 2.4 Plus Charger',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORDINARY_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x11',
            },
            [DEVICE_TYPES.DEV_TYPE_V24P_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_V24P_HOLDER,
                description: 'IQOS 2.4 Plus Holder',
                isHolder: true,
                name: 'IQOS 2.4 Plus Holder',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x12',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_CHARGER,
                description: 'IQOS 3.0 Charger (Cortez)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_CORTEZ_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x21',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30_HOLDER,
                description: 'IQOS 3.0 Holder (Cortez)',
                isHolder: true,
                name: 'Cortez',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x22',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V30M,
                description: 'IQOS 3.0 Multi',
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_VESPUCCI_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x31',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_CHARGER,
                description: 'IQOS 3.1 Charger (Soyuz)',
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_SOYUZ_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x33',
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_HOLDER]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P1V31_HOLDER,
                description: 'IQOS 3.1 Holder (Soyuz)',
                isHolder: true,
                name: 'Soyuz',
                type: DEVICE_TYPES.DEV_TYPE_P1,
                typeId: '0x34',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G1]: {
                description: 'IQOS P4 gen. 1 (Mesh)',
                isMono: true,
                name: 'Mesh',
                type: DEVICE_TYPES.DEV_TYPE_P4,
                typeId: '0x41',
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
                defaultImageSrc: defaultImage_DEV_TYPE_P4_M3_G2,
                description: 'IQOS P4 gen. 2 (Titan)/(VEEV)',
                isHolder: false,
                isMono: true,
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_TITAN_NAME],
                type: DEVICE_TYPES.DEV_TYPE_P4,
                typeId: '0x42',
            },
        };
    }

    return data;
};

export {getDeviceTypesData};
