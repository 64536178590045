import {useEffect} from 'react';

import ErrorHandlerService from '../../services/errorHandlerService';

const useGamErrorHandler = ({handler}) => {
    useEffect(() => {
        const errorHandlerService = new ErrorHandlerService();

        errorHandlerService.setGamErrorHandler({
            handler,
        });

        return () => errorHandlerService.removeErrorHandler();
    }, []);
};

export default useGamErrorHandler;
