import React from 'react';

import Link from '../../../../../components/Link/Link';
import appConfig from '../../../../../config/appConfig';
import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import styles from './AppIntroPageAlternativeDownloadSection.module.scss';

const TXT_REGULAR_CLASSNAME = 'ica--txt-regular';

const AppIntroPageAlternativeDownloadSection = () => {
    const linkDesktopApps = appConfig.getLinkDesktopApps();

    if (!linkDesktopApps) return null;

    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.Main}>
            <p className={TXT_REGULAR_CLASSNAME}>
                {localizedStrings[localizationKeys.APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_DESCRIPTION_TEXT]}
            </p>
            <Link className={TXT_REGULAR_CLASSNAME} isTargetBlank to={linkDesktopApps}>
                {localizedStrings[localizationKeys.APP_INTRO_PAGE_ALTERNATIVE_DOWNLOAD_LINK_TEXT]}
            </Link>
        </div>
    );
};

export default AppIntroPageAlternativeDownloadSection;
