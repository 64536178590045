import React, {useEffect} from 'react';

import * as notificationTypes from '../../../consts/app/notificationTypes';
import {removeNotification} from '../../../state/ducks/global';
import styles from './Notification.module.scss';

const Notification = (props) => {
    const {dispatch, notification} = props;
    const {notificationId, text, type} = notification;

    let notificationClass;
    switch (type) {
        case notificationTypes.FAILED:
            notificationClass = `${styles.FailMessageIcon} icon--fail`;
            break;

        case notificationTypes.SUCCESS:
            notificationClass = `${styles.SuccessfulMessageIcon} icon--success`;
            break;

        case notificationTypes.INFO:
            notificationClass = `${styles.InfoMessageIcon} icon--info`;
            break;

        default:
            break;
    }

    useEffect(() => {
        const timer = setTimeout(() => dispatch(removeNotification(notificationId)), 5500);

        return () => clearTimeout(timer);
    }, []);

    const onNotificationClick = () => dispatch(removeNotification(notificationId));

    return (
        <div className={styles.NotificationContainer} onClick={onNotificationClick}>
            <div className={notificationClass} />
            <p className={`${styles.Text} ica--txt-regular`}>{text}</p>
        </div>
    );
};

export default Notification;
