import classnames from 'classnames';
import React from 'react';

import Link from '../../../components/Link/Link';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import styles from './CompatibleBrowserDownloadLink.module.scss';

const CompatibleBrowserDownloadLink = ({className}) => {
    const localizedStrings = getLocalizedStrings();

    const listClassName = classnames(styles.BrowsersList, className);

    const linkDesktopChrome = appConfig.getLinkDesktopChrome();

    return (
        <div className={listClassName}>
            <span>{localizedStrings[localizationKeys.NOT_COMP_DESKTOP_DESCRIPTION_TEXT]} </span>
            <Link to={linkDesktopChrome} isExternalLink isTargetBlank>
                Google Chrome
            </Link>
        </div>
    );
};

export default CompatibleBrowserDownloadLink;
