import CryptoJS from 'crypto-js';

import log from './logger/log';
import tncService from './tncService';

const encryptDeviceSerialNumber = (deviceSerialNumber) => {
    const intToken = tncService.getIntToken();

    if (deviceSerialNumber && intToken) {
        try {
            const key = CryptoJS.enc.Base64.parse(intToken);
            const iv = CryptoJS.lib.WordArray.random(16);

            const encryptedData = CryptoJS.AES.encrypt(deviceSerialNumber, key, {
                iv: iv,
                padding: CryptoJS.pad.ZeroPadding,
                mode: CryptoJS.mode.CBC,
            });

            const convertWordArrayToUint8Array = (wordArray) => {
                let len = wordArray.words.length,
                    u8_array = new Uint8Array(wordArray.sigBytes),
                    offset = 0,
                    word,
                    i;
                for (i = 0; i < len; i++) {
                    word = wordArray.words[i];
                    u8_array[offset++] = word >> 24;
                    u8_array[offset++] = (word >> 16) & 0xff;
                    u8_array[offset++] = (word >> 8) & 0xff;
                    u8_array[offset++] = word & 0xff;
                }
                return u8_array;
            };

            const saltByteArray = new Buffer(convertWordArrayToUint8Array(iv));
            const deviceSerialNumberByteArray = new Buffer(convertWordArrayToUint8Array(encryptedData.ciphertext));
            const resultByteArray = Buffer.concat([saltByteArray, deviceSerialNumberByteArray]);

            const encryptedDeviceSerialNumber = resultByteArray.toString('base64');

            const preparedEncryptedDeviceSerialNumber = encodeURI(encryptedDeviceSerialNumber);

            return preparedEncryptedDeviceSerialNumber;
        } catch (e) {
            log.error(`cryptoService: encryptDeviceSerialNumber, error: ${e}`);
        }
    }
};

const aesDecrypt = (baseString) => {
    try {
        const crypto = require('crypto');
        const algorithm = 'aes-256-cbc';
        const keyBytes = '16c5d9e5a562f3b546f004b2bc5ec9d9';
        const key = [];
        const ivBytes = '__PMI__BWA__Int_';
        const iv = [];

        for (let i = 0; i < keyBytes.length; i++) {
            key.push(keyBytes.charCodeAt(i));
        }

        for (let i = 0; i < ivBytes.length; i++) {
            iv.push(ivBytes.charCodeAt(i));
        }

        const decipher = crypto.createDecipheriv(algorithm, key, iv);

        let decrypted = decipher.update(baseString, 'hex', 'utf8');
        decrypted += decipher.final('utf8');

        return decrypted;
    } catch (e) {
        log.error(`cryptoService: aesDecrypt, error: ${e}`);
    }
    return null;
};

const decodeBtoa = (baseString) => {
    if (baseString) {
        return window.btoa(baseString);
    }

    return null;
};

const encodeAtob = (encodedString) => {
    if (encodedString) {
        return window.atob(encodedString);
    }

    return null;
};

export default {
    decodeBtoa,
    encodeAtob,
    aesDecrypt,
    encryptDeviceSerialNumber,
};
