import React from 'react';

import Image from '../../../../../../components/Image/Image';
import layoutService from '../../../../../../services/layoutService';
import styles from './DeviceModeHeader.module.scss';

const DeviceModeHeader = ({imgUrls, imgAlt, imgClassName, title, subTitle, children, layout}) => {
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    return (
        <div className={styles.DeviceModeContainer}>
            <div className={styles.DeviceModeHeaderWrapper}>
                <div className={styles.DeviceModeHeader}>
                    <div className={styles.ImageHeaderIconBorder}>
                        <Image
                            src={imgUrls}
                            alt={imgAlt}
                            className={`${styles.ImageHeaderIcon} ${imgClassName ? imgClassName : ''}`}
                        />
                    </div>
                    <div className={styles.HeaderTextSection}>
                        <h2 className={styles.DeviceModeTitle}>{title}</h2>
                        {isDesktopLayout && (
                            <p className={`${styles.DeviceModeSubtitle} ica--txt-regular`}>{subTitle}</p>
                        )}
                    </div>
                </div>
                {!isDesktopLayout && (
                    <p className={`${styles.DeviceModeSubtitle} ica--txt-regular ica--txt-regular-fixed`}>{subTitle}</p>
                )}
            </div>
            <div className={'ica--txt-regular'}>{children}</div>
        </div>
    );
};

export default DeviceModeHeader;
