import classNames from 'classnames';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import imgIconLocation from '../../../assets/images/icons/illustration-country-selection.png';
import imgIconLocation2 from '../../../assets/images/icons/illustration-country-selection@2x.png';
import imgIconLocation3 from '../../../assets/images/icons/illustration-country-selection@3x.png';
import ButtonArrowChevronSVG from '../../components/Button/ButtonArrowChevronSVG/ButtonArrowChevronSVG';
import ButtonBorder from '../../components/Button/ButtonBorder/ButtonBorder';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import {customInitConfig} from '../../config/config';
import {initDataConfig} from '../../config/dataConfig/dataConfig';
import externalConfigService from '../../config/externalConfigService';
import standaloneConfig from '../../config/standaloneConfig/standaloneConfig';
import appRouterService from '../../services/appRouterService';
import appService from '../../services/appService';
import authService from '../../services/auth/authService';
import layoutService from '../../services/layoutService';
import {initLocalizations} from '../../services/localization/localizationService';
import userSessionService from '../../services/userSessionService';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {dispatch} from '../../state/store';
import elementsTesIds from '../../test/consts/elementsTesIds';
import CountrySelectTemplate from './components/CountrySelectTemplate/CountrySelectTemplate';
import LanguageSelectTemplate from './components/LanguageSelectTemplate/LanguageSelectTemplate';
import ProvinceSelectTemplate from './components/ProvinceSelectTemplate/ProvinceSelectTemplate';
import parseSelectDataService from './parseSelectDataService';
import styles from './SelectMarketPage.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const SelectMarketPage = (props) => {
    const {layout} = props;
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const {countryCodeConfig} = standaloneConfig || {};

    const icons = [imgIconLocation, imgIconLocation2, imgIconLocation3];
    const standaloneCountryCodesList = countryCodeConfig?.countryList || {};
    const standaloneLocalization = countryCodeConfig?.localization.en || {};

    const defaultCountry = standaloneCountryCodesList.sort((a, b) => a.name.localeCompare(b.name))[0];

    const [currentCountryItem, setCurrentCountryItem] = useState(null);
    const [readyCountryData, setReadyCountryData] = useState(null);
    const {countryName, countryCode, languageName, languageCode, provinceName, provinceCode} = readyCountryData || {};

    const [transmittedConfig, setTransmittedConfig] = useState(null);
    const [isVisibleSelectPopup, setIsVisibleSelectPopup] = useState(null);
    const {isVisibleCountryPopup, isVisibleLanguagePopup, isVisibleProvincePopup} = isVisibleSelectPopup || {};
    const [isVisibleProvinceField, setIsVisibleProvinceField] = useState(false);

    const isMultiplyLanguage = currentCountryItem?.languages.length > 1;
    const isMultiplyProvince = currentCountryItem?.provinces?.length > 1;
    const isMultiplyCountry = currentCountryItem?.name.length > 1;

    const getButtonClassName = (isMultiplySelect, isActivePopup) =>
        classNames(styles.SelectButton, {
            [styles.InactiveSelectButton]: !isMultiplySelect,
            [styles.ActiveSelectButton]: isActivePopup,
        });

    const classNameCountryButton = getButtonClassName(isMultiplyCountry, isVisibleCountryPopup);
    const classNameProvinceButton = getButtonClassName(isMultiplyProvince, isVisibleProvincePopup);
    const classNameLanguageButton = getButtonClassName(isMultiplyLanguage, isVisibleLanguagePopup);

    const parseCountryData = (acceptedCountryItem, acceptedLanguageCode, acceptedProvinceCode) => {
        const countryProvinces = acceptedCountryItem?.provinces;

        const transmittedAcceptedData = {
            acceptedCountryItem,
            acceptedLanguageCode,
            acceptedProvinceCode,
            provinceCode,
            countryCode,
        };
        const readyCurrentData = parseSelectDataService.getReadyData(transmittedAcceptedData);

        setReadyCountryData(readyCurrentData);

        setIsVisibleProvinceField(countryProvinces);
    };

    const setCurrentCountry = (countryCode, languageCode, provinceCode) => {
        if (languageCode || provinceCode) {
            parseCountryData(currentCountryItem, languageCode, provinceCode);
        } else {
            const currentCountry = countryCode
                ? standaloneCountryCodesList.find((country) => country.countryIsoCode === countryCode)
                : defaultCountry;

            setCurrentCountryItem(currentCountry);
            parseCountryData(currentCountry);
        }
    };

    useEffect(() => {
        setCurrentCountry();
    }, []);

    const onConfirmBtnClick = async () => {
        dispatch(showLoader());

        await customInitConfig(countryCode);

        await initDataConfig();
        await authService.login();
        await initLocalizations(languageCode);
        await externalConfigService.initExternalConfig();

        appService.onAppInit();

        userSessionService.setUserSessionLocalization(countryCode, languageCode);

        appRouterService.forwardToBrowserVerificationPage();

        dispatch(hideLoader());
    };

    const onSelectCountryClick = () => {
        setTransmittedConfig(standaloneCountryCodesList);
        setIsVisibleSelectPopup({isVisibleCountryPopup: !isVisibleCountryPopup});
    };

    const onSelectLanguageClick = () => {
        if (isMultiplyLanguage) {
            setTransmittedConfig(currentCountryItem);
            setIsVisibleSelectPopup({isVisibleLanguagePopup: !isVisibleLanguagePopup});
        }
    };

    const onSelectProvinceClick = () => {
        if (isMultiplyProvince) {
            setTransmittedConfig(currentCountryItem);
            setIsVisibleSelectPopup({isVisibleProvincePopup: !isVisibleProvincePopup});
        }
    };

    const closeSelectPopup = () => {
        setIsVisibleSelectPopup(false);
    };

    const setSelectedItem = ({countryCode, languageCode, provinceCode}) => {
        setCurrentCountry(countryCode, languageCode, provinceCode);
        setIsVisibleSelectPopup(false);
    };

    const selectTemplateProps = {
        onItemSelect: setSelectedItem,
        transmittedConfig: transmittedConfig,
        onClose: closeSelectPopup,
        layout: layout,
    };

    return (
        <div className={styles.Page} data-testid={elementsTesIds.MARKET_SELECT_PAGE}>
            <Image src={icons} className={styles.Icon} />
            <div className={styles.TitleText}>{standaloneLocalization.COUNTRY_AND_LANGUAGE_SELECTOR_TITLE}</div>
            <Container className={styles.Container}>
                <div className={styles.SelectBlock}>
                    <div className={styles.Label}>{standaloneLocalization.COUNTRY_SELECTOR_TITLE_TEXT}</div>
                    <ButtonBorder
                        className={classNameCountryButton}
                        onClick={onSelectCountryClick}
                        showEnterArrow={isMobileLayout}
                        testId={elementsTesIds.MARKET_DDL_MARKET}
                    >
                        {isVisibleCountryPopup ? standaloneLocalization.COUNTRY_SELECTOR_TEXT : countryName}
                        {!isMobileLayout && (
                            <ButtonArrowChevronSVG className={isVisibleCountryPopup ? styles.DownChevron : ''} />
                        )}
                    </ButtonBorder>
                    {isVisibleCountryPopup && <CountrySelectTemplate {...selectTemplateProps} itemCode={countryCode} />}
                </div>
                <div className={styles.SelectBlock}>
                    {isVisibleProvinceField && (
                        <>
                            <div className={styles.Label}>{standaloneLocalization.PROVINCE_SELECTOR_TITLE_TEXT}</div>
                            <ButtonBorder
                                showEnterArrow={isMobileLayout}
                                className={classNameProvinceButton}
                                onClick={onSelectProvinceClick}
                            >
                                {isVisibleProvincePopup ? standaloneLocalization.PROVINCE_SELECTOR_TEXT : provinceName}
                                {!isMobileLayout && (
                                    <ButtonArrowChevronSVG
                                        className={isVisibleProvincePopup ? styles.DownChevron : ''}
                                    />
                                )}
                            </ButtonBorder>
                            {isVisibleProvincePopup && (
                                <ProvinceSelectTemplate {...selectTemplateProps} itemCode={provinceCode} />
                            )}
                        </>
                    )}
                </div>
                <div className={styles.SelectBlock}>
                    <div className={styles.Label}>{standaloneLocalization.LANGUAGE_SELECTOR_TITLE_TEXT}</div>
                    <ButtonBorder
                        className={classNameLanguageButton}
                        onClick={onSelectLanguageClick}
                        showEnterArrow={isMultiplyLanguage && isMobileLayout}
                        testId={elementsTesIds.MARKET_DDL_LANGUAGE}
                    >
                        {isVisibleLanguagePopup ? standaloneLocalization.LANGUAGE_SELECTOR_TEXT : languageName}
                        {isMultiplyLanguage && !isMobileLayout && (
                            <ButtonArrowChevronSVG className={isVisibleLanguagePopup ? styles.DownChevron : ''} />
                        )}
                    </ButtonBorder>
                    {isVisibleLanguagePopup && (
                        <LanguageSelectTemplate {...selectTemplateProps} itemCode={languageCode} />
                    )}
                </div>
                <ButtonDark
                    className={styles.ConfirmButton}
                    onClick={onConfirmBtnClick}
                    showArrow
                    testId={elementsTesIds.MARKET_BTN_CONFIRM}
                >
                    {standaloneLocalization.COUNTRY_AND_LANGUAGE_SELECTOR_CONFIRM_BUTTON_TEXT}
                </ButtonDark>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(SelectMarketPage);
