import React from 'react';

import styles from './Battery.module.scss';

const Battery = ({percentage, className}) => {
    const isBatteryChargeValid = percentage !== undefined && percentage <= 100 && percentage >= 0;

    return (
        isBatteryChargeValid && (
            <div className={`${styles.BatteryContainer} ${className ? className : ''}`}>
                <span className={'ica--txt-medium-bold'}>{percentage.toString()}%</span>
                <div className={styles.Battery}>
                    <div className={styles.BatteryLeft} style={{width: `${percentage}%`}} />
                </div>
                <div className={styles.BatteryNib} />
            </div>
        )
    );
};

export default Battery;
