import React from 'react';

import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import styles from './AppIntroPageDivider.module.scss';

const AppIntroPageDivider = () => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.Divider}>
            <div className={styles.Line} />
            <div className={styles.Text}>{localizedStrings[localizationKeys.APP_INTRO_PAGE_DIVIDER_TEXT]}</div>
            <div className={styles.Line} />
        </div>
    );
};

export default AppIntroPageDivider;
