import React from 'react';

import Popup from '../../components/Popup/Popup';
import styles from './Popup.module.scss';

const PopupRounded = (props) => (
    <Popup {...props} className={`${styles.PopupRounded} ${props.className ? props.className : ''}`} />
);

export default PopupRounded;
