import React, {useEffect, useState} from 'react';

import iconVibrate from '../../../../../../../assets/images/icons/vibrate-icon.png';
import iconVibrate2 from '../../../../../../../assets/images/icons/vibrate-icon@2x.png';
import iconVibrate3 from '../../../../../../../assets/images/icons/vibrate-icon@3x.png';
import imgDesktopVibrateModeHeader1 from '../../../../../../../assets/images/settings/vibrate-mode-icon-desktop.png';
import imgDesktopVibrateModeHeader2 from '../../../../../../../assets/images/settings/vibrate-mode-icon-desktop@2x.png';
import imgDesktopVibrateModeHeader3 from '../../../../../../../assets/images/settings/vibrate-mode-icon-desktop@3x.png';
import imgMobileVibrateModeHeader1 from '../../../../../../../assets/images/settings/vibrate-mode-icon-mobile.png';
import imgMobileVibrateModeHeader2 from '../../../../../../../assets/images/settings/vibrate-mode-icon-mobile@2x.png';
import imgMobileVibrateModeHeader3 from '../../../../../../../assets/images/settings/vibrate-mode-icon-mobile@3x.png';
import ButtonDark from '../../../../../../components/Button/ButtonDark/ButtonDark';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import useDidUpdate from '../../../../../../hooks/effects/useDidUpdate';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import deviceConfigService from '../../../../../../services/iotDevice/iotDeviceConfigService';
import iotDeviceSettingsService from '../../../../../../services/iotDevice/iotDeviceSettingsService';
import iotMessageService from '../../../../../../services/iotMessageService';
import layoutService from '../../../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import {showLoader} from '../../../../../../state/ducks/global';
import elementsTesIds from '../../../../../../test/consts/elementsTesIds';
import DeviceModePopup from '../../../DeviceModePopup/DeviceModePopup';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import styles from '../DeviceModeComponents/DeviceMode.module.scss';
import TurnOnOffButton from '../DeviceModeComponents/TurnOnOffButton';

const LOADER_NAME = 'VIBRATION_PAGE_LOADER';

const DeviceVibrateMode = (props) => {
    const {dispatch, iotDevice, isConnected, disabled, deviceColor, iotMessage, onDisableClick, layout, testId} = props;
    const {vibrationData, device} = iotDevice || {};
    const [isVisibleVibratePopup, setIsVisibleVibratePopup] = useState(false);
    const isVibrationEnabled = deviceConfigService.isVibrationEnabled(iotDevice);

    const onVisibleVibratePopup = () => {
        if (isVisibleVibratePopup || iotDeviceSettingsService.checkSettingAvailable(iotDevice, isVibrationEnabled)) {
            setIsVisibleVibratePopup(!isVisibleVibratePopup);
        }
    };

    const isHolderChargedVibrationEnabled = deviceConfigService.isHolderChargedVibrationEnabled(iotDevice);
    const isProgrammableVibrationSettingsEnabled = deviceConfigService.isProgrammableVibrationSettingsEnabled(
        iotDevice
    );
    const isCommonVibrationEnabled = deviceConfigService.isCommonVibrationEnabled(iotDevice);
    const isVibrationsProcessFinished = iotMessageService.isVibrationsProcessFinished(iotMessage);
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    const isHolderConnected = device?.holder_connected;
    const vibrationDataObj = vibrationData ? vibrationData : {};
    const {
        battery_charged,
        start_heating,
        fully_heated,
        almost_finished,
        finished,
        all_holder_vibrations,
    } = vibrationDataObj;
    const isVibrationOn =
        battery_charged || start_heating || fully_heated || almost_finished || finished || all_holder_vibrations;

    const isVibrateModeDataLoaded = isHolderChargedVibrationEnabled
        ? battery_charged !== undefined && all_holder_vibrations !== undefined
        : isProgrammableVibrationSettingsEnabled
        ? start_heating !== undefined || all_holder_vibrations !== undefined
        : isCommonVibrationEnabled
        ? all_holder_vibrations !== undefined
        : false;

    useHideLoader(isVibrationsProcessFinished || !isConnected, LOADER_NAME);

    useDidUpdate(() => {
        if (isVisibleVibratePopup) {
            if (!iotDeviceSettingsService.checkSettingAvailable(iotDevice, isVibrationEnabled)) {
                setIsVisibleVibratePopup(false);
            }
        }
    }, [isHolderChargedVibrationEnabled, isProgrammableVibrationSettingsEnabled]);

    useEffect(() => {
        if (!isVibrateModeDataLoaded && isConnected && isVibrationEnabled) {
            dispatch(showLoader({name: LOADER_NAME}));
            getDeviceMessageRequestService().publishGetVibrationSettingsMessage();
        }
    }, [isConnected, isVibrationEnabled, isHolderConnected]);

    const onResetBtnClick = () => {
        dispatch(showLoader({name: LOADER_NAME}));
        getDeviceMessageRequestService().publishResetVibrationSettingsMessage();
    };

    const onChangeDeviceVibrateStatus = (settings) => {
        dispatch(showLoader({name: LOADER_NAME}));
        analyticsService.pushUsageSettingsVibrationsEvent();
        const data = {
            ...vibrationDataObj,
            ...settings,
        };

        getDeviceMessageRequestService().publishSetVibrationSettingsMessage(data);
    };

    const onChangeCommonVibrateStatus = (all_holder_vibrations) => {
        dispatch(showLoader({name: LOADER_NAME}));
        analyticsService.pushUsageSettingsVibrationsEvent();
        getDeviceMessageRequestService().publishSetVibrationSettingsMessage({
            all_holder_vibrations,
        });
    };
    const localizedStrings = getLocalizedStrings();

    const vibrationStateMessage = isVibrationOn
        ? localizedStrings[localizationKeys.VIBRATION_STATE_ON]
        : localizedStrings[localizationKeys.VIBRATION_STATE_OFF];

    const headerImageSet = isDesktopLayout
        ? [imgDesktopVibrateModeHeader1, imgDesktopVibrateModeHeader2, imgDesktopVibrateModeHeader3]
        : [imgMobileVibrateModeHeader1, imgMobileVibrateModeHeader2, imgMobileVibrateModeHeader3];

    return (
        <>
            <DeviceSettingsItem
                deviceColor={deviceColor}
                disabled={disabled}
                imgUrls={[iconVibrate, iconVibrate2, iconVibrate3]}
                onClick={onVisibleVibratePopup}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                {`${localizedStrings[localizationKeys.DEVICE_SETTINGS_VIBRATE_MODE]}`}
                <br />
                {`${vibrationStateMessage}`}
            </DeviceSettingsItem>
            {isVisibleVibratePopup && isConnected && (
                <DeviceModePopup
                    imgAlt='vibrate-mode-icon'
                    imgClassName={`${styles.ImageVibrateHeaderIcon} ${styles.CenterElement}`}
                    imgUrls={headerImageSet}
                    isClosingBySwipeEnabled
                    layout={layout}
                    onClose={() => setIsVisibleVibratePopup(false)}
                    subTitle={localizedStrings[localizationKeys.HAPTIC_MANAGEMENT_DESCRIPTION]}
                    testId={elementsTesIds.VIBRATION_MODE_POPUP}
                    title={localizedStrings[localizationKeys.HAPTIC_MANAGEMENT_TITLE]}
                >
                    <div className={styles.ContentContainer}>
                        {isHolderChargedVibrationEnabled && (
                            <>
                                <div className={styles.HeaderTitle}>
                                    {localizedStrings[localizationKeys.VIBRATE_MODE_DEVICE_SECTION_TITLE]}
                                </div>
                                <div className={styles.ButtonsContainer}>
                                    <TurnOnOffButton
                                        isActive={battery_charged}
                                        onClick={() =>
                                            onChangeDeviceVibrateStatus({
                                                battery_charged: !battery_charged,
                                            })
                                        }
                                        title={localizedStrings[localizationKeys.HAPTIC_MANAGEMENT_HOLDER_TITLE]}
                                    />
                                </div>
                            </>
                        )}
                        {isProgrammableVibrationSettingsEnabled ? (
                            <>
                                <div className={styles.HeaderTitle}>
                                    {localizedStrings[localizationKeys.HAPTIC_MANAGEMENT_HEAT_TITLE]}
                                </div>
                                {isCommonVibrationEnabled ? (
                                    <div className={styles.ButtonsContainer}>
                                        <TurnOnOffButton
                                            title={
                                                localizedStrings[
                                                    localizationKeys.HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY
                                                ]
                                            }
                                            isActive={all_holder_vibrations}
                                            onClick={() => onChangeCommonVibrateStatus(!all_holder_vibrations)}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.ButtonsContainer}>
                                        <TurnOnOffButton
                                            isActive={start_heating}
                                            onClick={() =>
                                                onChangeDeviceVibrateStatus({
                                                    start_heating: !start_heating,
                                                })
                                            }
                                            title={
                                                localizedStrings[
                                                    localizationKeys.HAPTIC_MANAGEMENT_START_HEATING_TITLE_TEXT_KEY
                                                ]
                                            }
                                        />
                                        <TurnOnOffButton
                                            isActive={fully_heated}
                                            onClick={() =>
                                                onChangeDeviceVibrateStatus({
                                                    fully_heated: !fully_heated,
                                                })
                                            }
                                            title={
                                                localizedStrings[
                                                    localizationKeys.HAPTIC_MANAGEMENT_FULLY_HEATED_TITLE_TEXT_KEY
                                                ]
                                            }
                                        />
                                        <TurnOnOffButton
                                            isActive={almost_finished}
                                            onClick={() =>
                                                onChangeDeviceVibrateStatus({
                                                    almost_finished: !almost_finished,
                                                })
                                            }
                                            title={
                                                localizedStrings[
                                                    localizationKeys.HAPTIC_MANAGEMENT_ALMOST_ENDED_TITLE_TEXT_KEY
                                                ]
                                            }
                                        />
                                        <TurnOnOffButton
                                            isActive={finished}
                                            onClick={() =>
                                                onChangeDeviceVibrateStatus({
                                                    finished: !finished,
                                                })
                                            }
                                            title={
                                                localizedStrings[
                                                    localizationKeys.HAPTIC_MANAGEMENT_ENDED_TITLE_TEXT_KEY
                                                ]
                                            }
                                        />
                                    </div>
                                )}
                            </>
                        ) : null}
                    </div>
                    <ButtonDark showArrow onClick={onResetBtnClick}>
                        {localizedStrings[localizationKeys.RESET_TO_DEFAULT_BUTTON_TEXT]}
                    </ButtonDark>
                </DeviceModePopup>
            )}
        </>
    );
};

export default DeviceVibrateMode;
