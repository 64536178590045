const PAIRING_ERRORS = {
    ERROR_CONNECTION_NOT_FOUND: {
        type: 'ERROR_CONNECTION_NOT_FOUND',
        code: 'pairing-error.device-not-found',
    },
    ERROR_PAIRING: {
        type: 'ERROR_PAIRING',
        code: 'pairing-error.pairing-error',
    },
    ERROR_NOT_SUPPORTED_DEVICE: {
        type: 'ERROR_NOT_SUPPORTED_DEVICE',
        code: 'pairing-error.error-not-supported-device',
    },
};

export default PAIRING_ERRORS;
