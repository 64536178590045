import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';

let data;

const getPairingGuideContent = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            errors: {
                pairing_errors: {
                    title: localizedStrings[localizationKeys.BLE_PAIRING_FAIL_TITLE],
                    description: localizedStrings[localizationKeys.BLE_PAIRING_FAIL_MESSAGE],
                    mainButtonText: localizedStrings[localizationKeys.PAIR_FAIL_TRY_AGAIN_BUTTON],
                    secondaryButtonText: localizedStrings[localizationKeys.PAIR_FAIL_SKIP_BUTTON],
                },
                connection_errors: {
                    DEV_TYPE_V24P_CHARGER: {
                        title: localizedStrings[localizationKeys.PAIRING_FAIL_DESCRIPTION_ORDINARY],
                        description: localizedStrings[localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_ORDINARY],
                    },
                    DEV_TYPE_P1V30_CHARGER: {
                        title: localizedStrings[localizationKeys.PAIRING_FAIL_DESCRIPTION_CORTEZ],
                        description: localizedStrings[localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_CORTEZ],
                    },
                    DEV_TYPE_P1V31_CHARGER: {
                        title: localizedStrings[localizationKeys.PAIRING_FAIL_DESCRIPTION_SOYUZ],
                        description: localizedStrings[localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_SOYUZ],
                    },
                    DEV_TYPE_P1V30M: {
                        title: localizedStrings[localizationKeys.PAIRING_FAIL_DESCRIPTION_VESPUCCI],
                        description: localizedStrings[localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_VESPUCCI],
                    },
                    DEV_TYPE_P4_M3_G2: {
                        title: localizedStrings[localizationKeys.PAIRING_FAIL_DESCRIPTION_TITAN],
                        description: localizedStrings[localizationKeys.PAIRING_TROUBLESHOOTING_TEXT_TITAN],
                    },
                    mainButtonText: localizedStrings[localizationKeys.PAIR_FAIL_TRY_AGAIN_BUTTON],
                    secondaryButtonText: localizedStrings[localizationKeys.PAIR_FAIL_SKIP_BUTTON],
                },
                device_support_failed_errors: {
                    title: localizedStrings[localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TITLE],
                    description: localizedStrings[localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_MESSAGE],
                    mainButtonText:
                        localizedStrings[localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_TRY_AGAIN_BUTTON],
                    secondaryButtonText:
                        localizedStrings[localizationKeys.PAIRING_UNABLE_DEVICE_NOT_SUPPORTED_SKIP_BUTTON],
                },
            },
            steps: {
                COMMON_CONNECTION_STEP_NUMBER: 2,
                COMMON_PAIRING_STEP_NUMBER: 3,
                COMMON_PAIRING_FINISH_STEP_NUMBER: 4,
                TITAN_CONNECTION_START_STEP_NUMBER: 2,
                TITAN_CONNECTION_STEP_NUMBER: 4,
                TITAN_PAIRING_STEP_NUMBER: 5,
                TITAN_PAIRING_FINISH_STEP_NUMBER: 6,
            },
        };
    }

    return data;
};

export {getPairingGuideContent};
