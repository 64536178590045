import AWS from 'aws-sdk';
import CloudWatchLogs from 'aws-sdk/clients/cloudwatchlogs';

import appConfig from '../../config/appConfig';
import * as awsErrorTypes from '../../consts/aws/awsErrorTypes';
import consoleLog from '../logger/consoleLogger';
import awsAuthenticationService from './awsAuthenticationService';

let AWSClient;
let cloudWatchConfig;
const retryCount = 1;

const putEvents = async ({eventMessages, onError, onComplete}) => {
    try {
        const cloudWatchClient = await getCloudWatchClient();
        const requestData = prepareEvent(eventMessages);

        await exposeEvents({cloudWatchClient, requestData, onError, onComplete});
    } catch {
        onError();
    }
};

const exposeEvents = async ({cloudWatchClient, requestData, onError, onComplete}) => {
    let retriesLeft = retryCount;
    let requestNeeded = true;

    while (requestNeeded && retriesLeft-- >= 0) {
        const cloudWatchResponse = await cloudWatchClient.putLogEvents(requestData, async (err, data) => {
            await onPutLogResponse(err, data, onError, onComplete);
        });
        requestNeeded = !!cloudWatchResponse.response.error;
    }
};

const onPutLogResponse = async (err, data, onError, onComplete) => {
    if (err) {
        consoleLog.error(`cloudWatchProvider: putLogEvents error: ${err.toString()}`);
        onError();

        switch (err.code) {
            case awsErrorTypes.EXPIRED_TOKEN_EXCEPTION:
                cloudWatchConfig = null;
                await awsAuthenticationService.getCredentials(true);
                break;
            default:
                break;
        }
    } else {
        cloudWatchConfig.sequenceToken = data ? data.nextSequenceToken : null;
        onComplete();
    }
};

const getCloudWatchClient = async () => {
    if (!AWSClient || !cloudWatchConfig) {
        const credentials = await awsAuthenticationService.getCredentials();

        createCloudWatchClient(credentials);
    }

    return AWSClient;
};

const createCloudWatchClient = (credentials) => {
    if (credentials) {
        cloudWatchConfig = credentials.cloudWatchSettings;

        AWSClient = new CloudWatchLogs({
            region: appConfig.getAwsRegion(),
            credentials: new AWS.Credentials(credentials),
            maxRetries: 0,
        });
    }
};

const updateCloudWatchClient = (credentials) => {
    if (AWSClient) {
        createCloudWatchClient(credentials);
    }
};

const prepareEvent = (logEvents) => {
    const {groupName, streamName, sequenceToken} = cloudWatchConfig;

    const data = {
        logEvents: [...logEvents],
        logGroupName: groupName,
        logStreamName: streamName,
    };

    if (sequenceToken) {
        data.sequenceToken = sequenceToken;
    }

    return data;
};

export default {
    putEvents,
    updateCloudWatchClient,
};
