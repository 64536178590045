import React from 'react';

import Image from '../../../../../components/Image/Image';
import layoutService from '../../../../../services/layoutService';
import styles from './TutorialListHeader.module.scss';

const TutorialListHeader = (props) => {
    const {title, description, image, layout} = props;
    const isMobileLayout = layoutService.isMobileLayout(layout);

    return (
        <div className={styles.HeaderContainer}>
            <div className={styles.TutorialListTitle}>{title}</div>
            <div className={styles.TutorialListSubtitle}>{description}</div>
            {isMobileLayout && <Image className={styles.Image} src={image} alt='' />}
        </div>
    );
};

export default TutorialListHeader;
