import appConfig from '../../config/appConfig';
import {makeSelectConsumerData} from '../../state/selectors/consumer';
import {getState} from '../../state/store';
import stringUtils from '../../utils/stringUtils';
import iccService from './iccService';

const getSsoLink = async (deeplinkTemplate) => {
    if (process.env.REACT_APP_IS_BUILD_REF !== 'true' && deeplinkTemplate) {
        const scpCloudTopicMarket = appConfig.getScpCloudTopicMarket();
        const {defaultLocaleCode} = makeSelectConsumerData()(getState());

        let localization = appConfig.getLocalizationKey() || defaultLocaleCode;

        if (localization && scpCloudTopicMarket) {
            const formattedDeeplink = stringUtils.formatString(
                deeplinkTemplate,
                scpCloudTopicMarket?.toUpperCase(),
                localization?.toUpperCase()
            );

            return await iccService.fetchSSOLink(formattedDeeplink);
        }
    } else {
        return null;
    }
};

export default {
    getSsoLink,
};
