import appConfig from '../../../config/appConfig';
import queryParameters from '../../../consts/route/queryParameters';
import appService from '../../appService';
import authDataStoreService from '../../auth/authDataStoreService';
import domService from '../../domService';
import consoleLog from '../../logger/consoleLogger';
import {tokenMapping} from '../../mapping/dceMarketMappings';
import marketTypeService from '../../marketTypeService';
import routerService from '../../routerService';
import server from '../../server/server';

const showLogin = () => {
    const isDCE1Black = marketTypeService.isDCE1Black();
    const isDCE1White = marketTypeService.isDCE1White();

    if (isDCE1Black) {
        const appBaseUrl = appService.getAppBaseUrl();
        const loginUrl = appBaseUrl + queryParameters.DCE_SHOW_LOGIN;

        routerService.redirect(loginUrl);
    } else if (isDCE1White) {
        const loginUrl = appConfig.getLoginUrlFormatted();

        routerService.redirect(loginUrl);
    } else {
        consoleLog.error('dce1Service: unknown dce1 market type');
    }
};

const fetchDce1AuthToken = async () => {
    try {
        const tokenUrl = domService.getTokenUrlFromRoot();
        const response = await server.get(tokenUrl);
        const {dceAuthToken} = tokenMapping(response.data);

        authDataStoreService.setSpiceToken(dceAuthToken);

        return {dceAuthToken};
    } catch (e) {
        Promise.reject(`fetchDce1AuthToken error: ${e}`);
    }
};

export default {
    showLogin,
    fetchDce1AuthToken,
};
