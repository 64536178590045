import React, {useEffect} from 'react';

import DeviceLockedPopup from '../../../../../src/app/containers/DeviceLock/components/DeviceLockPopups/DeviceLockedPopup';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import useDeviceLockUnlock from '../../../hooks/effects/useDeviceLockUnlock';
import {getLocalizedStrings} from '../../../services/localization/localizationService';

const DeviceSettingLockedPopup = ({iotMessage, onClose, isDeviceReady, isDeviceLocked}) => {
    const [isParentModeFinished, setDeviceLockUnlockData] = useDeviceLockUnlock(
        iotMessage,
        isDeviceReady,
        isDeviceLocked
    );

    const onUnlockDevice = () => {
        const messageDescription = getLocalizedStrings()[localizationKeys.UNLOCK_SUCCESS_NOTIFICATION];

        setDeviceLockUnlockData({
            isLock: false,
            messageDescription,
        });
    };

    useEffect(() => {
        if (isParentModeFinished) {
            onClose();
        }
    }, [isParentModeFinished]);

    return <DeviceLockedPopup onClose={onClose} onUnlockDevice={onUnlockDevice} />;
};

export default DeviceSettingLockedPopup;
