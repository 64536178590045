import ACTIVATION_ERRORS_DATA from '../consts/analytics/activationErrorsData';
import * as analyticsCategories from '../consts/analytics/analyticsCategories';
import {makeSelectAggregatedSettings} from '../state/selectors/consumer';
import {getState} from '../state/store';
import urlUtils from '../utils/urlUtils';
import consumerAggregatedSettingsService from './icc/consumerAggregatedSettingsService';

const pushToDataLayer = (message) => {
    const aggregatedSettings = makeSelectAggregatedSettings()(getState());
    const dataLayer = window.dataLayer;

    if (dataLayer) {
        if (aggregatedSettings) {
            const isGAEnabled = consumerAggregatedSettingsService.isEnabledGA();

            if (isGAEnabled) {
                dataLayer.push(message);
            }
        } else {
            dataLayer.push(message);
        }
    }
};

const getDataLayerObject = ({eventAction, eventNonInteraction = false, eventLabel, ...rest}) => {
    return {
        event: 'iqosConnectWeb',
        eventCategory: 'Iqos Connect Web',
        eventAction,
        eventLabel,
        eventNonInteraction,
        ...rest,
    };
};

const pushAppLoadedEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'web app loaded',
            eventNonInteraction: true,
        })
    );
};

const pushBrowserCompatibilityEvent = (isSuccess, isBluefy) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'browser compatibility check',
            eventLabel: isSuccess ? 'success' : 'fail',
            browser: isBluefy ? 'bluefy' : 'other',
            eventNonInteraction: true,
        })
    );
};

const pushBrowserDownloadEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA',
            eventLabel: 'download browser',
        })
    );
};

const pushTNCTermsOfUseInteractionEvent = (isSuccess) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - terms of use',
            eventLabel: isSuccess ? 'agree' : 'decline',
        })
    );
};

const pushTNCDataCollectionInteractionEvent = (isSuccess) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - data collection',
            eventLabel: isSuccess ? 'agree' : 'decline',
        })
    );
};

const pushPrePairingPairDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device',
        })
    );
};

const pushPrePairingPairRegisteredDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device - already registered',
            eventNonInteraction: false,
        })
    );
};

const pushPrePairingPairDisconnectedDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'pair device - disconnected',
            eventNonInteraction: false,
        })
    );
};

const pushPrePairingSetupNewIqosEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-pairing',
            eventLabel: 'set up a new iqos',
        })
    );
};

const pushPairingDeviceSelectionEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step 1',
            eventLabel: 'device selection',
        })
    );
};

const pushPairingTurnOnDeviceEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step 2',
            eventLabel: 'turn on device',
        })
    );
};

const pushPairingBluetoothEnabledEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step 3',
            eventLabel: 'enable device bluetooth',
        })
    );
};

const pushPairingDevicePairingEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step 4',
            eventLabel: 'pairing device',
        })
    );
};

const pushPairingSuccessfulEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step 5',
            eventLabel: 'successfully paired',
        })
    );
};

const pushPairingFailedEvent = (errorCode, errorType) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step error',
            eventLabel: `${errorCode} | ${errorType}`,
        })
    );
};

const pushPairingQuitSetupEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Pairing flow - step quit',
            eventLabel: 'quit set up',
        })
    );
};

const pushActivationPreactivationEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - pre-activation',
            eventLabel: 'activate device',
        })
    );
};

const pushActivationDeviceActivatingEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Activation flow - step 1',
            eventLabel: 'activating device',
        })
    );
};

const pushActivationDeviceActivatedSuccessEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Activation flow - step 2',
            eventLabel: 'successfully activated',
        })
    );
};

const pushErrorEvent = (errorCode, errorType) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Activation flow - step error',
            eventLabel: `${errorCode} | ${errorType}`,
        })
    );
};

const pushUsageMyDevicesScreenviewEvent = () => {
    const urlPath = urlUtils.getCurrentPathname();

    pushToDataLayer({
        event: 'iqosConnectWebScreenview',
        urlPath: urlPath,
    });
};

const pushUsageSettingsInteractionEvent = (category) => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - settings update',
            eventLabel: category,
        })
    );
};

const pushUsageSettingsRenameEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_RENAME);
const pushUsageSettingsAutoCleaningEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_AUTO_CLEANING);
const pushUsageSettingsDiagnosticEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_DIAGNOSTIC);
const pushUsageSettingsVibrationsEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_VIBRATIONS);
const pushUsageSettingsLedModeEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_LIGHT_MODE);
const pushUsageSettingsFindDeviceEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_FIND_DEVICE);
const pushUsageSettingsFwUpdateEvent = () => pushUsageSettingsInteractionEvent(analyticsCategories.AC_FW_UPDATE);
const pushUsageSettingsResponsiveDrawModeEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_RESPONSIVE_DRAW);
const pushUsageSettingsVapeCloudSizeModeEvent = () =>
    pushUsageSettingsInteractionEvent(analyticsCategories.AC_VAPE_CLOUD_sIZE);

const pushActivationFailedError = () => {
    pushErrorEvent(ACTIVATION_ERRORS_DATA.AE_001.code, ACTIVATION_ERRORS_DATA.AE_001.status);
};

const pushRegistrationFailedEvent = (statusMessage) => {
    const key = `AE_${statusMessage}`;

    pushErrorEvent(ACTIVATION_ERRORS_DATA[key].code, ACTIVATION_ERRORS_DATA[key].status);
};

const pushDeviceDiagnosticContactUsEvent = () => {
    pushToDataLayer(
        getDataLayerObject({
            eventAction: 'Click - CTA - device diagnostic',
            eventLabel: 'contact us',
            eventNonInteraction: false,
        })
    );
};

export default {
    pushActivationDeviceActivatedSuccessEvent,
    pushActivationDeviceActivatingEvent,
    pushActivationFailedError,
    pushActivationPreactivationEvent,
    pushAppLoadedEvent,
    pushBrowserCompatibilityEvent,
    pushBrowserDownloadEvent,
    pushDeviceDiagnosticContactUsEvent,
    pushPairingBluetoothEnabledEvent,
    pushPairingDevicePairingEvent,
    pushPairingDeviceSelectionEvent,
    pushPairingFailedEvent,
    pushPairingQuitSetupEvent,
    pushPairingSuccessfulEvent,
    pushPairingTurnOnDeviceEvent,
    pushPrePairingPairDeviceEvent,
    pushPrePairingPairDisconnectedDeviceEvent,
    pushPrePairingPairRegisteredDeviceEvent,
    pushPrePairingSetupNewIqosEvent,
    pushRegistrationFailedEvent,
    pushTNCDataCollectionInteractionEvent,
    pushTNCTermsOfUseInteractionEvent,
    pushUsageMyDevicesScreenviewEvent,
    pushUsageSettingsAutoCleaningEvent,
    pushUsageSettingsDiagnosticEvent,
    pushUsageSettingsFindDeviceEvent,
    pushUsageSettingsFwUpdateEvent,
    pushUsageSettingsLedModeEvent,
    pushUsageSettingsRenameEvent,
    pushUsageSettingsResponsiveDrawModeEvent,
    pushUsageSettingsVapeCloudSizeModeEvent,
    pushUsageSettingsVibrationsEvent,
};
