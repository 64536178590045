import {useEffect} from 'react';

import {hideLoader} from '../../state/ducks/global';
import {makeSelectIsLoaderVisible} from '../../state/selectors/global';
import {dispatch, getState} from '../../state/store';

const useHideLoader = (isNotVisible, loaderName) => {
    const checkAndHideLoader = () => {
        const isLoaderVisible = makeSelectIsLoaderVisible(loaderName)(getState());

        if (isLoaderVisible) {
            dispatch(hideLoader(loaderName));
        }
    };

    useEffect(() => {
        if (isNotVisible) {
            checkAndHideLoader();
        }
    }, [isNotVisible]);

    useEffect(() => {
        return checkAndHideLoader; //hide loader on component unmount
    }, []);
};

export default useHideLoader;
