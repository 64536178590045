import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useShowDisconnectNotification from '../../../hooks/effects/connection/useShowDisconnectNotification';
import appRouterService from '../../../services/appRouterService';
import tncService from '../../../services/tncService';
import {makeSelectDeviceConnectionStatus} from '../../../state/selectors/iotDevice';
import PreferenceCenterControl from '../../TNC/DataCollectionPage/PreferenceCenter/PreferenceCenterControl';

const mapStateToProps = createStructuredSelector({
    connectionStatus: makeSelectDeviceConnectionStatus(),
});

const PreferenceCenterSettingsPage = ({connectionStatus}) => {
    useEffect(() => {
        tncService.getTncData();
    }, []);

    useShowDisconnectNotification(connectionStatus);

    return (
        <PreferenceCenterControl
            backButtonClick={appRouterService.forwardToDeviceSettingsPage}
            onApproved={appRouterService.forwardToDeviceSettingsPage}
        />
    );
};

export default connect(mapStateToProps)(PreferenceCenterSettingsPage);
