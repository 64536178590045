import classnames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import Carousel from '../../components/Carousel/Carousel';
import CarouselArrowNext from '../../components/Carousel/CarouselArrow/CarouselArrowNext';
import CarouselArrowPrev from '../../components/Carousel/CarouselArrow/CarouselArrowPrev';
import * as localizationString from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import appRouterService from '../../services/appRouterService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import elementsTesIds from '../../test/consts/elementsTesIds';
import styles from './TipsAndTricksPage.module.scss';
import tipsAndTricksPageService from './tipsAndTricksPageService';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const TipsAndTricksPage = ({layout}) => {
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    const content = tipsAndTricksPageService.getAllowedTATScreens(isMobileLayout);

    const slider = useRef();
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [arrowPrevClassname, setArrowPrevClassname] = useState(null);
    const [arrowNextClassname, setArrowNextClassname] = useState(null);
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    useEffect(() => {
        if (isDesktopLayout) {
            const isFirstSlide = +currentSlideIndex === 0;
            const isLastSlide = +currentSlideIndex === content?.length - 1;

            if (isFirstSlide) {
                setArrowPrevClassname(styles.Hidden);
            } else if (isLastSlide) {
                setArrowNextClassname(styles.Hidden);
            } else {
                setArrowPrevClassname(null);
                setArrowNextClassname(null);
            }
        }
    }, [currentSlideIndex]);

    if (!content || content?.length === 0) {
        return <Redirect to={routePaths.LOGIN} />;
    }

    const localizedStrings = getLocalizedStrings();
    const sliderTextClassname = classnames(styles.SliderItemText, 'ica--txt-regular');

    const onGetStartedClick = () => {
        if (isIccJourneyScreensEnabled) {
            appRouterService.forwardToLoginPage();
        } else {
            appRouterService.forwardToMyDevicesPage();
        }
    };

    const onTransitionEnd = (index) => (isDesktopLayout ? setCurrentSlideIndex(index) : null);
    const slidePrev = () => slider.current.prev();
    const slideNext = () => slider.current.next();

    return (
        <div className={styles.Page} data-testid={elementsTesIds.TIPS_TRICKS_PAGE}>
            {isDesktopLayout && <CarouselArrowPrev onClick={slidePrev} className={arrowPrevClassname} />}
            <div className={styles.SliderWrapper}>
                <Carousel
                    dotsClassName={styles.Dots}
                    ref={slider}
                    onTransitionEnd={onTransitionEnd}
                    isArrowVisible={false}
                >
                    {content?.map((element, index) => {
                        const {text, image} = element || {};

                        const backgroundStyle = {
                            backgroundImage: `url(${image})`,
                        };

                        return (
                            <div key={index} className={styles.SliderItemWrapper}>
                                <div className={styles.SliderItem}>
                                    <div className={styles.SliderItemBackground} style={backgroundStyle} />
                                    <div className={sliderTextClassname}>{text}</div>
                                </div>
                            </div>
                        );
                    })}
                </Carousel>
            </div>
            {isDesktopLayout && <CarouselArrowNext onClick={slideNext} className={arrowNextClassname} />}
            <ButtonDark
                testId={elementsTesIds.TIPS_BTN_GET_STARTED}
                showArrow
                noArrowAnimation
                className={styles.Button}
                onClick={onGetStartedClick}
            >
                {localizedStrings[localizationString.BENEFITS_START_BUTTON_TEXT]}
            </ButtonDark>
        </div>
    );
};

export default connect(mapStateToProps)(TipsAndTricksPage);
