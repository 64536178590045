import {useEffect} from 'react';

import HttpErrorHandlerService from '../../services/httpErrorHandlerService';
import arrayUtils from '../../utils/arrayUtils';

const useHttpErrorHandler = ({httpErrorTypes = [], handler}) => {
    useEffect(() => {
        const httpErrorHandlerService = new HttpErrorHandlerService();
        const errorCodesList = arrayUtils.toArray(httpErrorTypes);

        httpErrorHandlerService.setErrorHandler({
            handler,
            httpErrorTypes: errorCodesList,
        });

        return () => httpErrorHandlerService.removeErrorHandler();
    }, []);
};

export default useHttpErrorHandler;
