import appConfig from '../config/appConfig';
import * as marketNames from '../config/configClient/marketNames';
import * as marketTypes from '../consts/market/marketTypes';

const convertMarketNameIfNeed = () => {
    const baseMarketName = appConfig.getScpCloudTopicMarket();

    if (baseMarketName === marketTypes.UK) {
        return marketNames.UK;
    } else {
        return appConfig.getScpCloudTopicMarket();
    }
};

const currentMarket = () => convertMarketNameIfNeed();

export default {
    currentMarket,
};
