import React from 'react';

import RemainingTime from '../../../components/RemainingTime/RemainingTime';

const FWU_DURATION_AFTER_DATA_UPLOAD_IN_SECONDS = 30;

const DeviceFWURemainingTime = ({progress}) => {
    return <RemainingTime progress={progress} additionalTime={FWU_DURATION_AFTER_DATA_UPLOAD_IN_SECONDS} />;
};

export default DeviceFWURemainingTime;
