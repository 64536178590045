import helpers from '../../utils/helpers';
import log from '../logger/log';
import HIDClient from './hidClient';

let instance = null;

const RESPONSE_TIMEOUT_MS = 1 * 2000;
const WRITE_FRAME_RETRY_COUNT = 3;

export default class HIDCommunicatorClient {
    constructor(createNew = false) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.isActive = true;

        this.hidClient = new HIDClient();
        this.resetState();
        this.addOnInputReportListener();
    }

    disable = () => {
        this.isActive = false;
        this.clearResponseTimeout();
        this.clearQueue();
    };

    responseTimeout;
    framesQueue = [];
    state = {};

    resetState = () => {
        this.state = {
            currentFrame: undefined,
            framesToResponse: [],
            onError: undefined,
            onSuccess: undefined,
            responseFrameHex: '',
            responses: [],
            retryCount: WRITE_FRAME_RETRY_COUNT,
        };
    };

    resetResponseState = () => {
        this.state.responseFrameHex = '';
    };

    addOnInputReportListener = () => {
        this.hidClient.subscribeOnInputReport(this.onInputReport);
    };

    onInputReport = (value) => {
        this.clearResponseTimeout();
        this.state.responses.push(value);

        this.writeFrames();
    };

    addFramesToQueue = ({frames, onSuccess, onError, processImmediately = true}) => {
        log.debug(`HIDCommunicatorClient: add frames to queue, frames: ${JSON.stringify(frames)}`);

        this.framesQueue.push({frames, onSuccess, onError});

        if (processImmediately) {
            this.processQueue();
        }
    };

    processQueue = () => {
        if (!this.state.framesToResponse.length) {
            this.processFrames();
        }
    };

    processFrames = () => {
        this.resetState();

        if (this.framesQueue.length) {
            const {frames, onSuccess, onError} = this.framesQueue.shift();

            log.debug(`HIDCommunicatorClient: process frames: ${JSON.stringify(frames)}`);

            this.state.framesToResponse = frames;
            this.state.onSuccess = onSuccess;
            this.state.onError = onError;
            this.writeFrames();
        }
    };

    writeFrames = () => {
        const {framesToResponse, responses} = this.state;
        const responseNumber = responses.length;
        const itWasLastFrame = !framesToResponse || responseNumber >= framesToResponse.length;

        if (itWasLastFrame) {
            this.onFramesResponse();
        } else {
            const frame = framesToResponse[responseNumber];
            this.resetResponseState();
            this.writeFrame(frame);
        }
    };

    writeFrame = (frame) => {
        this.hidClient.sendReport(frame);
        this.initResponseTimeout(frame);
    };

    initResponseTimeout = (frame) => {
        this.clearResponseTimeout();
        this.responseTimeout = setTimeout(() => {
            this.onResponseTimeout(frame);
        }, RESPONSE_TIMEOUT_MS);
    };

    onResponseTimeout = (frame) => {
        if (this.isActive) {
            if (this.state.retryCount > 0) {
                this.state.retryCount = this.state.retryCount - 1;

                log.debug(
                    `HIDCommunicatorClient: onResponseTimeout, there is no response from device for ${frame} frame. Try to write frame again, retry #${
                        WRITE_FRAME_RETRY_COUNT - this.state.retryCount
                    }`
                );

                this.writeFrames();
            } else {
                this.onFrameResponseError();
            }
        }
    };

    onFrameResponseError = () => {
        const {onError} = this.state;

        this.clearResponseTimeout();
        helpers.runFunction(onError);
    };

    clearResponseTimeout = () => clearTimeout(this.responseTimeout);

    clearQueue = () => {
        this.framesQueue = [];
        this.resetState();
    };

    onFramesResponse = () => {
        const {responses, onSuccess} = this.state;

        if (onSuccess) {
            onSuccess(responses);
        }

        this.processFrames();
    };
}
