import {useLayoutEffect} from 'react';

import domService from '../../services/domService';

const useHidePage = () => {
    useLayoutEffect(() => {
        const root = domService.getRoot();
        const hidePageClass = 'page-hide';

        root.classList.add(hidePageClass);

        return () => {
            root.classList.remove(hidePageClass);
        };
    }, []);
};

export default useHidePage;
