import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';

import appConfig from '../config/appConfig';
import * as reducers from '../state/ducks';
import * as global from '../state/ducks/global';

let dispatch;
let getState;

const configureStore = (initialState = {}) => {
    const appReducer = combineReducers(reducers);

    const rootReducer = (state, action) => {
        if (action.type === global.LOGOUT_CLEAR_DATA) {
            state = undefined;
        }

        return appReducer(state, action);
    };

    const middlewares = [promise, thunk];
    const isDebug = appConfig.getIsDebug();

    if (isDebug && process.env.NODE_ENV !== 'test') {
        const {logger} = require(`redux-logger`);

        middlewares.push(logger);
    }

    const middleware = applyMiddleware(...middlewares, dynamicMiddlewares);
    const composeEnhancers = (isDebug && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

    const store = createStore(rootReducer, initialState, composeEnhancers(middleware));
    dispatch = store.dispatch;
    getState = store.getState;

    return store;
};

export {configureStore, dispatch, getState};
