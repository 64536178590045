import React from 'react';

import imgVibrationOffIcon1 from '../../../../../../../assets/images/icons/vibration-off-icon.png';
import imgVibrationOffIcon2 from '../../../../../../../assets/images/icons/vibration-off-icon@2x.png';
import imgVibrationOffIcon3 from '../../../../../../../assets/images/icons/vibration-off-icon@3x.png';
import imgVibrationOnIcon1 from '../../../../../../../assets/images/icons/vibration-on-icon.png';
import imgVibrationOnIcon2 from '../../../../../../../assets/images/icons/vibration-on-icon@2x.png';
import imgVibrationOnIcon3 from '../../../../../../../assets/images/icons/vibration-on-icon@3x.png';
import ButtonWhiteHigh from '../../../../../../components/Button/ButtonWhiteHeight/ButtonWhiteHigh';
import Image from '../../../../../../components/Image/Image';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import styles from './TurnOnOffButton.module.scss';

const TurnOnOffButton = ({title, isActive, onClick}) => {
    const buttonClassName = isActive ? styles.ButtonWhiteHighSelected : '';
    const onIconSet = [imgVibrationOnIcon1, imgVibrationOnIcon2, imgVibrationOnIcon3];
    const offIconSet = [imgVibrationOffIcon1, imgVibrationOffIcon2, imgVibrationOffIcon3];
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.ButtonContainer}>
            <ButtonWhiteHigh className={buttonClassName} onClick={onClick}>
                <div className={styles.BtnContentContainer}>
                    <div className={styles.ButtonTitle}>{title}</div>
                    <div className={styles.ButtonState}>
                        {isActive
                            ? localizedStrings[localizationKeys.VIBRATION_STATE_ON]
                            : localizedStrings[localizationKeys.VIBRATION_STATE_OFF]}
                    </div>
                </div>
                <div className={styles.ButtonIcon}>
                    <Image src={isActive ? onIconSet : offIconSet} />
                </div>
            </ButtonWhiteHigh>
        </div>
    );
};

export default TurnOnOffButton;
