import React from 'react';

import styles from '../DevicesList.module.css';
import DeviceListItem from './DeviceListItem/DeviceListItem';

const SingleDevice = ({customerDeviceList}) => {
    if (!customerDeviceList) return null;

    const device = customerDeviceList?.length ? customerDeviceList[0] : null;

    return <DeviceListItem device={device} className={styles.Single} />;
};

export default SingleDevice;
