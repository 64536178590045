export default {
    DATA_DEVICE_CODENTIFY: 'data-codentify',
    DATA_DEVICE_FACTORY: 'data-factory',
    DATA_LANGUAGE: 'data-language',
    DATA_REFRESH_TOKEN_URL: 'data-refresh-token-url',
    DATA_USER_TOKEN: 'data-user-token',
    DCE_SHOW_LOGIN: '?showlogin=true',
    LANG: 'lang',
    USERNAME: 'username',
};
