import React from 'react';

import ButtonDark from '../../../../../components/Button/ButtonDark/ButtonDark';
import ButtonWhite from '../../../../../components/Button/ButtonWhite/ButtonWhite';
import appConfig from '../../../../../config/appConfig';
import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import appRouterService from '../../../../../services/appRouterService';
import authDataStoreService from '../../../../../services/auth/authDataStoreService';
import authService from '../../../../../services/auth/authService';
import havService from '../../../../../services/havService';
import iccMarketService from '../../../../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import marketTypeService from '../../../../../services/marketTypeService';
import userDeviceService from '../../../../../services/userDeviceService';
import elementsTesIds from '../../../../../test/consts/elementsTesIds';
import styles from './AppIntroPageContinueButton.module.scss';

const AppIntroPageContinueButton = ({isDownloadButtonAvailable}) => {
    const isBluetoothSupported = userDeviceService.isBluetoothSupported();

    if (!isBluetoothSupported) return null;

    const localizedStrings = getLocalizedStrings();
    const isDCE2 = marketTypeService.isDCE2();
    const isMarketDefined = marketTypeService.isMarketDefined();

    const onContinueButtonClick = async () => {
        if (isBluetoothSupported) {
            const spiceToken = authDataStoreService.getSpiceToken();

            if (spiceToken) {
                if (isDCE2) {
                    appRouterService.forwardToMyDevicesPage();
                    return;
                }

                const isHAVMandatory = appConfig.getIsHAVMandatory();
                const isMarketHAVAvailable = appConfig.getIsMarketHAVAvailable();

                if (isHAVMandatory && isMarketHAVAvailable) {
                    await havService.verifyHav();
                } else {
                    await iccMarketService.getConsumerProfile();
                }
                await iccMarketService.getConsumerAggregatedSettings();
                appRouterService.forwardToTNCTermsOfUsePage();
            } else {
                if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
                    if (!isDCE2 && isMarketDefined) {
                        authService.showMarketLogin();
                    } else {
                        appRouterService.forwardToMyDevicesPage();
                    }
                } else {
                    appRouterService.forwardToAgeGatePage();
                }
            }
        } else {
            appRouterService.forwardToNotCompatibleBrowserPage();
        }
    };

    return isDownloadButtonAvailable ? (
        <ButtonWhite
            className={styles.ButtonCustom}
            onClick={onContinueButtonClick}
            testId={elementsTesIds.COMPATIBILITY_BROWSER_BTN_CONTINUE}
        >
            <span>{localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT]}</span>
        </ButtonWhite>
    ) : (
        <ButtonDark
            className={styles.Button}
            onClick={onContinueButtonClick}
            testId={elementsTesIds.COMPATIBILITY_BROWSER_BTN_CONTINUE}
        >
            {localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_CONTINUE_BUTTON_TEXT]}
        </ButtonDark>
    );
};

export default AppIntroPageContinueButton;
