import {useEffect} from 'react';

import iotDeviceStateService from '../../../services/iotDevice/iotDeviceFWUStateService';
import {hideLoader} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';
import helpers from '../../../utils/helpers';

const useFWUInfoLoaded = (device, onPackageLoaded) => {
    const isFirmwareInfoLoaded = iotDeviceStateService.isFirmwareInfoLoaded(device);

    useEffect(() => {
        if (isFirmwareInfoLoaded) {
            dispatch(hideLoader());

            helpers.runFunction(onPackageLoaded);
        }
    }, [isFirmwareInfoLoaded]);

    return isFirmwareInfoLoaded;
};

export default useFWUInfoLoaded;
