import React from 'react';

import * as localizationKeys from '../../../../consts/app/localizationKeys';
// import iotDeviceStateService from '../../../../services/iotDevice/iotDeviceFWUStateService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import DeviceImage from '../../../DeviceImage/DeviceImage';
import styles from './AboutDeviceHeader.module.scss';
// import AboutDeviceUpdateText from './components/AboutDeviceUpdateText/AboutDeviceUpdateText';

const AboutDeviceHeader = ({device, holder}) => {
    // const isFirmwareLoaded = iotDeviceStateService.isFirmwarePackageLoaded(device, holder);
    // const isFirmwareUpToDate = iotDeviceStateService.isFirmwareUpToDate(device, holder);
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.Container}>
            <h2>{localizedStrings[localizationKeys.ABOUT_DEVICE_TITLE_TEXT]}</h2>
            {/*{*/}
            {/*    isFirmwareLoaded &&*/}
            {/*    <AboutDeviceUpdateText*/}
            {/*        text={localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_DESCRIPTION_TEXT}*/}
            {/*        to={'http://iqos.com'}*/}
            {/*    />*/}
            {/*}*/}
            {/*{*/}
            {/*    isFirmwareUpToDate &&*/}
            {/*    <AboutDeviceUpdateText*/}
            {/*        text={localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_IS_UP_TO_DATE_TEXT}*/}
            {/*    />*/}
            {/*}*/}
            <DeviceImage device={device} holder={holder} className={styles.ImageContainer} />
        </div>
    );
};

export default AboutDeviceHeader;
