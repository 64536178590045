import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {setNotificationInfo} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import deviceTypesService from '../device/deviceTypesService';
import {getLocalizedStrings} from '../localization/localizationService';

let vibrationAlarmTimeout;

const setVibrationAlarmTimeout = (deviceAlarmDuration, callback) => {
    vibrationAlarmTimeout = setTimeout(() => helpers.runFunction(callback), deviceAlarmDuration);
};

const clearVibrationAlarmTimeout = () => clearTimeout(vibrationAlarmTimeout);

const isSettingAvailable = (iotDevice, isGlobalSupported, isSupported, isNeedHolderCheck = true) => {
    if (isSupported && isGlobalSupported) return true;

    if (!isGlobalSupported) return false;

    const {device_global_features_config, device, holder} = iotDevice || {};
    if (!device_global_features_config || !device) return false;

    if (isNeedHolderCheck && device_global_features_config.is_holder_check) {
        if (!device?.holder_connected || !holder?.type) {
            return true;
        }
    }

    const isFWAvailableP1 = appConfig.getIsFWAvailableP1();
    const isFWAvailableP4 = appConfig.getIsFWAvailableP4();

    const isP1Device = deviceTypesService.isP1(device.type);
    const isP4Device = deviceTypesService.isP4(device.type);

    return (isFWAvailableP1 && isP1Device) || (isFWAvailableP4 && isP4Device);
};

const checkSettingAvailable = (iotDevice, is_supported) => {
    const {device_global_features_config, device, holder} = iotDevice;

    if (!device_global_features_config) return false;

    const localizedStrings = getLocalizedStrings();

    if (device_global_features_config.is_holder_check) {
        if (device?.holder_connected && holder?.type) {
            if (!is_supported) {
                dispatch(
                    setNotificationInfo(
                        localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT]
                    )
                );
                return false;
            }
        } else {
            dispatch(
                setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_INSERT_HOLDER_NOTIFICATION_TEXT])
            );
            return false;
        }
    } else if (!is_supported) {
        dispatch(
            setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_DEVICE_NOTIFICATION_TEXT])
        );
        return false;
    }
    return true;
};

const isHolderInSBLMode = ({device, holder}) => {
    const isHolderConnected = device?.holder_connected && holder?.is_holder_manageable !== undefined;
    const isHolderManageable = holder?.is_holder_manageable;

    return isHolderConnected && !isHolderManageable;
};

export default {
    setVibrationAlarmTimeout,
    clearVibrationAlarmTimeout,
    isSettingAvailable,
    checkSettingAvailable,
    isHolderInSBLMode,
};
