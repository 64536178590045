import React from 'react';

import Button from '../Button';
import styles from './ButtonDark.module.scss';

const ButtonDark = (props) => {
    return (
        <Button
            {...props}
            className={`${styles.ButtonDark} ${props.noArrowAnimation ? '' : 'button-arrow__animation'} ${
                props.className ? props.className : ''
            }`}
        >
            <span>{props.children}</span>
        </Button>
    );
};

export default ButtonDark;
