import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {clearMwDevice, setMwDeviceFirmwareData} from '../../state/ducks/mwDevice';
import {makeMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import DeviceCharacteristicClient from '../ble/deviceCharacteristicClient';
import devicePingService from '../ble/devicePingService';
import fwuService from '../ble/fwuService';
import connectivityService from '../device/connectivityService';
import framesProcessingService from '../device/framesProcessingService';
import {mwResponseBaseMapping} from '../mapping/iotMappings';
import storageService from '../storage/storageService';
import iotService from './iotService';
import mwIotMessageRequestService from './mwIotMessageRequestService';

const onIotMessage = (topic, message) => {
    const isIdentified = makeMwDeviceIsIdentified()(getState());
    const {type, status, body, request_id} = message;
    const frames = body?.frames;
    const data = mwResponseBaseMapping(body?.data);
    const deviceSerialNumber = data?.deviceSerialNumber;
    const isIdentificationFlow = !isIdentified && deviceSerialNumber;
    const isPingMessage = type === iotMessageTypes.DEVICE_PING;

    if (isIdentificationFlow) {
        onIdentifyDevice(topic, deviceSerialNumber);
    }

    if (frames?.length && !isPingMessage) {
        framesProcessingService.processFrames({
            frames,
            processImmediately: !isIdentificationFlow,
            request_id,
            type,
            status,
            onSuccess: (...responses) => {
                mwIotMessageRequestService.publishResponseEvent(type, request_id, responses);
            },
            onError: (isDeviceDisconnect) => {
                if (isDeviceDisconnect) return;

                mwIotMessageRequestService.publishErrorEvent({
                    type,
                    request_id,
                    errorCode: iotMessageErrorCodes.IO_ERROR_IO_GENERAL,
                });
            },
        });
    } else {
        switch (type) {
            case iotMessageTypes.CONNECTION:
                onConnectionMessage(topic, status);
                break;
            case iotMessageTypes.FIRMWARE_UPDATE:
                onFWUMessage(message);
                break;
            case iotMessageTypes.DEVICE_PING:
                onDevicePingMessage(message);
                break;
            default:
                break;
        }
    }
};

const onConnectionMessage = (topic, status) => {
    switch (status) {
        case iotMessageStatusTypes.DISCONNECTED:
            iotService.unsubscribeFromTopic(topic);
            dispatch(clearMwDevice());
            break;
        default:
            break;
    }
};

const onFWUMessage = ({status, body}) => {
    const {data} = body;

    switch (status) {
        case iotMessageStatusTypes.CHECK:
            dispatch(setMwDeviceFirmwareData(data));
            fwuService.clearPackageData();
            break;
        case iotMessageStatusTypes.STARTED:
            fwuService.startFwUpdate(data);
            break;
        default:
            break;
    }
};

const onDevicePingMessage = ({body}) => {
    const frames = body?.frames;

    devicePingService.startDevicePing(frames);
};

const onIdentifyDevice = (topic, deviceSerialNumber) => {
    subscribeOnIdentifiedId(topic, deviceSerialNumber);

    if (connectivityService.isBle()) {
        new DeviceCharacteristicClient(true);
    }
};

const subscribeOnIdentifiedId = (topic, deviceSerialNumber) => {
    iotService.unsubscribeFromTopic(topic);

    const storageDeviceData = storageService.getDeviceDataFromStorage();

    if (storageDeviceData) {
        const storagePreviousInternalId = storageDeviceData.deviceSerialNumber;

        if (deviceSerialNumber !== storagePreviousInternalId) {
            storageService.removeDeviceDataFromStorage();
        }
    }
};

export default {
    onIotMessage,
};
