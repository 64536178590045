import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {createStructuredSelector} from 'reselect';

import noRegisteredDeviceImg from '../../../assets/images/devices/no-registered-device/no-registered-device.png';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {makeSelectCommonProducts} from '../../state/selectors/products';
import elementsTesIds from '../../test/consts/elementsTesIds';
import LogoutButton from '../LogoutButton/LogoutButton';
import LearnMoreButton from './components/LearnMoreButton';
import SetupNewDeviceButton from './components/SetupNewDeviceButton';
import styles from './NoDevices.module.scss';

const mapStateToProps = createStructuredSelector({
    commonProducts: makeSelectCommonProducts(),
    layout: makeSelectLayoutType(),
});

const NoDevicesPage = (props) => {
    const {commonProducts, layout} = props;
    const localizedStrings = getLocalizedStrings();

    const isMobileLayout = layoutService.isMobileLayout(layout);

    if (commonProducts?.length) {
        return <Redirect to={routePaths.MY_DEVICES} />;
    }

    return (
        <div className={styles.Page} data-testid={elementsTesIds.SETUP_NEW_IQOS_PAGE}>
            {isMobileLayout && (
                <Container isFloatHeight>
                    <LearnMoreButton />
                    <LogoutButton isArrow className={styles.LogoutButton} />
                </Container>
            )}
            <Image src={noRegisteredDeviceImg} className={styles.ImageContainer} />
            <div className={styles.TextContainer}>
                <h2 className={styles.Title}>{localizedStrings[localizationKeys.NO_REGISTERED_DEVICES_TITLE_TEXT]}</h2>
                <p className={styles.Description}>
                    {localizedStrings[localizationKeys.NO_REGISTERED_DEVICES_DESCRIPTION_TEXT]}
                </p>
            </div>
            <SetupNewDeviceButton className={styles.NewDeviceButton} />
            {!isMobileLayout && <LogoutButton isArrow className={styles.LogoutButton} />}
        </div>
    );
};

export default connect(mapStateToProps)(NoDevicesPage);
