import * as mwFirmwareErrorCodes from '../../consts/iot/mwFirmwareErrorCodes';
import {setFWUFinished} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import log from '../logger/log';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import ScpCharacteristicClient from './scpCharacteristicClient';

const FWU_MAX_RETRY_COUNT = 3;
const FRAME_INDEX_AUTHENTICATION = 0;
const FRAME_INDEX_START_PROGRAMMING = 1;
const FRAME_INDEX_FWU_DATA_START = 2;

export default class HolderFwuClient {
    constructor(fwuPackageData) {
        this.fwuPackageData = fwuPackageData;
        this.isFwuStarted = false;
        this.currentFwuTry = 0;
        this.frameIndexEndProgramming = fwuPackageData.length - 1;
    }

    startFwUpdate = async () => {
        log.debug(`HolderFwuClient: start firmware update`);

        this.sendAuthenticationRequest();
    };

    sendAuthenticationRequest = () => {
        log.debug(`HolderFwuClient: sendAuthenticationRequest`);

        this.writeFrame(FRAME_INDEX_AUTHENTICATION);
    };

    sendStartUpgradeRequest = () => {
        log.debug(`HolderFwuClient: sendStartUpgradeRequest`);

        this.writeFrame(FRAME_INDEX_START_PROGRAMMING);
    };

    sendEndProgrammingRequest = () => {
        this.isFwuStarted = false;

        log.debug(`HolderFwuClient: sendEndProgrammingRequest`);

        this.writeFrame(this.frameIndexEndProgramming);
    };

    writeFrame = (frameIndex) => {
        const scpCharacteristicClient = new ScpCharacteristicClient();
        const frame = this.fwuPackageData[frameIndex];

        scpCharacteristicClient.addFramesToQueue({
            frames: [frame.f],
            onSuccess: (response) => this.onFrameResponse(response, frameIndex),
            //TODO: add onError
        });
    };

    onFrameResponse = async (response, frameIndex) => {
        const {fwuPackageData} = this;
        const responseFrame = response?.length ? response[0] : null;

        log.debug(`HolderFwuClient: fwu status message: ${responseFrame}`);

        const currentFrame = fwuPackageData[frameIndex];
        const {n, v} = currentFrame;
        const isResponseValid = responseFrame.toLowerCase() === v.toLowerCase();

        if (this.isFwuStarted) {
            if (isResponseValid) {
                if (n) {
                    mwIotMessageRequestService.publishFwuStatusEvent(n);
                }

                const nextFrameIsEndProgramming = frameIndex >= this.frameIndexEndProgramming - 1;

                if (nextFrameIsEndProgramming) {
                    this.sendEndProgrammingRequest();
                } else {
                    this.writeFrame(frameIndex + 1);
                }
            } else {
                mwIotMessageRequestService.publishFwuErrorEvent(null, responseFrame, true);
            }
        } else {
            if (isResponseValid) {
                if (n) {
                    log.debug(`HolderFwuClient: ${n}`);
                }

                switch (frameIndex) {
                    case FRAME_INDEX_AUTHENTICATION:
                        this.sendStartUpgradeRequest();
                        break;
                    case FRAME_INDEX_START_PROGRAMMING:
                        this.isFwuStarted = true;
                        this.writeFrame(FRAME_INDEX_FWU_DATA_START);
                        break;
                    case this.frameIndexEndProgramming:
                        dispatch(setFWUFinished());
                        mwIotMessageRequestService.publishFwuFinishEvent(true);
                        break;
                    default:
                        break;
                }
            } else {
                if (this.currentFwuTry <= FWU_MAX_RETRY_COUNT) {
                    this.isFwuStarted = false;
                    log.debug(`DeviceFwuClient: try to start FWU again: try #${this.currentFwuTry}`);
                    this.currentFwuTry = this.currentFwuTry + 1;
                    await helpers.timeout(500);
                    await this.startFwUpdate();
                } else {
                    this.onFwuFail();
                }
            }
        }
    };

    onFwuFail = () => {
        mwIotMessageRequestService.publishFwuErrorEvent(mwFirmwareErrorCodes.GENERAL, null, true);
    };
}
