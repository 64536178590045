import React from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import styles from './DeviceConnectionStatus.module.css';

const DeviceConnectionStatus = ({className, isDeviceReady, isLocked, isActivationNeeded}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={`${className ? className : ''} ica--txt-regular`}>
            {isDeviceReady
                ? localizedStrings[localizationKeys.DEVICE_STATUS_CONNECTED]
                : localizedStrings[localizationKeys.DEVICE_STATUS_DISCONNECTED]}
            <Info text={localizationKeys.DEVICE_STATUS_LOCKED} condition={isActivationNeeded ? null : isLocked} />
            <Info text={localizationKeys.YAP_DEVICE_LIST_NOT_ACTIVATED} condition={isActivationNeeded} />
        </div>
    );
};

const Info = ({text, condition}) => {
    return condition ? (
        <>
            &#160;|&#160;
            <span className={styles.ErrorInfo}>{getLocalizedStrings()[text]}</span>
        </>
    ) : null;
};

export default DeviceConnectionStatus;
