import appConfig from '../config/appConfig';
import deviceTypesService from './device/deviceTypesService';

//TODO: rename service

const isDeviceSupported = (type) => {
    const enableP1Support = appConfig.getEnableP1Support();
    const enableP4Support = appConfig.getEnableP4Support();

    return (enableP1Support && deviceTypesService.isP1(type)) || (enableP4Support && deviceTypesService.isP4(type));
};

const isIccJourneyScreensEnabled = () => appConfig.getIsICCConsumerServiceEnabled();

export default {
    isDeviceSupported,
    isIccJourneyScreensEnabled,
};
