import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import CommonLoaderScreen from '../../../components/Loader/CommonLoaderScreen';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as iotMessageTypes from '../../../consts/iot/iotMessageTypes';
import useErrorHandler from '../../../hooks/effects/useErrorHandler';
import useParentModeChanged from '../../../hooks/effects/useParentModeChanged';
import appErrorService from '../../../services/app/appErrorService';
import appRouterService from '../../../services/appRouterService';
import getDeviceMessageRequestService from '../../../services/deviceMessageRequestService';
import iccMarketService from '../../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../../state/selectors/consumer';
import {makeSelectIotMessage} from '../../../state/selectors/iotDevice';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import pairingGuidePageService from '../../PairingGuidePage/pairingGuidePageService';
import styles from '../DeviceActivationPage.module.scss';
import ActivationFailedPopup from './ActivationFailedPopup';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    iotMessage: makeSelectIotMessage(),
});

const DeviceActivationConnectedPage = (props) => {
    const [isVisibleActivationFailedPopup, setIsVisibleActivationFailedPopup] = useState(false);
    const {iotProduct, iotMessage} = props;
    const {device, deviceSerialNumber} = iotProduct;
    const {name, parent_protection_mode, codentify, type} = device;
    const [isParentModeFinished, startParentMode] = useParentModeChanged(iotMessage);
    const startActivation = () => {
        startParentMode();
        if (parent_protection_mode) {
            getDeviceMessageRequestService().publishParentProtectionDisableMessage();
        }
    };

    useEffect(() => {
        startActivation();
    }, []);

    const refreshActivationStatus = async () => {
        try {
            await iccMarketService.setConsumerProductActivate({data: codentify, throwException: true});

            const activationStatus = await iccMarketService.getConsumerProductActivationStatus({
                data: deviceSerialNumber,
                throwException: true,
            });

            if (activationStatus) {
                pairingGuidePageService.makeFinishRedirect(type);
            } else {
                setIsVisibleActivationFailedPopup(true);
            }
        } catch (e) {
            appErrorService.showGlobalErrorWithAppReset();
        }
    };

    useEffect(() => {
        if (!parent_protection_mode) {
            if (codentify) {
                refreshActivationStatus();
            }
        }
    }, [isParentModeFinished]);

    useErrorHandler({
        type: iotMessageTypes.PARENT_PROTECTION,
        handler: () => {
            setIsVisibleActivationFailedPopup(true);
        },
    });

    const onActivationTryAgainBtnClick = () => {
        setIsVisibleActivationFailedPopup(false);
        if (parent_protection_mode) {
            startActivation();
        } else {
            refreshActivationStatus();
        }
    };

    const onActivationFailedPopupCancelBtnClick = () => {
        appRouterService.forwardToMyDevicesPage();
    };

    const localizedStrings = getLocalizedStrings();
    const header = localizedStrings.formatString(localizedStrings[localizationKeys.YAP_ACTIVATING_DEVICE], name);
    const description =
        localizedStrings[localizationKeys.YAP_ACTIVATION_REGISTRATION_STATE_ACTIVATION_FLOW_PROGRESS_TEXT];

    return (
        <div className={styles.CommonLoaderContainer}>
            <CommonLoaderScreen
                description={description}
                header={header}
                headerTestId={elementsTesIds.ACTIVATION_FLOW_ACTIVATING_TITLE}
                iotDevice={iotProduct}
                isBig
            />
            {isVisibleActivationFailedPopup && (
                <ActivationFailedPopup
                    onTryAgain={onActivationTryAgainBtnClick}
                    onClose={onActivationFailedPopupCancelBtnClick}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(DeviceActivationConnectedPage);
