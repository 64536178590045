import stringFormatService from '../../services/stringFormatService';
import {config} from '../config';

const getBaseName = () => config.data.app.baseName;

export default {
    getAppVersion: () => config.data.app.appVersion,
    getAssetsPath: () => config.data.app.assetsPath,
    getBackendType: () => config.data.app.backendType,
    getBaseNameFormatted: () => stringFormatService.getLanguageFormattedString(getBaseName()),
    getDeviceListOrder: () => config.data.app.deviceListOrder,
    getEnableP1Support: () => config.data.app.enableP1Support,
    getEnableP4Support: () => config.data.app.enableP4Support,
    getEnv: () => config.data.app.env,
    getExternalConfigPath: () => config.data.app.externalConfigPath,
    getIsAccessProtected: () => config.data.app.isAccessProtected,
    getIsDeviceDiagnosticEnabled: () => config.data.app.isDeviceDiagnosticEnabled,
    getIsDeviceSettingAvailable: () => config.data.app.isDeviceSettingAvailable,
    getIsFWAvailableP1: () => config.data.app.isFWAvailableP1,
    getIsFWAvailableP4: () => config.data.app.isFWAvailableP4,
    getIsRememberMeEnabled: () => config.data.app.isRememberMeEnabled,
    getIsTestLoginEnabled: () => config.data.app.isTestLoginEnabled,
    getIsUnregistrationAvailable: () => config.data.app.isUnregistrationAvailable,
    getLegalDisclaimePdfName: () => config.data.app.legalDisclaimePdfName,
    getPublicUrl: () => config.data.app.publicUrl,
    getTipsAndTricksAllowedSteps: () => config.data.app.tipsAndTricksAllowedSteps,
    getWelcomePageImagePath: () => config.data.app.welcomePageImagePath,
    getYapMode: () => config.data.app.yapMode,
};
