import classNames from 'classnames';
import React from 'react';

import styles from './ButtonArrowChevronSVG.module.scss';

const ButtonArrowChevronSVG = ({className}) => {
    const classNameContainer = classNames(styles.ArrowContainer, className);

    return (
        <div className={classNameContainer}>
            <ArrowChevronSVGIcon />
        </div>
    );
};

const ArrowChevronSVGIcon = () => {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8' className={styles.Arrow}>
            <path fill='none' stroke='#666' d='M1 1l5 5 5-5' />
        </svg>
    );
};

export default ButtonArrowChevronSVG;
