import {hideAllLoaders} from '../state/ducks/global';
import {clearIotDevice} from '../state/ducks/iotDevice/actions';
import {setMessage} from '../state/ducks/iotDevice/actions';
import {clearMwDeviceInternalId} from '../state/ducks/mwDevice';
import {
    setYapActivationInProgress,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../state/ducks/yapEncrypted';
import {dispatch} from '../state/store';
import backendService from './app/backendService';
import appRouterService from './appRouterService';
import cmClientService from './communicationLayer/cmClientService';
import IqosServiceClient from './device/iqosServiceClient';
import gamWebSocketService from './gam/gamWebSocketService';
import log from './logger/log';
import iotService from './scpCloud/iotService';
import uiIotMessageResponseChecker from './scpCloud/uiIotMessageResponseChecker';
import uamWebSocketService from './uam/uamWebSocketService';

const onAvailabilityChanged = (e) => {
    const isEnabled = e.target.value;

    log.info(`iqosBleService: onAvailabilityChanged, isEnabled: ${isEnabled}`);

    if (!isEnabled) {
        appRouterService.forwardToMyDevicesPage();
        //IA: exactly order that is needed
        //    first forward
        //    than clearDevice
        disconnectAndClearState();
        dispatch(hideAllLoaders());
    }
};

const addBleAvailabilityListener = () => {
    if (navigator.bluetooth.removeEventListener && navigator.bluetooth.addEventListener) {
        navigator.bluetooth.removeEventListener('availabilitychanged', onAvailabilityChanged);
        navigator.bluetooth.addEventListener('availabilitychanged', onAvailabilityChanged);
    }
};

const disconnectAndClearState = () => {
    const iqosService = new IqosServiceClient();

    iqosService.disconnect();
    iotService.disconnectIoT();
    uiIotMessageResponseChecker.clearResponsesTimeouts();

    const isGamBackend = backendService.isGamBackend();
    if (isGamBackend) {
        disconnectAndClearGamState();
    } else {
        disconnectAndClearUamState();
    }

    dispatch(clearMwDeviceInternalId());
    dispatch(clearIotDevice());
    dispatch(setMessage(null));
};

const disconnectAndClearUamState = () => {
    uamWebSocketService.disconnectUamWebSocket();
    cmClientService.disconnectCmClient();
    dispatch(setYapActivationInProgress(false));
    dispatch(setYapSyncInProgress(false));
};

const disconnectAndClearGamState = () => {
    gamWebSocketService.disconnectGamWebSocket();
    cmClientService.disconnectCmClient();
    dispatch(setYapActivationInProgress(false));
    dispatch(setYapUpdateSettingsInProgress(false));
    dispatch(setYapSyncInProgress(false));
};

const disconnectAndForwardToMyDevicesPage = (isDeviceDisconnected) => {
    if (!isDeviceDisconnected) {
        disconnectAndClearState();
    }

    appRouterService.forwardToMyDevicesPage();
};

const onPairingError = () => disconnectAndClearState();

export default {
    addBleAvailabilityListener,
    disconnectAndClearState,
    disconnectAndForwardToMyDevicesPage,
    onPairingError,
};
