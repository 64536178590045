import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import appConfig from '../../config/appConfig';
import analyticsService from '../../services/analyticsService';
import appService from '../../services/appService';
import log from '../../services/logger/log';
import userDeviceService from '../../services/userDeviceService';
import {hideLoader, setIsAppLoaded} from '../../state/ducks/global';
import {makeIsAppLoading} from '../../state/selectors/global';

const mapStateToProps = createStructuredSelector({
    isAppLoading: makeIsAppLoading(),
});

const App = (props) => {
    const {isAppLoading, children, dispatch} = props;
    const onInit = async () => {
        const isBleSupported = userDeviceService.isBluetoothSupported();
        const isBluefyBrowser = userDeviceService.isBluefyBrowser();

        if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
            await appService.initializeStartActions();
        } else {
            if (appConfig.getIsMarketInitialized()) {
                await appService.initializeStartActions();
            } else {
                await appService.initializeUserSessionActions();
            }
        }

        analyticsService.pushAppLoadedEvent();
        analyticsService.pushBrowserCompatibilityEvent(isBleSupported, isBluefyBrowser);

        dispatch(setIsAppLoaded());

        log.info(`App: app is started successfully, isBleSupported: ${isBleSupported})}`);

        if (!isBleSupported) {
            dispatch(hideLoader());
        }
    };

    useEffect(() => {
        onInit();
    }, []);

    return isAppLoading ? (
        <div>
            <AppLoader isVisible loaderText={''} />
        </div>
    ) : (
        children || null
    );
};

export default connect(mapStateToProps)(App);
