import React from 'react';

import standaloneConfig from '../../../../config/standaloneConfig/standaloneConfig';
import SelectMarketSettingsPopup from '../SelectMarketSettingsPopup/SelectMarketSettingsPopup';

const ProvinceSelectTemplate = (props) => {
    const {transmittedConfig} = props;
    const {countryCodeConfig} = standaloneConfig || {};
    const standaloneLocalization = countryCodeConfig.localization.en;
    const provinceConfig = transmittedConfig.provinces;

    const provinceCodeOnItemSelect = 'provinceCode';
    const provincePopupTitleText = standaloneLocalization.SELECTOR_PROVINCE_HEADER_TEXT;

    return (
        <SelectMarketSettingsPopup
            {...props}
            transmittedConfig={provinceConfig}
            codeOnItemSelect={provinceCodeOnItemSelect}
            popupTitleText={provincePopupTitleText}
        />
    );
};

export default ProvinceSelectTemplate;
