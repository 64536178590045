import * as localizationKeys from '../app/localizationKeys';

export const iotErrorKeys = {
    0: localizationKeys.CHARGER_ERROR_HOLDER_BATTERY_CHARGER_DEFECT,
    1: localizationKeys.CHARGER_ERROR_CHARGER_BATTERY_CHARGING_TIMEOUT_ERROR_MESSAGE,
    2: localizationKeys.CHARGER_ERROR_CHARGER_BATTERY_CHARGER_DEFECT,
    3: localizationKeys.CHARGER_ERROR_SELF_TEST_FAILURE_2_4,
    4: localizationKeys.CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_ERROR_MESSAGE,
    5: localizationKeys.HOLDER_ERROR_SYSTEM_DEFECT,
    6: localizationKeys.HOLDER_ERROR_HOLDER_BATTERY_CHARGING_TIMEOUT,
    7: localizationKeys.HOLDER_ERROR_HOLDER_BATTERY_TEMP_OUTSIDE_RANGE_ERROR_MESSAGE,
    8: localizationKeys.HOLDER_ERROR_HOLDER_IDENTIFICATION_FAILURE,
    9: localizationKeys.HOLDER_WARNING_HOLDER_COMMUNICATION_PROBLEM,
    10: localizationKeys.HOLDER_WARNING_NULL_HOLDER_CHARGING_CURRENT,
    11: localizationKeys.HEATING_SYSTEM_FAULT_HW_SAFETY_TRIGGERED,
    12: localizationKeys.HEATING_SYSTEM_FAULT_UNEXP_VOLTAGE_SW_OFF,
    13: localizationKeys.HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_MINUS_SW_ON,
    14: localizationKeys.HEATING_SYSTEM_FAULT_NO_VOLTAGE_VH_PLUS_SW_ON,
    15: localizationKeys.HW_ISSUES_PWR_DIST_FAILURE,
    16: localizationKeys.HEATING_SYSTEM_FAULT_CANT_START_HW_SAFETY,
    17: localizationKeys.HEATING_SYSTEM_FAULT_POWER_DIST_FAILURE,
    18: localizationKeys.INTERNAL_ERR_ADC_COMP_TIMEOUT,
    19: localizationKeys.ENV_LOW_MCU_VOLTAGE_ERROR_MESSAGE,
    20: localizationKeys.SYSTEM_FAULT_EXT_VOLTAGE_REF_FAILURE_ERROR_MESSAGE,
    21: localizationKeys.SYSTEM_FAULT_HEATER_BROKEN_OR_ABSENT,
    22: localizationKeys.SYSTEM_FAULT_UNEXP_HEATER_CURRENT_SW_ON,
    23: localizationKeys.SYSTEM_FAULT_INV_MANUF_INFO_BLOCK_CRC,
    24: localizationKeys.HEATING_REGULATION_HEATER_CURRENT_LOW,
    25: localizationKeys.HEATING_REGULATION_HEATER_CURRENT_HIGH,
    26: localizationKeys.HEATING_REGULATION_HEATER_TEMP_ABOVE_LIMIT_ERROR_MESSAGE,
    27: localizationKeys.HEATING_REGULATION_HEATER_POWER_OUT_RANGE_ERROR_MESSAGE,
    28: localizationKeys.SYSTEM_FAULT_RTC_OSC_FAULT,
    29: localizationKeys.SYSTEM_FAULT_TOO_MUCH_UNEXP_DUTY_CYCLE_VAR,
    30: localizationKeys.ENV_HIGH_MCU_VOLTAGE,
    31: localizationKeys.CHARGER_ERROR_BANK_CHRG_DEFECT,
    32: localizationKeys.CHARGER_ERROR_LED_DRIVER_FAILURE,
    33: localizationKeys.CHARGER_ERROR_DOOR_SWITCH_FAILURE,
    34: localizationKeys.CHARGER_ERROR_CHARGE_TEMP_OUT_OF_RANGE_CHRGN_ERROR_MESSAGE,
    35: localizationKeys.HOLDER_ERROR_HLDR_CHRG_TIMEOUT,
    36: localizationKeys.HOLDER_ERROR_HLDR_TEMPERATURE_ERROR_MESSAGE,
    37: localizationKeys.HOLDER_ERROR_HLDR_CHARGER_DEFECT,
    38: localizationKeys.HOLDER_ERROR_HLDR_CHRG_ERROR,
    39: localizationKeys.HOLDER_WARNING_BAD_COMM,
    40: localizationKeys.HOLDER_WARNING_END_OF_LIFE,
    41: localizationKeys.HOLDER_WARNING_AUTH_FAILURE,
    42: localizationKeys.JTAG_RDP_LEVEL_ERROR,
    43: localizationKeys.EEPROM_INT_FAILURE,
    44: localizationKeys.S1_PULL_UP_RES_TEST_FAILURE,
    45: localizationKeys.INVALID_CALIBRATION_DATA_CRC,
    46: localizationKeys.EXT_VOLTAGE_REF_FAILURE,
    47: localizationKeys.LED_DRV_LM3535_I2C_COMM_ERROR,
    48: localizationKeys.LED_DRV_VLED_EN_PULL_DOWN_TEST_FAILURE,
    49: localizationKeys.VIBRATOR_VMG_PULL_DOWN_TEST_FAILURE,
    50: localizationKeys.BATTERY_CHARGER_BQ24250_I2C_COMM_ERROR,
    51: localizationKeys.BQ24250_CHG_EN_PULL_DOWN_RES_TEST_FAILURE,
    52: localizationKeys.HEATING_HCM_SWITCHES_ERROR,
    53: localizationKeys.RES_CHAIN_FAULT_DETECTED,
    54: localizationKeys.HEATER_RESISTANCE_FAULT_DETECTED,
    55: localizationKeys.LOW_SIDE_FAULT_DETECTED,
    56: localizationKeys.HCM_HMG_PULL_DOWN_RES_TEST_FAILURE,
    57: localizationKeys.BATTERY_VOLTAGE_TOO_LOW_ERROR_MESSAGE,
    58: localizationKeys.AMBIENT_TEMPERATURE_TOO_LOW_ERROR_MESSAGE,
    59: localizationKeys.AMBIENT_TEMPERATURE_TOO_HIGH_ERROR_MESSAGE,
    60: localizationKeys.BATTERY_CHARGER_FAULT,
    61: localizationKeys.BATTERY_CHARGING_DURATION_EXCEEDED,
    62: localizationKeys.HEATING_DURATION_EXCEEDED,
    63: localizationKeys.DUTY_CYCLE_TOO_LOW_WHILE_HEATING_ERROR_MESSAGE,
    64: localizationKeys.ERROR_HEATER_PMOS_OPEN,
    65: localizationKeys.DEVICE_NOT_IDENTIFIABLE,
    66: localizationKeys.HOLDER_CANNOT_COMMUNICATE_WITH_FUEL_GAUGE,
    67: localizationKeys.ERR_I2C_BUS,
    68: localizationKeys.ERR_LED_DRV,
    69: localizationKeys.ERR_CHRG_DRV_I2C,
    70: localizationKeys.ERR_EEPROM_DRV,
    71: localizationKeys.ERR_FLASH_DRV,
    72: localizationKeys.ERR_BAT_TEMP_LOW_ERROR_MESSAGE,
    73: localizationKeys.ERR_BAT_TEMP_HIGH_ERROR_MESSAGE,
    74: localizationKeys.ERR_CHRG_INIT,
    75: localizationKeys.ERR_CHRG_CONFIG,
    76: localizationKeys.ERR_CHRG_FAILURE,
    77: localizationKeys.ERR_SYS_VOLTAGE_ERROR_MESSAGE,
    78: localizationKeys.ERR_FLASH_INIT,
    79: localizationKeys.ERR_BLE_INIT,
    80: localizationKeys.ERROR_OVERHEATING_ERROR_MESSAGE,
    81: localizationKeys.ERROR_LOW_TEMP_ERROR_MESSAGE,
    82: localizationKeys.ERROR_HIGH_TEMP_ERROR_MESSAGE,
    83: localizationKeys.ERROR_HCM_SWITCHES_FAILED,
    84: localizationKeys.HEATING_TIMEOUT_ERROR,
    85: localizationKeys.ERR_POST_FAIL,
};
