import React from 'react';

import styles from './Container.module.scss';

const Container = ({className, children, isFloatHeight}) => {
    return (
        <section className={`${styles.Container} ${className ? className : ''} ${isFloatHeight ? styles.Float : ''}`}>
            {children}
        </section>
    );
};

export default Container;
