import React from 'react';

import styles from './DeviceImage.module.css';
import ProductImage from './ProductImage';

const DeviceImage = (props) => {
    const {className, imgClassName, device, holder} = props;

    return (
        <div className={`${styles.Container} ${className}`}>
            {device && (
                <ProductImage
                    key={device.mediaId}
                    mediaId={device.mediaId}
                    type={device.type}
                    imgClassName={imgClassName}
                />
            )}
            {device?.holder_connected && holder && holder.type && (
                <ProductImage
                    key={holder.mediaId}
                    mediaId={holder.mediaId}
                    type={holder.type}
                    imgClassName={imgClassName}
                />
            )}
        </div>
    );
};

export default DeviceImage;
