import React from 'react';

import ProgressLoader from '../../../../components/ProgressLoader/ProgressLoader';
import DeviceImage from '../../../DeviceImage/DeviceImage';
import DeviceFWURemainingTime from '../DeviceFWURemainingTime';
import styles from './DeviceFWUProgress.module.scss';

const DeviceFWUProgress = (props) => {
    const {iotDevice, isFirmwareFinished, isRemainingTimeVisible} = props;
    const {device, holder} = iotDevice;
    const FULL_PROGRESS = 100;
    const progress = isFirmwareFinished ? FULL_PROGRESS : device?.firmware?.progress;

    return (
        <>
            <div className={styles.FWUProgressContainer}>
                <ProgressLoader progress={progress} className={styles.Loader} />
                <DeviceImage device={device} holder={holder} className={styles.ImageDeviceContainer} />
            </div>
            {isRemainingTimeVisible && <DeviceFWURemainingTime progress={progress} />}
        </>
    );
};

export default DeviceFWUProgress;
