import {LOADER_RESET} from '../../consts/app/loaderNames';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {setIsReset} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import getDeviceMessageRequestService from '../deviceMessageRequestService';

const resetDevice = (loaderText) => {
    dispatch(showLoader({name: LOADER_RESET, text: loaderText}));

    getDeviceMessageRequestService().publishResetMessage();
    dispatch(setIsReset(true));
};

const onResetDevice = () => {
    dispatch(setIsReset(false));
    dispatch(hideLoader(LOADER_RESET));
};

export default {
    onResetDevice,
    resetDevice,
};
