import iotMessageService from '../../../services/iotMessageService';
import useLostDeviceConnection from './useLostDeviceConnection';

const useDeviceDisconnected = (iotMessage, handler) => {
    const isDisconnected = iotMessageService.isDeviceDisconnected(iotMessage);

    useLostDeviceConnection(isDisconnected, handler);
};

export default useDeviceDisconnected;
