import React, {useEffect, useState} from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupError from '../../../../components/Popup/PopupError';
import PopupInfo from '../../../../components/Popup/PopupInfo';
import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import * as iccProductsStatusMessageTypes from '../../../../consts/icc/iccProductsStatusMessageTypes';
import * as iccProductStatusTypes from '../../../../consts/icc/iccProductStatusTypes';
import * as iccSsoDeeplinkTemplates from '../../../../consts/icc/iccSsoDeeplinkTemplates';
import analyticsService from '../../../../services/analyticsService';
import appRouterService from '../../../../services/appRouterService';
import deviceTypesService from '../../../../services/device/deviceTypesService';
import iccSsoService from '../../../../services/icc/iccSsoService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import log from '../../../../services/logger/log';
import productService from '../../../../services/productService';
import routerService from '../../../../services/routerService';
import uamClientService from '../../../../services/uam/uamClientService';
import {hideLoader, setNotificationSuccess, showLoader} from '../../../../state/ducks/global';
import {removeProductToChangeRegistrationStatus} from '../../../../state/ducks/productSettings';
import styles from './ProductRegistartionFailedPopup.module.scss';

const USER_REGISTRATION_ATTEMPTS = 2;
const REDIRECT_ROUTER_NAME = 'REDIRECT_ROUTER_NAME';

const ProductRegistrationFailedPopup = (props) => {
    const {dispatch, productStatus, stopScheduler, deviceName, iotDevice, isHolder} = props;
    const {deviceSerialNumber, codentify, status, statusMessage} = productStatus;
    const [failedRegistrationPopupData, setFailedRegistrationPopupData] = useState({
        statusError: '',
        isVisible: false,
    });
    const currentProductRegistrationAttempts = productService.getProductRegistrationAttempts();
    const {isVisible, statusError} = failedRegistrationPopupData;
    const iotDeviceType = iotDevice.device.type;
    const isP4 = deviceTypesService.isP4(iotDeviceType);
    const [isInfoError, setIsInfoError] = useState(false);
    const severalAttemptsMade = currentProductRegistrationAttempts > USER_REGISTRATION_ATTEMPTS;
    const isAdditionalButtonVisible = severalAttemptsMade && statusError === iccProductsStatusMessageTypes.NOT_FOUND;
    const localizedStrings = getLocalizedStrings();

    const setDeviceRegistered = async () => {
        if (!isHolder) {
            dispatch(setNotificationSuccess(localizedStrings[localizationKeys.DEVICE_REGISTER_SUCCESSFUL_TEXT]));
            uamClientService.getAssets();
        }

        dispatch(removeProductToChangeRegistrationStatus({isP4, deviceSerialNumber, codentify}));
    };

    useEffect(() => {
        switch (status) {
            case iccProductStatusTypes.REGISTERED:
                log.info('End registration process. Device is successfully registered.');
                setDeviceRegistered();
                break;
            case iccProductStatusTypes.FAILED:
                log.info('End registration process. Device registration is failed.');
                const newStatusError = getStatusMessage(statusMessage);

                analyticsService.pushRegistrationFailedEvent(newStatusError);
                stopScheduler();

                setFailedRegistrationPopupData({
                    statusError: newStatusError,
                    isVisible: true,
                });

                if (isP4) {
                    switch (newStatusError) {
                        case iccProductsStatusMessageTypes.DEVICE_ALREADY_OWNED:
                            setIsInfoError(true);
                            break;
                        case iccProductsStatusMessageTypes.NOT_FOUND:
                            if (currentProductRegistrationAttempts <= USER_REGISTRATION_ATTEMPTS) {
                                productService.setProductRegistrationAttempts(currentProductRegistrationAttempts + 1);
                            }
                            break;
                        default:
                            break;
                    }
                }
                break;
            default:
                break;
        }
    }, [status]);

    const onFailedRegistrationPopupClick = () => {
        dispatch(removeProductToChangeRegistrationStatus({isP4, deviceSerialNumber, codentify}));
        setFailedRegistrationPopupData({isVisible: false});
    };

    const getPopupErrorButtonData = () => {
        switch (statusError) {
            case iccProductsStatusMessageTypes.EXTERNAL_SYSTEM_UNAVAILABLE:
            case iccProductsStatusMessageTypes.DEVICE_REMOVED_FROM_MASTER_DB:
                return {
                    key: localizationKeys.REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT,
                    onClick: async () => {
                        await productService.registerConsumerProduct(iotDevice, deviceName);

                        setFailedRegistrationPopupData({isVisible: false});
                    },
                };
            case iccProductsStatusMessageTypes.NOT_FOUND:
                if (isP4) {
                    return {
                        key: severalAttemptsMade
                            ? localizationKeys.REGISTRATION_FAIL_TRY_AGAIN_ACTIVATION_BUTTON_TEXT
                            : localizationKeys.REGISTRATION_FAILED_TRY_AGAIN_BUTTON_TEXT,
                        onClick: async () => {
                            await productService.registerConsumerProduct(iotDevice, deviceName);

                            setFailedRegistrationPopupData({isVisible: false});
                        },
                    };
                } else {
                    return {
                        key: localizationKeys.REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT,
                        onClick: () => {
                            onFailedRegistrationPopupClick();
                        },
                    };
                }
            case iccProductsStatusMessageTypes.DEVICE_ALREADY_OWNED:
                return {
                    key: localizationKeys.REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT,
                    onClick: () => {
                        onFailedRegistrationPopupClick();
                        if (isP4) {
                            appRouterService.forwardToMyDevicesPage();
                        }
                    },
                };
            case iccProductsStatusMessageTypes.INVALID_DEVICE_STATUS:
                return {
                    key: localizationKeys.REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT,
                    onClick: () => {
                        if (isP4) {
                            onFailedRegistrationPopupClick();
                            appRouterService.forwardToMyDevicesPage();
                        } else {
                            onFailedRegistrationPopupClick();
                        }
                    },
                };
            case iccProductsStatusMessageTypes.INVALID_CODENTIFYID:
                if (isP4) {
                    return {
                        key: localizationKeys.REGISTRATION_FAILED_WEB_BUTTON_TEXT,
                        onClick: async () => {
                            dispatch(showLoader(REDIRECT_ROUTER_NAME));

                            const ssoLink = await iccSsoService.getSsoLink(
                                iccSsoDeeplinkTemplates.ICC_SSO_DSC_REGISTER_DEEPLINK_TEMPLATE
                            );

                            const redirectUrl = ssoLink ? ssoLink : appConfig.getDcsRegisterUrlFormatted();
                            onFailedRegistrationPopupClick();
                            routerService.redirect(redirectUrl);

                            dispatch(hideLoader(REDIRECT_ROUTER_NAME));
                        },
                    };
                } else {
                    return {
                        key: localizationKeys.REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT,
                        onClick: onFailedRegistrationPopupClick,
                    };
                }
            default:
                return {
                    key: localizationKeys.REGISTRATION_FAILED_GOT_IT_BUTTON_TEXT,
                    onClick: () => {
                        if (isP4) {
                            onFailedRegistrationPopupClick();
                            appRouterService.forwardToMyDevicesPage();
                        } else {
                            onFailedRegistrationPopupClick();
                        }
                    },
                };
        }
    };

    const PopupComponent = isInfoError ? PopupInfo : PopupError;

    const onSupportButtonClick = async () => {
        dispatch(showLoader(REDIRECT_ROUTER_NAME));

        const ssoLink = await iccSsoService.getSsoLink(iccSsoDeeplinkTemplates.ICC_SSO_DSC_SUPPORT_DEEPLINK_TEMPLATE);

        const redirectUrl = ssoLink ? ssoLink : appConfig.getDcsSupportUrlFormatted();
        routerService.redirect(redirectUrl);

        dispatch(hideLoader(REDIRECT_ROUTER_NAME));
    };

    return (
        isVisible &&
        !isHolder && (
            <PopupComponent>
                <h2 className={styles.Title}>
                    {localizedStrings.formatString(localizedStrings[statusError + '_TITLE'], deviceName)}
                </h2>
                <p className={`${styles.Description} ica--txt-regular`}>
                    {localizedStrings.formatString(localizedStrings[statusError + '_MESSAGE'], deviceName)}
                </p>
                <ButtonDark className={styles.Button} onClick={getPopupErrorButtonData()?.onClick} showArrow>
                    {localizedStrings[getPopupErrorButtonData()?.key]}
                </ButtonDark>
                {isAdditionalButtonVisible && (
                    <Button onClick={onSupportButtonClick} showArrow>
                        {localizedStrings[localizationKeys.REGISTRATION_FAILED_CONTACT_BUTTON_TEXT]}
                    </Button>
                )}
            </PopupComponent>
        )
    );
};

export default ProductRegistrationFailedPopup;

const getStatusMessage = (statusMessage) => {
    switch (statusMessage) {
        case iccProductsStatusMessageTypes.EXTERNAL_SYSTEM_UNAVAILABLE:
        case iccProductsStatusMessageTypes.DEVICE_REMOVED_FROM_MASTER_DB:
        case iccProductsStatusMessageTypes.DEVICE_ALREADY_OWNED:
        case iccProductsStatusMessageTypes.INVALID_DEVICE_STATUS:
        case iccProductsStatusMessageTypes.INVALID_CODENTIFYID:
            return statusMessage;
        case iccProductsStatusMessageTypes.NOT_FOUND_404:
            return iccProductsStatusMessageTypes.NOT_FOUND;
        default:
            return iccProductsStatusMessageTypes.INVALID_DEVICE_STATUS;
    }
};
