import React, {useEffect, useState} from 'react';

import iconLedModeMoon from '../../../../../../../assets/images/settings/led-mode-moon-icon.png';
import iconLedModeMoon2 from '../../../../../../../assets/images/settings/led-mode-moon-icon@2x.png';
import iconLedModeMoon3 from '../../../../../../../assets/images/settings/led-mode-moon-icon@3x.png';
import iconLedModeSun from '../../../../../../../assets/images/settings/led-mode-sun-icon.png';
import iconLedModeSun2 from '../../../../../../../assets/images/settings/led-mode-sun-icon@2x.png';
import iconLedModeSun3 from '../../../../../../../assets/images/settings/led-mode-sun-icon@3x.png';
import iconLedMoon from '../../../../../../../assets/images/settings/led-moon-icon.png';
import iconLedMoon2 from '../../../../../../../assets/images/settings/led-moon-icon@2x.png';
import iconLedMoon3 from '../../../../../../../assets/images/settings/led-moon-icon@3x.png';
import iconLedSun from '../../../../../../../assets/images/settings/led-sun-icon.png';
import iconLedSun2 from '../../../../../../../assets/images/settings/led-sun-icon@2x.png';
import iconLedSun3 from '../../../../../../../assets/images/settings/led-sun-icon@3x.png';
import Button from '../../../../../../components/Button/Button';
import Image from '../../../../../../components/Image/Image';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as ledModeTypes from '../../../../../../consts/iot/ledModeTypes';
import useDidUpdate from '../../../../../../hooks/effects/useDidUpdate';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import backendService from '../../../../../../services/app/backendService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import deviceConfigService from '../../../../../../services/iotDevice/iotDeviceConfigService';
import iotMessageService from '../../../../../../services/iotMessageService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import {setNotificationInfo, showLoader} from '../../../../../../state/ducks/global';
import elementsTesIds from '../../../../../../test/consts/elementsTesIds';
import DeviceModePopup from '../../../DeviceModePopup/DeviceModePopup';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import styles from '../DeviceModeComponents/DeviceMode.module.scss';

const LOADER_NAME = 'LED_MODE_PAGE_LOADER';

const DeviceLedMode = (props) => {
    const {
        dispatch,
        iotDevice,
        isConnected,
        disabled,
        iotMessage,
        onDisableClick,
        layout,
        testId,
        isYapUpdateSettingsInProgress,
    } = props;
    const {ledData, device_global_features_config} = iotDevice;
    const [isVisibleLedPopup, setIsVisibleLedPopup] = useState(false);
    const isDeviceLedModeEnabled = deviceConfigService.isDeviceLedModeEnabled(iotDevice);
    const isHolderLedModeEnabled = deviceConfigService.isHolderLedModeEnabled(iotDevice);
    const deviceIntensity = ledData?.device?.intensity;
    const holderIntensity = ledData?.holder?.intensity;
    const intensity = deviceIntensity ? deviceIntensity : holderIntensity;
    const localizedStrings = getLocalizedStrings();
    const isGamBackend = backendService.isGamBackend();

    const isLedSettingAvailable = () => {
        if (!isDeviceLedModeEnabled && !isHolderLedModeEnabled) {
            dispatch(
                setNotificationInfo(
                    localizedStrings[localizationKeys.DEVICE_SETTINGS_UPDATE_DEVICE_WITH_HOLDER_NOTIFICATION_TEXT]
                )
            );
            return false;
        }
        return true;
    };

    useDidUpdate(() => {
        if (isVisibleLedPopup && !isLedSettingAvailable()) {
            setIsVisibleLedPopup(false);
        }
    }, [isDeviceLedModeEnabled, isHolderLedModeEnabled]);

    useEffect(() => {
        if (!isConnected && !isYapUpdateSettingsInProgress) {
            setIsVisibleLedPopup(false);
        }
    }, [isConnected, isYapUpdateSettingsInProgress]);

    useEffect(() => {
        if (!intensity && isConnected && (isDeviceLedModeEnabled || isHolderLedModeEnabled)) {
            if (isGamBackend) {
                //todo: uncomment after device info implementation
                //appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_TEMPORARY_ERROR);
            } else {
                dispatch(showLoader({name: LOADER_NAME}));
                getDeviceMessageRequestService().publishGetLedSettingsMessage();
            }
        }
    }, [isConnected, isDeviceLedModeEnabled, isHolderLedModeEnabled]);

    const isLedProcessFinished = iotMessageService.isLedProcessFinished(iotMessage);
    const ledModeRightBtnText = localizedStrings[localizationKeys.LED_MODE_RIGHT_BUTTON_TEXT];
    const ledModeLeftBtnText = localizedStrings[localizationKeys.LED_MODE_LEFT_BUTTON_TEXT];

    useHideLoader(isLedProcessFinished || !isConnected, LOADER_NAME);

    let ledModeValue = '';
    let isNightMode = false;
    let isDayMode = false;
    let imgUrls = [];
    let popupImgUrls = [];

    if (isDeviceLedModeEnabled || isHolderLedModeEnabled) {
        if (intensity) {
            switch (intensity) {
                case ledModeTypes.DAY_MODE: {
                    isDayMode = true;
                    ledModeValue = ledModeRightBtnText;
                    imgUrls = [iconLedSun, iconLedSun2, iconLedSun3];
                    popupImgUrls = [iconLedModeSun, iconLedModeSun2, iconLedModeSun3];
                    break;
                }
                case ledModeTypes.NIGHT_MODE: {
                    isNightMode = true;
                    ledModeValue = ledModeLeftBtnText;
                    imgUrls = [iconLedMoon, iconLedMoon2, iconLedMoon3];
                    popupImgUrls = [iconLedModeMoon, iconLedModeMoon2, iconLedModeMoon3];
                    break;
                }
                default:
                    break;
            }
        }
    } else {
        ledModeValue = ledModeRightBtnText;
        imgUrls = [iconLedSun, iconLedSun2, iconLedSun3];
    }

    const onVisibleLedPopup = () => {
        if (isVisibleLedPopup || isLedSettingAvailable()) {
            setIsVisibleLedPopup(!isVisibleLedPopup);
        }
    };

    const onLedSettingClick = (newIntensity) => {
        if (!isGamBackend) {
            dispatch(showLoader({name: LOADER_NAME}));
        }
        if (device_global_features_config?.is_holder_check && !isHolderLedModeEnabled) {
            dispatch(setNotificationInfo(localizedStrings[localizationKeys.LED_HOLDER_NOT_CHANGED_NOTIFICATION_TEXT]));
        } else if (!isDeviceLedModeEnabled) {
            dispatch(setNotificationInfo(localizedStrings[localizationKeys.LED_CHARGER_NOT_CHANGED_NOTIFICATION_TEXT]));
        }
        analyticsService.pushUsageSettingsLedModeEvent();
        getDeviceMessageRequestService().publishSetLedSettingsMessage(
            newIntensity,
            isDeviceLedModeEnabled,
            isHolderLedModeEnabled
        );
    };

    return (
        <>
            <DeviceSettingsItem
                disabled={disabled}
                imgUrls={imgUrls}
                onClick={onVisibleLedPopup}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                {localizedStrings.formatString(
                    localizedStrings[localizationKeys.DEVICE_SETTINGS_LED_MODE],
                    ledModeValue
                )}
            </DeviceSettingsItem>
            {isVisibleLedPopup && (isConnected || isYapUpdateSettingsInProgress) && (
                <DeviceModePopup
                    onClose={() => setIsVisibleLedPopup(false)}
                    isClosingBySwipeEnabled
                    imgUrls={popupImgUrls}
                    imgAlt='led-mode-icon'
                    imgClassName={`${styles.ImageLedHeaderIcon} ${styles.CenterElement}`}
                    title={localizedStrings[localizationKeys.LED_MODE_TITLE]}
                    subTitle={localizedStrings[localizationKeys.LED_MODE_DESCRIPTION]}
                    layout={layout}
                    testId={elementsTesIds.LED_MODE_POPUP}
                >
                    <div className={`${styles.ButtonContainer} ${styles.LedMode}`}>
                        <LedButton
                            imageSrc={[iconLedMoon, iconLedMoon2, iconLedMoon3]}
                            isActive={isNightMode}
                            onClick={() => onLedSettingClick(ledModeTypes.NIGHT_MODE)}
                            testId={elementsTesIds.LED_POPUP_BTN_50}
                            text={ledModeLeftBtnText}
                        />
                        <LedButton
                            imageSrc={[iconLedSun, iconLedSun2, iconLedSun3]}
                            isActive={isDayMode}
                            onClick={() => onLedSettingClick(ledModeTypes.DAY_MODE)}
                            testId={elementsTesIds.LED_POPUP_BTN_100}
                            text={ledModeRightBtnText}
                        />
                    </div>
                </DeviceModePopup>
            )}
        </>
    );
};

export default DeviceLedMode;

const LedButton = ({text, onClick, isActive, imageSrc, testId}) => {
    return (
        <Button
            className={`${styles.LedModeButton} ${isActive ? styles.Active : ''}`}
            onClick={isActive ? undefined : onClick}
            testId={testId}
        >
            <div className={styles.ShowLedProperty}>
                <span className='ica--txt-regular'>{text}</span>
                <Image src={imageSrc} alt={text} className={styles.ImageLedDayIcon} />
            </div>
        </Button>
    );
};
