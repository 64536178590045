import * as bleUuidTypes from '../../consts/ble/bleUuidTypes';
import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import BLEClient from './bleClient';

export default class IqosBLEClient extends BLEClient {
    constructor(options) {
        const bleClientOptions = options
            ? {
                  ...options,
                  onReconnectSuccess: async () => {
                      await this._onConnect();
                      options.onDeviceReconnectSuccess();
                  },
              }
            : undefined;
        super(bleClientOptions);
    }

    connect = async ({isNewDevice, onConnect, onError}) => {
        await this.connectDevice({
            isNewDevice,
            services: [bleUuidTypes.RRP_SERVICE],
            onConnect: async () => {
                this._options.onFinishDeviceSelect();

                await this._onConnect();
                onConnect();
            },
            onError,
        });
    };

    _onConnect = async () => {
        await helpers.timeout(200);
        await this.getPrimaryService(bleUuidTypes.RRP_SERVICE);

        await helpers.timeout(200);
        this.scpCharacteristic = await this.getPrimaryServiceCharacteristic(bleUuidTypes.SCP_CONTROL_POINT);
        this.deviceStatusCharacteristic = await this.getPrimaryServiceCharacteristic(bleUuidTypes.DEVICE_STATUS_CHAR);
        this.batteryInformationCharacteristic = await this.getPrimaryServiceCharacteristic(
            bleUuidTypes.DEVICE_BATTERY_CHAR
        );
        this.fwuDataCharacteristic = await this.getPrimaryServiceCharacteristic(bleUuidTypes.FW_UPGRADE_DATA);
        this.fwuControlCharacteristic = await this.getPrimaryServiceCharacteristic(bleUuidTypes.FW_UPGRADE_CONTROL);
        this.fwuStatusCharacteristic = await this.getPrimaryServiceCharacteristic(bleUuidTypes.FW_UPGRADE_STATUS);

        this._options.onDeviceConnect();
    };

    addScpCharacteristicListener = (handler) => this.addCharacteristicListener(this.scpCharacteristic, handler);

    addDeviceStatusCharacteristicListener = (handler) =>
        this.addCharacteristicListener(this.deviceStatusCharacteristic, handler);

    addBatteryInformationCharacteristicListener = (handler) =>
        this.addCharacteristicListener(this.batteryInformationCharacteristic, handler);

    addFwuStatusCharacteristicListener = (handler) =>
        this.addCharacteristicListener(this.fwuStatusCharacteristic, handler);

    removeFwuStatusCharacteristicListener = () => this.removeCharacteristicListener(this.fwuStatusCharacteristic);

    writeValueToScpCharacteristic = (frame, throwError) =>
        this._writeFrameToCharacteristic(this.scpCharacteristic, frame, throwError);

    writeValueToFwuControlCharacteristic = (frame, throwError) =>
        this._writeFrameToCharacteristic(this.fwuControlCharacteristic, frame, throwError);

    writeValueToFwuDataCharacteristic = (frame, throwError) =>
        this._writeFrameToCharacteristic(this.fwuDataCharacteristic, frame, throwError);

    readFwuDataCharacteristic = (handler) => this.readCharacteristic(this.fwuDataCharacteristic, handler);

    _writeFrameToCharacteristic = async (characteristic, frame, throwError) => {
        const chunks = this._splitFrame(frame);

        for (let j = 0; j < chunks.length; j++) {
            const chunk = chunks[j];

            await this.writeValueToCharacteristic(characteristic, chunk, throwError);
        }
    };

    _splitFrame = (frame) => {
        const result = [];

        this._splitChunkRecursive(frame, result);

        return result;
    };

    _splitChunkRecursive = (frame, result) => {
        const CHUNK_COUNT_LENGTH = 2;
        const CHUNK_LENGTH = 40;
        const chunk = frame.substring(0, CHUNK_LENGTH);

        result.push(chunk);

        const chunkLengthHex = frame.substring(0, CHUNK_COUNT_LENGTH);
        const chunkLengthDec = converter.hex2dec(chunkLengthHex);

        if (chunkLengthDec > 0 && frame.length > CHUNK_LENGTH) {
            const frameRest = frame.substring(CHUNK_LENGTH);

            this._splitChunkRecursive(frameRest, result);
        }
    };
}
