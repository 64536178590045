export const AC_RENAME = 'device rename';
export const AC_AUTO_CLEANING = 'device auto cleaning';
export const AC_DIAGNOSTIC = 'device diagnostic';
export const AC_VIBRATIONS = 'device vibrations';
export const AC_LIGHT_MODE = 'device light mode';
export const AC_RESPONSIVE_DRAW = 'device responsive draw';
export const AC_VAPE_CLOUD_sIZE = 'device vape cloud size';
export const AC_FIND_DEVICE = 'device find my device';
export const AC_FW_UPDATE = 'device FW upgrade';
export const AC_RESET = 'device reset';
