import appConfig from '../config/appConfig';
import {customInitConfig} from '../config/config';
import {initDataConfig} from '../config/dataConfig/dataConfig';
import externalConfigService from '../config/externalConfigService';
import urlUtils from '../utils/urlUtils';
import appRouterService from './appRouterService';
import authDataService from './auth/authDataService';
import authService from './auth/authService';
import gamService from './gam/gamService';
import havService from './havService';
import iccMarketService from './icc/iccMarketService';
import {initLocalizations} from './localization/localizationService';
import log from './logger/log';
import marketService from './marketService';
import storageService from './storage/storageService';
import userDeviceService from './userDeviceService';
import userSessionService from './userSessionService';

const onAppStart = async (isRememberMeEnabled, languageCode) => {
    try {
        let havVerificationResult = true;
        const isHAVMandatory = appConfig.getIsHAVMandatory();
        const isMarketHAVAvailable = appConfig.getIsMarketHAVAvailable();

        await initDataConfig();
        if (!isRememberMeEnabled) {
            await authService.login();
        }
        onAppInit();
        await initLocalizations(languageCode);

        if (isHAVMandatory && !isMarketHAVAvailable) {
            havVerificationResult = await havService.verifyHav();
        }

        return {havVerificationResult};
    } catch (e) {
        log.error(`appService: onAppStart, error: ${e}`);
    }
};

const initializeUserSessionActions = async () => {
    const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
    const accessTokenFromLocalStorage = storageService.getAccessTokenFromStorage();

    if (marketCodeFromLocalStorage && accessTokenFromLocalStorage) {
        await customInitConfig(marketCodeFromLocalStorage);
        await externalConfigService.initExternalConfig();

        const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

        if (isRememberMeEnabled) {
            const languageCodeFromLocalStorage = storageService.getLanguageCodeFromLocalStorage();

            const {havVerificationResult} = await onAppStart(isRememberMeEnabled, languageCodeFromLocalStorage);

            if (havVerificationResult) {
                await iccMarketService.getConsumerAggregatedSettings();

                appRouterService.forwardToTNCTermsOfUsePage();
            }
        } else {
            appRouterService.forwardToSelectMarketPage();
        }
    } else {
        appRouterService.forwardToSelectMarketPage();
    }
};

const initializeStartActions = async () => {
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();
    if (isRememberMeEnabled) {
        const marketCodeFromLocalStorage = storageService.getMarketCodeFromLocalStorage();
        const accessTokenFromStorage = storageService.getAccessTokenFromStorage();

        const currentMarket = marketService.currentMarket();

        if (marketCodeFromLocalStorage?.toLowerCase() === currentMarket && accessTokenFromStorage) {
            await initializeUserSessionActions();
        } else {
            const {havVerificationResult} = await onAppStart();

            if (havVerificationResult) {
                const {spiceToken} = authDataService.getInitialCredentials();

                if (spiceToken) {
                    userSessionService.setUserSessionLocalization();
                }
                await externalConfigService.initExternalConfig();

                appRouterService.forwardToBrowserVerificationPage();
            }
        }
    } else {
        const {havVerificationResult} = await onAppStart();

        if (havVerificationResult === undefined || havVerificationResult) {
            await externalConfigService.initExternalConfig();
            appRouterService.forwardToBrowserVerificationPage();
        }
    }
};

const onAppInit = () => {
    log.info(`appService: app is initialized, ua: ${userDeviceService.getUserAgent()}`);
    gamService.saveGamRootDataToLocalStorage();
};

const getAppBaseUrl = () => {
    const baseName = appConfig.getBaseNameFormatted();
    const originLocation = urlUtils.getOriginLocation();
    const baseUrl = urlUtils.join(originLocation, baseName);

    return baseUrl;
};

export default {
    getAppBaseUrl,
    initializeStartActions,
    onAppInit,
    initializeUserSessionActions,
};
