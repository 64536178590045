import React, {forwardRef} from 'react';

import FixedContainer from '../../Layout/FixedContainer/FixedContainer';
import styles from './PopupFullPage.module.css';

const PopupFullPage = forwardRef(({children, className, containerClassName}, ref) => {
    return (
        <FixedContainer className={containerClassName}>
            <div className={styles.PopupContainer}>
                <div className={`${styles.Popup} ${className ? className : ''}`} ref={ref}>
                    {children}
                </div>
            </div>
        </FixedContainer>
    );
});

PopupFullPage.displayName = 'PopupFullPage';

export default PopupFullPage;
