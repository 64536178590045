import React, {useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {setNotificationInfo} from '../../../state/ducks/global';
import {setProductToChangeRegisteringStatus} from '../../../state/ducks/productSettings';
import {makeSelectIotDeviceMergedWithIccProduct, makeSelectProductStatus} from '../../../state/selectors/consumer';
import useRegistrationInterval from '../effects/useRegistrationInterval';
import ProductRegistrationFailedPopup from './ProductRegistartionFailedPopup/ProductRegistrationFailedPopup';

const REGISTRATION_TRY_NOTIFICATION_TIMEOUT = 30 * 60 * 1000;

const mapStateToProps = (state, ownProps) => {
    const {isP4, deviceSerialNumber, codentify} = ownProps.productToChangeRegistrationStatus;

    return createStructuredSelector({
        productStatus: makeSelectProductStatus(isP4, deviceSerialNumber, codentify),
        iotDevice: makeSelectIotDeviceMergedWithIccProduct(),
    });
};

const ProductRegisteringStatus = (props) => {
    const {dispatch, productStatus, productToChangeRegistrationStatus, iotDevice} = props;
    const {
        deviceSerialNumber,
        codentify,
        isP4,
        isSchedulerRequired,
        name,
        createTs,
        isHolder,
    } = productToChangeRegistrationStatus;

    const [stopScheduler] = useRegistrationInterval(isSchedulerRequired, isHolder);

    const notificationTimeout = useRef(false);
    const clearNotificationTimeout = () => clearTimeout(notificationTimeout.current);

    useEffect(() => {
        if (!isHolder) {
            const registrationTryNotificationTimeout = createTs - Date.now() + REGISTRATION_TRY_NOTIFICATION_TIMEOUT;
            const timeoutTime = registrationTryNotificationTimeout > 0 ? registrationTryNotificationTimeout : 0;

            notificationTimeout.current = setTimeout(() => {
                dispatch(
                    setNotificationInfo(getLocalizedStrings()[localizationKeys.DEVICE_EXPECTATION_REGISTER__TEXT])
                );
                dispatch(setProductToChangeRegisteringStatus(isP4, deviceSerialNumber, codentify, name, false));
            }, timeoutTime);

            return () => clearNotificationTimeout();
        }
    }, [createTs]);

    return productStatus ? (
        <ProductRegistrationFailedPopup
            deviceName={name}
            dispatch={dispatch}
            iotDevice={iotDevice}
            isHolder={isHolder}
            productStatus={productStatus}
            stopScheduler={stopScheduler}
        />
    ) : null;
};

export default connect(mapStateToProps)(ProductRegisteringStatus);
