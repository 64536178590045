import React, {useState} from 'react';

import imgArrowIcon from '../../../assets/images/icons/expansion-panel-arrow.svg';
import TermsOfUseDescription from '../../containers/TNC/TermsOfUseDescription/TermsOfUseDescription';
import helpers from '../../utils/helpers';
import Image from '../Image/Image';
import styles from './ExpansionPanel.module.scss';

const ExpansionPanel = ({title, innerText, state, onClick, children}) => {
    const [isExpanded, setIsExpanded] = useState(state);

    const arrowClassName = `${styles.Arrow} ${isExpanded ? styles.ArrowExpanded : ''}`;

    const onExpansionPanelClick = () => {
        setIsExpanded(!isExpanded);
        helpers.runFunction(onClick);
    };

    return (
        <div className={styles.ExpansionPanel}>
            <header className={`${styles.Main}`}>
                <div className={styles.ArrowWrapper} onClick={onExpansionPanelClick}>
                    <Image src={[imgArrowIcon]} className={arrowClassName} />
                </div>
                <div className={styles.TitleWrapper} onClick={onExpansionPanelClick}>
                    <p className={`ica--txt-regular`}>{title}</p>
                </div>
                {children}
            </header>
            {isExpanded && innerText && <TermsOfUseDescription innerHtml={innerText} className={styles.Body} />}
        </div>
    );
};

export default ExpansionPanel;
