import React from 'react';

import Image from '../../../components/Image/Image';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import styles from '../PairingSkipPopup.module.scss';

const IconTextRow = ({img, text, imgClassName}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.ImageTextContainer}>
            <Image className={`${styles.Image} ${imgClassName ? imgClassName : ''}`} src={img} />
            <p className={`${styles.Text} ica--txt-regular`}>{localizedStrings[text]}</p>
        </div>
    );
};

export default IconTextRow;
