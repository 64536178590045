import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as scpMethods from '../../consts/scp/scpMethods';
import {setMwDeviceInternalId} from '../../state/ducks/mwDevice';
import {makeMwDeviceInternalId, makeMwDeviceIsIdentified} from '../../state/selectors/mwDevice';
import {dispatch, getState} from '../../state/store';
import urlUtils from '../../utils/urlUtils';
import appInstanceService from '../appInstanceService';
import authDataService from '../auth/authDataService';
import iccService from '../icc/iccService';
import consoleLog from '../logger/consoleLogger';
import log from '../logger/log';
import {validateTokenMapping} from '../mapping/scpMappings';
import server from '../server/server';
import cloudWatchProvider from './cloudWatchProvider';

let credentials = null;
let isAwsCredentialsRequested = false;
let currentAwsCredentialsRequest = null;

const getCredentials = async (fetchNew) => {
    if (currentAwsCredentialsRequest) {
        await currentAwsCredentialsRequest;
    } else if (fetchNew || !credentials) {
        await fetchAwsCredentials();
    }

    currentAwsCredentialsRequest = null;
    return credentials;
};

const getRequestConfig = async () => {
    const state = getState();
    const authToken = await authDataService.getAuthToken();
    const appInstanceId = appInstanceService.getAppInstanceId();
    const isIdentified = makeMwDeviceIsIdentified()(state);
    const deviceSerialNumber = isIdentified ? makeMwDeviceInternalId()(state) : undefined;

    const data = {
        app_id: appConfig.getIccApiHeader_a(),
        app_instance_id: appInstanceId,
        cloudwatch_logging: appConfig.getIsAwsLoggingEnabled(),
        dusn: deviceSerialNumber,
        market_iso: appConfig.getScpCloudTopicMarket(),
    };

    const headers = {Token: authToken};

    return {data, headers};
};

const getScpApiUrl = () => urlUtils.join(appConfig.getScpApiUrl(), scpMethods.VALIDATE_SCP_TOKEN);

const fetchAwsCredentials = async () => {
    credentials = null;
    isAwsCredentialsRequested = true;
    const requestConfig = await getRequestConfig();
    const scpApiUrl = getScpApiUrl();

    try {
        currentAwsCredentialsRequest = server.post(scpApiUrl, requestConfig);
        const response = await currentAwsCredentialsRequest;

        isAwsCredentialsRequested = false;

        log.debug(`fetchAwsCredentials: fetchAwsCredentials has successful response`);

        return onResponse(response);
    } catch (e) {
        isAwsCredentialsRequested = false;
        consoleLog.info(`awsAuthenticationService: fetchAwsCredentials, error: ${e}`);

        return onError(e);
    }
};

const setInternalId = ({dusn}) => {
    const isIdentified = makeMwDeviceIsIdentified()(getState());

    dispatch(setMwDeviceInternalId({deviceSerialNumber: dusn, isIdentified}));
};

const onResponse = (response) => {
    const mappedData = validateTokenMapping(response.data);

    setInternalId(mappedData);
    updateCredentials(mappedData);

    return mappedData;
};

const onError = async (e) => {
    switch (e?.response?.status) {
        case httpErrorsTypes.NOT_AUTHORIZED: {
            if (!isAwsCredentialsRequested) {
                isAwsCredentialsRequested = true;
                await iccService.refreshIccToken();

                return fetchAwsCredentials();
            }

            break;
        }
        default:
            break;
    }
};

const updateCredentials = (data) => {
    credentials = data;

    cloudWatchProvider.updateCloudWatchClient(credentials);
};

export default {
    getCredentials,
};
