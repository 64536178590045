const DEVICE_TYPES = {
    DEV_TYPE_UNKNOWN: 'DEV_TYPE_UNKNOWN',
    DEV_TYPE_V24_CHARGER: 'DEV_TYPE_V24_CHARGER',
    DEV_TYPE_V24_HOLDER: 'DEV_TYPE_V24_HOLDER',
    DEV_TYPE_V24P_CHARGER: 'DEV_TYPE_V24P_CHARGER',
    DEV_TYPE_V24P_HOLDER: 'DEV_TYPE_V24P_HOLDER',
    DEV_TYPE_P1V30_CHARGER: 'DEV_TYPE_P1V30_CHARGER',
    DEV_TYPE_P1V30_HOLDER: 'DEV_TYPE_P1V30_HOLDER',
    DEV_TYPE_P1V30M: 'DEV_TYPE_P1V30M',
    DEV_TYPE_P1V31_CHARGER: 'DEV_TYPE_P1V31_CHARGER',
    DEV_TYPE_P1V31_HOLDER: 'DEV_TYPE_P1V31_HOLDER',
    DEV_TYPE_P4_M3_G1: 'DEV_TYPE_P4_M3_G1',
    DEV_TYPE_P4_M3_G2: 'DEV_TYPE_P4_M3_G2',
    DEV_TYPE_P1: 'P1',
    DEV_TYPE_P4: 'P4',
};

export default DEVICE_TYPES;
