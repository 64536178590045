import imgTutorialOrdinaryS1 from '../../../assets/images/tutorials/cleaning-tutorial/s1-ordinary-img.png';
import imgTutorialSoyuzCortezS1 from '../../../assets/images/tutorials/cleaning-tutorial/s1-soyuz-cortez-img.png';
import imgTutorialOrdinaryS2 from '../../../assets/images/tutorials/cleaning-tutorial/s2-ordinary-img.png';
import imgTutorialSoyuzCortezS2_1 from '../../../assets/images/tutorials/cleaning-tutorial/s2-soyuz-cortez-img.png';
import imgTutorialSoyuzCortezS2_2 from '../../../assets/images/tutorials/cleaning-tutorial/s2-soyuz-cortez-img@2x.png';
import imgTutorialSoyuzCortezS2_3 from '../../../assets/images/tutorials/cleaning-tutorial/s2-soyuz-cortez-img@3x.png';
import imgTutorialSoyuzCortezS3 from '../../../assets/images/tutorials/cleaning-tutorial/s3-soyuz-cortez-img.png';
import imgTutorialCortezS4 from '../../../assets/images/tutorials/cleaning-tutorial/s4-cortez-img.png';
import imgTutorialSoyuzS4 from '../../../assets/images/tutorials/cleaning-tutorial/s4-soyuz-img.png';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';

let data;

const getCleaningGuidePagesData = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            DEV_TYPE_V24P_CHARGER: [
                {
                    images: [imgTutorialOrdinaryS1],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN5_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN5_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialOrdinaryS2],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN6_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN6_DESCRIPTION_TEXT],
                },
            ],
            DEV_TYPE_P1V30_CHARGER: [
                {
                    images: [imgTutorialSoyuzCortezS1],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialSoyuzCortezS2_1, imgTutorialSoyuzCortezS2_2, imgTutorialSoyuzCortezS2_3],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialSoyuzCortezS3],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialCortezS4],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT],
                },
            ],
            DEV_TYPE_P1V31_CHARGER: [
                {
                    images: [imgTutorialSoyuzCortezS1],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN1_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN1_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialSoyuzCortezS2_1, imgTutorialSoyuzCortezS2_2, imgTutorialSoyuzCortezS2_3],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN2_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN2_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialSoyuzCortezS3],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN3_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN3_DESCRIPTION_TEXT],
                },
                {
                    images: [imgTutorialSoyuzS4],
                    header: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN4_TITLE_TEXT],
                    desc: localizedStrings[localizationKeys.CLEAN_INSTRUCTION_SCREEN4_DESCRIPTION_TEXT],
                },
            ],
        };
    }

    return data;
};

export {getCleaningGuidePagesData};
