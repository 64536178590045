import React from 'react';

import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import analyticsService from '../../../../services/analyticsService';
import userDeviceService from '../../../../services/userDeviceService';
import styles from '../NotCompatibleBrowserPage.module.scss';

const NotCompatibleBrowserDownloadButton = ({text, link}) => {
    const isMobileIOSDevice = userDeviceService.isMobileIOSDevice();

    const onClick = () => {
        analyticsService.pushBrowserDownloadEvent();
    };

    return (
        <ButtonDark
            className={styles.ButtonDark}
            isExternalLink
            isTargetBlank={!isMobileIOSDevice}
            onClick={onClick}
            showArrow
            to={link}
        >
            {text}
        </ButtonDark>
    );
};

export default NotCompatibleBrowserDownloadButton;
