import React, {useEffect} from 'react';

import ButtonDark from '../../../../../../components/Button/ButtonDark/ButtonDark';
import appConfig from '../../../../../../config/appConfig';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as iccSsoDeeplinkTemplates from '../../../../../../consts/icc/iccSsoDeeplinkTemplates';
import {iotErrorKeys} from '../../../../../../consts/iot/iotErrorKeys';
import * as iotErrorModeTypes from '../../../../../../consts/iot/iotErrorModeTypes';
import * as routePaths from '../../../../../../consts/route/routePaths';
import analyticsService from '../../../../../../services/analyticsService';
import iccSsoService from '../../../../../../services/icc/iccSsoService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import routerService from '../../../../../../services/routerService';
import storageService from '../../../../../../services/storage/storageService';
import {hideLoader, showLoader} from '../../../../../../state/ducks/global';
import {dispatch} from '../../../../../../state/store';
import urlUtils from '../../../../../../utils/urlUtils';
import styles from '../../../SettingsOptions/SettingsOptions.module.scss';

const LOADER_NAME = 'REDIRECT_LOADER_NAME';

const DeviceDiagnosticResults = (props) => {
    const {device, holder, errors, onClose, onErrorSendMessage, onSendMessage} = props;
    const {codentify, name} = device;
    const isAnyError = errors?.length > 0;
    const isErrorPresent = (errorMode) => {
        return errors?.some(({error_mode}) => error_mode === errorMode);
    };

    const isCritical = isErrorPresent(iotErrorModeTypes.M4);
    const isM1 = isErrorPresent(iotErrorModeTypes.M1);
    const isM2 = isErrorPresent(iotErrorModeTypes.M2);
    const isM3 = isErrorPresent(iotErrorModeTypes.M3);

    const redirectToContactUs = async () => {
        dispatch(showLoader(LOADER_NAME));
        const error = errors.find((item) => item.error_mode === iotErrorModeTypes.M4);

        const ssoLink = await iccSsoService.getSsoLink(
            iccSsoDeeplinkTemplates.ICC_SSO_CONNECTED_CARE_DEEPLINK_TEMPLATE
        );

        const urlCodentify = error.is_charger ? codentify : holder.codentify;

        const redirectUrl = ssoLink
            ? urlUtils.updateParams(ssoLink, [
                  {name: 'codentify', value: urlCodentify},
                  {name: 'code', value: error.l1_code},
              ])
            : appConfig.getConnectingCareUrlFormatted(urlCodentify, error.l1_code);

        analyticsService.pushDeviceDiagnosticContactUsEvent();
        routerService.redirect(redirectUrl);
        dispatch(hideLoader(LOADER_NAME));
    };

    const getErrorObject = (mode) => {
        return errors?.find((item) => item.error_mode === mode);
    };
    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        if (!isAnyError) {
            onSendMessage(localizedStrings[localizationKeys.DEVICE_DIAGNOSTIC_RESULT_NO_ERROR_TITLE]);
        }
        if (isM3) {
            onErrorSendMessage(
                localizedStrings[localizationKeys[iotErrorKeys[getErrorObject(iotErrorModeTypes.M3).error_code]]]
            );
        }

        if (!isAnyError || isM3) {
            storageService.removeDeviceDataFromStorage();
            onClose();
        }
    }, [isM3, isAnyError]);

    return (
        isAnyError && (
            <div className={styles.DeviceDiagnosticStatus}>
                <div className={`${styles.FailMessageIcon} icon--fail`} />
                {isCritical ? (
                    <DeviceDiagnostic
                        buttonText={localizedStrings[localizationKeys.POPUP_CARE_CONTACT_DEVICE_ACTION_BUTTON_TEXT]}
                        descriptionText={localizedStrings[localizationKeys.POPUP_CARE_CONTACT_DEVICE_DESCRIPTION_TEXT]}
                        error={iotErrorModeTypes.M4}
                        onClose={redirectToContactUs}
                        titleText={localizedStrings[localizationKeys.POPUP_CARE_CONTACT_DEVICE_TITLE_TEXT]}
                    />
                ) : isM1 ? (
                    <DeviceDiagnostic
                        buttonText={localizedStrings[localizationKeys.POPUP_CARE_RESET_DEVICE_ACTION_BUTTON_TEXT]}
                        descriptionText={localizedStrings[localizationKeys.POPUP_CARE_RESET_DEVICE_DESCRIPTION_TEXT]}
                        error={iotErrorModeTypes.M1}
                        name={name}
                        routeTo={routePaths.RESET_DEVICE}
                        titleText={localizedStrings[localizationKeys.POPUP_CARE_RESET_DEVICE_TITLE_TEXT]}
                    />
                ) : (
                    isM2 && (
                        <DeviceDiagnostic
                            buttonText={localizedStrings[localizationKeys.POPUP_CARE_CLEAN_DEVICE_ACTION_BUTTON_TEXT]}
                            descriptionText={
                                localizedStrings[localizationKeys.POPUP_CARE_CLEAN_DEVICE_DESCRIPTION_TEXT]
                            }
                            error={iotErrorModeTypes.M2}
                            name={name}
                            routeTo={routePaths.CLEAN_TUTORIAL}
                            titleText={localizedStrings[localizationKeys.POPUP_CARE_CLEAN_DEVICE_TITLE_TEXT]}
                        />
                    )
                )}
            </div>
        )
    );
};

export default DeviceDiagnosticResults;

const DeviceDiagnostic = (props) => {
    const {name = '', onClose, titleText, descriptionText, buttonText, routeTo} = props;

    return (
        <div>
            <h2 className={styles.DeviceDiagnosticTitle}>{`${titleText} ${name}`}</h2>
            <>
                <div className={`${styles.DeviceDiagnosticDescription} ica--txt-regular`}>{descriptionText}</div>
                <ButtonDark showArrow onClick={onClose} to={routeTo}>
                    {buttonText}
                </ButtonDark>
            </>
        </div>
    );
};
