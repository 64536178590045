import React, {useEffect, useState} from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import styles from './RemainingTime.module.css';

const FULL_PROGRESS = 100;

const RemainingTime = ({progress, additionalTime}) => {
    const [startDate, setStartDate] = useState(new Date());
    const [remainingTimeMessage, setRemainingTimeMessage] = useState(
        getLocalizedStrings()[localizationKeys.FIRMWARE_INSTALL_ESTIMATING_TIME]
    );
    const isVisibleRemainingTime = progress < FULL_PROGRESS;

    useEffect(() => {
        if (progress === 1) {
            setStartDate(new Date());
        } else if (progress >= 5) {
            const currentDate = new Date();
            const diff = currentDate - startDate;
            const diffSeconds = diff / 1000;
            const remainingProgress = FULL_PROGRESS - progress;
            const secondsPerProgress = diffSeconds / progress;
            const remainingProgressDuration = secondsPerProgress * remainingProgress;
            const remainingTime = additionalTime
                ? remainingProgressDuration + additionalTime
                : remainingProgressDuration;
            const remainingMinutes = Math.ceil(remainingTime / 60);

            setRemainingTimeMessage(
                `${remainingMinutes} ${getLocalizedStrings()[localizationKeys.FIRMWARE_INSTALL_REMAINING_TIME]}`
            );
        }
    }, [progress]);

    return (
        isVisibleRemainingTime && (
            <div className={`ica--txt-regular ${styles.TimeRemainingText}`}>{remainingTimeMessage}</div>
        )
    );
};

export default RemainingTime;
