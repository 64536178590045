import React from 'react';

import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import DeviceLockedPopup from './DeviceLockedPopup';
import DeviceUnlockedPopup from './DeviceUnlockedPopup';

const DeviceLockPopups = (props) => {
    const {isDeviceLocked, isVisibleLockPopup, onLockPopupChange, onLockProduct, onUnlockProduct} = props;

    const onLockDevice = () => {
        const lockMessageDescription = getLocalizedStrings()[localizationKeys.PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE];
        onLockPopupChange();
        onLockProduct(lockMessageDescription);
    };

    const onUnlockDevice = () => {
        const unlockMessageDescription = getLocalizedStrings()[localizationKeys.UNLOCK_SUCCESS_NOTIFICATION];
        onLockPopupChange();
        onUnlockProduct(unlockMessageDescription);
    };

    return (
        <>
            {isVisibleLockPopup ? (
                isDeviceLocked ? (
                    <DeviceLockedPopup onClose={onLockPopupChange} onUnlockDevice={onUnlockDevice} />
                ) : (
                    <DeviceUnlockedPopup onClose={onLockPopupChange} onLockDevice={onLockDevice} />
                )
            ) : null}
        </>
    );
};

export default DeviceLockPopups;
