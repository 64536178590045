import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Container from '../../../components/Layout/Container/Container';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import layoutService from '../../../services/layoutService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import browserCompatibilityService from '../browserCompatibilityService';
import CompatibleBrowserDownloadLink from '../CompatibleBrowserDownloadLink/CompatibleBrowserDownloadLink';
import InstructionStep from './components/InstructionStep/InstructionStep';
import NotCompatibleBrowserBluetoothImage from './components/NotCompatibleBrowserBluetoothImage';
import NotCompatibleBrowserDownloadButton from './components/NotCompatibleBrowserDownloadButton';
import NotCompatibleBrowserHeaderImage from './components/NotCompatibleBrowserHeaderImage';
import styles from './NotCompatibleBrowserPage.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const NotCompatibleBrowserPage = ({layout}) => {
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    const enableP1Support = appConfig.getEnableP1Support();

    const localizedStrings = getLocalizedStrings();

    const browserDownloadText = localizedStrings[localizationKeys.NOT_BROWSER_BUTTON_TEXT];

    const buttonData = browserCompatibilityService.getBrowserNotCompatibleButtonData();
    const {browserLink} = buttonData || {};

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <div>
                    <div className={styles.Header}>
                        <div className={styles.HeaderText}>
                            {localizedStrings.formatString(
                                localizedStrings[localizationKeys.NOT_BROWSER_COMP_HEADER_TEXT],
                                <span>{localizedStrings[localizationKeys.NOT_BROWSER_COMP_HEADER_TEXT_BOLD]}</span>
                            )}
                            <div className={styles.HeaderTextImage}>
                                <NotCompatibleBrowserBluetoothImage isDesktopLayout={isDesktopLayout} />
                            </div>
                        </div>
                        {!isDesktopLayout && (
                            <div className={styles.HeaderImage}>
                                {<NotCompatibleBrowserHeaderImage layout={layout} enableP1Support={enableP1Support} />}
                            </div>
                        )}
                    </div>
                    {isDesktopLayout && <CompatibleBrowserDownloadLink browserLink={browserLink} />}
                </div>
                {isDesktopLayout && (
                    <div className={styles.SettingsImage}>
                        {<NotCompatibleBrowserHeaderImage layout={layout} enableP1Support={enableP1Support} />}
                    </div>
                )}
                {!isDesktopLayout && (
                    <section className={styles.Body}>
                        <div className={`${styles.BodyTitle} ica--txt-regular`}>
                            {localizedStrings[localizationKeys.NOT_BROWSER_COMP_INSTRUCTION_TITLE]}
                        </div>
                        <div className={styles.Steps}>
                            <InstructionStep number={1} localizationKey={localizationKeys.NOT_BROWSER_COMP_STEP_1} />
                            <InstructionStep number={2} localizationKey={localizationKeys.NOT_BROWSER_COMP_STEP_2} />
                            <InstructionStep number={3} localizationKey={localizationKeys.NOT_BROWSER_COMP_STEP_3} />
                        </div>
                        <div className={styles.DownloadButtonContainer}>
                            <NotCompatibleBrowserDownloadButton link={browserLink} text={browserDownloadText} />
                        </div>
                    </section>
                )}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(NotCompatibleBrowserPage);
