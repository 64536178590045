import appConfig from '../../config/appConfig';
import {getSelectDeviceData} from '../../containers/SelectYourDevicePage/selectDeviceData';

const getSortedDeviceList = () => {
    const deviceListOrder = appConfig.getDeviceListOrder();
    const selectDeviceData = getSelectDeviceData();

    const sortedDeviceListArray = deviceListOrder.map((deviceType) => {
        return selectDeviceData.find((deviceData) => {
            return deviceData.type === deviceType;
        });
    });

    return [...new Set([...sortedDeviceListArray, ...selectDeviceData])];
};

export default {getSortedDeviceList};
