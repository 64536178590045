import * as mwFirmwareErrorCodes from '../../consts/iot/mwFirmwareErrorCodes';
import log from '../logger/log';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import HIDClient from './hidClient';

let instance = null;

export default class DeviceHidFwuClient {
    constructor(createNew, fwuPackageData) {
        if (createNew && instance) {
            instance.stop();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.fwuPackageData = fwuPackageData;
        this.currentFwuTry = 0;
        this.currentBlockIndex = 0;
        this.hidClient = new HIDClient();
        this.isStopped = true;
    }

    stop = () => {
        if (this.isStopped) return;

        this.isStopped = true;
        this.stopFwuDataClient();
    };

    startFwUpdate = () => {
        this.isStopped = false;

        log.debug(`DeviceHidFwuClient: start firmware update`);

        this.sendAuthenticationRequest();
    };

    sendRequestAndSubscribeOnReport = (blockData, onReport) => {
        const {f, v, n} = blockData;

        this.hidClient.subscribeOnInputReport((value) => {
            if (value === v) {
                if (n) {
                    mwIotMessageRequestService.publishFwuStatusEvent(n);
                }
                onReport();
            } else {
                mwIotMessageRequestService.publishFwuErrorEvent(null, value);
            }
        });
        this.hidClient.sendReport(f);
    };

    sendAuthenticationRequest = () => {
        log.debug(`DeviceHidFwuClient: send sendAuthenticationRequest`);

        this.sendRequestAndSubscribeOnReport(this.fwuPackageData.authentication, this.sendStartProgrammingRequest);
    };

    sendStartProgrammingRequest = () => {
        log.debug(`DeviceHidFwuClient: send sendStartProgrammingRequest`);

        this.sendRequestAndSubscribeOnReport(this.fwuPackageData.start_programming, this.sendProgramBlocks);
    };

    sendEndProgrammingRequest = () => {
        log.debug(`DeviceHidFwuClient: send sendEndProgrammingRequest`);

        this.sendRequestAndSubscribeOnReport(this.fwuPackageData.end_programming, () => {});
    };

    sendProgramBlocks = (blockIndex = 0) => {
        log.debug(`DeviceHidFwuClient: send sendProgramBlocks`);

        this.hidClient.subscribeOnInputReport((value) => {
            const {program_blocks} = this.fwuPackageData;
            const {v, n} = this.fwuPackageData.program_blocks[this.currentBlockIndex];

            if (value === v) {
                if (n) {
                    mwIotMessageRequestService.publishFwuStatusEvent(n);
                }

                if (this.currentBlockIndex >= program_blocks.length) {
                    this.sendEndProgrammingRequest();
                } else {
                    this.sendProgramBlock(this.currentBlockIndex + 1);
                }
            } else {
                mwIotMessageRequestService.publishFwuErrorEvent(null, value);
            }
        });

        this.sendProgramBlock(blockIndex);
    };

    sendProgramBlock = (blockIndex) => {
        this.currentBlockIndex = blockIndex;
        const {f} = this.fwuPackageData.program_blocks[blockIndex];

        this.hidClient.sendReport(f);
    };

    onFwuFail = () => {
        this.stop();
        mwIotMessageRequestService.publishFwuErrorEvent(mwFirmwareErrorCodes.GENERAL);
    };
}
