import React from 'react';

import FWUFailedPopup from '../../../components/FWUFailedPopup/FWUFailedPopup';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {FW_CHECK_ERROR_DOWNLOAD_OR_PARSE} from '../../../consts/iot/iotMessageErrorCodes';

const FailedUpdateFWPopup = (props) => {
    const {errorCode, isForcedDisconnected} = props;

    let headerKey;
    let descriptionKey;
    let okBtnKey;
    let cancelBtnKey;

    if (isForcedDisconnected) {
        headerKey = localizationKeys.FIRMWARE_INSTALL_CONNECTION_FAILED_TITLE;
        descriptionKey = localizationKeys.FIRMWARE_INSTALL_CONNECTION_FAILED_DESCRIPTION;
        okBtnKey = localizationKeys.FIRMWARE_INSTALL_CONNECTION_FAILED_TRY_AGAIN_BUTTON_TEXT;
        cancelBtnKey = localizationKeys.FIRMWARE_INSTALL_CONNECTION_FAILED_CANCEL_BUTTON_TEXT;
    } else {
        headerKey = localizationKeys.FIRMWARE_INSTALL_FAILED_TITLE;
        descriptionKey =
            errorCode === FW_CHECK_ERROR_DOWNLOAD_OR_PARSE
                ? localizationKeys.FIRMWARE_DOWNLOADING_FAILED_POPUP_MESSAGE
                : localizationKeys.FIRMWARE_INSTALL_FAILED_MESSAGE;
        okBtnKey = localizationKeys.FIRMWARE_INSTALL_FAILED_TRY_AGAIN_BUTTON_TEXT;
        cancelBtnKey = localizationKeys.FIRMWARE_INSTALL_FAILED_CANCEL_BUTTON_TEXT;
    }

    return (
        <FWUFailedPopup
            headerKey={headerKey}
            descriptionKey={descriptionKey}
            okBtnKey={okBtnKey}
            cancelBtnKey={cancelBtnKey}
            {...props}
        />
    );
};

export default FailedUpdateFWPopup;
