import {createSelector} from 'reselect';

import firmwareStatusTypes from '../../consts/device/firmwareStatusTypes';
import codentifyTransformService from '../../services/codentifyTransformService';
import deviceTypesService from '../../services/device/deviceTypesService';
import iotDeviceConnectionStatusService from '../../services/iotDevice/iotDeviceConnectionStatusService';

const selectState = (state) => state.iotDevice;

const makeSelectIotDevice = () => createSelector(selectState, (state) => state);

const makeSelectDeviceConnectionStatus = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.deviceConnectionStatus);

const makeSelectDeviceActivationStatus = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isDeviceActivated);

const makeSelectShouldShowSBLModePopup = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.shouldShowSBLModePopup);

const makeSelectIsDeviceDisconnected = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceDisconnected(status)
    );

const makeSelectIsDeviceConnectedStatus = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceConnectedStatus(status)
    );

const makeSelectIsDeviceConnected = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceConnected(status)
    );

const makeSelectIsDevicePaired = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDevicePaired(status)
    );

const makeSelectIsDeviceReady = () =>
    createSelector(makeSelectDeviceConnectionStatus(), (status) =>
        iotDeviceConnectionStatusService.isDeviceReady(status)
    );

const makeSelectIotDeviceIsVibrationAlarmActive = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice.isVibrationAlarmActive);

const makeSelectIotDeviceData = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => {
        if (!iotDevice?.data) return returnEmptyObjectIfUndefined(iotDevice?.data);

        const {device} = iotDevice?.data;
        const {type, codentify} = device || {};
        const isP4Device = deviceTypesService.isP4(type);

        const preparedCodentify = codentifyTransformService.transform(isP4Device, codentify); //transform modify codentify only for P4 device and on DCS market
        const preparedDevice = {...device, codentify: preparedCodentify, codentifyOriginal: codentify};
        const iotDeviceData = {...iotDevice?.data, device: preparedDevice};

        return iotDeviceData;
    });

const makeSelectIotMessage = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => returnEmptyObjectIfUndefined(iotDevice?.message));

const makeSelectIsReset = () => createSelector(makeSelectIotDevice(), (iotDevice) => iotDevice?.reset?.isReset);

const makeSelectFirmwareState = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => returnEmptyObjectIfUndefined(iotDevice?.firmware));

const makeSelectIsFirmwareFinished = () =>
    createSelector(makeSelectFirmwareState(), (state) => firmwareStatusTypes.isFinished(state.firmwareStatus));

const makeSelectIsIotDeviceP4 = () =>
    createSelector(makeSelectIotDevice(), (iotDevice) => deviceTypesService.isP4(iotDevice?.data?.device?.type));

const returnEmptyObjectIfUndefined = (obj) => (obj ? obj : {});

export {
    makeSelectDeviceActivationStatus,
    makeSelectDeviceConnectionStatus,
    makeSelectFirmwareState,
    makeSelectIotDeviceData,
    makeSelectIotDeviceIsVibrationAlarmActive,
    makeSelectIotMessage,
    makeSelectIsDeviceConnected,
    makeSelectIsDeviceConnectedStatus,
    makeSelectIsDeviceDisconnected,
    makeSelectIsDevicePaired,
    makeSelectIsDeviceReady,
    makeSelectIsFirmwareFinished,
    makeSelectIsIotDeviceP4,
    makeSelectIsReset,
    makeSelectShouldShowSBLModePopup,
};
