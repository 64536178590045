import * as mwFirmwareErrorCodes from '../../consts/iot/mwFirmwareErrorCodes';
import {makeMwDeviceFirmwareData} from '../../state/selectors/mwDevice';
import {getState} from '../../state/store';
import converter from '../../utils/converter';
import IqosDeviceFwuClient from '../device/iqosDeviceFwuClient';
import log from '../logger/log';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import server from '../server/server';
import HolderFwuClient from './holderFwuClient';

const fwuPackageData = {
    devicePackage: undefined,
    holderPackage: undefined,
};

const clearPackageData = () => {
    fwuPackageData.devicePackage = null;
    fwuPackageData.holderPackage = null;
};

const downloadFwuPackages = async (isHolder) => {
    try {
        if ((!isHolder && fwuPackageData.devicePackage) || (isHolder && fwuPackageData.holderPackage)) return true;

        await downloadFirmware(isHolder);

        return true;
    } catch (e) {
        log.error(`FWUService: FWU package downloading is failed, error: ${e}`);

        mwIotMessageRequestService.publishFwuErrorEvent(mwFirmwareErrorCodes.CHECK_ERROR_DOWNLOAD_OR_PARSE);

        return false;
    }
};

const downloadFirmware = async (isHolder) => {
    const {device, holder} = makeMwDeviceFirmwareData()(getState());
    const firmware = isHolder ? holder : device;
    if (!firmware?.package_available) return;

    const {download_url} = firmware;
    const deviceType = isHolder ? 'holder' : 'device';

    const res = await server.get(download_url);

    if (isHolder) {
        fwuPackageData.holderPackage = res.data;
    } else {
        fwuPackageData.devicePackage = res.data;
    }

    log.debug(`FWUService: FWU package for ${deviceType} has been downloaded successfully`);

    mwIotMessageRequestService.publishFwuPackageLoadedEvent(isHolder);
};

const startFwUpdate = async ({targets_holder}) => {
    const isPackageLoaded = await downloadFwuPackages(targets_holder);

    if (isPackageLoaded) {
        let fwuClient;

        log.debug(`FWUService: start FWU update`);

        if (targets_holder) {
            fwuClient = new HolderFwuClient(fwuPackageData.holderPackage);
        } else {
            fwuClient = new IqosDeviceFwuClient(true, fwuPackageData.devicePackage);
        }

        fwuClient.startFwUpdate();
    }
};

const convertHexIndexToInt = (hex) => {
    const hexIndexReversed = hex
        .match(/.{1,2}/g)
        .reverse()
        .join('');
    const index = converter.hex2dec(hexIndexReversed);
    return parseInt(index);
};

export default {
    convertHexIndexToInt,
    startFwUpdate,
    clearPackageData,
};
