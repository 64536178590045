import DEVICE_TYPES from '../../consts/device/deviceTypes';
import {getDeviceTypesData} from '../../consts/device/deviceTypesData';

const getDeviceData = (type) => (type && getDeviceTypesData()[type]) || {};

const getProductNameByType = (type) => getDeviceData(type).name;
const isMono = (type) => getDeviceData(type).isMono;
const isP1 = (type) => getDeviceData(type).type === DEVICE_TYPES.DEV_TYPE_P1;
const isP4 = (type) => getDeviceData(type).type === DEVICE_TYPES.DEV_TYPE_P4;

export default {
    getProductNameByType,
    isMono,
    isP1,
    isP4,
};
