import React from 'react';
import {Link as ReactLink} from 'react-router-dom';

import styles from './Link.module.css';

const Link = (props) => {
    const {className, isExternalLink, value, to, onClick, children, isTargetBlank, testId} = props;
    const Component = to ? ReactLink : 'button';
    const target = isTargetBlank ? '_blank' : null;

    return isExternalLink ? (
        <a
            className={`${className} ${styles.Link}`}
            href={to}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            data-testid={testId}
        >
            {children || value}
        </a>
    ) : (
        <Component
            className={className}
            onClick={onClick}
            rel={isTargetBlank ? 'noopener noreferrer' : null}
            target={target}
            to={to}
            data-testid={testId}
        >
            {children || value}
        </Component>
    );
};

Link.defaultProps = {
    className: '',
    isExternalLink: false,
    isTargetBlank: false,
};

export default Link;
