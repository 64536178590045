import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import {makeNotifications} from '../../state/selectors/global';
import Notification from './Notification/Notification';
import styles from './Notifications.module.scss';

const mapStateToProps = createStructuredSelector({
    notifications: makeNotifications(),
});

const Notifications = (props) => {
    const {dispatch, notifications} = props;

    return notifications.length ? (
        <div className={styles.Wrapper}>
            <div className={styles.NotificationsContainer}>
                <div className={styles.NotificationList}>
                    {notifications.map((notification) => (
                        <Notification
                            dispatch={dispatch}
                            key={notification.notificationId}
                            notification={notification}
                        />
                    ))}
                </div>
            </div>
        </div>
    ) : null;
};

export default connect(mapStateToProps)(Notifications);
