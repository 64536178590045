import {useEffect} from 'react';

import ErrorHandlerService from '../../services/errorHandlerService';
import arrayUtils from '../../utils/arrayUtils';

const useErrorHandler = ({iotErrorCodes = [], handler, type}) => {
    useEffect(() => {
        const errorHandlerService = new ErrorHandlerService();
        const errorCodesList = arrayUtils.toArray(iotErrorCodes);

        errorHandlerService.setErrorHandler({
            handler,
            iotErrorCodes: errorCodesList,
            type,
        });

        return () => errorHandlerService.removeErrorHandler();
    }, []);
};

export default useErrorHandler;
