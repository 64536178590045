import converter from '../../utils/converter';
import ExponentialBackOff from '../../utils/exponentialBackOff';
import helpers from '../../utils/helpers';
import objUtils from '../../utils/objUtils';
import log from '../logger/log';
import FwuDataClient from './fwuDataClient';
import fwuService from './fwuService';
import IqosBLEClient from './iqosBleClient';

const FWU_STATUS_VALUES = {
    NO_IMAGE: '00',
    UPLOAD_PROCESSING: '01',
    UPGRADE_PROCESSING: '02',
    IMAGE_VERIFICATION_PROCESSING: '03',
    IMAGE_UPLOAD_ERROR: '80',
    IMAGE_STORAGE_ERROR: '81',
    IMAGE_SIGNATURE_ERROR: '82',
    PROCEDURE_NOT_ALLOWED: '83',
};

export default class DeviceFwuCharacteristicClient {
    constructor(options) {
        this.options = options;
        this.iqosBLEClient = new IqosBLEClient();
        this.isStopped = true;
        this.isLastFrameLoaded = false;
        this.isReadingFWUDataCharacteristic = false;
        this.exponentialBackOff = undefined;

        this.startFwUpdate();
    }

    stop = () => {
        if (this.isStopped) return;

        this.isReadingFWUDataCharacteristic = false;
        this.isStopped = true;
        this.stopFwuDataClient();
        this.iqosBLEClient.removeFwuStatusCharacteristicListener();
    };

    initNewFwuDataClient = () => {
        this.stopFwuDataClient();

        this.fwuDataClient = new FwuDataClient({
            fwuPackageData: this.options.fwuPackageData.program_blocks,
            onLastFrame: this.onLastFrame,
            onFail: this.onFwuFail,
        });
    };

    onLastFrame = () => {
        this.isLastFrameLoaded = true;
    };

    startFwUpdate = async () => {
        this.isStopped = false;

        log.debug(`DeviceFwuCharacteristicClient: start firmware update`);

        await this.iqosBLEClient.addFwuStatusCharacteristicListener(this.onFwuStatusNotification);
        await helpers.timeout(1000);

        this.sendStartUpgradeRequest();
    };

    sendStartUpgradeRequest = () => {
        this.tryToWriteValueToFwuControl(this.options.fwuPackageData.start_programming);
    };

    onFwuStatusNotification = async (value) => {
        if (this.isStopped) return;

        this.disableStartExponentialBackOff();
        this.stopFwuDataClient();

        const response = converter.buffer2hex(value);
        const statusName = objUtils.getKeyByValue(FWU_STATUS_VALUES, response);

        log.info(`DeviceFwuCharacteristicClient: fwu status message: ${response} - ${statusName}`);

        switch (response.toString()) {
            case FWU_STATUS_VALUES.UPLOAD_PROCESSING:
                this.startFwuDataClient();
                return;
            case FWU_STATUS_VALUES.IMAGE_UPLOAD_ERROR:
                if (this.isLastFrameLoaded) {
                    this.onFwuFail();
                } else {
                    this.readFwuDataCharacteristic();
                }
                break;
            case FWU_STATUS_VALUES.NO_IMAGE:
            case FWU_STATUS_VALUES.PROCEDURE_NOT_ALLOWED:
                this.stop();

                if (!this.isLastFrameLoaded && this.options.canRetry) {
                    this.options.retryToUpdateFW();
                } else {
                    this.onFwuFail();
                }

                break;
            case FWU_STATUS_VALUES.IMAGE_STORAGE_ERROR:
            case FWU_STATUS_VALUES.IMAGE_SIGNATURE_ERROR:
                this.onFwuFail();
                break;
            case FWU_STATUS_VALUES.UPGRADE_PROCESSING:
                this.options.onUpgradeProcessing();
                return;
            default:
                break;
        }
    };

    onFwuFail = () => {
        this.stop();
        this.options.onErrorWithoutRetry();
    };

    readFwuDataCharacteristic = async () => {
        if (!this.isReadingFWUDataCharacteristic) {
            this.isReadingFWUDataCharacteristic = true;
            await this.iqosBLEClient.readFwuDataCharacteristic(this.onFwuDataCharacteristicMessage);
        }
    };

    onFwuDataCharacteristicMessage = (value) => {
        if (this.isStopped) return;

        this.isReadingFWUDataCharacteristic = false;
        const response = converter.buffer2hex(value);

        const frameIndex = fwuService.convertHexIndexToInt(response);
        const frame = this.options.fwuPackageData.program_blocks[frameIndex];

        log.debug(`DeviceFwuCharacteristicClient: read data characteristic: ${response}, frameIndex: ${frameIndex}`);

        if (frame) {
            log.debug(`DeviceFwuCharacteristicClient: try to resend frame: ${frameIndex} - ${frame?.f}`);

            this.startFwuDataClient(frameIndex);
        } else {
            this.onFwuFail();
        }
    };

    startFwuDataClient = (frameIndex = 0) => {
        if (this.isStopped) return;
        this.initNewFwuDataClient();

        this.fwuDataClient.start(frameIndex);
    };

    stopFwuDataClient = () => {
        if (this.fwuDataClient) {
            this.fwuDataClient.stop();
            this.fwuDataClient = null;
        }
    };

    tryToWriteValueToFwuControl = (value) => {
        const clearExponentialBackOff = () => (this.exponentialBackOff = undefined);
        const toTry = async () => {
            log.debug(`DeviceFwuCharacteristicClient: try to writeValue: ${value}`);
            await this.iqosBLEClient.writeValueToFwuControlCharacteristic(value, true);
        };
        const success = async () => {
            log.debug(`DeviceFwuCharacteristicClient: writeValue: ${value} success`);
            clearExponentialBackOff();
        };
        const fail = () => {
            log.debug(`DeviceFwuCharacteristicClient: writeValue: ${value} failed`);
            this.onFwuFail();
            clearExponentialBackOff();
        };

        this.exponentialBackOff = new ExponentialBackOff();

        this.exponentialBackOff.run(3, 250, toTry, success, fail);
    };

    disableStartExponentialBackOff = () => {
        if (this.exponentialBackOff) this.exponentialBackOff.disable();
    };
}
