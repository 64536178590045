import React from 'react';

import imgWarningIcon1 from '../../../assets/images/icons/info-icon-yellow.png';
import imgWarningIcon2 from '../../../assets/images/icons/info-icon-yellow@2x.png';
import imgWarningIcon3 from '../../../assets/images/icons/info-icon-yellow@3x.png';
import Image from '../Image/Image';
import Popup from './Popup';
import styles from './Popup.module.scss';

const PopupWarning = (props) => {
    return (
        <Popup {...props}>
            <div className={styles.HeaderIcon}>
                <Image src={[imgWarningIcon1, imgWarningIcon2, imgWarningIcon3]} />
            </div>
            {props.children}
        </Popup>
    );
};

export default PopupWarning;
