import React from 'react';

import imgBluetoothDesktop1 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-desktop@3x.png';
import imgBluetoothDesktop2 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-desktop@3x.png';
import imgBluetoothDesktop3 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-desktop@3x.png';
import imgBluetoothMobile1 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-mob.png';
import imgBluetoothMobile2 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-mob@2x.png';
import imgBluetoothMobile3 from '../../../../../assets/images/not-compatible-browser/icon-bluetooth-mob@3x.png';
import ResponsiveImage from '../../../../components/Image/ResponsiveImage';

const NotCompatibleBrowserBluetoothImage = () => (
    <ResponsiveImage
        desktop={[imgBluetoothDesktop1, imgBluetoothDesktop2, imgBluetoothDesktop3]}
        mobile={[imgBluetoothMobile1, imgBluetoothMobile2, imgBluetoothMobile3]}
    />
);

export default NotCompatibleBrowserBluetoothImage;
