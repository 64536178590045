import React from 'react';
import Img from 'react-image/cjs';

import arrayUtils from '../../utils/arrayUtils';

const AppImage = ({src, ...restProps}) => {
    const srcSet = arrayUtils.toArray(src);

    return <Img {...restProps} src={srcSet} />;
};

export default AppImage;
