import * as iotMessageErrorCodes from '../../consts/iot/iotMessageErrorCodes';
import * as iotMessageStatusTypes from '../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import arrayUtils from '../../utils/arrayUtils';
import helpers from '../../utils/helpers';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import connectivityService from './connectivityService';
import IqosCommunicatorClient from './iqosCommunicatorClient';

let cyclicFramesData = {};
const CYCLIC_FRAME_TIMEOUT = 5000;

const resetCyclicFrames = () => {
    cyclicFramesData = {};
};

const processFrames = (props) => {
    const {status, type} = props;
    const isCyclicFrame =
        connectivityService.isHid() &&
        type === iotMessageTypes.DEVICE_STATUS_CHARACTERISTIC_USB &&
        status === iotMessageStatusTypes.STARTED;

    if (isCyclicFrame) {
        writeFrameCyclic(props);
    } else {
        writeFrameAndPublishResponse(props);
    }
};

const writeFrameCyclic = ({type, frames, request_id, processImmediately}) => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();
    const addFramesToQueue = () =>
        iqosCommunicatorClient.addFramesToQueue({
            frames,
            processImmediately,
            onSuccess: onSuccessResponse,
            onError: () => {
                resetCyclicFrames();
                mwIotMessageRequestService.publishErrorEvent({
                    type,
                    request_id,
                    errorCode: iotMessageErrorCodes.IO_ERROR_IO_GENERAL,
                });
            },
        });

    if (cyclicFramesData[type]) return;

    cyclicFramesData[type] = {};

    const onSuccessResponse = (responses) => {
        if (!cyclicFramesData[type]) return;
        const isCurrentEqualToPreviousResponse = arrayUtils.equals(cyclicFramesData[type].currentResponse, responses);

        if (!isCurrentEqualToPreviousResponse) {
            cyclicFramesData[type].currentResponse = responses;
            mwIotMessageRequestService.publishResponseEvent(type, request_id, responses);
        }

        cyclicFramesData[type].timeout = setTimeout(() => {
            if (cyclicFramesData[type]) {
                addFramesToQueue();
            }
        }, CYCLIC_FRAME_TIMEOUT);
    };

    addFramesToQueue();
};

const writeFrameAndPublishResponse = ({frames, onSuccess, onError, processImmediately}) => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.addFramesToQueue({
        frames,
        processImmediately,
        onSuccess: (responses) => {
            helpers.runFunction(onSuccess, responses);
        },
        onError: onError,
    });
};

const createNew = () => {
    new IqosCommunicatorClient(true);
    resetCyclicFrames();
};

const processQueue = () => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.processQueue();
};

const disable = () => {
    const iqosCommunicatorClient = new IqosCommunicatorClient();

    iqosCommunicatorClient.disable();
    resetCyclicFrames();
};

export default {
    createNew,
    disable,
    processFrames,
    processQueue,
    writeFrameAndPublishResponse,
};
