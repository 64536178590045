import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as productRegistrationStatusTypes from '../../consts/product/productRegistrationStatusTypes';
import {makeSelectCurrentProductToChangeRegistrationStatus} from '../../state/selectors/productSettings';
import ProductRegisteringStatus from './ProductRegisteringStatus/ProductRegisteringStatus';
import ProductUnregisteringStatus from './ProductUnregisteringStatus/ProductUnregisteringStatus';

const mapStateToProps = createStructuredSelector({
    currentProductToChangeRegistrationStatus: makeSelectCurrentProductToChangeRegistrationStatus(),
});

const ProductRegistrationStatus = (props) => {
    const {currentProductToChangeRegistrationStatus} = props;

    if (!currentProductToChangeRegistrationStatus) return null;

    const Component =
        currentProductToChangeRegistrationStatus.status === productRegistrationStatusTypes.REGISTERING
            ? ProductRegisteringStatus
            : ProductUnregisteringStatus;

    return (
        <Component
            productToChangeRegistrationStatus={currentProductToChangeRegistrationStatus}
            key={currentProductToChangeRegistrationStatus.deviceSerialNumber}
        />
    );
};

export default connect(mapStateToProps)(ProductRegistrationStatus);
