import ExponentialBackOff from '../../utils/exponentialBackOff';
import DisconnectEventEmitter from '../events/disconnectEventEmitter';

export default class ConnectedExponentialBackOff extends ExponentialBackOff {
    constructor() {
        super();
        this.subscribe();
    }

    subscribe() {
        const disconnectEventEmitter = new DisconnectEventEmitter();

        disconnectEventEmitter.onDeviceDisconnect(this.disable);
    }

    unsubscribe() {
        const disconnectEventEmitter = new DisconnectEventEmitter();

        disconnectEventEmitter.offDeviceDisconnect(this.disable);
    }

    run(max, delay, toTry, success, fail) {
        if (this.isEnabled) {
            toTry()
                .then((result) => {
                    this.unsubscribe();
                    success(result);
                })
                .catch((error) => {
                    if (max <= 0) {
                        this.unsubscribe();
                        fail(error);
                    } else {
                        if (this.isEnabled) {
                            setTimeout(() => {
                                this.run(--max, delay * 2, toTry, success, fail);
                            }, delay);
                        } else {
                            this.unsubscribe();
                        }
                    }
                });
        } else {
            this.unsubscribe();
        }
    }
}
