import {useEffect, useRef} from 'react';

import log from '../../../services/logger/log';
import productService from '../../../services/productService';
import {removeSchedulerFromProductsToChangeRegistrationStatus} from '../../../state/ducks/productSettings';
import {dispatch} from '../../../state/store';

const REQUEST_TIMEOUT_LIST_SEC = [1, 2, 3, 3, 3, 3, 15, 15, 15, 30 * 60, 60 * 60];

const useRegistrationInterval = (isSchedulerRequired, isHolder) => {
    const registrationTimeout = useRef(false);
    const stopScheduler = () => {
        log.debug(`useRegistrationInterval, stopScheduler`);
        clearTimeout(registrationTimeout.current);
    };

    useEffect(() => {
        if (isSchedulerRequired) {
            stopScheduler();
            let currentRequest = 0;

            const setRegistrationTimeout = () => {
                registrationTimeout.current = setTimeout(
                    timeoutFunction,
                    REQUEST_TIMEOUT_LIST_SEC[currentRequest] * 1000
                );
            };
            const timeoutFunction = () => {
                productService.fetchProductsStatusAndCheckIotProductStatus(isHolder);
                currentRequest++;

                if (currentRequest >= REQUEST_TIMEOUT_LIST_SEC.length) {
                    stopScheduler();
                } else {
                    setRegistrationTimeout();
                }
            };

            dispatch(removeSchedulerFromProductsToChangeRegistrationStatus());
            setRegistrationTimeout();
        }
    }, [isSchedulerRequired]);

    useEffect(() => {
        return () => stopScheduler();
    }, []);

    return [stopScheduler];
};

export default useRegistrationInterval;
