export default class ExponentialBackOff {
    constructor() {
        this.isEnabled = true;
    }

    run(max, delay, toTry, success, fail) {
        if (this.isEnabled) {
            toTry()
                .then((result) => success(result))
                .catch((error) => {
                    if (max <= 0) {
                        fail(error);
                    } else {
                        if (this.isEnabled) {
                            setTimeout(() => {
                                this.run(--max, delay * 2, toTry, success, fail);
                            }, delay);
                        }
                    }
                });
        }
    }

    disable() {
        this.isEnabled = false;
    }
}
