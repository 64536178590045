import React, {useState} from 'react';
import {connect} from 'react-redux';

import Button from '../../../components/Button/Button';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as iccProductStatusTypes from '../../../consts/icc/iccProductStatusTypes';
import backendService from '../../../services/app/backendService';
import gamClientService from '../../../services/gam/gamClientService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import marketConfigService from '../../../services/marketConfigService';
import productService from '../../../services/productService';
import routerService from '../../../services/routerService';
import storageService from '../../../services/storage/storageService';
import uamClientService from '../../../services/uam/uamClientService';
import uamService from '../../../services/uam/uamService';
import {hideLoader, setNotificationInfo, showLoader} from '../../../state/ducks/global';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../components/AboutDeviceButton/AboutDeviceButton.module.scss';
import * as deactivationStatuses from './deactivationStatuses';
import DeviceDeletePopup from './DeviceDeletePopup/DeviceDeletePopup';
import DeviceUamDeactivation from './DeviceUamDeactivation';

const LOADER_NAME = 'REGISTERING_LOADER';

const ProductRegistrationButton = (props) => {
    const {dispatch, device, deviceSerialNumber, isHolder, name, className, isDeviceReady} = props;
    const {productId, codentify, status, isP4} = device || {};

    const [isVisibleDeviceDeletePopup, setIsVisibleDeviceDeletePopup] = useState(false);
    const [unregisterBtnText, setUnregisterBtnText] = useState(
        getLocalizedStrings()[localizationKeys.ABOUT_DEVICE_REMOVE_DEVICE_TEXT]
    );
    const isGamBackend = backendService.isGamBackend();
    const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();
    const {dataFactory} = deviceDataFromLocalStorage || {};
    const isUamMode = uamService.isUamMode(isP4) || isGamBackend;
    const isUnregistrationAvailable =
        appConfig.getIsUnregistrationAvailable() && (!isGamBackend || dataFactory !== false);

    const onDeviceRegisterBtnClick = async () => {
        dispatch(showLoader({name: LOADER_NAME}));

        const registerData = {deviceSerialNumber, codentify, name, isP4, isHolder};

        await productService.registerDevice(registerData).finally(() => {
            dispatch(hideLoader(LOADER_NAME));
        });
    };
    const onDeviceDeleteBtnClick = () => setIsVisibleDeviceDeletePopup(!isVisibleDeviceDeletePopup);

    const onDeviceDeleteOk = async () => {
        if (isUamMode && !isDeviceReady) {
            dispatch(
                setNotificationInfo(getLocalizedStrings()[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT])
            );
            setIsVisibleDeviceDeletePopup(false);
            return;
        }

        dispatch(showLoader({name: LOADER_NAME}));

        if (isUamMode) {
            setUnregisterBtnText(getLocalizedStrings()[localizationKeys.YAP_DEACTIVATION_STATE_TEXT]);
            setIsVisibleDeviceDeletePopup(false);
            if (isGamBackend) {
                await gamClientService.deactivateDevice();
            } else {
                await uamClientService.deactivateDevice(deviceSerialNumber);
            }
            return;
        }

        productService
            .unregisterConsumerProduct({
                productId,
                codentify,
                deviceSerialNumber,
                isP4,
                isHolder,
            })
            .finally(async () => {
                setIsVisibleDeviceDeletePopup(false);

                dispatch(hideLoader(LOADER_NAME));
            });
    };

    const onDeactivationStatusChange = (deactivationStatus) => {
        switch (deactivationStatus) {
            case deactivationStatuses.DEACTIVATION_FAILED: {
                setUnregisterBtnText(getLocalizedStrings()[localizationKeys.ABOUT_DEVICE_REMOVE_DEVICE_TEXT]);
                setIsVisibleDeviceDeletePopup(false);
                dispatch(hideLoader(LOADER_NAME));
                break;
            }
            case deactivationStatuses.DEACTIVATION_FINISH: {
                setIsVisibleDeviceDeletePopup(false);
                dispatch(hideLoader(LOADER_NAME));
                break;
            }
            case deactivationStatuses.DEACTIVATING: {
                setUnregisterBtnText(getLocalizedStrings()[localizationKeys.YAP_DEACTIVATION_STATE_TEXT]);
                dispatch(showLoader({name: LOADER_NAME}));
                break;
            }
            case deactivationStatuses.UNREGISTERING:
                setUnregisterBtnText(getLocalizedStrings()[localizationKeys.YAP_UNREGISTRATION_STATE_TEXT]);
                break;
            default:
                setUnregisterBtnText(getLocalizedStrings()[localizationKeys.ABOUT_DEVICE_REMOVE_DEVICE_TEXT]);
        }
    };

    const unregistrationBtnActionProps = {};
    const productUnregistrationUrl = appConfig.getProductUnregistrationUrlFormatted();

    if (productUnregistrationUrl) {
        unregistrationBtnActionProps.onClick = () => routerService.redirect(productUnregistrationUrl);
    } else {
        unregistrationBtnActionProps.onClick = onDeviceDeleteBtnClick;
    }

    const getUnregistrationBtnAction = () => {
        if (productUnregistrationUrl) {
            routerService.redirect(productUnregistrationUrl);
        } else {
            onDeviceDeleteBtnClick();
        }
    };

    const getStatus = (statusType) => status === statusType;
    const isCurrentProductRegistered = getStatus(iccProductStatusTypes.REGISTERED);
    const isCurrentProductUnregistering = getStatus(iccProductStatusTypes.UNREGISTERING);
    const isCurrentProductRegistering = getStatus(iccProductStatusTypes.REGISTERING);
    const isRegistered = isCurrentProductRegistered || isCurrentProductUnregistering;
    const isDisabled = isCurrentProductUnregistering || isCurrentProductRegistering;
    const btnClassName = isDisabled ? styles.DeviceRegisteringStatus : undefined;
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    return (
        <>
            {isUamMode && <DeviceUamDeactivation onChange={onDeactivationStatusChange} />}

            <div className={`${styles.RegisterBtnContainer} ${className}`}>
                {isRegistered || !isIccJourneyScreensEnabled ? (
                    isUnregistrationAvailable && (
                        <Button
                            className={btnClassName}
                            disabled={isDisabled}
                            showArrow
                            onClick={getUnregistrationBtnAction}
                            testId={elementsTesIds.ABOUT_DEVICE_BTN_UNREGISTER}
                        >
                            {unregisterBtnText}
                        </Button>
                    )
                ) : (
                    <ButtonDark
                        className={`${styles.RegisterButtons} ${btnClassName}`}
                        disabled={isDisabled}
                        onClick={onDeviceRegisterBtnClick}
                        showArrow
                        testId={elementsTesIds.ABOUT_DEVICE_BTN_REGISTER}
                    >
                        {getLocalizedStrings()[localizationKeys.ABOUT_DEVICE_REGISTER_DEVICE_TEXT]}
                    </ButtonDark>
                )}
            </div>
            {isVisibleDeviceDeletePopup && (
                <DeviceDeletePopup
                    isUamMode={isUamMode}
                    onClose={onDeviceDeleteBtnClick}
                    onDeviceDelete={onDeviceDeleteOk}
                />
            )}
        </>
    );
};

export default connect()(ProductRegistrationButton);
