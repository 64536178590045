import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import './assets/scss/styles.scss';
import './assets/css/index.scss';
import './assets/css/layout.css';
import './assets/css/objects.scss';
import './assets/css/spacing.css';
import './assets/css/typography.scss';
import './assets/css/variables.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';

import appConfig from './app/config/appConfig';
import {initConfig} from './app/config/config';
import App from './app/containers/App/App';
import Routes from './app/containers/Routes/Routes';
import history from './app/services/history';
import log from './app/services/logger/log';
import {configureStore} from './app/state/store';

const render = async () => {
    await initConfig();

    if (appConfig.getIsMarketInitialized()) {
        log.setLevel(appConfig.getLogLevel(), appConfig.getAwsLogLevel());
    }

    const store = configureStore();

    ReactDOM.render(
        <Provider store={store}>
            <App>
                <Router history={history.getHistory()}>
                    <Routes />
                </Router>
            </App>
        </Provider>,
        document.getElementById('root')
    );
};

render();
