import React from 'react';

import Image from '../../../components/Image/Image';
import helpers from '../../../utils/helpers';
import styles from './SelectDeviceItem.module.scss';

const SelectDeviceItem = (props) => {
    const {image, name, onClick, type, testId} = props;

    const onClickFunc = () => helpers.runFunction(onClick, [type]);

    return (
        <div onClick={onClickFunc} className={styles.ImageContainer} data-testid={testId}>
            <Image className={`${styles.Image}`} src={image} alt={name} />
            <p className={'ica--txt-medium-light'}>{name}</p>
        </div>
    );
};

export default SelectDeviceItem;
