import React from 'react';

import PopupRounded from '../../../../components/Popup/PopupRounded';
import DeviceModeHeader from './components/DeviceModeHeader/DeviceModeHeader';
import styles from './DeviceModePopup.module.scss';

const DeviceModePopup = ({
    className,
    onClose,
    children,
    imgUrls,
    imgAlt,
    imgClassName,
    title,
    subTitle,
    headerChildren,
    layout,
    testId,
}) => {
    return (
        <PopupRounded
            className={`${styles.Popup} ${className ? className : ''}`}
            isClosingBySwipeEnabled
            onClose={onClose}
            testId={testId}
        >
            <DeviceModeHeader
                imgAlt={imgAlt}
                imgClassName={imgClassName}
                imgUrls={imgUrls}
                layout={layout}
                subTitle={subTitle}
                title={title}
            >
                {headerChildren}
            </DeviceModeHeader>
            {children}
        </PopupRounded>
    );
};

export default DeviceModePopup;
