import React from 'react';

import Button from '../Button';
import styles from './FloatButton.module.scss';

const FloatButton = ({className, isLeft, ...props}) => {
    const buttonClassName = `${styles.FloatButton} ${className ? className : ''} ${isLeft ? styles.IsLeft : ''}`;

    return <Button {...props} className={buttonClassName} />;
};

export default FloatButton;
