import React, {useEffect} from 'react';

import appConfig from '../../config/appConfig';
import backendService from '../../services/app/backendService';
import productService from '../../services/productService';
import stylesService from '../../services/stylesService';
import DeviceLockBtn from './components/DeviceLockBtn/DeviceLockBtn';

const DeviceLock = ({product, isDeviceReady, onDisableClick, lockTestId, unlockTestId}) => {
    if (!product) return null;

    const isDeviceSettingAvailable = appConfig.getIsDeviceSettingAvailable();
    const {parent_protection_mode, colorHex, status, isP4, isDeviceActivated} = product;
    const backgroundColorStyle = stylesService.getBackgroundColorStyle(colorHex);
    const isDeviceLockBtnVisible = isDeviceActivated && isP4 && isDeviceSettingAvailable;
    const isProductRegistered = productService.isStatusRegistered(status);
    const isGamBackend = backendService.isGamBackend();

    useEffect(() => {
        if (isGamBackend && isDeviceLockBtnVisible) {
            if (parent_protection_mode === undefined) {
                //todo: uncomment after device info implementation
                //appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_TEMPORARY_ERROR);
            }
        }
    }, []);

    return isDeviceLockBtnVisible ? (
        <DeviceLockBtn
            isDeviceLocked={parent_protection_mode}
            isDeviceReady={isDeviceReady}
            isProductRegistered={isProductRegistered}
            lockTestId={lockTestId}
            onDisableClick={onDisableClick}
            style={backgroundColorStyle}
            unlockTestId={unlockTestId}
        />
    ) : null;
};

export default DeviceLock;
