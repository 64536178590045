import {useEffect} from 'react';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import iotDeviceConnectionStatusService from '../../../services/iotDevice/iotDeviceConnectionStatusService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {setNotificationInfo} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';

const useShowDisconnectNotification = (connectionStatus, isYapUpdateSettingsInProgress = false) => {
    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(connectionStatus);

    useEffect(() => {
        const isDeviceDisconnected = iotDeviceConnectionStatusService.isDeviceDisconnected(connectionStatus);

        if (!isDeviceReady && !isDeviceDisconnected && !isYapUpdateSettingsInProgress) {
            dispatch(
                setNotificationInfo(getLocalizedStrings()[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT])
            );
        }
    }, [isDeviceReady, isYapUpdateSettingsInProgress]);
};

export default useShowDisconnectNotification;
