import * as localizationKeys from '../consts/app/localizationKeys';
import {IO_ERROR_IO_GENERAL} from '../consts/iot/iotMessageErrorCodes';
import {getLocalizedStrings} from '../services/localization/localizationService';
import {hideAllLoaders, setNotificationFailed} from '../state/ducks/global';
import {makeSelectIsDeviceReady} from '../state/selectors/iotDevice';
import {dispatch, getState} from '../state/store';
import helpers from '../utils/helpers';
import pairingService from './pairingService';

let instance = null;

export default class ErrorHandlerService {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.errorHandler = null;
        this.gamErrorHandler = null;
        this.iotErrorCodes = [];
        this.type = null;
    }

    setErrorHandler({iotErrorCodes, handler, type}) {
        this.errorHandler = handler;
        this.iotErrorCodes = iotErrorCodes;
        this.type = type;
    }

    setGamErrorHandler({handler}) {
        this.gamErrorHandler = handler;
    }

    removeErrorHandler() {
        this.errorHandler = null;
        this.gamErrorHandler = null;
        this.iotErrorCodes = [];
        this.type = null;
    }

    handleError(iotMessage) {
        const {type, body} = iotMessage;
        const error_code = body?.error?.code;

        if (type === this.type || this.iotErrorCodes.includes(error_code)) {
            helpers.runFunction(this.errorHandler, error_code);
        } else {
            const errorKey =
                error_code === IO_ERROR_IO_GENERAL
                    ? localizationKeys.IO_ERROR_NOTIFICATION_TEXT
                    : localizationKeys.GLOBAL_ERROR_NOTIFICATION_TEXT;

            dispatch(hideAllLoaders());
            pairingService.disconnectAndForwardToMyDevicesPage();

            dispatch(setNotificationFailed(getLocalizedStrings()[errorKey]));
        }
    }

    checkGamError() {
        const isDeviceReady = makeSelectIsDeviceReady()(getState());
        if (isDeviceReady) return;

        if (typeof this.gamErrorHandler === 'function') {
            helpers.runFunction(this.gamErrorHandler);
        } else {
            dispatch(hideAllLoaders());
            pairingService.disconnectAndForwardToMyDevicesPage();

            dispatch(setNotificationFailed(getLocalizedStrings()[localizationKeys.IO_ERROR_NOTIFICATION_TEXT]));
        }
    }
}
