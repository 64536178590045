import React, {useEffect, useRef, useState} from 'react';

import styles from './ProgressLoader.module.css';

const ProgressLoader = ({progress, className, classNameContainer}) => {
    const SVGCircleCenterCoord = '50%';
    const SVGBigCircleRadius = '46%';
    const SVGSmallCircleRadius = '39%';

    const [progressStep, setProgressStep] = useState(0);

    const initialStrokeDashoffset = 1100;
    const multipliedProgress = progress ? progress * progressStep : 0;
    const currentProgress = initialStrokeDashoffset - multipliedProgress;

    const animatedCircle = useRef();

    useEffect(() => {
        computeProgressStep();
    }, []);

    const computeProgressStep = () => {
        const circleBBox = animatedCircle.current?.getBBox();

        if (circleBBox) {
            const {height} = circleBBox;

            const circleLength = Math.ceil(height * Math.PI);

            const progressStep = circleLength / 100;

            setProgressStep(progressStep);
        }
    };

    return (
        <section className={classNameContainer ? classNameContainer : ''}>
            <svg className={`${styles.Loader} ${className ? className : ''}`}>
                <circle
                    className={styles.LoaderCircleStatic}
                    cx={SVGCircleCenterCoord}
                    cy={SVGCircleCenterCoord}
                    r={SVGBigCircleRadius}
                />
                <circle
                    className={styles.LoaderCircleStatic}
                    cx={SVGCircleCenterCoord}
                    cy={SVGCircleCenterCoord}
                    r={SVGSmallCircleRadius}
                />
                <circle
                    className={`${styles.LoaderCircleStatic} ${styles.LoaderCircleProgress}`}
                    cx={SVGCircleCenterCoord}
                    cy={SVGCircleCenterCoord}
                    r={SVGSmallCircleRadius}
                    ref={animatedCircle}
                    strokeDashoffset={currentProgress}
                />
            </svg>
        </section>
    );
};

export default ProgressLoader;
