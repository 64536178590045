import * as mwFirmwareErrorCodes from '../../consts/iot/mwFirmwareErrorCodes';
import {setFWUFinished} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import log from '../logger/log';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import DeviceFwuCharacteristicClient from './deviceFwuCharacteristicClient';

const FWU_MAX_RETRY_COUNT = 3;
let instance = null;

export default class DeviceFwuClient {
    constructor(createNew, fwuPackageData) {
        if (createNew && instance) {
            instance.stop();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;
        this.fwuPackageData = fwuPackageData;
        this.currentFwuTry = 0;
        this.deviceFwuCharacteristicClient = undefined;
    }

    stop = () => {
        if (this.deviceFwuCharacteristicClient) {
            this.deviceFwuCharacteristicClient.stop();
            this.deviceFwuCharacteristicClient = undefined;
        }
    };

    startFwUpdate = async () => {
        this.deviceFwuCharacteristicClient = new DeviceFwuCharacteristicClient({
            fwuPackageData: this.fwuPackageData,
            canRetry: this.currentFwuTry <= FWU_MAX_RETRY_COUNT,
            onUpgradeProcessing: () => {
                mwIotMessageRequestService.publishFwuFinishEvent();
                dispatch(setFWUFinished());
            },
            retryToUpdateFW: async () => {
                log.debug(`DeviceFwuClient: try to start FWU again: try #${this.currentFwuTry}`);
                this.currentFwuTry = this.currentFwuTry + 1;
                await helpers.timeout(500);
                await this.startFwUpdate();
            },
            onErrorWithoutRetry: () => {
                mwIotMessageRequestService.publishFwuErrorEvent(mwFirmwareErrorCodes.GENERAL);
            },
        });
    };
}
