import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import log from '../../../services/logger/log';
import uamService from '../../../services/uam/uamService';
import {setNotificationSuccess} from '../../../state/ducks/global';
import {removeProductToChangeRegistrationStatus} from '../../../state/ducks/productSettings';
import {makeSelectConsumerProduct} from '../../../state/selectors/consumer';
import useRegistrationInterval from '../effects/useRegistrationInterval';

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        consumerProduct: makeSelectConsumerProduct(ownProps.productToChangeRegistrationStatus),
    });

const ProductUnregisteringStatus = (props) => {
    const {dispatch, consumerProduct, productToChangeRegistrationStatus} = props;
    const {deviceSerialNumber, codentify, isP4, isSchedulerRequired, isHolder} = productToChangeRegistrationStatus;

    useRegistrationInterval(isSchedulerRequired, isHolder);

    useEffect(() => {
        if (!consumerProduct) {
            setDeviceUnregistered();
        }
    }, [consumerProduct]);

    const setDeviceUnregistered = async () => {
        if (!isHolder) {
            log.info('End unregistration process. Device is successfully unregistered.');
            const isUamMode = uamService.isUamMode(true);

            dispatch(
                setNotificationSuccess(
                    getLocalizedStrings()[
                        isUamMode
                            ? localizationKeys.YAP_UNREGISTRATION_SUCCESSFULLY_TEXT
                            : localizationKeys.DEVICE_UNREGISTER_SUCCESSFUL_TEXT
                    ]
                )
            );
        }

        dispatch(removeProductToChangeRegistrationStatus({deviceSerialNumber, codentify, isP4}));
    };

    return null;
};

export default connect(mapStateToProps)(ProductUnregisteringStatus);
