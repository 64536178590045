export const REFRESH_TOKEN = '/identity/refresh-token?identity-key={0}';
export const GET_ASSETS = '/assets';
export const ACTIVATE_DEVICE = '/asset/{0}/device/actions/activate';
export const DEACTIVATE_DEVICE = '/asset/{0}/device/actions/deactivate';
export const CONNECT_DEVICE = '/asset/{0}/device/connectivity/connect';
export const CHECK_REQUEST_STATUS = '/asset/{0}/device/request-status/{1}';
export const CHECK_CONNECTIVITY_STATUS = '/asset/{0}/device/connectivity/status';
export const SYNCHRONIZE_DEVICE = '/asset/{0}/device/actions/synchronize';
export const GET_DEVICE_INFO = '/assets?filter-asset={0}';
export const ASSET_SUBSCRIBE = '/assets/{0}/subscription';
export const ASSET_ACTIVATION_STATUS = '/asset/device/activation-status?codentify-code={0}';
