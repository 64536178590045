import * as gamUpdateSettingsTypes from '../../consts/gam/gamUpdateSettingsTypes';
import * as gamResponsiveDrawModeTypes from '../../consts/gam/responsiveDrawModeTypes';
import * as iotParentProtectionStatus from '../../consts/iot/iotParentProtectionStatus';
import * as responsiveDrawModeTypes from '../../consts/iot/responsiveDrawModeTypes';
import gamClientService from './gamClientService';

const publishConnectionCareMessage = () => {};

const publishVibrationAlarmMessage = () => {
    const message = {
        settingName: gamUpdateSettingsTypes.ALARM_PROFILE,
        newValue: {alarm_profile: 'VIBRATE'},
    };

    publishUiMessage(message);
};

const publishStopVibrationAlarmMessage = () => {
    const message = {
        settingName: gamUpdateSettingsTypes.ALARM_PROFILE,
        newValue: {alarm_profile: 'STOP_VIBRATE'},
    };

    publishUiMessage(message);
};

const publishParentProtectionEnableMessage = () => {
    publishParentProtectionStatusMessage(iotParentProtectionStatus.PARENT_PROTECTION_ENABLED);
};

const publishParentProtectionDisableMessage = () => {
    publishParentProtectionStatusMessage(iotParentProtectionStatus.PARENT_PROTECTION_DISABLED);
};

const publishParentProtectionStatusMessage = (protectionType) => {
    const message = {
        settingName: gamUpdateSettingsTypes.PARENT_PROTECTION,
    };

    if (protectionType) {
        message.newValue = {
            protection_type: protectionType,
            timeout: null,
        };
    }

    publishUiMessage(message);
};

const publishResetMessage = () => {};

const publishCheckFirmwareMessage = () => {};

const publishStartUpdateFirmwareMessage = () => {};

const publishGetLedSettingsMessage = () => {};

const publishGetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishGetResponsiveDrawSettingsMessage = () => publishResponsiveDrawSettingsMessage();

const publishGetVapeCloudSizeSettingsMessage = () => publishVapeCloudSizeSettingsMessage();

const publishSetLedSettingsMessage = (mode) => {
    const message = {
        settingName: gamUpdateSettingsTypes.LED_INTENSITY,
    };

    if (mode) {
        message.newValue = {intensity: mode};
    }

    publishUiMessage(message);
};

const publishSetResponsiveDrawSettingsMessage = (mode) => publishResponsiveDrawSettingsMessage(mode);

const publishSetVapeCloudSizeSettingsMessage = (mode) => publishVapeCloudSizeSettingsMessage(mode);

const publishSetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishResetVibrationSettingsMessage = () => publishVibrationSettingsMessage();

const publishResponsiveDrawSettingsMessage = (mode) => {
    const message = {
        settingName: gamUpdateSettingsTypes.RESPONSIVE_DRAW_PROFILE,
    };

    if (mode) {
        if (mode === responsiveDrawModeTypes.VIVACE) {
            mode = gamResponsiveDrawModeTypes.ALLEGRO;
        } else if (mode === responsiveDrawModeTypes.STACCATO) {
            mode = gamResponsiveDrawModeTypes.VIVALDI;
        }
        message.newValue = {haptic_pattern: mode};
    }

    publishUiMessage(message);
};

const publishVapeCloudSizeSettingsMessage = (mode) => {
    const message = {
        settingName: gamUpdateSettingsTypes.VAPE_CLOUD_SIZE,
    };

    if (mode) {
        message.newValue = {heating_profile: mode};
    }

    publishUiMessage(message);
};

const publishVibrationSettingsMessage = () => {};

const publishCleaningMessage = () => {};

const publishHolderStatusMessage = () => {};

const publishUiMessage = async (message) => {
    await gamClientService.updateGamSettings(message);
};

export default {
    publishCheckFirmwareMessage,
    publishCleaningMessage,
    publishConnectionCareMessage,
    publishGetLedSettingsMessage,
    publishGetVibrationSettingsMessage,
    publishHolderStatusMessage,
    publishParentProtectionDisableMessage,
    publishParentProtectionEnableMessage,
    publishParentProtectionStatusMessage,
    publishResetMessage,
    publishResetVibrationSettingsMessage,
    publishSetLedSettingsMessage,
    publishSetVibrationSettingsMessage,
    publishStartUpdateFirmwareMessage,
    publishStopVibrationAlarmMessage,
    publishVibrationAlarmMessage,
    publishGetResponsiveDrawSettingsMessage,
    publishSetResponsiveDrawSettingsMessage,
    publishGetVapeCloudSizeSettingsMessage,
    publishSetVapeCloudSizeSettingsMessage,
};
