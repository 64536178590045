import React from 'react';

import Button from '../Button';
import styles from './ButtonBorder.module.css';

const ButtonBorder = (props) => (
    <Button {...props} className={`${styles.ButtonBorder} ${props.className && props.className}`} />
);

export default ButtonBorder;
