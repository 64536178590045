export const CONNECTION = 0;
export const IDLE = 1;
export const DEVICE_INFO = 2;
export const COLLECTING_DATA = 3;
export const DIAGNOSTICS = 4;
export const VIBRATION_ALARM = 5;
export const RESET = 6;
export const LED = 7;
export const VIBRATION = 8;
export const CLEANING = 9;
export const FIRMWARE_UPDATE = 10;
export const CONNECTION_CARE = 11;
export const PARENT_PROTECTION = 12;
export const RESPONSIVE_DRAW_PROFILES = 13;
export const VAPE_CLOUD_SIZE = 14;
export const HOLDER_STATUS = 15;
export const DEVICE_STATUS_CHARACTERISTIC = 16;
export const DEVICE_STATUS_CHARACTERISTIC_USB = 17;
export const BATTERY_INFORMATION_CHARACTERISTIC = 18;
export const DEVICE_PING = 19;
