import {makeSelectConsumerProductsAssets, makeSelectConsumerProductsInfo} from '../../state/selectors/consumer';
import {getState} from '../../state/store';

const getConsumerProductsAssets = () => makeSelectConsumerProductsAssets()(getState());
const getConsumerProductsInfo = () => makeSelectConsumerProductsInfo()(getState());

export default {
    getConsumerProductsAssets,
    getConsumerProductsInfo,
};
