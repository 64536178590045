import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as iotErrorModeTypes from '../../consts/iot/iotErrorModeTypes';
import iotDeviceResetService from '../../services/iotDevice/iotDeviceResetService';
import storageService from '../../services/storage/storageService';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import dateTimeUtils from '../../utils/dateTimeUtils';
import DeviceSettingsPage from '../DeviceSettingsPage/DeviceSettingsPage';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
});

const ResetDevicePage = (props) => {
    const {iotDevice} = props;
    const {errors, deviceSerialNumber} = iotDevice;

    useEffect(() => {
        iotDeviceResetService.resetDevice();
        storeErrorData();
    }, []);

    const storeErrorData = () => {
        const isAnyError = errors?.length > 0;

        if (isAnyError) {
            const isM3Exist = errors?.some(({error_mode}) => error_mode === iotErrorModeTypes.M3);

            if (!isM3Exist) {
                const diagnosticData = {
                    deviceSerialNumber,
                    diagnosticTime: dateTimeUtils.getTimeNowInMilliseconds(),
                };
                storageService.setDeviceDataToStorage(diagnosticData);
            }
        }
    };

    return <DeviceSettingsPage />;
};

export default connect(mapStateToProps)(ResetDevicePage);
