import cryptoService from '../cryptoService';

export default class StorageClient {
    constructor(storage) {
        this.storage = storage;
    }

    getItem = (key) => JSON.parse(this.storage.getItem(key));

    setItem = (key, value) => this.storage.setItem(key, JSON.stringify(value));

    removeItem = (key) => this.storage.removeItem(key);

    getEncodedItem = (key) => {
        try {
            const item = this.storage.getItem(key);
            const encodedItem = cryptoService.encodeAtob(item);

            return JSON.parse(encodedItem);
        } catch (e) {
            return null;
        }
    };

    setDecodedItem = (key, value) => this.storage.setItem(key, cryptoService.decodeBtoa(JSON.stringify(value)));
}
