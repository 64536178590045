import React from 'react';

import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import PopupError from '../../../components/Popup/PopupError';
import appConfig from '../../../config/appConfig';
import standaloneConfig from '../../../config/standaloneConfig/standaloneConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/routerService';

const NotCompatibleOSPopup = () => {
    const {countryCodeConfig} = standaloneConfig || {};

    const localizedStrings = getLocalizedStrings();
    const standaloneLocalization = countryCodeConfig?.localization.en || {};
    const localizationSource = localizedStrings ? localizedStrings : standaloneLocalization;
    const iqosHomeUrl = appConfig.getIqosHomeUrlFormatted();

    return (
        <PopupError>
            <h2>{localizationSource[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_TITLE]}</h2>
            <p className={`txt--14 ica--txt-regular`}>
                {localizationSource[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE]}
            </p>
            <ButtonDark isExternalLink showArrow onClick={() => routerService.redirect(iqosHomeUrl)}>
                {localizationSource[localizationKeys.PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON]}
            </ButtonDark>
        </PopupError>
    );
};

export default NotCompatibleOSPopup;
