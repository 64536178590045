import React from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import Container from '../Layout/Container/Container';
import styles from './GuideSliderItem.module.scss';

const GuideSliderItem = (props) => {
    const {header, description, subTitle, element} = props;
    const localizedStrings = getLocalizedStrings();
    const headerByLocalizationKey = localizedStrings[localizationKeys[header]];
    const descriptionByLocalizationKey = localizedStrings[localizationKeys[description]];
    const headerText = headerByLocalizationKey ? headerByLocalizationKey : header;
    const descriptionText = descriptionByLocalizationKey ? descriptionByLocalizationKey : description;

    return (
        <div className={styles.GuideItem}>
            <Container className={styles.Container}>
                <div className={styles.BackgroundContainer}>
                    <div className={styles.MediaContainer}>{element}</div>
                    <div className={styles.TextContainer}>
                        <span className={`${styles.SubTitle} txt--11`}>{subTitle}</span>
                        <h2 className={styles.Title}>{headerText}</h2>
                        <div className={`${styles.Description} ica--txt-regular ica--txt-regular-fixed`}>
                            {descriptionText}
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default GuideSliderItem;
