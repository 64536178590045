import React from 'react';

import imgP1Desktop1 from '../../../../../assets/images/not-compatible-browser/device-settings-P1.png';
import imgP1Desktop2 from '../../../../../assets/images/not-compatible-browser/device-settings-P1@2x.png';
import imgP1Desktop3 from '../../../../../assets/images/not-compatible-browser/device-settings-P1@3x.png';
import imgP4Desktop1 from '../../../../../assets/images/not-compatible-browser/device-settings-P4.png';
import imgP4Desktop2 from '../../../../../assets/images/not-compatible-browser/device-settings-P4@2x.png';
import imgP4Desktop3 from '../../../../../assets/images/not-compatible-browser/device-settings-P4@3x.png';
import imgP1Mobile1 from '../../../../../assets/images/not-compatible-browser/iphoneP1.png';
import imgP1Mobile2 from '../../../../../assets/images/not-compatible-browser/iphoneP1@2x.png';
import imgP1Mobile3 from '../../../../../assets/images/not-compatible-browser/iphoneP1@3x.png';
import imgP4Mobile1 from '../../../../../assets/images/not-compatible-browser/iphoneP4.png';
import imgP4Mobile2 from '../../../../../assets/images/not-compatible-browser/iphoneP4@2x.png';
import imgP4Mobile3 from '../../../../../assets/images/not-compatible-browser/iphoneP4@3x.png';
import Image from '../../../../components/Image/Image';
import layoutService from '../../../../services/layoutService';

const NotCompatibleBrowserHeaderImage = ({layout, enableP1Support}) => {
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    let srcSet;

    if (isDesktopLayout) {
        srcSet = enableP1Support
            ? [imgP1Desktop1, imgP1Desktop2, imgP1Desktop3]
            : [imgP4Desktop1, imgP4Desktop2, imgP4Desktop3];
    } else {
        srcSet = enableP1Support
            ? [imgP1Mobile1, imgP1Mobile2, imgP1Mobile3]
            : [imgP4Mobile1, imgP4Mobile2, imgP4Mobile3];
    }

    return <Image src={srcSet} />;
};

export default NotCompatibleBrowserHeaderImage;
