import uaUtils from '../utils/uaUtils';

const onTouchStartEvent = 'ontouchstart';

const isMobile = {
    Android: () => navigator.userAgent.match(/Android/i),
    iOS: () => navigator.userAgent.match(/iPhone|iPod/i),
    any: () => isMobile.Android() || isMobile.iOS(),
};

const isTablet = {
    iOS: () => {
        const userAgent = navigator.userAgent;

        const isIPadWordExists = userAgent.match(/iPad/i);

        if (isIPadWordExists) {
            return isIPadWordExists;
        } else {
            const isMacintoshWordExists = userAgent.match(/Macintosh/i);

            if (isMacintoshWordExists) {
                const isTouchDevice = isTouchSupported();

                return !userAgent.match(/Chrome/i) && isTouchDevice;
            }
        }
    },
};

const isDesktop = {
    Windows: () => navigator.userAgent.match(/Windows/i),
    Mac: () => navigator.userAgent.match(/Macintosh/i),
    Linux: () => navigator.userAgent.match(/Linux/i),
};

const isBluefyBrowser = () => navigator.userAgent.match(/Bluefy/i);

const isTouchSupported = () => {
    return onTouchStartEvent in window || navigator.maxTouchPoints || navigator.msMaxTouchPoints;
};

const isMobileDevice = () => !!isMobile.any();

const isMobileAndroidDevice = () => !!isMobile.Android();

const isMobileIOSDevice = () => !!isMobile.iOS();

const isTabletIOSDevice = () => !!isTablet.iOS();

const isDesktopWindows = () => !!isDesktop.Windows();

const isDesktopMac = () => !!isDesktop.Mac();

const isDesktopLinux = () => !!isDesktop.Linux();

const isIos_13_4_x = () => {
    const {name, version} = uaUtils.getOS();

    return name.toLowerCase() === 'ios' && version.match('13.4');
};

const isIosLessThan_13_5 = () => {
    const ACCEPTABLE_VERSION_MAJOR = 13;
    const ACCEPTABLE_VERSION_MINOR = 5;

    const {name, version} = uaUtils.getOS();

    const versionMajor = version.split('.')[0];
    const versionMinor = version.split('.')[1];

    return (
        name.toLowerCase() === 'ios' &&
        versionMajor <= ACCEPTABLE_VERSION_MAJOR &&
        versionMinor < ACCEPTABLE_VERSION_MINOR
    );
};

const isBluetoothSupported = () => !!navigator.bluetooth;

const isAppleTouchDevice = () => isDesktopMac() && isTouchSupported();

const getUserAgent = () => navigator.userAgent;

export default {
    isAppleTouchDevice,
    isBluefyBrowser,
    isBluetoothSupported,
    isDesktopLinux,
    isDesktopMac,
    isDesktopWindows,
    isIos_13_4_x,
    isIosLessThan_13_5,
    isMobileAndroidDevice,
    isMobileDevice,
    isMobileIOSDevice,
    isTabletIOSDevice,
    isTouchSupported,
    getUserAgent,
};
