import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import layoutService from '../../services/layoutService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import Image from './Image';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const ResponsiveImage = ({desktop, mobile, tablet, className, layout}) => {
    if (!mobile?.length && !desktop?.length && !tablet?.length) return null;

    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const isTabletLayout = layoutService.isTabletLayout(layout);

    if (isDesktopLayout && desktop) {
        return <Image src={desktop} className={className} />;
    } else if (isTabletLayout && tablet) {
        return <Image src={tablet} className={className} />;
    } else if (mobile) {
        return <Image src={mobile} className={className} />;
    }
};

export default connect(mapStateToProps)(ResponsiveImage);
