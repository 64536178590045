import {createBrowserHistory} from 'history';

import appConfig from '../config/appConfig';

let history;

const getHistory = () => {
    if (!history) {
        history = createBrowserHistory({
            basename: appConfig.getBaseNameFormatted(),
        });
    }

    return history;
};

export default {
    getHistory,
};
