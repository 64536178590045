import consoleLog from './consoleLogger';
import SmartLogger from './smartLogger';

const log = {
    setLevel: (consoleLogLevel, awsLoggerLevel) => {
        const smartLogger = new SmartLogger();

        consoleLog.setLevel(consoleLogLevel);
        smartLogger.setLevel(awsLoggerLevel);
    },
    debug: (msg) => {
        const smartLogger = new SmartLogger();

        consoleLog.debug(msg);
        smartLogger.debug(msg);
    },
    info: (msg) => {
        const smartLogger = new SmartLogger();

        consoleLog.info(msg);
        smartLogger.info(msg);
    },
    error: (msg) => {
        const smartLogger = new SmartLogger();
        consoleLog.error(msg);
        smartLogger.error(msg);
    },
};

export default log;
