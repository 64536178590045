import imgIconVivace from '../../../../assets/images/settings/responsive-draw-vivace-icon.png';
import imgIconVivace2 from '../../../../assets/images/settings/responsive-draw-vivace-icon@2x.png';
import imgIconVivace3 from '../../../../assets/images/settings/responsive-draw-vivace-icon@3x.png';
import imgIconCharge from '../../../../assets/images/tips-tutorials-list/icon-charge-black.png';
import imgIconCharge2 from '../../../../assets/images/tips-tutorials-list/icon-charge-black@2x.png';
import imgIconCharge3 from '../../../../assets/images/tips-tutorials-list/icon-charge-black@3x.png';
import imgIconClean from '../../../../assets/images/tips-tutorials-list/icon-clean-black.png';
import imgIconClean2 from '../../../../assets/images/tips-tutorials-list/icon-clean-black@2x.png';
import imgIconClean3 from '../../../../assets/images/tips-tutorials-list/icon-clean-black@3x.png';
import imgIconDos from '../../../../assets/images/tips-tutorials-list/icon-dodont-black.png';
import imgIconDos2 from '../../../../assets/images/tips-tutorials-list/icon-dodont-black@2x.png';
import imgIconDos3 from '../../../../assets/images/tips-tutorials-list/icon-dodont-black@3x.png';
import imgIconHowTo from '../../../../assets/images/tips-tutorials-list/icon-howto-black.png';
import imgIconHowTo2 from '../../../../assets/images/tips-tutorials-list/icon-howto-black@2x.png';
import imgIconHowTo3 from '../../../../assets/images/tips-tutorials-list/icon-howto-black@3x.png';
import imgTutorialListCortez from '../../../../assets/images/tips-tutorials-list/tips&tutorials_list_cortez.png';
import imgTutorialListMulti from '../../../../assets/images/tips-tutorials-list/tips&tutorials_list_multi.png';
import imgTutorialListOrdinary from '../../../../assets/images/tips-tutorials-list/tips&tutorials_list_ordinary.png';
import imgTutorialListSoyuz from '../../../../assets/images/tips-tutorials-list/tips&tutorials_list_soyuz.png';
import imgTutorialListVeev from '../../../../assets/images/tips-tutorials-list/tips&tutorials_list_veev.png';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import tutorialTypes from '../../../consts/app/tutorialTypes';
import DEVICE_TYPES from '../../../consts/device/deviceTypes';
import {getLocalizedStrings} from '../../../services/localization/localizationService';

let data;

const getTutorialListData = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = {
            [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: {
                header: {
                    title: localizedStrings[localizationKeys.TIPS_ORDINARY_TITLE],
                    desc: localizedStrings[localizationKeys.TIPS_IQOS_DESCRIPTION],
                    image: getImageFromConfigOrDefault(DEVICE_TYPES.DEV_TYPE_V24P_CHARGER, imgTutorialListOrdinary),
                },
                buttons: [
                    {
                        icons: [imgIconHowTo, imgIconHowTo2, imgIconHowTo3],
                        name: localizedStrings[localizationKeys.ORDINARY_FIRST_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
                    },
                    {
                        icons: [imgIconClean, imgIconClean2, imgIconClean3],
                        name: localizedStrings[localizationKeys.ORDINARY_SECOND_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE,
                    },
                    {
                        icons: [imgIconCharge, imgIconCharge2, imgIconCharge3],
                        name: localizedStrings[localizationKeys.ORDINARY_THIRD_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
                    },
                    {
                        icons: [imgIconDos, imgIconDos2, imgIconDos3],
                        name: localizedStrings[localizationKeys.ORDINARY_FOURTH_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS,
                    },
                ],
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: {
                header: {
                    title: localizedStrings[localizationKeys.TIPS_CORTEZ_TITLE],
                    desc: localizedStrings[localizationKeys.TIPS_IQOS_DESCRIPTION],
                    image: getImageFromConfigOrDefault(DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER, imgTutorialListCortez),
                },
                buttons: [
                    {
                        icons: [imgIconHowTo, imgIconHowTo2, imgIconHowTo3],
                        name: localizedStrings[localizationKeys.CORTEZ_FIRST_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
                    },
                    {
                        icons: [imgIconClean, imgIconClean2, imgIconClean3],
                        name: localizedStrings[localizationKeys.CORTEZ_SECOND_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE,
                    },
                    {
                        icons: [imgIconCharge, imgIconCharge2, imgIconCharge3],
                        name: localizedStrings[localizationKeys.CORTEZ_THIRD_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
                    },
                    {
                        icons: [imgIconDos, imgIconDos2, imgIconDos3],
                        name: localizedStrings[localizationKeys.CORTEZ_FOURTH_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS,
                    },
                ],
            },
            [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: {
                header: {
                    title: localizedStrings[localizationKeys.TIPS_SOYUZ_TITLE],
                    desc: localizedStrings[localizationKeys.TIPS_IQOS_DESCRIPTION],
                    image: getImageFromConfigOrDefault(DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER, imgTutorialListSoyuz),
                },
                buttons: [
                    {
                        icons: [imgIconHowTo, imgIconHowTo2, imgIconHowTo3],
                        name: localizedStrings[localizationKeys.SOYUZ_FIRST_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
                    },
                    {
                        icons: [imgIconClean, imgIconClean2, imgIconClean3],
                        name: localizedStrings[localizationKeys.SOYUZ_SECOND_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE,
                    },
                    {
                        icons: [imgIconCharge, imgIconCharge2, imgIconCharge3],
                        name: localizedStrings[localizationKeys.SOYUZ_THIRD_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
                    },
                    {
                        icons: [imgIconDos, imgIconDos2, imgIconDos3],
                        name: localizedStrings[localizationKeys.SOYUZ_FOURTH_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS,
                    },
                ],
            },
            [DEVICE_TYPES.DEV_TYPE_P1V30M]: {
                header: {
                    title: localizedStrings[localizationKeys.TIPS_MULTI_TITLE],
                    desc: localizedStrings[localizationKeys.TIPS_IQOS_DESCRIPTION],
                    image: getImageFromConfigOrDefault(DEVICE_TYPES.DEV_TYPE_P1V30M, imgTutorialListMulti),
                },
                buttons: [
                    {
                        icons: [imgIconHowTo, imgIconHowTo2, imgIconHowTo3],
                        name: localizedStrings[localizationKeys.MULTI_FIRST_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
                    },
                    {
                        icons: [imgIconClean, imgIconClean2, imgIconClean3],
                        name: localizedStrings[localizationKeys.MULTI_SECOND_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE,
                    },
                    {
                        icons: [imgIconCharge, imgIconCharge2, imgIconCharge3],
                        name: localizedStrings[localizationKeys.MULTI_THIRD_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
                    },
                    {
                        icons: [imgIconDos, imgIconDos2, imgIconDos3],
                        name: localizedStrings[localizationKeys.MULTI_FOURTH_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS,
                    },
                ],
            },
            [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: {
                header: {
                    title: localizedStrings[localizationKeys.TIPS_P4_TITLE],
                    desc: localizedStrings[localizationKeys.TIPS_P4_DESCRIPTION],
                    image: getImageFromConfigOrDefault(DEVICE_TYPES.DEV_TYPE_P4_M3_G2, imgTutorialListVeev),
                },
                buttons: [
                    {
                        icons: [imgIconHowTo, imgIconHowTo2, imgIconHowTo3],
                        name: localizedStrings[localizationKeys.P4_FIRST_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
                    },
                    {
                        icons: [imgIconClean, imgIconClean2, imgIconClean3],
                        name: localizedStrings[localizationKeys.P4_SECOND_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
                    },
                    {
                        icons: [imgIconCharge, imgIconCharge2, imgIconCharge3],
                        name: localizedStrings[localizationKeys.P4_THIRD_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_POD_GUIDE,
                    },
                    {
                        icons: [imgIconVivace, imgIconVivace2, imgIconVivace3],
                        name: localizedStrings[localizationKeys.P4_FOURTH_ITEM_TITLE],
                        type: tutorialTypes.TUTORIAL_TYPE_RESPONSIVE_DRAW_GUIDE,
                    },
                ],
            },
        };
    }

    return data;
};

const getImageFromConfigOrDefault = (deviceType, defaultImage) => {
    const deviceRelatedImage = appConfig.getTutorialsLifestyleImage(deviceType);

    return deviceRelatedImage ? [deviceRelatedImage] : [defaultImage];
};

export {getTutorialListData};
