const types = {
    ERROR: 0,
    CHECKING: 1,
    STARTED: 2,
    FINISHED: 3,
    SUCCESS: 4,
};

const methods = {
    isFinished: (type) => type === types.FINISHED,
};

export default {
    ...types,
    ...methods,
};
