import appConfig from '../../config/appConfig';
import * as yapModeTypes from '../../consts/yap/yapModeTypes';

const getYapMode = (type) => appConfig.getYapMode() === type;

const isYapEncryptedMode = () => getYapMode(yapModeTypes.YAP_ENCRYPTED_MODE);
const isYapMode = () => !getYapMode(yapModeTypes.YAP_DISABLED_MODE);
const isYapParentProtectionMode = () => getYapMode(yapModeTypes.YAP_PARENT_PROTECTION_MODE);

export default {
    isYapEncryptedMode,
    isYapMode,
    isYapParentProtectionMode,
};
