import {createSelector} from 'reselect';

const selectMwDevice = (state) => state.mwDevice;

const makeMwDeviceInternalId = () => createSelector(selectMwDevice, (globalState) => globalState.deviceSerialNumber);

const makeMwDeviceIsIdentified = () => createSelector(selectMwDevice, (globalState) => globalState.isIdentified);

const makeMwDeviceIsDisconnectedWithNoReconnect = () =>
    createSelector(selectMwDevice, (globalState) => globalState.isDisconnectedWithNoReconnect);

const makeMwDeviceFirmwareData = () => createSelector(selectMwDevice, (globalState) => globalState.firmwareData);

export {
    makeMwDeviceFirmwareData,
    makeMwDeviceInternalId,
    makeMwDeviceIsDisconnectedWithNoReconnect,
    makeMwDeviceIsIdentified,
};
