const THUMBNAIL_NAME_PREFIX = '_50x50';

const getThumbnailName = (mediaId) => {
    const thumbnailName = mediaId ? mediaId.replace(/(\.[\w\d_-]+)$/i, `${THUMBNAIL_NAME_PREFIX}$1`) : undefined;

    return thumbnailName;
};

const getDeviceAssetsList = (assets) => {
    const mediaNames = assets.filter((mediaId) => mediaId);
    const mediaThumbnails = mediaNames.map((mediaId) => getThumbnailName(mediaId));
    const result = [...mediaNames, ...mediaThumbnails];

    return result;
};

export default {
    getThumbnailName,
    getDeviceAssetsList,
};
