import classnames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import ErrorIcon from '../../components/ErrorIcon/ErrorIcon';
import Container from '../../components/Layout/Container/Container';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import LogoutButton from '../LogoutButton/LogoutButton';
import styles from './AgeVerificationFailedPage.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const AgeVerificationFailedPage = ({layout}) => {
    const localizedStrings = getLocalizedStrings();
    const isMobileLayout = layoutService.isMobileLayout(layout);

    const descriptionClassName = classnames(styles.Description, 'ica--txt-regular');

    const iqosHomeUrl = appConfig.getIqosHomeUrlFormatted();
    const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

    return (
        <div className={styles.Page}>
            {isRememberMeEnabled && isMobileLayout && (
                <Container isFloatHeight>
                    <LogoutButton />
                </Container>
            )}
            <Container className={styles.Container}>
                <div className={styles.Content}>
                    <ErrorIcon className={styles.Icon} />
                    <h2 className={styles.Title}>
                        {localizedStrings[localizationKeys.HARD_AGE_VERIFICATION_FAILED_TITLE_TEXT]}
                    </h2>
                    <p className={descriptionClassName}>
                        {localizedStrings[localizationKeys.HARD_AGE_VERIFICATION_FAILED_DESCRIPTION_TEXT]}
                    </p>
                </div>
                {iqosHomeUrl && (
                    <ButtonDark showArrow className={styles.Button} to={iqosHomeUrl} isExternalLink>
                        {localizedStrings[localizationKeys.HARD_AGE_VERIFICATION_FAILED_GO_TO_WEBSITE_BUTTON_TEXT]}
                    </ButtonDark>
                )}
                {isRememberMeEnabled && !isMobileLayout && <LogoutButton className={styles.LogoutButton} />}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(AgeVerificationFailedPage);
