import classnames from 'classnames';
import React from 'react';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import FixedContainer from '../../Layout/FixedContainer/FixedContainer';
import Overlay from '../../Layout/Overlay/Overlay';
import styles from './AppLoader.module.scss';

const AppLoader = ({isVisible, loaderText, isOpaque}) => {
    const localizedStrings = getLocalizedStrings();

    const overlayClassName = classnames({[styles.Opaque]: isOpaque});

    return (
        isVisible && (
            <FixedContainer>
                <Overlay className={overlayClassName} />
                <div data-testid={elementsTesIds.LOADER_CONTROL} className={styles.AppLoaderContainer}>
                    <div className={styles.AppLoader}>
                        <div />
                        <div />
                        <div />
                        <div />
                    </div>
                    <div className={styles.LoaderMessage}>
                        {loaderText !== undefined
                            ? loaderText
                            : localizedStrings && (
                                  <>
                                      <p className={styles.TextMain}>
                                          {localizedStrings[localizationKeys?.GLOBAL_LOADER_DEFAULT_TEXT_MAIN]}
                                      </p>
                                      <p className={styles.TextSecondary}>
                                          {localizedStrings[localizationKeys?.GLOBAL_LOADER_DEFAULT_TEXT_SECONDARY]}
                                      </p>
                                  </>
                              )}
                    </div>
                </div>
            </FixedContainer>
        )
    );
};

export default AppLoader;
