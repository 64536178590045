import imageFlagCh from '../../../../assets/images/flags_icons/icon-flag-country-ch.png';
import imageFlagCz from '../../../../assets/images/flags_icons/icon-flag-country-cz.png';
import imageFlagFr from '../../../../assets/images/flags_icons/icon-flag-country-fr.png';
import imageFlagGr from '../../../../assets/images/flags_icons/icon-flag-country-gr.png';
import imageFlagIt from '../../../../assets/images/flags_icons/icon-flag-country-it.png';
import imageFlagNz from '../../../../assets/images/flags_icons/icon-flag-country-nz.png';
import imageFlagRo from '../../../../assets/images/flags_icons/icon-flag-country-ro.png';
import imageFlagCs from '../../../../assets/images/flags_icons/icon-flag-language-cs.png';
import imageFlagEn from '../../../../assets/images/flags_icons/icon-flag-language-en.png';

export default {
    countryList: [
        {
            name: 'Romania',
            countryIsoCode: 'RO',
            languages: [
                {
                    name: 'Romanian',
                    languageCode: 'ro',
                    default: true,
                },
                {
                    name: 'English',
                    languageCode: 'en',
                },
            ],
        },
        {
            name: 'New Zealand',
            countryIsoCode: 'NZ',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
            ],
        },
        {
            name: 'Czech Republic',
            countryIsoCode: 'CZ',
            languages: [
                {
                    name: 'Czech',
                    languageCode: 'cs',
                    default: true,
                },
                {
                    name: 'English',
                    languageCode: 'en',
                },
            ],
        },
        {
            name: 'Switzerland',
            countryIsoCode: 'CH',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
                {
                    name: 'German',
                    languageCode: 'de',
                },
                {
                    name: 'French',
                    languageCode: 'fr',
                },
                {
                    name: 'Italian',
                    languageCode: 'it',
                },
            ],
        },
        {
            name: 'France',
            countryIsoCode: 'FR',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
                {
                    name: 'French',
                    languageCode: 'fr',
                },
            ],
        },
        {
            name: 'Italy',
            countryIsoCode: 'IT',
            languages: [
                {
                    name: 'Italian',
                    languageCode: 'it',
                    default: true,
                },
            ],
        },
        {
            name: 'United Kingdom',
            countryIsoCode: 'UK',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
            ],
        },
        {
            name: 'Greece',
            countryIsoCode: 'GR',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
                {
                    name: 'Greek',
                    languageCode: 'el',
                },
            ],
        },
        {
            name: 'UAT',
            countryIsoCode: 'UAT',
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
            ],
        },
        {
            name: 'PMI',
            countryIsoCode: 'PMI',
            provinces: [
                {
                    name: 'aProvince',
                    provinceCode: '1A',
                    defaultLanguageCode: 'en',
                },
                {
                    name: 'bProvince',
                    provinceCode: '2B',
                    defaultLanguageCode: 'en',
                },
                {
                    name: 'cProvince',
                    provinceCode: '3C',
                    defaultLanguageCode: 'ro',
                },
            ],
            languages: [
                {
                    name: 'English',
                    languageCode: 'en',
                    default: true,
                },
                {
                    name: 'Romanian',
                    languageCode: 'ro',
                },
                {
                    name: 'Czech',
                    languageCode: 'cs',
                },
            ],
        },
    ],
    localization: {
        en: {
            LANGUAGE_SELECTOR_TITLE_TEXT: 'Language',
            COUNTRY_SELECTOR_TITLE_TEXT: 'Country',
            PROVINCE_SELECTOR_TITLE_TEXT: 'Province',
            COUNTRY_AND_LANGUAGE_SELECTOR_TITLE: 'Select your country and preferred language',
            COUNTRY_AND_LANGUAGE_SELECTOR_CONFIRM_BUTTON_TEXT: 'Confirm',
            SELECTOR_COUNTRY_HEADER_TEXT: 'Select your country',
            SELECTOR_LANGUAGE_HEADER_TEXT: 'Select your language',
            SELECTOR_PROVINCE_HEADER_TEXT: 'Select your province',
            DETECTOR_ONLINE: 'You are online!',
            DETECTOR_OFFLINE: 'You are offline!',
            PAIRING_NOT_COMPATIBILITY_VERSION_TITLE:
                'Sorry, your iOS 13.4 version is not compatibility for the IQOS Connect WEB solution',
            PAIRING_NOT_COMPATIBILITY_VERSION_MESSAGE:
                'Unfortunately, a new iOS 13.4 has major Bluetooth issues which can cause problems with IQOS device connectivity. Please, come back yo us later',
            PAIRING_NOT_COMPATIBILITY_VERSION_BUTTON: 'GO TO IQOS SITE',
            PROVINCE_SELECTOR_TEXT: 'Please select',
            COUNTRY_SELECTOR_TEXT: 'Please select',
            LANGUAGE_SELECTOR_TEXT: 'Please select',
        },
    },
    flags: [
        {
            code: 'RO',
            imageLink: imageFlagRo,
        },
        {
            code: 'NZ',
            imageLink: imageFlagNz,
        },
        {
            code: 'CH',
            imageLink: imageFlagCh,
        },
        {
            code: 'FR',
            imageLink: imageFlagFr,
        },
        {
            code: 'CZ',
            imageLink: imageFlagCz,
        },
        {
            code: 'FR',
            imageLink: imageFlagFr,
        },
        {
            code: 'IT',
            imageLink: imageFlagIt,
        },
        {
            code: 'GR',
            imageLink: imageFlagGr,
        },
        {
            code: 'EN',
            imageLink: imageFlagEn,
        },
        {
            code: 'CS',
            imageLink: imageFlagCs,
        },
    ],
};
