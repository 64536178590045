import React from 'react';

import appRouterService from '../../../../services/appRouterService';
import PreferenceCenterControl from './PreferenceCenterControl';

const PreferenceCenterPage = () => {
    return (
        <PreferenceCenterControl
            onApproved={appRouterService.forwardToMyDevicesPage}
            backButtonClick={appRouterService.forwardToTNCDataCollectionPage}
        />
    );
};

export default PreferenceCenterPage;
