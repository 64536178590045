import marketTypeService from './marketTypeService';

const transform = (isP4, codentify) => {
    if (isP4 && marketTypeService.isDCS()) {
        let transformedCodentify = '';

        for (let i = 0; i < codentify?.length; i++) {
            if (i > 6 && i <= 9) {
                transformedCodentify = transformedCodentify + '*';
            } else {
                transformedCodentify = transformedCodentify + codentify[i];
            }
        }
        return transformedCodentify;
    }
    return codentify;
};

export default {
    transform,
};
