import * as iotMessageStatusTypes from '../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../consts/iot/iotMessageTypes';

const isDeviceInfoFinished = ({type, status}) =>
    type === iotMessageTypes.DEVICE_INFO && status === iotMessageStatusTypes.FINISHED;

const isHolderFwuFinished = ({type, status, body}) => {
    const isTargetsHolder = body?.data?.targets_holder;

    return type === iotMessageTypes.FIRMWARE_UPDATE && status === iotMessageStatusTypes.FINISHED && isTargetsHolder;
};

const isFwuTargetsHolder = ({type, body}) => type === iotMessageTypes.FIRMWARE_UPDATE && body?.data?.targets_holder;

const isVibrationsProcessFinished = ({type, status}) =>
    type === iotMessageTypes.VIBRATION && status === iotMessageStatusTypes.FINISHED;

const isParentModeFlowFinished = ({type, status}) =>
    type === iotMessageTypes.PARENT_PROTECTION && status === iotMessageStatusTypes.FINISHED;

const isLedProcessFinished = ({type, status}) =>
    type === iotMessageTypes.LED && status === iotMessageStatusTypes.FINISHED;

const isDeviceDisconnected = ({type, status}) =>
    type === iotMessageTypes.CONNECTION && status === iotMessageStatusTypes.DISCONNECTED;

const isHolderStatusFinished = ({type, status}) =>
    type === iotMessageTypes.HOLDER_STATUS && status === iotMessageStatusTypes.FINISHED;

const isResponsiveDrawStatusFinished = ({type, status}) =>
    type === iotMessageTypes.RESPONSIVE_DRAW_PROFILES && status === iotMessageStatusTypes.FINISHED;

const isVapeCloudSizeStatusFinished = ({type, status}) =>
    type === iotMessageTypes.VAPE_CLOUD_SIZE && status === iotMessageStatusTypes.FINISHED;

const isDeviceCleaningFinished = ({type, status}) =>
    type === iotMessageTypes.CLEANING && status === iotMessageStatusTypes.FINISHED;

export default {
    isDeviceCleaningFinished,
    isDeviceDisconnected,
    isDeviceInfoFinished,
    isFwuTargetsHolder,
    isHolderFwuFinished,
    isHolderStatusFinished,
    isLedProcessFinished,
    isParentModeFlowFinished,
    isResponsiveDrawStatusFinished,
    isVapeCloudSizeStatusFinished,
    isVibrationsProcessFinished,
};
