import React from 'react';

import styles from './CarouselArrow.module.scss';
import CarouselArrowPrev from './CarouselArrowPrev';

const CarouselArrowNext = ({testId, className, onClick, to, customClassName}) => (
    <CarouselArrowPrev
        className={`${styles.RightArrow} ${className ? className : ''} ${customClassName ? customClassName : ''}`}
        onClick={onClick}
        testId={testId}
        to={to}
    />
);

export default CarouselArrowNext;
