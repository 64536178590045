import * as localizationKeys from '../../consts/app/localizationKeys';
import ageGatePageConsts from './ageGatePageConsts';

let data;

const getAgeGatePageData = (localizedStrings) => {
    if (!data) {
        const yearsArray = getYearsArray(localizedStrings);

        data = [
            [
                {
                    value: ageGatePageConsts.DEFAULT_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_MONTH],
                },
                {
                    value: ageGatePageConsts.MONTH_JANUARY_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_JANUARY_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_FEBRUARY_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_FEBRUARY_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_MARCH_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_MARCH_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_APRIL_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_APRIL_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_MAY_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_MAY_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_JUNE_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_JUNE_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_JULY_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_JULY_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_AUGUST_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_AUGUST_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_SEPTEMBER_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_SEPTEMBER_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_OCTOBER_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_OCTOBER_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_NOVEMBER_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_NOVEMBER_TEXT],
                },
                {
                    value: ageGatePageConsts.MONTH_DECEMBER_VALUE,
                    text: localizedStrings[localizationKeys.AGE_GATE_DECEMBER_TEXT],
                },
            ],
            yearsArray,
        ];
    }

    return data;
};

const getYearsArray = (localizedStrings) => {
    const yearsArray = [
        {
            value: ageGatePageConsts.DEFAULT_VALUE,
            text: localizedStrings[localizationKeys.AGE_GATE_YEAR],
        },
    ];

    for (let i = ageGatePageConsts.YEAR_CURRENT; i >= ageGatePageConsts.YEAR_START_VALUE; i--) {
        yearsArray.push({
            value: i,
            text: i,
        });
    }

    return yearsArray;
};

export {getAgeGatePageData};
