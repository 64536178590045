import React, {useEffect} from 'react';

import Video from '../../../components/Video/Video';
import styles from '../PairingGuidePage.module.scss';

const PairingGuideVideo = ({src, content, index, isVideoPaused, onLoad, onLoadError}) => {
    const videoRef = React.createRef();
    const currentContent = content[index];
    let req;

    useEffect(() => {
        handleVideoUpdate(index);
    }, [videoRef]);

    useEffect(() => {
        if (isVideoPaused) {
            videoRef.current.pause();
        }
    }, [isVideoPaused]);

    useEffect(() => {
        if (videoRef.current) {
            onLoadedData(index);
        }

        return () => {
            cancelAnimationFrame(req);
        };
    }, [index]);

    const onLoadedData = (index) => {
        videoRef.current.currentTime = currentContent?.videoStartTime;
        handleVideoUpdate(index);
    };

    const handleVideoUpdate = (index) => {
        if (videoRef.current) {
            if (videoRef.current.currentTime >= currentContent?.videoStopTime) {
                videoRef.current.pause();
            } else {
                videoRef.current.play().catch(() => {});
                req = requestAnimationFrame(() => handleVideoUpdate(index));
            }
        }
    };

    const videoOnCanPlay = () => videoRef.current.pause();

    const onPairingVideoLoad = () => {
        onLoad();
    };

    return (
        <Video
            autoplay
            className={styles.SliderVideo}
            isPreloadEnabled
            muted
            onCanPlay={videoOnCanPlay}
            onLoad={onPairingVideoLoad}
            onLoadError={onLoadError}
            playsinline
            ref={videoRef}
            src={src}
        />
    );
};

export default PairingGuideVideo;
