import classNames from 'classnames';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import * as iccProductStatusTypes from '../../../../consts/icc/iccProductStatusTypes';
import deviceTypesService from '../../../../services/device/deviceTypesService';
import layoutService from '../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import marketConfigService from '../../../../services/marketConfigService';
import {makeSelectLayoutType} from '../../../../state/selectors/global';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import styles from './DeviceInfoBlock.module.scss';

const mapStateToProps = createStructuredSelector({
    layoutType: makeSelectLayoutType(),
});

const DeviceInfoBlock = (props) => {
    const {
        device,
        // lastUpdate,
        // updateDescriptionList,
        isHolder,
        isDeviceReady,
        onCheckUpdateFWBtnClick,
        layoutType,
        onDisableClick,
    } = props;
    const {firmware, status, name, codentify, version, type} = device || {};
    const isPackageAvailable = isDeviceReady && firmware?.package_available;
    const isDesktopLayout = layoutService.isDesktopLayout(layoutType);
    const isMobileLayout = layoutService.isMobileLayout(layoutType);
    const [isVisible, setIsVisible] = useState(!isMobileLayout);
    const deviceNameTitleKey = isHolder
        ? localizationKeys.ABOUT_DEVICE_HOLDER_MODEL_TEXT
        : localizationKeys.ABOUT_DEVICE_DEVICE_MODEL_TEXT;
    const localizedStrings = getLocalizedStrings();
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const onHeaderClickInfo = () => {
        setIsVisible(!isVisible);
    };

    const infoContainerClassName = classNames(styles.DeviceInfoContainer, {
        [styles.Expanded]: isVisible,
        [styles.IsPackageAvailable]: isPackageAvailable,
    });

    const statusText = localizedStrings[getStatusLocalizationKey(status)];
    const statusTitle = isHolder
        ? localizedStrings[localizationKeys.ABOUT_DEVICE_HOLDER_STATUS_TEXT]
        : localizedStrings[localizationKeys.ABOUT_DEVICE_DEVICE_STATUS_TEXT];

    const isFWAvailableP1 = appConfig.getIsFWAvailableP1();
    const isFWAvailableP4 = appConfig.getIsFWAvailableP4();

    const isP1Device = deviceTypesService.isP1(type);
    const isP4Device = deviceTypesService.isP4(type);

    const isVisibleFWUButton = !isHolder && ((isFWAvailableP1 && isP1Device) || (isFWAvailableP4 && isP4Device));

    const ButtonTemplate = () => {
        return (
            <ButtonDark
                className={`${styles.FwuBtn} ${!isDeviceReady && styles.ButtonDisabled}`}
                disabled={!isDeviceReady}
                onClick={() => onCheckUpdateFWBtnClick(isHolder)}
                onDisableClick={onDisableClick}
                showArrow
                testId={
                    isHolder
                        ? elementsTesIds.ABOUT_DEVICE_BTN_FWU_CHECK_HOLDER
                        : elementsTesIds.ABOUT_DEVICE_BTN_FWU_CHECK_CHARGER
                }
            >
                {localizedStrings[localizationKeys.ABOUT_DEVICE_CHECK_FIRMWARE_UPDATE_BUTTON_TEXT]}
            </ButtonDark>
        );
    };

    const getAction = () => (isMobileLayout ? onHeaderClickInfo() : null);

    const testId = isMobileLayout
        ? isHolder
            ? elementsTesIds.ABOUT_DEVICE_BTN_HOLDER
            : elementsTesIds.ABOUT_DEVICE_BTN_CHARGER
        : undefined;

    return (
        <div className={styles.InfoContainer}>
            <div className={infoContainerClassName} onClick={getAction} data-testid={testId}>
                <div className={styles.DeviceNameTitle}>{localizedStrings[deviceNameTitleKey]}</div>
                <div className={`${styles.DeviceName} ica--txt-regular`}>
                    <div>{name}</div>
                </div>
                {isDesktopLayout && isVisibleFWUButton && <ButtonTemplate />}
            </div>
            {(!isMobileLayout || isVisible) && (
                <div className={styles.DeviceDetailsContainer}>
                    <DeviceDetailsItem
                        name={localizedStrings[localizationKeys.ABOUT_DEVICE_DEVICE_CODENTIFY_TEXT]}
                        values={codentify}
                    />
                    <DeviceDetailsItem
                        name={localizedStrings[localizationKeys.ABOUT_DEVICE_DEVICE_FIRMWARE_TEXT]}
                        values={version}
                    />
                    {isIccJourneyScreensEnabled && <DeviceDetailsItem name={statusTitle} values={statusText} />}
                    {/*<DeviceDetailsItem*/}
                    {/*    name={localizedStrings[localizationKeys.ABOUT_DEVICE_DEVICE_LAST_UPDATE_TEXT]}*/}
                    {/*    values={lastUpdate}/>*/}
                    {/*<DeviceDetailsItem*/}
                    {/*    name={`${updateDescriptionList.length} ${localizedStrings[localizationKeys.ABOUT_DEVICE_DEVICE_UPDATE_TEXT]}`}*/}
                    {/*    values={updateDescriptionList}/>*/}
                    {!isDesktopLayout && isVisibleFWUButton && <ButtonTemplate />}
                </div>
            )}
        </div>
    );
};

const getStatusLocalizationKey = (status) => {
    switch (status) {
        case iccProductStatusTypes.FAILED:
            return localizationKeys.ABOUT_DEVICE_FAILED_STATUS_TEXT;
        case iccProductStatusTypes.UNREGISTERING:
            return localizationKeys.ABOUT_DEVICE_UNREGISTERING_STATUS_TEXT;
        case iccProductStatusTypes.REGISTERING:
            return localizationKeys.ABOUT_DEVICE_REGISTERING_STATUS_TEXT;
        case iccProductStatusTypes.REGISTERED:
            return localizationKeys.ABOUT_DEVICE_REGISTERED_STATUS_TEXT;
        case iccProductStatusTypes.UNREGISTERED:
        default:
            return localizationKeys.ABOUT_DEVICE_UNREGISTERED_STATUS_TEXT;
    }
};

const DeviceDetailsItem = ({name, values}) => {
    const isArray = Array.isArray(values);

    return (
        <div className={`${styles.DeviceDetailsItem} ica--txt-regular`}>
            <div className={styles.DeviceInfoTitle}>{name}</div>
            <div className={styles.DeviceInfoRow}>
                {isArray ? (
                    <ul>
                        {values.map((value, i) => (
                            <li key={i}>{value}</li>
                        ))}
                    </ul>
                ) : (
                    values
                )}
            </div>
        </div>
    );
};

export default connect(mapStateToProps)(DeviceInfoBlock);
