import imgIconAppleMobile1 from '../../../assets/images/compatible-browser/icon-apple-mobile.png';
import imgIconAppleMobile2 from '../../../assets/images/compatible-browser/icon-apple-mobile@2x.png';
import imgIconAppleMobile3 from '../../../assets/images/compatible-browser/icon-apple-mobile@3x.png';
import imgIconGoogleMobile1 from '../../../assets/images/compatible-browser/icon-google-mobile.png';
import imgIconGoogleMobile2 from '../../../assets/images/compatible-browser/icon-google-mobile@2x.png';
import imgIconGoogleMobile3 from '../../../assets/images/compatible-browser/icon-google-mobile@3x.png';
import imgIconMacOSDesktop1 from '../../../assets/images/compatible-browser/icon-macos-desktop.png';
import imgIconMacOSDesktop2 from '../../../assets/images/compatible-browser/icon-macos-desktop@2x.png';
import imgIconMacOSDesktop3 from '../../../assets/images/compatible-browser/icon-macos-desktop@3x.png';
import imgIconWindowsDesktop1 from '../../../assets/images/compatible-browser/icon-windows-desktop.png';
import imgIconWindowsDesktop2 from '../../../assets/images/compatible-browser/icon-windows-desktop@2x.png';
import imgIconWindowsDesktop3 from '../../../assets/images/compatible-browser/icon-windows-desktop@3x.png';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';

let data;

const getBrowserCompatibilityPageData = (availableContent, isBrowserCompatible) => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();
        const {isAndroidContent, isIOSContent, isMacOSContent, isWindowsContent} = availableContent || {};

        if (isAndroidContent) {
            data = {
                link: appConfig.getLinkAndroidStoreIqos(),
                browserLink: appConfig.getLinkAndroidStoreChrome(),
                srcSet: [imgIconGoogleMobile1, imgIconGoogleMobile2, imgIconGoogleMobile3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_GOOGLE_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_ANDROID_APP_BUTTON_TEXT],
            };
        } else if (isIOSContent) {
            data = {
                link: appConfig.getLinkIosStoreIqos(),
                browserLink: appConfig.getLinkIosStoreBluenet(),
                srcSet: [imgIconAppleMobile1, imgIconAppleMobile2, imgIconAppleMobile3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_APPLE_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_BLUEFY_APP_BUTTON_TEXT],
            };
        } else if (isMacOSContent) {
            data = {
                link: appConfig.getLinkMacWidget(),
                browserLink: appConfig.getLinkDesktopChrome(),
                srcSet: [imgIconMacOSDesktop1, imgIconMacOSDesktop2, imgIconMacOSDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_MACOS_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_MAC_WIDGET_APP_BUTTON_TEXT],
            };
        } else if (isWindowsContent) {
            data = {
                link: appConfig.getLinkWindowsWidget(),
                browserLink: appConfig.getLinkDesktopChrome(),
                srcSet: [imgIconWindowsDesktop1, imgIconWindowsDesktop2, imgIconWindowsDesktop3],
                text: isBrowserCompatible
                    ? localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_WINDOWS_BUTTON_TEXT]
                    : localizedStrings[localizationKeys.NOT_COMP_WINDOWS_WIDGET_APP_BUTTON_TEXT],
            };
        }
    }

    return data;
};

export {getBrowserCompatibilityPageData};
