const ACTIVATION_ERRORS_DATA = {
    AE_001: {
        status: '001',
        code: 'Failed Activation (FW Update)',
    },
    AE_EXTERNAL_SYSTEM_UNAVAILABLE: {
        status: 'EXTERNAL_SYSTEM_UNAVAILABLE',
        code: 'Error #101',
    },
    AE_DEVICE_ALREADY_OWNED: {
        status: 'DEVICE_ALREADY_OWNED',
        code: 'Error #102',
    },
    AE_NOT_FOUND: {
        status: '404_NOT_FOUND',
        code: 'Error #103',
    },
    AE_INVALID_CODENTIFYID: {
        status: 'INVALID_CODENTIFYID',
        code: 'Error #104',
    },
    AE_DEVICE_REMOVED_FROM_MASTER_DB: {
        status: 'DEVICE_REMOVED_FROM_MASTER_DB',
        code: 'Error #105',
    },
    AE_INVALID_DEVICE_STATUS: {
        status: 'INVALID_DEVICE_STATUS',
        code: 'Error #106',
    },
};

export default ACTIVATION_ERRORS_DATA;
