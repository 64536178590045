import React from 'react';

import standaloneConfig from '../../../../config/standaloneConfig/standaloneConfig';
import SelectMarketSettingsPopup from '../SelectMarketSettingsPopup/SelectMarketSettingsPopup';

const LanguageSelectTemplate = (props) => {
    const {transmittedConfig} = props;
    const {countryCodeConfig} = standaloneConfig || {};
    const standaloneLocalization = countryCodeConfig.localization.en;
    const standaloneFlagsConfig = countryCodeConfig.flags;
    const languageConfig = transmittedConfig.languages;

    const languageCodeOnItemSelect = 'languageCode';
    const languagePopupTitleText = standaloneLocalization.SELECTOR_LANGUAGE_HEADER_TEXT;

    return (
        <SelectMarketSettingsPopup
            {...props}
            transmittedConfig={languageConfig}
            codeOnItemSelect={languageCodeOnItemSelect}
            popupTitleText={languagePopupTitleText}
            flagsConfig={standaloneFlagsConfig}
        />
    );
};

export default LanguageSelectTemplate;
