import imgCortezDesktop1 from '../../../assets/images/select-device/cortez-desktop.png';
import imgCortezDesktop2 from '../../../assets/images/select-device/cortez-desktop@2x.png';
import imgCortezDesktop3 from '../../../assets/images/select-device/cortez-desktop@3x.png';
import imgCortezMobile1 from '../../../assets/images/select-device/cortez-mobile.png';
import imgCortezMobile2 from '../../../assets/images/select-device/cortez-mobile@2x.png';
import imgCortezMobile3 from '../../../assets/images/select-device/cortez-mobile@3x.png';
import imgMultiDesktop1 from '../../../assets/images/select-device/multi-desktop.png';
import imgMultiDesktop2 from '../../../assets/images/select-device/multi-desktop@2x.png';
import imgMultiDesktop3 from '../../../assets/images/select-device/multi-desktop@3x.png';
import imgMultiMobile1 from '../../../assets/images/select-device/multi-mobile.png';
import imgMultiMobile2 from '../../../assets/images/select-device/multi-mobile@2x.png';
import imgMultiMobile3 from '../../../assets/images/select-device/multi-mobile@3x.png';
import imgOrdinaryDesktop1 from '../../../assets/images/select-device/ordinary-desktop.png';
import imgOrdinaryDesktop2 from '../../../assets/images/select-device/ordinary-desktop@2x.png';
import imgOrdinaryDesktop3 from '../../../assets/images/select-device/ordinary-desktop@3x.png';
import imgOrdinaryMobile1 from '../../../assets/images/select-device/ordinary-mobile.png';
import imgOrdinaryMobile2 from '../../../assets/images/select-device/ordinary-mobile@2x.png';
import imgOrdinaryMobile3 from '../../../assets/images/select-device/ordinary-mobile@3x.png';
import imgSoyuzDesktop1 from '../../../assets/images/select-device/soyuz-desktop.png';
import imgSoyuzDesktop2 from '../../../assets/images/select-device/soyuz-desktop@2x.png';
import imgSoyuzDesktop3 from '../../../assets/images/select-device/soyuz-desktop@3x.png';
import imgSoyuzMobile1 from '../../../assets/images/select-device/soyuz-mobile.png';
import imgSoyuzMobile2 from '../../../assets/images/select-device/soyuz-mobile@2x.png';
import imgSoyuzMobile3 from '../../../assets/images/select-device/soyuz-mobile@3x.png';
import imgVeevDesktop1 from '../../../assets/images/select-device/veev-desktop.png';
import imgVeevDesktop2 from '../../../assets/images/select-device/veev-desktop@2x.png';
import imgVeevDesktop3 from '../../../assets/images/select-device/veev-desktop@3x.png';
import imgVeevMobile1 from '../../../assets/images/select-device/veev-mobile.png';
import imgVeevMobile2 from '../../../assets/images/select-device/veev-mobile@2x.png';
import imgVeevMobile3 from '../../../assets/images/select-device/veev-mobile@3x.png';
import * as localizationKeys from '../../consts/app/localizationKeys';
import DEVICE_TYPES from '../../consts/device/deviceTypes';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import elementsTesIds from '../../test/consts/elementsTesIds';

let selectDeviceData;

const getSelectDeviceData = () => {
    if (!selectDeviceData) {
        const localizedStrings = getLocalizedStrings();

        selectDeviceData = [
            {
                type: DEVICE_TYPES.DEV_TYPE_P4_M3_G2,
                imageSet: {
                    desktop: [imgVeevDesktop1, imgVeevDesktop2, imgVeevDesktop3],
                    mobile: [imgVeevMobile1, imgVeevMobile2, imgVeevMobile3],
                },
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_TITAN_NAME],
                testId: elementsTesIds.PAIRING_SEQUENCE_BTN_SELECT_VEEV,
            },
            {
                type: DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER,
                imageSet: {
                    desktop: [imgSoyuzDesktop1, imgSoyuzDesktop2, imgSoyuzDesktop3],
                    mobile: [imgSoyuzMobile1, imgSoyuzMobile2, imgSoyuzMobile3],
                },
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_SOYUZ_NAME],
            },
            {
                type: DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER,
                imageSet: {
                    desktop: [imgCortezDesktop1, imgCortezDesktop2, imgCortezDesktop3],
                    mobile: [imgCortezMobile1, imgCortezMobile2, imgCortezMobile3],
                },
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_CORTEZ_NAME],
            },
            {
                type: DEVICE_TYPES.DEV_TYPE_P1V30M,
                imageSet: {
                    desktop: [imgMultiDesktop1, imgMultiDesktop2, imgMultiDesktop3],
                    mobile: [imgMultiMobile1, imgMultiMobile2, imgMultiMobile3],
                },
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_VESPUCCI_NAME],
            },
            {
                type: DEVICE_TYPES.DEV_TYPE_V24P_CHARGER,
                imageSet: {
                    desktop: [imgOrdinaryDesktop1, imgOrdinaryDesktop2, imgOrdinaryDesktop3],
                    mobile: [imgOrdinaryMobile1, imgOrdinaryMobile2, imgOrdinaryMobile3],
                },
                name: localizedStrings[localizationKeys.DEVICE_CAROUSEL_ORDINARY_NAME],
            },
        ];
    }

    return selectDeviceData;
};

export {getSelectDeviceData};
