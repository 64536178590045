import productService from '../../services/productService';

export const SET_CONSUMER_ACCEPTED_TNC = 'app/consumer/SET_CONSUMER_ACCEPTED_TNC';
export const SET_CONSUMER_AGGREGATED_SETTINGS = 'app/consumer/SET_CONSUMER_AGGREGATED_SETTINGS';
export const SET_CONSUMER_DATA = 'app/consumer/SET_CONSUMER_DATA';
export const SET_CONSUMER_PRODUCTS = 'app/consumer/SET_CONSUMER_PRODUCTS';
export const SET_CONSUMER_PRODUCTS_ASSET = 'app/consumer/SET_CONSUMER_PRODUCTS_ASSET';
export const SET_CONSUMER_PRODUCTS_INFO = 'app/consumer/SET_CONSUMER_PRODUCTS_INFO';
export const SET_CONSUMER_PRODUCTS_STATUS = 'app/consumer/SET_CONSUMER_PRODUCTS_STATUS';
export const SET_CONSUMER_TNC = 'app/consumer/SET_CONSUMER_TNC';
export const UPDATE_CONSUMER_PRODUCT_DATA = 'app/consumer/UPDATE_CONSUMER_PRODUCT_DATA';
export const UPDATE_IOT_PRODUCT_DATA = 'app/consumer/UPDATE_IOT_PRODUCT_DATA';
export const SET_CONSUMER_PRODUCT_NAME = 'app/consumer/SET_CONSUMER_PRODUCT_NAME';
export const SET_CONSUMER_RECENTLY_PAIRED_DEVICES = 'app/consumer/SET_CONSUMER_RECENTLY_CONNECTED_DEVICES';

const initialData = {
    acceptedTnc: undefined,
    aggregatedSettings: undefined,
    data: undefined,
    products: undefined,
    productsAsset: [],
    productsInfo: undefined,
    productsStatus: undefined,
    recentlyPairedDevices: [],
    tnc: undefined,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case SET_CONSUMER_AGGREGATED_SETTINGS: {
            return {
                ...state,
                aggregatedSettings: action.payload,
            };
        }
        case SET_CONSUMER_PRODUCTS: {
            return {
                ...state,
                products: action.payload,
            };
        }
        case SET_CONSUMER_PRODUCT_NAME: {
            const {deviceSerialNumber, name} = action.payload;

            const newProductList = state.products.map((product) => {
                if (product.deviceSerialNumber === deviceSerialNumber) {
                    product.productName = name;
                }

                return product;
            });

            return {
                ...state,
                products: newProductList,
            };
        }
        case UPDATE_CONSUMER_PRODUCT_DATA: {
            const {deviceSerialNumber, codentify, isP4, ...dataToUpdate} = action.payload;
            const {products} = state;
            const isProductExist = productService.someProduct({
                products,
                isP4,
                deviceSerialNumber,
                codentify,
            });

            let newList = products?.length ? [...products] : [];

            if (isProductExist) {
                newList = newList.map((product) => {
                    if (product.deviceSerialNumber === deviceSerialNumber || product.codentify === codentify) {
                        return {...product, ...dataToUpdate};
                    }

                    return product;
                });
            } else {
                newList.push({
                    deviceSerialNumber,
                    codentify,
                    ...dataToUpdate,
                });
            }

            return {
                ...state,
                products: newList,
            };
        }
        case SET_CONSUMER_PRODUCTS_INFO: {
            return {
                ...state,
                productsInfo: action.payload,
            };
        }
        case SET_CONSUMER_PRODUCTS_ASSET: {
            const productAssets = action.payload;
            const productAssetsFromState = state.productsAsset;
            const filteredProductAssetsFromState = productAssetsFromState.filter(
                ({mediaId}) => !productAssets.some((asset) => mediaId === asset.mediaId)
            );

            return {
                ...state,
                productsAsset: [...filteredProductAssetsFromState, ...productAssets],
            };
        }
        case SET_CONSUMER_PRODUCTS_STATUS: {
            return {
                ...state,
                productsStatus: action.payload,
            };
        }
        case SET_CONSUMER_DATA: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case SET_CONSUMER_TNC: {
            return {
                ...state,
                tnc: action.payload,
            };
        }
        case SET_CONSUMER_ACCEPTED_TNC: {
            return {
                ...state,
                acceptedTnc: action.payload,
            };
        }
        case UPDATE_IOT_PRODUCT_DATA: {
            return {
                ...state,
                iotProduct: action.payload,
            };
        }
        case SET_CONSUMER_RECENTLY_PAIRED_DEVICES: {
            return {
                ...state,
                recentlyPairedDevices: [...state.recentlyPairedDevices, action.payload],
            };
        }
        default:
            return state;
    }
}

export const removeConsumerProductsStatus = () => setConsumerProductsStatus(null);
export const setConsumerAcceptedTnc = (data) => ({
    type: SET_CONSUMER_ACCEPTED_TNC,
    payload: data,
});
export const setConsumerAggregatedSettings = (data) => ({
    type: SET_CONSUMER_AGGREGATED_SETTINGS,
    payload: data,
});
export const setConsumerData = (data) => ({
    type: SET_CONSUMER_DATA,
    payload: data,
});
export const setConsumerProducts = (data) => ({
    type: SET_CONSUMER_PRODUCTS,
    payload: data,
});
export const setConsumerProductName = (data) => ({
    type: SET_CONSUMER_PRODUCT_NAME,
    payload: data,
});
export const setConsumerProductsAsset = (data) => ({
    type: SET_CONSUMER_PRODUCTS_ASSET,
    payload: data,
});
export const setConsumerProductsInfo = (data) => ({
    type: SET_CONSUMER_PRODUCTS_INFO,
    payload: data,
});
export const setConsumerProductsStatus = (data) => ({
    type: SET_CONSUMER_PRODUCTS_STATUS,
    payload: data,
});
export const setConsumerTnc = (data) => ({
    type: SET_CONSUMER_TNC,
    payload: data,
});
export const updateConsumerProductData = (data) => ({
    type: UPDATE_CONSUMER_PRODUCT_DATA,
    payload: data,
});
export const setConsumerRecentlyPairedDevices = (data) => ({
    type: SET_CONSUMER_RECENTLY_PAIRED_DEVICES,
    payload: data,
});
