import React, {useEffect} from 'react';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import appIntroService from '../../../services/appIntroService';
import appRouterService from '../../../services/appRouterService';
import iccMarketService from '../../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import marketConfigService from '../../../services/marketConfigService';
import userDeviceService from '../../../services/userDeviceService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import browserCompatibilityService from '../browserCompatibilityService';
import * as styles from './BrowserVerificationPage.module.scss';

const REDIRECT_TIMEOUT = 2000;

const BrowserVerificationPage = () => {
    const isBleSupported = userDeviceService.isBluetoothSupported();

    const downloadButtonData = browserCompatibilityService.getBrowserCompatibleButtonData();
    const isDownloadButtonAvailable = !!downloadButtonData?.link;

    const isBleNotSupportedWithAvailableConnectApp = !isBleSupported && isDownloadButtonAvailable;
    const isIntroPageVisible = appIntroService.getIsIntroPageVisible();
    const isMobileIOSDevice = userDeviceService.isMobileIOSDevice();
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const isTabletIOSDevice = userDeviceService.isTabletIOSDevice();

    const isAppleTouchDevice = isMobileIOSDevice || isTabletIOSDevice;

    const shouldRedirectToNotCompatiblePage = !isBleSupported && !isDownloadButtonAvailable && isAppleTouchDevice;

    const timeoutFunction = async () => {
        if (shouldRedirectToNotCompatiblePage) {
            appRouterService.forwardToNotCompatibleBrowserPage();
        } else {
            if (isBleSupported || isBleNotSupportedWithAvailableConnectApp) {
                if (isIntroPageVisible || !isIccJourneyScreensEnabled) {
                    appRouterService.forwardToAppIntroPage();
                } else {
                    if (isBleSupported) {
                        await iccMarketService.getConsumerAggregatedSettings();
                        appRouterService.forwardToTNCTermsOfUsePage();
                    } else {
                        appRouterService.forwardToNotCompatibleBrowserPage();
                    }
                }
            } else {
                appRouterService.forwardToAppIntroPage();
            }
        }
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            timeoutFunction();
        }, REDIRECT_TIMEOUT);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className={styles.Page}>
            <section>
                <div className={styles.PulseContainer} data-testid={elementsTesIds.LOADER_CONTROL}>
                    <div className={`${styles.PulseCircle} ${styles.Circle1}`} />
                    <div className={`${styles.PulseCircle} ${styles.Circle2}`} />
                </div>
            </section>
            <div className={styles.Title}>{getLocalizedStrings()[localizationKeys.BROWSER_VERIFICATION_TITLE]}</div>
            <div className={styles.Text}>{getLocalizedStrings()[localizationKeys.BROWSER_VERIFICATION_TEXT]}</div>
        </div>
    );
};

export default BrowserVerificationPage;
