import PCancelable from 'p-cancelable';

import converter from '../../utils/converter';
import helpers from '../../utils/helpers';
import BleClienNotInitializedError from '../errors/bleClientNotInitializedError';
import GetPermittedBuetoothDeviceError from '../errors/getPermittedBuetoothDeviceError';
import ReconectPromiseCancelError from '../errors/reconectPromiseCancelError';
import log from '../logger/log';

let instance = null;

export default class BLEClient {
    constructor(options) {
        if (!options && instance) {
            return instance;
        }
        if (!options) {
            throw new BleClienNotInitializedError();
        }

        this._options = options;
        this._isConnected = false; //device is connected after getPrimaryService

        instance = this;
    }

    _getPermittedDeviceTimeout = null;
    _reconnectPromise = null;

    isDeviceConnected = () => this._isConnected;

    _clearGetPermittedDeviceTimeout = () => {
        clearTimeout(this._getPermittedDeviceTimeout);
    };

    getPermittedBluetoothDevices = async (services, onConnect, onError) => {
        const GET_PERMITTED_DEVICE_TIMEOUT_MS = 10 * 1000;
        let removeAdverismentReceivedListener = () => {};

        try {
            let devices = await navigator.bluetooth.getDevices({
                acceptAllDevices: false,
                filters: [{services}],
            });

            if (devices.length) {
                log.debug(`BLEClient: getPermittedBluetoothDevices, there are ${devices.length} permitted device(s)`);

                removeAdverismentReceivedListener = () => {
                    for (const device of devices) {
                        device.onadvertisementreceived = null;
                    }
                };

                this._getPermittedDeviceTimeout = setTimeout(() => {
                    removeAdverismentReceivedListener();
                    onError(new GetPermittedBuetoothDeviceError());
                }, GET_PERMITTED_DEVICE_TIMEOUT_MS);

                for (const device of devices) {
                    const abortController = new AbortController();

                    await device.watchAdvertisements({signal: abortController.signal});

                    device.onadvertisementreceived = async (evt) => {
                        log.debug(
                            `BLEClient: getPermittedBluetoothDevices, advertisementreceived, device name: ${evt.target?.name}`
                        );

                        this._clearGetPermittedDeviceTimeout();
                        abortController.abort();
                        removeAdverismentReceivedListener();

                        await evt.device.gatt.connect();
                        onConnect(evt.device);
                    };
                }
            }

            return devices;
        } catch (e) {
            this._clearGetPermittedDeviceTimeout();

            log.info(`BLEClient: getPermittedBluetoothDevices error: ${e}`);
            return null;
        }
    };

    unmount = () => {
        this._clearGetPermittedDeviceTimeout();
    };

    requestDevice = async (services, onConnect) => {
        log.info('BLEClient: requesting Bluetooth Device');

        const device = await navigator.bluetooth.requestDevice({
            acceptAllDevices: false,
            filters: [{services}],
        });

        onConnect(device);
    };

    getDevice = async ({isNewDevice, services, onConnect, onError}) => {
        let isRequestDevice = true;

        if (!isNewDevice) {
            const permittedDevices = await this.getPermittedBluetoothDevices(services, onConnect, onError);
            isRequestDevice = !permittedDevices?.length;
        }

        if (isRequestDevice) {
            await this.requestDevice(services, onConnect);
        }
    };

    connectDevice = async ({isNewDevice, services, onConnect, onError}) => {
        try {
            this._isDisconnectedByUser = false;

            await this.getDevice({
                isNewDevice,
                services,
                onConnect: async (device) => {
                    this._device = device;

                    log.info(`BLEClient: device with id: ${device.id} is connected`);

                    this._options.onDeviceSelect();

                    device.addEventListener('gattserverdisconnected', this._onDisconnected);

                    device.addEventListener('gattserverforcedisconnected', this._onGattServerForceDisconnected);

                    let server;
                    try {
                        server = await this.gattConnect(device);
                        this._server = server;

                        onConnect();
                    } catch (e) {
                        log.info(`BLEClient: gattConnect: ${e}`);
                    }
                },
                onError,
            });
        } catch (e) {
            log.debug(`BLEClient: requestDeviceService failed, error: ${e}`);

            throw e;
        }
    };

    gattConnect = (device) => {
        const connectPromise = new PCancelable((resolve, reject, onCancel) => {
            log.info('BLEClient: connecting to GATT Server');
            device.gatt.connect().then((server) => resolve(server));

            onCancel(() => {
                this._removeOnDisconnectedListener(device);
                reject('connectPromise rejected');
            });
        });

        this._connectPromise = connectPromise;

        return connectPromise;
    };

    cancelConnect = () => {
        try {
            if (this._connectPromise) {
                this._connectPromise.cancel();
                this._connectPromise = null;
                this._device = null;
                this._server = null;
            }
        } catch (e) {
            log.debug(`BLEClient: cancel connect promise failed e: ${e}`);
        }
    };

    getPrimaryService = async (serviceUuid) => {
        try {
            log.debug('BLEClient: getting Primary Service');

            this._primaryService = await this._server.getPrimaryService(serviceUuid);

            log.debug('BLEClient: getting Primary Service success');
            this._isConnected = true;
        } catch (e) {
            log.debug(`BLEClient: getPrimaryService failed, error: ${e}`);

            throw e;
        }
    };

    getPrimaryServiceCharacteristic = async (characteristicUuid) => {
        log.debug('BLEClient: getting characteristics');
        return this._primaryService.getCharacteristic(characteristicUuid);
    };

    addCharacteristicListener = async (characteristic, handler) => {
        try {
            const {uuid} = characteristic;

            if (!this._managedListeners) {
                this._managedListeners = {};
            }

            this._removeEventListener(uuid);

            this._managedListeners[uuid] = {
                characteristic,
                handler: (event) => {
                    handler(event.target.value);
                },
            };

            // log.debug(`BLEClient: add characteristic event listener`);
            characteristic.addEventListener('characteristicvaluechanged', this._managedListeners[uuid].handler);

            // await helpers.timeout(500);
            characteristic.startNotifications().catch((e) => {
                log.info(`BLEClient: addCharacteristicListener error: ${e}`);
            });
        } catch (e) {
            log.info(`BLEClient: addCharacteristicListener error: ${e}`);
        }
    };

    writeValueToCharacteristic = async (characteristic, frame, throwError) => {
        if (!this.isDeviceConnected()) return;

        try {
            const frameDecoded = converter.hex2bin(frame);
            const value = frameDecoded.buffer;

            return await characteristic.writeValue(value);
        } catch (e) {
            log.debug(`BLEClient: frame: ${frame}, writeValueToCharacteristic error: ${e}`);

            if (throwError) {
                throw e;
            }
        }
    };

    readCharacteristic = async (characteristic, handler) => {
        if (!this.isDeviceConnected()) return;

        try {
            log.debug('BLEClient: try to read characteristic');
            const value = await characteristic.readValue();
            handler(value);
        } catch (e) {
            log.debug(`BLEClient: read characteristic error: ${e}`);
        }
    };

    removeCharacteristicListener = (characteristic) => {
        const {uuid} = characteristic;

        this._removeEventListener(uuid);
    };

    disconnect = () => {
        this._isDisconnectedByUser = true;
        this._removeAllEventListeners();
        this._removeOnDisconnectedListener(this._device);
        this._disconnectByDevice(this._device);
        this.cancelConnect(); //clear connect promise
        this.cancelReconnect(); //clear reconnect promise if device is already disconnected
        instance = null;
    };

    reconnect = async () => {
        try {
            const device = this._device;

            if (device) {
                log.debug('BLEClient: try to reconnect device');

                const reconnectPromise = new PCancelable((resolve, reject, onCancel) => {
                    device.gatt.connect().then((server) => resolve(server));

                    onCancel(() => {
                        log.debug('BLEClient: device reconnection canceled');
                        reject(new ReconectPromiseCancelError());
                    });
                });

                this._reconnectPromise = reconnectPromise;

                const server = await reconnectPromise;

                this._reconnectPromise = null;

                log.info(`BLEClient: device with id: ${device.id} is reconnected`);

                this._device = device;
                this._server = server;

                this._options.onReconnectSuccess();
            }
        } catch (e) {
            const isReconnectCanceled = e instanceof ReconectPromiseCancelError;

            if (!isReconnectCanceled) {
                log.debug('BLEClient: device reconnection failed');

                helpers.runFunction(this._options.onReconnectFail);
            }
        }
    };

    cancelReconnect = () => {
        try {
            if (this._reconnectPromise) {
                this._reconnectPromise.cancel();
                this._reconnectPromise = null;
                this._device = null;
                this._server = null;

                return true;
            }
        } catch (e) {
            log.debug(`BLEClient: cancel reconnect promise failed e: ${e}`);
        }
    };

    _onGattServerForceDisconnected = () => {
        log.info(`BLEClient: "gattserverforcedisconnected" is fired`);

        this._options.onForcedDisconnect();
        this._onDisconnected();
        // IA - call _onDisconnected directly, because onDisconnect
        // is not called after gattserverforcedisconnected event
    };

    _onDisconnected = () => {
        const {_isDisconnectedByUser} = this;

        log.info('BLEClient: device disconnected');

        this._isConnected = false;
        this._options.onDisconnected(_isDisconnectedByUser);
        this._removeAllEventListeners();

        if (_isDisconnectedByUser) {
            this._removeOnDisconnectedListener(this._device);
            this._device = null;
        } else {
            this.reconnect();
        }
    };

    _removeOnDisconnectedListener = (device) => {
        device?.removeEventListener('gattserverdisconnected', this._onDisconnected);
        device?.removeEventListener('gattserverforcedisconnected', this._onGattServerForceDisconnected);
    };

    _removeAllEventListeners = () => {
        if (this._managedListeners) {
            Object.keys(this._managedListeners).forEach(this._removeEventListener);
        }

        this._managedListeners = {};
    };

    _removeEventListener = (uuid) => {
        try {
            const listener = this._managedListeners[uuid];

            if (listener) {
                const {characteristic, handler} = listener;

                // log.debug(`BLEClient: remove characteristic event listener`);
                characteristic.removeEventListener('characteristicvaluechanged', handler);
                delete this._managedListeners[uuid];
            }
        } catch (e) {
            log.info(`BLEClient: removeEventListener error: ${e}`);
        }
    };

    _disconnectByDevice = (device) => {
        if (device) {
            log.info(`BLEClient: disconnect device with id: ${device.id}`);
            device.gatt.disconnect();
        }
    };
}
