import React from 'react';

import ButtonWhiteHigh from '../../../../../../components/Button/ButtonWhiteHeight/ButtonWhiteHigh';
import Image from '../../../../../../components/Image/Image';
import styles from './TurnOnOffButton.module.scss';

const SelectedUnselectedButton = ({title, isActive, onClick, imgSet, titleClassName, testId}) => {
    const buttonClassName = isActive ? styles.ButtonWhiteHighSelected : '';

    return (
        <div className={styles.ButtonContainer}>
            <ButtonWhiteHigh className={buttonClassName} onClick={isActive ? undefined : onClick} testId={testId}>
                <div>
                    <div className={`${styles.ButtonTitle} ${titleClassName ? titleClassName : ''}`}>{title}</div>
                </div>
                <div className={`${styles.ButtonIcon} ${styles.ResponsiveDrawButtonIcon}`}>
                    <Image src={imgSet} />
                </div>
            </ButtonWhiteHigh>
        </div>
    );
};

export default SelectedUnselectedButton;
