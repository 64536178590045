export const CLEAR_MW_DEVICE = 'app/mwDevice/CLEAR_MW_DEVICE';
export const SET_MW_DEVICE_FIRMWARE_DATA = 'app/mwDevice/SET_MW_DEVICE_FIRMWARE_DATA';
export const SET_MW_DEVICE_INTERNAL_ID = 'app/mwDevice/SET_MW_DEVICE_INTERNAL_ID';
export const SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT =
    'app/mwDevice/SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT';

const initialState = {
    firmwareData: {},
    deviceSerialNumber: undefined,
    isIdentified: false,
    isDisconnectedWithNoReconnect: false,
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case CLEAR_MW_DEVICE: {
            return initialState;
        }
        case SET_MW_DEVICE_FIRMWARE_DATA: {
            const firmwareData = {...state.firmwareData, ...action.payload};

            return {...state, firmwareData};
        }
        case SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT: {
            return {...state, isDisconnectedWithNoReconnect: action.payload};
        }
        case SET_MW_DEVICE_INTERNAL_ID: {
            const {deviceSerialNumber, isIdentified} = action.payload;

            return {...state, deviceSerialNumber, isIdentified};
        }
        default:
            return state;
    }
}

export const clearMwDevice = () => ({
    type: CLEAR_MW_DEVICE,
});

export const setMwDeviceFirmwareData = (data) => ({
    type: SET_MW_DEVICE_FIRMWARE_DATA,
    payload: data,
});

export const setMwDeviceInternalId = (data) => ({
    type: SET_MW_DEVICE_INTERNAL_ID,
    payload: data,
});

export const setMwDeviceIsDisconnectedWithNoReconnect = (data) => ({
    type: SET_MW_DEVICE_IS_DISCONNECTED_WITH_NO_RECONNECT,
    payload: data,
});

export const clearMwDeviceInternalId = () => ({
    type: SET_MW_DEVICE_INTERNAL_ID,
    payload: {deviceSerialNumber: undefined, isIdentified: false},
});
