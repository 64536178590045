import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as uamMethods from '../../consts/uam/uamMethods';
import {
    makeSelectUamApiKey,
    makeSelectUamApiUrl,
    makeSelectUamAuthToken,
    makeSelectUamIdentityKey,
} from '../../state/selectors/auth';
import {getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import authDataStoreService from '../auth/authDataStoreService';
import iccService from '../icc/iccService';
import log from '../logger/log';
import server from '../server/server';

let isTokenRefreshInProgress = false;

const getUamApiUrl = (methodName) => {
    const uamApiUrl = makeSelectUamApiUrl()(getState());
    return urlUtils.join(uamApiUrl, methodName);
};

const getHeaders = () => {
    const headers = {
        Accept: 'application/json',
    };

    const state = getState();
    const xApiKey = makeSelectUamApiKey()(state);
    if (xApiKey) {
        headers['X-API-Key'] = xApiKey;
    }

    const idToken = makeSelectUamAuthToken()(state);
    if (idToken) {
        headers['id_token'] = idToken;
    }

    return headers;
};

const errorCheck = async (error, onSuccess, ignoreErrorCodes = []) => {
    const status = error?.response?.status;
    ignoreErrorCodes = arrayUtils.toArray(ignoreErrorCodes);
    if (ignoreErrorCodes.includes(status)) return null;

    switch (status) {
        case httpErrorsTypes.NOT_AUTHORIZED: {
            if (!isTokenRefreshInProgress) {
                isTokenRefreshInProgress = true;
                try {
                    //during the e2e Tap Testing call we agreed not to integrate with UAM Refresh-Token
                    //await refreshUamToken();
                    await iccService.fetchUamToken();
                    isTokenRefreshInProgress = false;
                    return onSuccess ? onSuccess() : null;
                } catch (e) {
                    isTokenRefreshInProgress = false;
                }
            }
            break;
        }
        default:
            throw error;
    }
};

// eslint-disable-next-line no-unused-vars
const refreshUamToken = async () => {
    const uamIdentityKey = makeSelectUamIdentityKey()(getState());
    const url = stringUtils.formatString(getUamApiUrl(uamMethods.REFRESH_TOKEN), uamIdentityKey);

    try {
        const response = await server.get(url, {headers: getHeaders()});

        isTokenRefreshInProgress = false;

        if (response.data) {
            await authDataStoreService.setUamApiData(response.data);
        }

        log.debug(`uamClient: refreshUamToken, request: "${uamMethods.REFRESH_TOKEN}" has successful response`);
    } catch (e) {
        log.error(`uamClient: refreshUamToken, request: "${uamMethods.REFRESH_TOKEN}", error: ${e}`);
        throw e;
    }
};

export default {
    errorCheck,
    getUamApiUrl,
    getHeaders,
};
