import React, {useState} from 'react';

import imgIconClose from '../../../../assets/images/icons/clean-icon.png';
import imgIconClose2 from '../../../../assets/images/icons/clean-icon@2x.png';
import imgIconClose3 from '../../../../assets/images/icons/clean-icon@3x.png';
import Button from '../../../components/Button/Button';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import ButtonText from '../../../components/Button/ButtonText/ButtonText';
import Image from '../../../components/Image/Image';
import Input from '../../../components/Input/Input';
import PopupRounded from '../../../components/Popup/PopupRounded';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import analyticsService from '../../../services/analyticsService';
import iccMarketService from '../../../services/icc/iccMarketService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {updateConsumerProductData} from '../../../state/ducks/consumer';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../DeviceSettingsPage.module.scss';

const DeviceRenamePopup = (props) => {
    const {dispatch, setIsVisibleRenamePopup, device, deviceSerialNumber} = props;
    const [deviceName, setDeviceName] = useState(device?.name);
    const [isInputError, setIsInputError] = useState(false);

    const clearInput = () => {
        setDeviceName('');
    };

    const onChange = ({target}) => {
        setDeviceName(target.value);

        if (target.value) {
            setIsInputError(false);
        }
    };

    const onRenamePopupOk = () => {
        const isDeviceNameNotEmpty = deviceName?.trim().length;
        const processedDeviceName = deviceName.trim();

        if (isDeviceNameNotEmpty) {
            analyticsService.pushUsageSettingsRenameEvent();
            dispatch(
                updateConsumerProductData({
                    deviceSerialNumber,
                    productName: processedDeviceName,
                })
            );
            iccMarketService.setConsumerProductName({productName: processedDeviceName, deviceSerialNumber});
            closePopup();
        } else {
            setDeviceName(processedDeviceName);
            setIsInputError(true);
        }
    };

    const closePopup = () => setIsVisibleRenamePopup(false);
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupRounded onClose={closePopup} isClosingBySwipeEnabled testId={elementsTesIds.RENAME_DEVICE_POPUP}>
            <div className={styles.InputWrapper}>
                <Input
                    className={`${styles.InputRename} ${isInputError && styles.InputError}`}
                    maxLength={50}
                    minLength={3}
                    onChange={onChange}
                    testId={elementsTesIds.RENAME_DEVICE_POPUP_INPUT}
                    type='text'
                    value={deviceName}
                />
                <ButtonText onClick={clearInput} testId={elementsTesIds.RENAME_DEVICE_POPUP_BTN_CLEAR_INPUT}>
                    <Image className={styles.InputRenameIcon} src={[imgIconClose, imgIconClose2, imgIconClose3]} />
                </ButtonText>
            </div>
            <ButtonDark
                className={styles.RenamePopupButton}
                onClick={onRenamePopupOk}
                showArrow
                testId={elementsTesIds.RENAME_DEVICE_POPUP_BTN_OK}
            >
                {localizedStrings[localizationKeys.RENAME_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT]}
            </ButtonDark>
            <Button showUnderline onClick={closePopup} testId={elementsTesIds.RENAME_DEVICE_POPUP_BTN_CANCEL}>
                {localizedStrings[localizationKeys.RENAME_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT]}
            </Button>
        </PopupRounded>
    );
};

export default DeviceRenamePopup;
