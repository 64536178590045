const formatString = (string, ...values) => {
    for (let i = 0; i < values.length; i++) {
        string = string.replace(`{${i}}`, values[i]);
    }

    return string;
};

const formatByName = function (str, col) {
    if (!str) return str;
    col = typeof col === 'object' ? col : Array.prototype.slice.call(arguments, 1);

    return str.replace(/\{\{|\}\}|\{(\w+)\}/g, function (m, n) {
        if (m == '{{') {
            return '{';
        }
        if (m == '}}') {
            return '}';
        }
        return col[n];
    });
};

const isNullOrEmpty = (str) => !str || str === 'null';

export default {formatString, formatByName, isNullOrEmpty};
