import {getDeviceTypesData} from '../../consts/device/deviceTypesData';
import * as iotParentProtectionStatus from '../../consts/iot/iotParentProtectionStatus';
import productService from '../productService';
import {mediaIdMapping} from './iccMarketMappings';

export const mwResponseBaseMapping = (data) => {
    if (!data) return null;
    const mappedData = {...data};

    if (mappedData.internal_id) {
        mappedData.deviceSerialNumber = mappedData.internal_id;

        delete mappedData.internal_id;
    }

    return mappedData;
};

export const uiResponseBaseMapping = (data) => {
    if (!data) return null;
    const mappedData = {...data};

    if (mappedData.internal_id) {
        mappedData.deviceSerialNumber = mappedData.internal_id;

        delete mappedData.internal_id;
    }

    if (mappedData.holder?.internal_id) {
        mappedData.holder.deviceSerialNumber = mappedData.holder.internal_id;

        delete mappedData.holder.internal_id;
    }

    return mappedData;
};

export const deviceInfoMapping = (data) => {
    const mappedData = {...data};
    const {device, holder} = data;

    if (device?.device_color) {
        mappedData.device.colorHex = productService.getHexColorByMaterialColor(device.type, device.device_color);
    }
    if (device?.media_id) {
        mappedData.device.mediaId = mediaIdMapping(device.media_id);
    }
    if (holder?.media_id) {
        mappedData.holder.mediaId = mediaIdMapping(holder.media_id);
    }
    if (device?.type) {
        mappedData.device.familyType = getDeviceTypesData()[device.type].type;
    }

    return mappedData;
};

export const deviceParentModeMapping = (data) => {
    const mappedData = {...data};

    switch (mappedData?.device?.parent_protection_mode) {
        case iotParentProtectionStatus.PARENT_PROTECTION_ENABLED:
            mappedData.device.parent_protection_mode = true;
            break;
        case iotParentProtectionStatus.PARENT_PROTECTION_DISABLED:
            mappedData.device.parent_protection_mode = false;
            break;
        default:
    }

    return mappedData;
};
