import userDeviceService from '../../services/userDeviceService';
import {getBrowserCompatibilityPageData} from './browserCompatibilityData';

const BROWSER_NOT_COMPATIBLE_KEY = 0;
const BROWSER_COMPATIBLE_KEY = 1;

const getBrowserCompatibleButtonData = () => {
    const availableContent = getContentAvailability();

    return getBrowserCompatibilityPageData(availableContent, BROWSER_COMPATIBLE_KEY);
};

const getBrowserNotCompatibleButtonData = () => {
    const availableContent = getContentAvailability();

    return getBrowserCompatibilityPageData(availableContent, BROWSER_NOT_COMPATIBLE_KEY);
};

const getContentAvailability = () => {
    const isAppleTouchDevice = userDeviceService.isAppleTouchDevice();
    const isMobileAndroidDevice = userDeviceService.isMobileAndroidDevice();
    const isMobileIOSDevice = userDeviceService.isMobileIOSDevice();
    const isTabletIOSDevice = userDeviceService.isTabletIOSDevice();
    const isDesktopMacOSDevice = userDeviceService.isDesktopMac();
    const isDesktopWindowsDevice = userDeviceService.isDesktopWindows();
    const isTouchSupported = userDeviceService.isTouchSupported();

    const isAndroidContent = isMobileAndroidDevice;
    const isIOSContent = isMobileIOSDevice || isTabletIOSDevice || isAppleTouchDevice;
    const isMacOSContent = isDesktopMacOSDevice && !isTouchSupported;
    const isWindowsContent = isDesktopWindowsDevice;

    return {isAndroidContent, isIOSContent, isMacOSContent, isWindowsContent};
};

export default {
    getBrowserCompatibleButtonData,
    getBrowserNotCompatibleButtonData,
};
