import classnames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Container from '../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import appIntroService from '../../../services/appIntroService';
import layoutService from '../../../services/layoutService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import userDeviceService from '../../../services/userDeviceService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import browserCompatibilityService from '../browserCompatibilityService';
import CompatibleBrowserDownloadLink from '../CompatibleBrowserDownloadLink/CompatibleBrowserDownloadLink';
import styles from './AppIntroPage.module.scss';
import AppIntroBTIconText from './components/AppIntroBTIconText/AppIntroBTIconText';
import AppIntroPageAlternativeDownloadSection from './components/AppIntroPageAlternativeDownloadSection/AppIntroPageAlternativeDownloadSection';
import AppIntroPageConnectDescription from './components/AppIntroPageConnectDescription';
import AppIntroPageContinueButton from './components/AppIntroPageContinueButton/AppIntroPageContinueButton';
import AppIntroPageDesktopImage from './components/AppIntroPageDesktopImage';
import AppIntroPageDivider from './components/AppIntroPageDivider/AppIntroPageDivider';
import AppIntroPageDownloadSection from './components/AppIntroPageDownloadSection/AppIntroPageDownloadSection';
import AppIntroPageValidateIcon from './components/AppIntroPageValidateIcon';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const TXT_REGULAR_CLASSNAME = 'ica--txt-regular';

const AppIntroPage = ({layout}) => {
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const isBluetoothSupported = userDeviceService.isBluetoothSupported();
    const localizedStrings = getLocalizedStrings();

    const downloadButtonData = browserCompatibilityService.getBrowserCompatibleButtonData();
    const isDownloadButtonAvailable = !!downloadButtonData?.link;

    const connectTextClassName = classnames(styles.ConnectText, TXT_REGULAR_CLASSNAME);

    appIntroService.setSessionStorageData();

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <div className={styles.ContentSide}>
                    <AppIntroPageValidateIcon />

                    <div className={styles.Title}>{localizedStrings[localizationKeys.APP_INTRO_PAGE_TITLE_TEXT]}</div>

                    <AppIntroPageDownloadSection buttonData={downloadButtonData} layout={layout} />

                    {isDownloadButtonAvailable && (
                        <>
                            <AppIntroPageDivider />

                            {!isBluetoothSupported && <AppIntroPageConnectDescription />}
                        </>
                    )}

                    <AppIntroBTIconText layout={layout} isDownloadButtonAvailable={isDownloadButtonAvailable} />

                    {!isDownloadButtonAvailable && isBluetoothSupported && (
                        <p className={connectTextClassName}>
                            {localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_CONNECT]}
                        </p>
                    )}

                    {isDownloadButtonAvailable && isBluetoothSupported && <AppIntroPageConnectDescription />}

                    {!isBluetoothSupported && <CompatibleBrowserDownloadLink className={styles.BrowserLink} />}

                    <AppIntroPageContinueButton isDownloadButtonAvailable={isDownloadButtonAvailable} />

                    {!isDownloadButtonAvailable && isMobileLayout && <AppIntroPageAlternativeDownloadSection />}
                </div>
                {isDesktopLayout && (
                    <div className={styles.ImageSide}>
                        <AppIntroPageDesktopImage />
                    </div>
                )}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(AppIntroPage);
