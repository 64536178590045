import classNames from 'classnames';
import React, {useEffect} from 'react';

import Button from '../../components/Button/Button';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as gamErrorPageTypes from '../../consts/gam/gamErrorPageTypes';
import * as routeParams from '../../consts/route/routeParams';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import pairingService from '../../services/pairingService';
import routerService from '../../services/routerService';
import styles from './GamErrorHandlingPage.module.scss';

const GamErrorHandlingPage = (props) => {
    const {
        match: {params},
    } = props;

    const gamErrorType = params[routeParams.GAM_ERROR_TYPE];
    const localizedStrings = getLocalizedStrings();

    const onTryAgainClick = () => {
        appRouterService.forwardToMyDevicesPage();
    };

    const onContactSupportClick = () => {
        const supportUrl = appConfig.getDcsSupportUrlFormatted();

        routerService.redirect(supportUrl);
    };

    useEffect(() => {
        pairingService.disconnectAndClearState();
    }, []);

    let headerText;
    let descriptionText;
    let darkBtnText;
    let btnText;
    let darkBtnAction;
    let btnAction;

    switch (gamErrorType) {
        case gamErrorPageTypes.GAM_SYSTEM_ERROR: {
            headerText = localizedStrings[localizationKeys.GAM_GLOBAL_ERROR_TITLE_TEXT];
            descriptionText = localizedStrings[localizationKeys.GAM_GLOBAL_ERROR_DESCRIPTION_TEXT];
            darkBtnText = localizedStrings[localizationKeys.GAM_GLOBAL_ERROR_CONTACT_TO_SUPPORT_BUTTON_TEXT];
            darkBtnAction = onContactSupportClick;
            btnText = null;
            break;
        }
        case gamErrorPageTypes.GAM_TEMPORARY_ERROR: {
            headerText = localizedStrings[localizationKeys.GAM_DEVICE_METHODS_ERROR_TITLE_TEXT];
            descriptionText = localizedStrings[localizationKeys.GAM_DEVICE_METHODS_ERROR_DESCRIPTION_TEXT];
            darkBtnText = localizedStrings[localizationKeys.GAM_DEVICE_METHODS_ERROR_TRY_AGAIN_BUTTON_TEXT];
            darkBtnAction = onTryAgainClick;
            btnText = localizedStrings[localizationKeys.GAM_DEVICE_METHODS_ERROR_CONTACT_TO_SUPPORT_BUTTON_TEXT];
            btnAction = onContactSupportClick;
            break;
        }
    }

    const failIconClassName = classNames(styles.FailMessageIcon, 'icon--fail');
    const errorDescriptionClassName = classNames(styles.ErrorHandlingDescription, 'ica--txt-regular');

    return (
        <div className={styles.Page}>
            <div className={failIconClassName} />
            <div className={styles.ErrorHandlingContainer}>
                <h2 className={styles.ErrorHandlingTitle}>{headerText}</h2>
                <>
                    <div className={errorDescriptionClassName}>{descriptionText}</div>
                    <ButtonDark showArrow onClick={darkBtnAction}>
                        {darkBtnText}
                    </ButtonDark>
                    {btnText && (
                        <Button showArrow showUnderline onClick={btnAction}>
                            {btnText}
                        </Button>
                    )}
                </>
            </div>
        </div>
    );
};

export default GamErrorHandlingPage;
