import {config} from '../config';

export default {
    getGamCheckRequestInterval: () => config.data.gam.gamCheckRequestInterval,
    getGamTimeout: () => config.data.gam.gamTimeout,
    getGamWebSocketMessageTimeout: () => config.data.gam.gamWebSocketMessageTimeout,
    getGamUpdateSettingTimeout: () => config.data.gam.gamUpdateSettingTimeout,
    getGamApiUrl: () => config.data.gam.gamApiUrl,
    getGamWssUrl: () => config.data.gam.gamWssUrl,
};
