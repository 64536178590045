import classNames from 'classnames';
import React from 'react';

import layoutService from '../../services/layoutService';
import Image from '../Image/Image';
import styles from './TableCell.module.scss';

const TableCell = ({
    name,
    imgUrls,
    children,
    imgElement,
    onClick,
    classNameContainer,
    classNameImageContainer,
    className,
    layout,
    testId,
}) => {
    const isTabletLayout = layoutService.isTabletLayout(layout);
    const classNameText = classNames('ica--txt-medium', className);
    const classNameTable = classNames(styles.TableCellContainer, classNameContainer);
    const classNameImage = classNames(styles.ImageContainer, classNameImageContainer);

    const getFormattedText = () => {
        if (isTabletLayout) {
            const MAX_VISIBLE_SYMBOLS = 7;
            const text = name.split(' ');
            let formattedText = '';

            text.forEach((word) => {
                formattedText =
                    formattedText +
                    ' ' +
                    (word.length > MAX_VISIBLE_SYMBOLS ? word.substring(0, MAX_VISIBLE_SYMBOLS) + '...' : word);
            });

            return formattedText;
        } else {
            return name;
        }
    };

    return (
        <div className={classNameTable} onClick={onClick} data-testid={testId}>
            <div className={classNameImage}>
                {imgUrls && <Image src={imgUrls} />}
                {imgElement}
            </div>
            <div>
                <span className={classNameText}>{getFormattedText()}</span>
            </div>
            {children}
        </div>
    );
};

export default TableCell;
