import React, {useEffect, useState} from 'react';

import Button from '../../../../../../components/Button/Button';
import ButtonDark from '../../../../../../components/Button/ButtonDark/ButtonDark';
import PopupWarning from '../../../../../../components/Popup/PopupWarning';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import pairingService from '../../../../../../services/pairingService';
import elementsTesIds from '../../../../../../test/consts/elementsTesIds';
import styles from './UnPairBtn.module.scss';

const UnPairBtn = ({onDisableClick, isDeviceReady}) => {
    const [isVisibleUnPairPopup, setIsVisibleUnPairPopup] = useState(false);

    const onUnPairPopupChange = () => {
        if (isDeviceReady) {
            setIsVisibleUnPairPopup(!isVisibleUnPairPopup);
        } else {
            onDisableClick();
        }
    };

    const onUnPairDeviceClick = () => pairingService.disconnectAndForwardToMyDevicesPage();

    const localizedStrings = getLocalizedStrings();

    useEffect(() => {
        setIsVisibleUnPairPopup(false);
    }, [isDeviceReady]);

    return (
        <>
            <ButtonDark
                className={!isDeviceReady && styles.ButtonDisabled}
                onClick={onUnPairPopupChange}
                showArrow
                testId={elementsTesIds.DEVICE_SETTINGS_BTN_UNPAIR}
            >
                {localizedStrings[localizationKeys.DEVICE_SETTINGS_UNPAIR]}
            </ButtonDark>
            {isVisibleUnPairPopup && isDeviceReady && (
                <PopupWarning onClose={onUnPairPopupChange}>
                    <div className={styles.SettingsPopup}>
                        <div className={styles.SettingsPopupHeader}>
                            <h2>{localizedStrings[localizationKeys.UNPAIR_DEVICE_CONFIRM_POPUP_TITLE_TEXT]}</h2>
                        </div>
                        <div className={'ica--txt-regular'}>
                            {localizedStrings[localizationKeys.UNPAIR_DEVICE_CONFIRM_POPUP_DESCRIPTION_TEXT]}
                        </div>
                        <br />
                        <ButtonDark showArrow onClick={onUnPairPopupChange} className={styles.ButtonDecline}>
                            {localizedStrings[localizationKeys.UNPAIR_DEVICE_CONFIRM_POPUP_PRIMARY_TEXT]}
                        </ButtonDark>
                        <Button showUnderline onClick={onUnPairDeviceClick} className={styles.ButtonAccept}>
                            {localizedStrings[localizationKeys.UNPAIR_DEVICE_CONFIRM_POPUP_SECONDARY_TEXT]}
                        </Button>
                    </div>
                </PopupWarning>
            )}
        </>
    );
};

export default UnPairBtn;
