import React from 'react';

import imgBluetoothIconDesktop1 from '../../../../../assets/images/compatible-browser/icon-bluetooth-desktop.png';
import imgBluetoothIconDesktop2 from '../../../../../assets/images/compatible-browser/icon-bluetooth-desktop@2x.png';
import imgBluetoothIconDesktop3 from '../../../../../assets/images/compatible-browser/icon-bluetooth-desktop@3x.png';
import imgBluetoothIconMobile1 from '../../../../../assets/images/compatible-browser/icon-bluetooth-mobile.png';
import imgBluetoothIconMobile2 from '../../../../../assets/images/compatible-browser/icon-bluetooth-mobile@2x.png';
import imgBluetoothIconMobile3 from '../../../../../assets/images/compatible-browser/icon-bluetooth-mobile@3x.png';
import ResponsiveImage from '../../../../components/Image/ResponsiveImage';

const AppIntroPageBluetoothIcon = () => (
    <ResponsiveImage
        desktop={[imgBluetoothIconDesktop1, imgBluetoothIconDesktop2, imgBluetoothIconDesktop3]}
        mobile={[imgBluetoothIconMobile1, imgBluetoothIconMobile2, imgBluetoothIconMobile3]}
    />
);

export default AppIntroPageBluetoothIcon;
