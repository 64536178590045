import appConfig from '../../config/appConfig';
import * as gamDataChangePropertyTypes from '../../consts/gam/gamDataChangePropertyTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapWebSocketActionTypes from '../../consts/yap/yapWebSocketActionTypes';
import * as yapWebSocketEventTypes from '../../consts/yap/yapWebSocketEventTypes';
import {setIsDeviceReady} from '../../state/ducks/iotDevice/actions';
import {
    setDeviceDataChangeReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapDeviceConnected,
    setYapLastRequestId,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {
    makeSelectIsDeviceDataChangeReceived,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapUpdateSettingsInProgress,
    makeSelectSyncLastRequestId,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import appErrorService from '../app/appErrorService';
import iqosBleService from '../ble/iqosBleService';
import cmClientService from '../communicationLayer/cmClientService';
import log from '../logger/log';
import gamClientService from './gamClientService';
import gamService from './gamService';

const proceedMessage = (message) => {
    const data = JSON.parse(message.data) || {};

    switch (data?.eventType) {
        case yapWebSocketEventTypes.CONNECTION_STATUS_CHANGE:
            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                if (cmClientService.isCmClientConnected(data.assetId)) {
                    dispatch(setYapDeviceConnected(true));
                }
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                dispatch(setYapDeviceConnected(false));
            }
            break;
        case yapWebSocketEventTypes.ASYNC_REQUEST:
            switch (data.request?.action) {
                case yapWebSocketActionTypes.ACTIVATE:
                case yapWebSocketActionTypes.DEACTIVATE:
                    if (proceedGamAction(data.status, data.requestId)) {
                        checkProcessFinish(true);
                    }
                    break;
                case yapWebSocketActionTypes.UPDATE_SETTING:
                    if (proceedGamAction(data.status, data.requestId)) {
                        checkProcessFinish(false);
                    }
                    break;
                case yapWebSocketActionTypes.REFRESH_INFO:
                    const yapStatus = proceedGamAction(data.status, data.requestId, true);
                    if (yapStatus) {
                        proceedRefreshInfo(yapStatus);
                    }
                    break;
            }
            break;

        case yapWebSocketEventTypes.DEVICE_DATA_CHANGE:
            if (!data.updatedData) return;

            data.updatedData.forEach((updatedObj) => {
                switch (updatedObj?.propertyName) {
                    case gamDataChangePropertyTypes.GAM_YAP_ACTIVATION_STATUS_PROPERTY:
                        updateYapActivationStatus(updatedObj.newValue);
                        break;
                    case gamDataChangePropertyTypes.GAM_YAP_RESPONSIVE_DRAW_PROPERTY:
                        updateDeviceSettings(iotMessageTypes.RESPONSIVE_DRAW_PROFILES, updatedObj.newValue);
                        break;
                    case gamDataChangePropertyTypes.GAM_YAP_VAPE_CLOUD_SIZE_PROPERTY:
                        updateDeviceSettings(iotMessageTypes.VAPE_CLOUD_SIZE, updatedObj.newValue);
                        break;
                    case gamDataChangePropertyTypes.GAM_YAP_PARENT_PROTECTION_PROPERTY:
                        updateDeviceSettings(iotMessageTypes.PARENT_PROTECTION, updatedObj.newValue);
                        break;
                    case gamDataChangePropertyTypes.GAM_YAP_LED_PROPERTY:
                        updateDeviceSettings(iotMessageTypes.LED, updatedObj.newValue);
                        break;
                }
            });

            break;
        default:
            break;
    }
};

const proceedRefreshInfo = async (status) => {
    if (yapActionStatusTypes.SUCCESS === status) {
        let isDeviceConnected = iqosBleService.isDeviceConnected();
        if (isDeviceConnected) {
            if (await gamClientService.getGamDeviceInfo()) {
                isDeviceConnected = iqosBleService.isDeviceConnected();
                if (isDeviceConnected) {
                    dispatch(setIsDeviceReady());
                }
            }
        }
    }

    dispatch(setYapSyncInProgress(false));
};

let gamCheckProcessFinishTimeout;
const checkProcessFinish = (isActivation) => {
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());

    if (!isYapActivationInProgress && !isYapUpdateSettingsInProgress) return;

    const onFinish = () => {
        dispatch(setDeviceDataChangeReceived(false));
        dispatch(setYapLastRequestId(null));

        if (isActivation) {
            if (isYapActivationInProgress) {
                dispatch(setYapActivationInProgress(false));
            }
        } else {
            if (isYapUpdateSettingsInProgress) {
                dispatch(setYapUpdateSettingsInProgress(false));
            }
        }
    };

    const state = getState();
    const lastRequestId = makeSelectYapLastRequestId()(state);
    const isDeviceDataChangeReceived = makeSelectIsDeviceDataChangeReceived()(state);
    if (isDeviceDataChangeReceived && !lastRequestId) {
        clearTimeout(gamCheckProcessFinishTimeout);

        onFinish();
        return;
    }

    const onFinishFailed = async () => {
        if (isActivation) {
            await gamClientService.getGamAssets(true);
            await gamService.checkIsDeviceActivatedInGam();
        } else {
            appErrorService.showDeviceSettingsGlobalError();
        }

        onFinish();
    };

    gamCheckProcessFinishTimeout = setTimeout(onFinishFailed, appConfig.getGamWebSocketMessageTimeout() * 1000);
};

const proceedGamAction = (status, requestId, isSync = false) => {
    const isPending = status === yapActionStatusTypes.PENDING;
    if (isPending) return false;

    const lastRequestId = isSync ? makeSelectSyncLastRequestId()(getState()) : makeSelectYapLastRequestId()(getState());
    if (!lastRequestId || requestId !== lastRequestId) {
        log.info(`gamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for ${lastRequestId}`);
        return false;
    }

    const isFailed = status === yapActionStatusTypes.FAILED;
    const isSuccess = status === yapActionStatusTypes.SUCCESS;
    if (!isFailed && !isSuccess) return false;

    if (isSync) {
        dispatch(setSyncLastRequestId(null));
    } else {
        dispatch(setYapLastRequestId(null));
    }

    return isSuccess ? yapActionStatusTypes.SUCCESS : yapActionStatusTypes.FAILED;
};

const updateYapActivationStatus = async (yapActivationStatus) => {
    await gamService.updateGamActivationStatus(yapActivationStatus);

    dispatch(setDeviceDataChangeReceived(true));

    checkProcessFinish(true);
};

const updateDeviceSettings = (type, newValue) => {
    gamService.updateDeviceSettings(type, newValue);

    dispatch(setDeviceDataChangeReceived(true));

    checkProcessFinish(false);
};

export default {proceedMessage};
