import React from 'react';

import imgIconValidateDesktop1 from '../../../../../assets/images/compatible-browser/icon-validate-desktop.png';
import imgIconValidateDesktop2 from '../../../../../assets/images/compatible-browser/icon-validate-desktop@2x.png';
import imgIconValidateDesktop3 from '../../../../../assets/images/compatible-browser/icon-validate-desktop@3x.png';
import imgIconValidateMobile1 from '../../../../../assets/images/compatible-browser/icon-validate-mobile.png';
import imgIconValidateMobile2 from '../../../../../assets/images/compatible-browser/icon-validate-mobile@2x.png';
import imgIconValidateMobile3 from '../../../../../assets/images/compatible-browser/icon-validate-mobile@3x.png';
import ResponsiveImage from '../../../../components/Image/ResponsiveImage';
import styles from '../AppIntroPage.module.scss';

const AppIntroPageValidateIcon = () => {
    return (
        <div className={styles.IconWrapper}>
            <ResponsiveImage
                className={styles.Icon}
                desktop={[imgIconValidateDesktop1, imgIconValidateDesktop2, imgIconValidateDesktop3]}
                mobile={[imgIconValidateMobile1, imgIconValidateMobile2, imgIconValidateMobile3]}
            />
        </div>
    );
};

export default AppIntroPageValidateIcon;
