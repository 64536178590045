import appConfig from '../../config/appConfig';
import ageGatePageConsts from './ageGatePageConsts';

const DEFAULT_DAYS_COUNT = 31;
const DEFAULT_MIN_YEARS = 18;

const isAgeAcceptable = ({month, year, day}) => {
    if (month !== undefined && year !== undefined) {
        const isMonthDefault = month === ageGatePageConsts.DEFAULT_VALUE;
        const isYearDefault = year === ageGatePageConsts.DEFAULT_VALUE;
        const isDayDefault = day === ageGatePageConsts.DEFAULT_VALUE;
        const isDayExists = day !== null && day !== undefined;

        if (!isMonthDefault && !isYearDefault) {
            const minAcceptedAge = appConfig.getAgeGateMinAge() || DEFAULT_MIN_YEARS;

            const date = new Date();
            const currentMonth = date.getMonth();
            const currentYear = date.getFullYear();
            const currentDay = date.getDate();

            const yearsDelta = currentYear - year;
            const isYearsDeltaAcceptable = yearsDelta > minAcceptedAge;
            const isConsumerAgeMinToVerify = yearsDelta === minAcceptedAge;
            const isYearAcceptable = isYearsDeltaAcceptable || isConsumerAgeMinToVerify;

            let isMonthAcceptable = false;
            let isDayAcceptable = isDayExists && !isDayDefault;

            if (isConsumerAgeMinToVerify) {
                isMonthAcceptable = isDayExists ? month <= currentMonth : month < currentMonth;

                if (!isDayDefault && isMonthAcceptable) {
                    isDayAcceptable = currentDay > day;
                }
            } else if (isYearsDeltaAcceptable) {
                isMonthAcceptable = true;

                if (!isDayDefault && isMonthAcceptable) {
                    isDayAcceptable = true;
                }
            }

            if (isDayExists) {
                return isMonthAcceptable && isYearAcceptable && isDayAcceptable;
            } else {
                return isMonthAcceptable && isYearAcceptable;
            }
        } else {
            return false;
        }
    } else {
        return false;
    }
};

const getDaysCount = ({month, year}) => {
    const isMonthDefault = month === ageGatePageConsts.DEFAULT_VALUE;
    const isYearDefault = year === ageGatePageConsts.DEFAULT_VALUE;

    return !isMonthDefault && !isYearDefault ? new Date(year, month + 1, 0).getDate() : DEFAULT_DAYS_COUNT;
};

export default {
    isAgeAcceptable,
    getDaysCount,
};
