const PRODUCT_COLORS = {
    BLUE_METALLIC: 'EAEFF5',
    COPPER: 'F4EDE9',
    DEFAULT: 'F1F2F1',
    GRAPHITE: 'F2F2F2',
    LUCID_TEAL: 'E4EEEF',
    MELLOW_GOLD: 'F2EFEB',
    RADIENT_RED: 'EFE2E4',
    SLATE: 'E2E5E6',
    WARM_WHITE: 'F1F2F1',
};

export default PRODUCT_COLORS;
