import React from 'react';

import elementsTesIds from '../../../test/consts/elementsTesIds';
import FloatButton from '../../Button/FloatButton/FloatButton';
import styles from './PopupCloseButton.module.scss';

const PopupCloseButton = ({onClick}) => {
    return (
        <FloatButton className={styles.CloseButton} onClick={onClick} testId={elementsTesIds.POPUP_COMMON_BTN_CLOSE} />
    );
};

export default PopupCloseButton;
