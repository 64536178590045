import queryParameters from '../consts/route/queryParameters';

const POPUP_SPAWN_NODE_ID = 'popup-spawn';

const getDataFromRoot = (attr) => getRoot().getAttribute(attr);
const getDataLanguageFromRoot = () => getDataFromRoot(queryParameters.DATA_LANGUAGE);
const getDataUserTokenFromRoot = () => getDataFromRoot(queryParameters.DATA_USER_TOKEN);
const getElementBySelector = (selector) => document.querySelector(selector);
const getRoot = () => document.getElementById('root');
const getTokenUrlFromRoot = () => getDataFromRoot(queryParameters.DATA_REFRESH_TOKEN_URL);
const getDeviceCodentifyFromRoot = () => {
    const codentify = getDataFromRoot(queryParameters.DATA_DEVICE_CODENTIFY);
    if (codentify && codentify.toLowerCase() === 'undefined') {
        return null;
    }
    return codentify;
};

const DATA_FACTORY_RESPONSE_YES = 'Y';
const DATA_FACTORY_RESPONSE_NO = 'N';
const DATA_FACTORY_RESPONSE_NULL = 'null';
const DATA_FACTORY_RESPONSE_UNDEFINED = 'undefined';
const DATA_FACTORY_RESPONSE_EMPTY = '';

const getDeviceFactoryFromRoot = () => {
    const dataFactory = getDataFromRoot(queryParameters.DATA_DEVICE_FACTORY);

    switch (dataFactory) {
        case DATA_FACTORY_RESPONSE_YES:
            return true;
        case DATA_FACTORY_RESPONSE_NO:
            return false;
        case DATA_FACTORY_RESPONSE_NULL:
            return null;
        case DATA_FACTORY_RESPONSE_UNDEFINED:
        case DATA_FACTORY_RESPONSE_EMPTY:
        case null:
            return null;
        //return empty message when device factory will be implemented on dce2 side
        //return '';
        default:
            return undefined;
    }
};

const getPopupSpawnNode = () => document.getElementById(POPUP_SPAWN_NODE_ID);

export default {
    getDataFromRoot,
    getDataLanguageFromRoot,
    getDataUserTokenFromRoot,
    getDeviceCodentifyFromRoot,
    getDeviceFactoryFromRoot,
    getElementBySelector,
    getPopupSpawnNode,
    getRoot,
    getTokenUrlFromRoot,
};
