import {config} from '../config';

export default {
    getAnonymousToken: () => config.data.icc.anonymousToken,
    getDataUserToken: () => config.data.icc.dataUserToken,
    getIccApiBodyScope: () => config.data.icc.iccApiBodyScope,
    getIccApiHeader_a: () => config.data.icc.iccApiHeader_a,
    getIccApiHeader_m: () => config.data.icc.iccApiHeader_m,
    getIccApiHeaderAuthorization: () => config.data.icc.iccApiHeaderAuthorization,
    getIccApiHeaderUsername: () => config.data.icc.iccApiHeaderUsername,
    getIccApiUrl: () => config.data.icc.iccApiUrl,
    getIccAuthUrl: () => config.data.icc.iccAuthUrl,
    getIsICCConsumerServiceEnabled: () => config.data.icc.isICCConsumerServiceEnabled,
};
