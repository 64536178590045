const ageGatePageConsts = {
    DEFAULT_VALUE: 'DEFAULT',
    MONTH_JANUARY_VALUE: 0,
    MONTH_FEBRUARY_VALUE: 1,
    MONTH_MARCH_VALUE: 2,
    MONTH_APRIL_VALUE: 3,
    MONTH_MAY_VALUE: 4,
    MONTH_JUNE_VALUE: 5,
    MONTH_JULY_VALUE: 6,
    MONTH_AUGUST_VALUE: 7,
    MONTH_SEPTEMBER_VALUE: 8,
    MONTH_OCTOBER_VALUE: 9,
    MONTH_NOVEMBER_VALUE: 10,
    MONTH_DECEMBER_VALUE: 11,
    YEAR_START_VALUE: 1920,
    YEAR_CURRENT: new Date().getFullYear(),
    AGE_GATE_PAGE_DATA_MAPPING: {
        month: null,
        year: null,
    },
};

export default ageGatePageConsts;
