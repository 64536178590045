import React from 'react';

import Image from '../../../components/Image/Image';
import routerService from '../../../services/routerService';
import helpers from '../../../utils/helpers';
import styles from './OSRelatedDownloadButton.module.scss';

const OSRelatedDownloadButton = ({buttonData}) => {
    const {link, text, onClick, srcSet} = buttonData || {};

    const isButtonVisible = link && text && srcSet;

    if (!isButtonVisible) return null;

    const onButtonClick = () => {
        if (onClick) {
            helpers.runFunction(onClick);
        } else if (link) {
            routerService.blankLinkOpen(link);
        }
    };

    return (
        <div className={styles.ButtonContainer} onClick={onButtonClick}>
            <Image src={srcSet} className={styles.Image} />
            <p className={styles.Text} dangerouslySetInnerHTML={{__html: text}} />
        </div>
    );
};

export default OSRelatedDownloadButton;
