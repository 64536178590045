import {setMwDeviceIsDisconnectedWithNoReconnect} from '../../state/ducks/mwDevice';
import {dispatch} from '../../state/store';
import helpers from '../../utils/helpers';
import framesProcessingService from '../device/framesProcessingService';
import DisconnectEventEmitter from '../events/disconnectEventEmitter';
import log from '../logger/log';
import iotService from '../scpCloud/iotService';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import uiIotMessageResponseChecker from '../scpCloud/uiIotMessageResponseChecker';
import HIDClient from './hidClient';

const connect = async ({isNewDevice, onConnect, onError}) => {
    try {
        const options = {
            onDisconnected: async (isDisconnectedByUser) => {
                //TODO: IA: create one service for hid and ble?
                const disconnectEventEmitter = new DisconnectEventEmitter();

                if (isDisconnectedByUser) {
                    dispatch(setMwDeviceIsDisconnectedWithNoReconnect(true));
                }

                disconnectEventEmitter.emitDeviceDisconnect();
                disconnectEventEmitter.removeAllDisconnectListeners();
                framesProcessingService.disable();
                iotService.unsubscribeFromDeviceTopic();
                uiIotMessageResponseChecker.clearResponsesTimeouts();
                mwIotMessageRequestService.publishDisconnectedEvent();
            },
        };
        const hidClient = new HIDClient(true, options);

        await hidClient.connectDevice({isNewDevice, onConnect});
    } catch (e) {
        log.debug(`iqosHidService: device connection failed, error: ${e}`);
        helpers.runFunction(onError, [e]);
        throw e;
    }
};

const disconnect = () => {
    const hidClient = new HIDClient();

    hidClient.disconnect();
};

const isDeviceConnected = () => {
    const hidClient = new HIDClient();

    return hidClient.isDeviceConnected();
};

const unmount = () => {};

export default {
    connect,
    disconnect,
    isDeviceConnected,
    unmount,
};
