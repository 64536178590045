import React from 'react';

import styles from './VibrationCircles.module.scss';

const VibrationCircles = (props) => {
    const {children, circleState} = props;

    return (
        <div className={styles.VibrationCircles}>
            {circleState ? (
                <div className={styles.First}>
                    <div className={styles.Second}>
                        <div className={styles.Third}>
                            <div className={styles.Fourth} />
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles.First}>
                    <div className={styles.Second} />
                </div>
            )}
            <div className={styles.ChildContainer}>{children}</div>
        </div>
    );
};

export default VibrationCircles;
