import React from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupWarning from '../../../../components/Popup/PopupWarning';
import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';

const HolderSBLModePopup = ({onClose}) => {
    const localizedStrings = getLocalizedStrings();
    const iqosHomeUrl = appConfig.getIqosHomeUrlFormatted();

    return (
        <PopupWarning testId='sbl-popup-container'>
            <h2>{localizedStrings[localizationKeys.HOLDER_IN_SBL_UPDATE_POPUP_DESCRIPTION_TEXT]}</h2>
            <ButtonDark showArrow onClick={onClose} testId='sbl-popup-close-button'>
                {localizedStrings[localizationKeys.HOLDER_IN_SBL_UPDATE_POPUP_OK_GOT_IT_BUTTON_TEXT]}
            </ButtonDark>
            {iqosHomeUrl && (
                <Button showUnderline to={iqosHomeUrl} isExternalLink isTargetBlank>
                    {localizedStrings[localizationKeys.HOLDER_IN_SBL_UPDATE_POPUP_GO_TO_IQOS_COM_BUTTON_TEXT]}
                </Button>
            )}
        </PopupWarning>
    );
};

export default HolderSBLModePopup;
