import deviceConnectionStatusTypes from '../../../consts/device/deviceConnectionStatusTypes';
import firmwareStatusTypes from '../../../consts/device/firmwareStatusTypes';
import {makeSelectIsDeviceDisconnected} from '../../selectors/iotDevice';
import {makeMwDeviceIsDisconnectedWithNoReconnect} from '../../selectors/mwDevice';
import {setMwDeviceIsDisconnectedWithNoReconnect} from '../mwDevice';
import * as types from './actionTypes';

export const clearIotDevice = () => ({type: types.IOT_DEVICE_CLEAR});

export const setIsDisconnected = () => {
    return (dispatch, getState) => {
        const state = getState();
        const isDeviceDisconnected = makeSelectIsDeviceDisconnected()(state);
        const isDisconnectedWithNoReconnect = makeMwDeviceIsDisconnectedWithNoReconnect()(state);
        const statusToChange =
            isDisconnectedWithNoReconnect || isDeviceDisconnected
                ? deviceConnectionStatusTypes.DISCONNECTED
                : deviceConnectionStatusTypes.PAIRED;

        dispatch(setConnectedStatus(statusToChange, isDisconnectedWithNoReconnect));
    };
};

export const setIsPaired = () => setDeviceConnectionStatus(deviceConnectionStatusTypes.PAIRED);

const setConnectedStatus = (connectionStatus, isDisconnectedWithNoReconnect) => {
    return (dispatch) => {
        dispatch(setDeviceConnectionStatus(connectionStatus));

        if (isDisconnectedWithNoReconnect) {
            dispatch(setMwDeviceIsDisconnectedWithNoReconnect(false));
        }
    };
};

export const setIsConnected = () => {
    return (dispatch, getState) => {
        const state = getState();
        const isDisconnectedWithNoReconnect = makeMwDeviceIsDisconnectedWithNoReconnect()(state);

        dispatch(setConnectedStatus(deviceConnectionStatusTypes.CONNECTED, isDisconnectedWithNoReconnect));
    };
};

export const setIsDeviceReady = () => setDeviceConnectionStatus(deviceConnectionStatusTypes.READY);

export const setDeviceConnectionStatus = (status) => ({
    type: types.IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS,
    payload: {status},
});

export const setDeviceActivationStatus = (status) => ({
    type: types.IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS,
    payload: {status},
});

export const clearIotDeviceData = () => ({type: types.IOT_DEVICE_CLEAR_DATA});

export const setIsReset = (isReset) => ({
    type: types.IOT_DEVICE_SET_IS_RESET,
    payload: {isReset},
});

export const setShouldShowSBLModePopup = (shouldShowSBLModePopup) => ({
    type: types.IOT_DEVICE_SET_SHOULD_SHOW_SBL_POPUP,
    payload: {shouldShowSBLModePopup},
});

export const updateIotDeviceData = (data) => ({
    type: types.IOT_DEVICE_UPDATE_DATA,
    payload: {data},
});

export const setFWUStarted = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.STARTED}},
});

export const setFWUFinished = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.FINISHED}},
});

export const setFWUSuccess = () => ({
    type: types.IOT_DEVICE_SET_FIRMWARE_DATA,
    payload: {data: {firmwareStatus: firmwareStatusTypes.SUCCESS}},
});

export const setMessage = (message) => ({
    type: types.IOT_DEVICE_SET_MESSAGE,
    payload: {data: message},
});

export const clearDeviceConnectionCareState = () =>
    updateIotDeviceData({
        errors: null,
    });

export const setIsVibrationAlarmActive = (state) => ({
    type: types.IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE,
    payload: {data: state},
});
