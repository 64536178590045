import appConfig from '../config/appConfig';
import * as iccSsoDeeplinkTemplates from '../consts/icc/iccSsoDeeplinkTemplates';
import appRouterService from './appRouterService';
import authDataStoreService from './auth/authDataStoreService';
import iccMarketService from './icc/iccMarketService';
import iccSsoService from './icc/iccSsoService';
import log from './logger/log';
import dcsService from './market/dcs/dcsService';
import marketConfigService from './marketConfigService';
import routerService from './routerService';

const CONSUMER_HAV_CONFIRMED = 'YES';
const CONSUMER_HAV_DECLINED = 'NO';

const verifyHav = async () => {
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    if (isIccJourneyScreensEnabled) {
        const spiceToken = authDataStoreService.getSpiceToken();

        if (spiceToken) {
            const isHAVMandatory = appConfig.getIsHAVMandatory();

            if (isHAVMandatory) {
                const consumerData = await iccMarketService.getExternalConsumerProfile();

                if (consumerData) {
                    const hav = consumerData.hav;
                    const isMarketHAVAvailable = appConfig.getIsMarketHAVAvailable();

                    switch (hav?.toUpperCase()) {
                        case CONSUMER_HAV_CONFIRMED:
                            //TODO: action when hav confirmed
                            return true;
                        case CONSUMER_HAV_DECLINED:
                            if (isMarketHAVAvailable) {
                                const iqosHomeUrl = appConfig.getIqosHomeUrlFormatted();

                                routerService.redirect(iqosHomeUrl);

                                return false;
                            } else {
                                appRouterService.forwardToAgeVerificationFailedPage();

                                return false;
                            }
                        default:
                            try {
                                const ssoLink = await iccSsoService.getSsoLink(
                                    iccSsoDeeplinkTemplates.ICC_SSO_HAV_DEEPLINK_TEMPLATE
                                );

                                if (ssoLink) {
                                    routerService.redirect(ssoLink);

                                    return false;
                                } else {
                                    if (isMarketHAVAvailable) {
                                        dcsService.showHAV();

                                        return false;
                                    } else {
                                        appRouterService.forwardToAgeVerificationFailedPage();

                                        return false;
                                    }
                                }
                            } catch (e) {
                                log.error(`havService: showHAV, error: ${e}`);
                            }
                            break;
                    }
                } else {
                    appRouterService.forwardToAgeVerificationFailedPage();

                    return false;
                }
            } else {
                await iccMarketService.getConsumerProfile();
                return true;
            }
        }
    }
};

export default {
    verifyHav,
};
