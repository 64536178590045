import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import validateImg1 from '../../../assets/images/welcome-user/icon-validate-mobile-white.png';
import validateImg2 from '../../../assets/images/welcome-user/icon-validate-mobile-white@2x.png';
import validateImg3 from '../../../assets/images/welcome-user/icon-validate-mobile-white@3x.png';
import heroImgDesktop from '../../../assets/images/welcome-user/welcome-hero-img-desktop.png';
import heroImgMobile from '../../../assets/images/welcome-user/welcome-hero-img-mobile@3x.png';
import Image from '../../components/Image/Image';
import appConfig from '../../config/appConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import authDataStoreService from '../../services/auth/authDataStoreService';
import iccMarketService from '../../services/icc/iccMarketService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectConsumerFirstName} from '../../state/selectors/consumer';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {getState} from '../../state/store';
import stringUtils from '../../utils/stringUtils';
import styles from './WelcomeUserPage.module.scss';

const mapStateToProps = createStructuredSelector({
    layout: makeSelectLayoutType(),
});

const REDIRECT_TIMEOUT = 2000;

const WelcomeUserPage = (props) => {
    const {layout} = props;
    const isDesktopLayout = layoutService.isDesktopLayout(layout);

    const [consumerName, setConsumerName] = useState(null);
    const backgroundImageWrapper = useRef();

    const localizedStrings = getLocalizedStrings();

    const validateImg = [validateImg1, validateImg2, validateImg3];

    let timer;
    const setupTimeout = () => {
        timer = setTimeout(() => {
            appRouterService.forwardToTNCTermsOfUsePage();

            clearTimeout(timer);
        }, REDIRECT_TIMEOUT);
    };

    const getBackgroundImage = async () => {
        const PREFIX_DESKTOP = 'desktop';
        const PREFIX_MOBILE = 'mobile';
        const prefix = isDesktopLayout ? PREFIX_DESKTOP : PREFIX_MOBILE;

        const heroImagePath = appConfig.getWelcomePageImagePath();
        const formattedHeroImagePath = stringUtils.formatString(heroImagePath, prefix);

        const data = await iccMarketService.getMediaUrl({mediaNameList: formattedHeroImagePath});

        const backgroundImageUrl = data?.[0]?.url;
        let finalImageSrc;

        if (backgroundImageUrl) {
            finalImageSrc = backgroundImageUrl;
        } else {
            finalImageSrc = isDesktopLayout ? heroImgDesktop : heroImgMobile;
        }

        const tempImage = new window.Image();
        tempImage.src = finalImageSrc;
        tempImage.onload = setupTimeout;

        backgroundImageWrapper.current.style.backgroundImage = `url(${tempImage.src})`;
    };

    const getFirstName = async () => {
        await iccMarketService.getExternalConsumerProfile();

        const consumerUsername = authDataStoreService.getAuthUsername();
        const consumerFirstName = makeSelectConsumerFirstName()(getState());

        setConsumerName(consumerFirstName || consumerUsername || '');
    };

    useEffect(() => {
        getFirstName();
        getBackgroundImage();
    }, []);

    return (
        <div className={styles.Page}>
            <div className={styles.BackgroundImage} ref={backgroundImageWrapper} />
            <Image src={validateImg} className={styles.ValidateImage} />
            <div className={styles.TextContainer}>{`${
                localizedStrings[localizationKeys.WELCOME_TEXT]
            } ${consumerName}`}</div>
        </div>
    );
};

export default connect(mapStateToProps)(WelcomeUserPage);
