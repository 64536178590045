import appConfig from '../../config/appConfig';
import * as uamWebSocketActionValues from '../../consts/uam/uamWebSocketActionValues';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import * as yapWebSocketActionTypes from '../../consts/yap/yapWebSocketActionTypes';
import * as yapWebSocketEventTypes from '../../consts/yap/yapWebSocketEventTypes';
import {setDeviceActivationStatus} from '../../state/ducks/iotDevice/actions';
import {setYapActivationInProgress, setYapDeviceConnected, setYapSyncInProgress} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapDeviceConnected,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import cmClientService from '../communicationLayer/cmClientService';
import log from '../logger/log';
import YapWebSocketClient from '../yap/yapWebSocketClient';
import uamClientService from './uamClientService';
import uamService from './uamService';

let checkStatusScheduler;

let uamReconnectTimeout;
const clearReconnectTimeout = () => clearTimeout(uamReconnectTimeout);

let isOneMoreCheckStatusNeeded = false;
let isUamSocketClosed = true;

const initUamWebSocket = async (assetId, isNewConnection = true) => {
    if (!isNewConnection && isUamSocketClosed) {
        return;
    }

    isUamSocketClosed = false;

    clearReconnectTimeout();

    if (isUamWebSocketConnected(assetId)) {
        return;
    }

    const onError = () => {
        if (!isNewConnection && isUamSocketClosed) {
            return;
        }

        createCheckStatusScheduler(assetId);
        const RECONNECT_TIMEOUT_MS = 5000;

        disconnectUamWebSocket(false);
        uamReconnectTimeout = setTimeout(() => initUamWebSocket(assetId, false), RECONNECT_TIMEOUT_MS);
    };

    const uamTopicSubscribeSettings = await uamClientService.assetSubscribe(assetId);

    if (uamTopicSubscribeSettings) {
        if (!isNewConnection && isUamSocketClosed) {
            return;
        }

        const uamClient = new YapWebSocketClient(assetId, true, uamTopicSubscribeSettings);

        uamClient.attachCloseHandler(async (message) => {
            //normal close from code
            if (message?.code !== 1000) {
                onError();
            }
        });

        uamClient.attachConnectHandler(() => {
            isOneMoreCheckStatusNeeded = true;
            subscribeOnMessage();
        });
    } else {
        onError();
    }
};

const createCheckStatusScheduler = (assetId, interval = appConfig.getUamCheckStatusInterval()) => {
    if (checkStatusScheduler) return;

    log.info('uamWebSocketService: check status scheduler is started');
    checkStatusScheduler = setTimeout(checkStatus, interval * 1000, assetId, interval);
};

const checkStatus = async (assetId, interval) => {
    const isWebSocketConnected = isUamWebSocketConnected(assetId);
    if (isOneMoreCheckStatusNeeded || !isWebSocketConnected) {
        if (isWebSocketConnected) {
            isOneMoreCheckStatusNeeded = false;
        }

        const state = getState();
        const isUamDeviceConnected = makeSelectIsYapDeviceConnected()(state);
        if (!isUamDeviceConnected) {
            await uamClientService.checkConnectivityStatus(assetId);
        } else {
            const isUamActivationInProgress = makeSelectIsYapActivationInProgress()(state);
            if (isUamActivationInProgress) {
                const requestId = makeSelectYapLastRequestId()(state);
                if (requestId) {
                    await uamClientService.checkRequestStatus(assetId, requestId);
                }
            }
        }
    }

    checkStatusScheduler = setTimeout(checkStatus, interval * 1000, assetId, interval);
};

const disconnectUamWebSocket = (isForceDisconnect = true) => {
    if (isForceDisconnect) {
        isUamSocketClosed = true;
    }

    if (isForceDisconnect && checkStatusScheduler) {
        clearTimeout(checkStatusScheduler);
        checkStatusScheduler = null;
        log.info('uamWebSocketService: check status scheduler is stopped');
    }
    clearReconnectTimeout();

    const yapWebSocketClient = new YapWebSocketClient();

    yapWebSocketClient?.disconnect();
};

const subscribeOnMessage = () => {
    new YapWebSocketClient().attachMessageHandler((message) => {
        const data = JSON.parse(message.data) || {};

        switch (data?.eventType) {
            case yapWebSocketEventTypes.CONNECTION_STATUS_CHANGE:
                if (data.status === yapConnectionStatusTypes.CONNECTED) {
                    if (cmClientService.isCmClientConnected(data.assetId)) {
                        dispatch(setYapDeviceConnected(true));
                    }
                } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                    dispatch(setYapDeviceConnected(false));
                }
                break;
            case yapWebSocketEventTypes.ASYNC_REQUEST:
                switch (data.request?.action) {
                    case yapWebSocketActionTypes.ACTIVATE:
                        return proceedUamAction(true, data.status, data.request.value, data.requestId);
                    case yapWebSocketActionTypes.DEACTIVATE:
                        return proceedUamAction(false, data.status, data.request.value, data.requestId);
                    case yapWebSocketActionTypes.REFRESH_INFO:
                        return proceedRefreshInfo();
                }
                break;
            default:
                break;
        }
    });
};

const proceedRefreshInfo = async () => {
    await refreshAssetsAndSetIsActivated();
    dispatch(setYapSyncInProgress(false));
};

const proceedUamAction = async (isActivateAction, status, value, requestId) => {
    const isPending = status === yapActionStatusTypes.PENDING;
    if (isPending) return;

    const lastRequestId = makeSelectYapLastRequestId()(getState());
    if (!lastRequestId || requestId !== lastRequestId) {
        log.info(`uamWebSocketService: answer for requestId ${requestId} is ignored. Waiting for ${lastRequestId}`);
        return;
    }

    const isFailed = status === yapActionStatusTypes.FAILED;
    const isSuccess = status === yapActionStatusTypes.SUCCESS && value === uamWebSocketActionValues.COMPLETE_WORKFLOW;
    if (!isFailed && !isSuccess) return;

    if (isFailed) {
        try {
            await refreshAssetsAndSetIsActivated();
        } catch (e) {
            log.error(`uamWebSocketService: check activation action after failed status error: ${e}`);
        }
    } else {
        //success
        dispatch(setDeviceActivationStatus(isActivateAction));
    }
    dispatch(setYapActivationInProgress(false));
};

const refreshAssetsAndSetIsActivated = async () => {
    await uamClientService.getAssets();

    const iotProduct = makeSelectIotDeviceMergedWithIccProduct()(getState());
    const {deviceSerialNumber} = iotProduct || {};
    await uamService.isDeviceActivatedInUam(deviceSerialNumber);
};

const isUamWebSocketConnected = (assetId) => new YapWebSocketClient().isConnectedCheck(assetId);

export default {
    initUamWebSocket,
    disconnectUamWebSocket,
};
