import deviceConnectionStatusTypes from '../../../consts/device/deviceConnectionStatusTypes';
import helpers from '../../../utils/helpers';
import * as types from './actionTypes';

const initialData = {
    data: undefined,
    isVibrationAlarmActive: false,
    message: undefined,
    deviceConnectionStatus: deviceConnectionStatusTypes.DISCONNECTED,
    isDeviceActivated: false,
    shouldShowSBLModePopup: true,
};

export default function reducer(state = initialData, action) {
    switch (action.type) {
        case types.IOT_DEVICE_CLEAR: {
            return {
                ...state,
                data: undefined,
                deviceConnectionStatus: deviceConnectionStatusTypes.DISCONNECTED,
                shouldShowSBLModePopup: true,
            };
        }
        case types.IOT_DEVICE_SET_MESSAGE: {
            const {data} = action.payload;

            return {
                ...state,
                message: data,
            };
        }
        case types.IOT_DEVICE_SET_DEVICE_CONNECTION_STATUS: {
            const {status} = action.payload;

            return {
                ...state,
                deviceConnectionStatus: status,
            };
        }
        case types.IOT_DEVICE_SET_DEVICE_ACTIVATION_STATUS: {
            const {status} = action.payload;

            return {
                ...state,
                isDeviceActivated: status,
            };
        }

        case types.IOT_DEVICE_UPDATE_DATA: {
            const {data} = action.payload;
            const currentData = state.data;

            if (!data) return state;

            if (data.device?.exp_read) {
                const isDeviceConnected = currentData?.device;

                if (isDeviceConnected) {
                    if (currentData.device?.exp_read) {
                        data.device.exp_read += currentData.device.exp_read;
                    }
                } else {
                    //skip collecting data messages when device is disconnected but collecting data flow is still in progress
                    return state;
                }
            }

            const newData = currentData ? helpers.mergeDeep(currentData, data) : data;

            return {
                ...state,
                data: newData,
            };
        }
        case types.IOT_DEVICE_CLEAR_DATA: {
            const {device} = state.data;
            const {name, type, parent_protection_mode} = device || {};

            const data = {
                device: {
                    name,
                    type,
                },
                holder: null,
                device_management_features_config: null,
                device_global_features_config: null,
            };

            if (parent_protection_mode !== undefined) {
                data.device.parent_protection_mode = parent_protection_mode;
            }

            return {
                ...state,
                data,
            };
        }
        case types.IOT_DEVICE_SET_IS_RESET: {
            const {isReset} = action.payload;

            return {
                ...state,
                reset: {
                    ...state.reset,
                    isReset,
                },
            };
        }
        case types.IOT_DEVICE_SET_FIRMWARE_DATA: {
            const {data} = action.payload;

            return {
                ...state,
                firmware: {
                    ...state.firmware,
                    ...data,
                },
            };
        }

        case types.IOT_DEVICE_SET_IS_VIBRATION_ALARM_ACTIVE: {
            const {data} = action.payload;

            return {
                ...state,
                isVibrationAlarmActive: data,
            };
        }

        case types.IOT_DEVICE_SET_SHOULD_SHOW_SBL_POPUP: {
            const {shouldShowSBLModePopup} = action.payload;

            return {
                ...state,
                shouldShowSBLModePopup,
            };
        }

        default:
            return state;
    }
}
