import React from 'react';

import DeviceInfoBlock from './DeviceInfoBlock/DeviceInfoBlock';
import ProductRegistrationButton from './ProductRegistrationButton';

const DeviceInfoContainer = (props) => {
    const {
        device,
        deviceSerialNumber,
        isDeviceReady,
        onCheckUpdateFWBtnClick,
        isHolder,
        name,
        parentProtectionMode,
        onDisableClick,
    } = props;

    //TODO: remove hardcoded info
    return (
        <>
            <DeviceInfoBlock
                device={device}
                isDeviceReady={isDeviceReady}
                isHolder={isHolder}
                lastUpdate={'4th June 2019'}
                onCheckUpdateFWBtnClick={onCheckUpdateFWBtnClick}
                updateDescriptionList={['General bug fixes']}
                onDisableClick={onDisableClick}
            />

            <ProductRegistrationButton
                device={device}
                deviceSerialNumber={deviceSerialNumber}
                isHolder={isHolder}
                name={name}
                isDeviceReady={isDeviceReady}
                parentProtectionMode={parentProtectionMode}
            />
        </>
    );
};

export default DeviceInfoContainer;
