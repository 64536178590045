import iccConsumerAggregatedSettingsKeys from '../../consts/icc/iccConsumerAggregatedSettingsKeys';
import {makeSelectAggregatedSettings} from '../../state/selectors/consumer';
import {getState} from '../../state/store';

const getConsumerAggregatedSettings = () => makeSelectAggregatedSettings()(getState())?.consumerAggregatedSettings;

const getSettingValueByKey = (key) => getConsumerAggregatedSettings().find((item) => item.key === key).value;

const isEnabledGA = () => getSettingValueByKey(iccConsumerAggregatedSettingsKeys.ENABLE_GA);

export default {
    isEnabledGA,
};
