import appConfig from '../../config/appConfig';
import * as iccMethods from '../../consts/icc/iccMethods';
import {
    setConsumerAcceptedTnc,
    setConsumerAggregatedSettings,
    setConsumerData,
    setConsumerProducts,
    setConsumerProductsStatus,
    setConsumerTnc,
} from '../../state/ducks/consumer';
import {setDeviceActivationStatus} from '../../state/ducks/iotDevice/actions';
import {dispatch} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import urlUtils from '../../utils/urlUtils';
import authDataService from '../auth/authDataService';
import log from '../logger/log';
import {
    aggregatedSettingsMapping,
    consumerMapping,
    consumerProductsInfoMapping,
    consumerProductsMapping,
    consumerProductsStatusMapping,
    deviceActivationStatusMapping,
    deviceRegistrationMapping,
    deviceUnregistrationMapping,
    externalConsumerMapping,
    mediaUrlsMapping,
    tncMapping,
} from '../mapping/iccMarketMappings';
import server from '../server/server';
import iccResponseErrorCheckService from './iccResponseErrorCheckService';

const getIccMarketApiUrl = (methodName) => urlUtils.join(appConfig.getIccApiUrl(), methodName);

const getConsumerAggregatedSettings = () =>
    callGet({
        methodName: iccMethods.GET_CONSUMER_AGGREGATED_SETTINGS,
        mapper: aggregatedSettingsMapping,
        action: setConsumerAggregatedSettings,
    });

const getConsumerProfile = () =>
    callGet({
        methodName: iccMethods.GET_CONSUMER,
        mapper: consumerMapping,
        action: setConsumerData,
    });

const getExternalConsumerProfile = () =>
    callGet({
        methodName: iccMethods.GET_EXTERNAL_CONSUMER,
        mapper: externalConsumerMapping,
        action: setConsumerData,
    });

const getConsumerProducts = () =>
    callGet({
        methodName: iccMethods.CONSUMER_PRODUCTS,
        mapper: consumerProductsMapping,
        action: setConsumerProducts,
    });

const getConsumerProductsStatus = () =>
    callGet({
        methodName: iccMethods.GET_CONSUMER_PRODUCTS_STATUS,
        mapper: consumerProductsStatusMapping,
        action: setConsumerProductsStatus,
    });

const getMediaUrl = ({mediaNameList, throwException}) =>
    callPost({
        methodName: iccMethods.GET_MEDIA_URL,
        requestConfig: {data: {mediaName: arrayUtils.toArray(mediaNameList)}},
        mapper: mediaUrlsMapping,
        throwException,
    });

const getProductInfoList = ({serialNumbers}) =>
    callPost({
        methodName: iccMethods.GET_PRODUCT_INFO_LIST,
        requestConfig: {data: {serialNumbers}},
        mapper: consumerProductsInfoMapping,
    });

const setConsumerProductName = ({productName, deviceSerialNumber}) =>
    callPost({
        methodName: iccMethods.SET_CONSUMER_PRODUCT_NAME,
        requestConfig: {
            data: {
                productName,
                deviceSerialNumber,
            },
        },
    });

const getTnc = () =>
    callGet({
        methodName: iccMethods.GET_TNC,
        mapper: tncMapping,
        action: setConsumerTnc,
    });

const setTnc = ({data}) =>
    callPost({
        methodName: iccMethods.SET_CONSUMER_TNC,
        requestConfig: {data},
    });

const declineTnc = ({data}) =>
    callDelete({
        methodName: iccMethods.SET_CONSUMER_TNC,
        requestConfig: {data},
    });

const getAcceptedTnc = () =>
    callGet({
        methodName: iccMethods.GET_ACCEPTED_TNC,
        mapper: tncMapping,
        action: setConsumerAcceptedTnc,
        params: {
            includeDeclined: true,
        },
    });

const setConsumerProductV2 = ({codentify}) =>
    callPost({
        methodName: iccMethods.CONSUMER_PRODUCTS_V2,
        requestConfig: {params: {codentifyId: codentify}},
        mapper: deviceRegistrationMapping,
    });

const setConsumerProductV3 = ({deviceSerialNumber}) =>
    callPost({
        methodName: iccMethods.CONSUMER_PRODUCTS_V3,
        requestConfig: {params: {deviceSerialNumber: deviceSerialNumber}},
        mapper: deviceRegistrationMapping,
    });

const setConsumerProductActivate = ({data, throwException}) =>
    callPost({
        methodName: iccMethods.SET_CONSUMER_PRODUCT_ACTIVATED,
        requestConfig: {
            data: {CodentifyID: data},
        },
        throwException,
    });

const removeConsumerProduct = ({data}) =>
    callDelete({
        methodName: iccMethods.CONSUMER_PRODUCTS,
        requestConfig: {params: {...data}},
        mapper: deviceUnregistrationMapping,
    });

const getConsumerProductActivationStatus = ({data, throwException}) =>
    callGet({
        methodName: iccMethods.GET_CONSUMER_ACTIVATION_STATUS,
        mapper: deviceActivationStatusMapping,
        action: setDeviceActivationStatus,
        params: {
            DeviceSerialNumber: data,
        },
        throwException,
    });

const callGet = ({methodName, mapper, action, params = {}, throwException}) => {
    return callRequest({
        httpMethod: server.get,
        methodName,
        requestConfig: {params},
        mapper,
        action,
        throwException,
    });
};

const callPost = ({methodName, requestConfig, mapper, throwException}) => {
    return callRequest({
        httpMethod: server.post,
        methodName,
        requestConfig,
        mapper,
        throwException,
    });
};

const callDelete = ({methodName, requestConfig, mapper, throwException}) => {
    return callRequest({
        httpMethod: server.requestDelete,
        methodName,
        requestConfig,
        mapper,
        throwException,
    });
};

const callRequest = async ({httpMethod, methodName, requestConfig, mapper, action, throwException = false}) => {
    try {
        const response = await callIccMethod(httpMethod, methodName, requestConfig);

        const {data} = response;

        if (mapper) {
            const mappedData = mapper(data);

            if (action) {
                dispatch(action(mappedData));
            }

            return mappedData;
        } else {
            return httpMethod === server.get ? data : response;
        }
    } catch (e) {
        if (throwException) {
            return Promise.reject();
        }
    }
};

const callIccMethod = async (httpMethod, methodName, requestConfig = {}) => {
    const methodUrl = getIccMarketApiUrl(methodName);

    try {
        const config = await getIccRequestConfig(requestConfig);
        const response = await httpMethod(methodUrl, config);

        log.debug(`iccMarketService: request: '${methodName}' has successful response`);

        return response;
    } catch (e) {
        await iccResponseErrorCheckService(e);

        const config = await getIccRequestConfig(requestConfig);

        return await httpMethod(methodUrl, config);
    }
};

const getIccRequestConfig = async (requestConfig) => {
    const authToken = await authDataService.getAuthToken();

    return {
        headers: {Authorization: 'Bearer ' + authToken},
        ...requestConfig,
    };
};

export default {
    declineTnc,
    getAcceptedTnc,
    getConsumerAggregatedSettings,
    getConsumerProductActivationStatus,
    getConsumerProducts,
    getConsumerProductsStatus,
    getConsumerProfile,
    getExternalConsumerProfile,
    getIccMarketApiUrl,
    getMediaUrl,
    getProductInfoList,
    getTnc,
    removeConsumerProduct,
    setConsumerProductActivate,
    setConsumerProductName,
    setConsumerProductV2,
    setConsumerProductV3,
    setTnc,
};
