import React from 'react';

import DeviceInfoContainer from '../DeviceInfoContainer';
import styles from './DeviceAndHolderInfo.module.scss';

const DeviceAndHolderInfo = (props) => {
    const {iotDevice, isDeviceReady, onCheckUpdateFWBtnClick, onDisableClick} = props;
    const {device, holder, deviceSerialNumber} = iotDevice || {};
    const {name, parent_protection_mode, holder_connected} = device || {};

    return (
        <div className={styles.DeviceAndHolderInfoContainer}>
            <DeviceInfoContainer
                device={device}
                deviceSerialNumber={deviceSerialNumber}
                isDeviceReady={isDeviceReady}
                name={name}
                onCheckUpdateFWBtnClick={onCheckUpdateFWBtnClick}
                parentProtectionMode={parent_protection_mode}
                onDisableClick={onDisableClick}
            />
            {holder && holder_connected && (
                <DeviceInfoContainer
                    device={holder}
                    deviceSerialNumber={holder.deviceSerialNumber}
                    isDeviceReady={isDeviceReady}
                    isHolder
                    name={name}
                    onCheckUpdateFWBtnClick={onCheckUpdateFWBtnClick}
                    parentProtectionMode={parent_protection_mode}
                />
            )}
        </div>
    );
};

export default DeviceAndHolderInfo;
