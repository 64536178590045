import appConfig from '../../config/appConfig';
import {NOT_FOUND} from '../../consts/app/httpErrorsTypes';
import iccMarketService from '../../services/icc/iccMarketService';
import {getLocalizedLanguage} from '../../services/localization/localizationService';
import stringUtils from '../../utils/stringUtils';

const fetchConversionPractices = async () => {
    const currentLocalizedLanguage = getLocalizedLanguage();
    const localizationKey = appConfig.getLocalizationKey();

    try {
        return await getConversionPractices(currentLocalizedLanguage);
    } catch (e) {
        const isDifferentLanguageLocalized = currentLocalizedLanguage.toUpperCase() !== localizationKey.toUpperCase();

        if (e.response?.status === NOT_FOUND && isDifferentLanguageLocalized) {
            return await getConversionPractices(localizationKey);
        }

        return null;
    }
};

const getConversionPractices = async (currentLocalizedLanguage) => {
    const legalDisclaimerPdfName = appConfig.getLegalDisclaimePdfName();

    if (legalDisclaimerPdfName.length) {
        const formattedStrings = stringUtils.formatString(legalDisclaimerPdfName, currentLocalizedLanguage);
        const legalDisclaimerAsset = await iccMarketService.getMediaUrl({
            mediaNameList: formattedStrings,
            throwException: true,
        });
        return legalDisclaimerAsset[0].url;
    }

    return null;
};

export default {fetchConversionPractices};
