const showLoginOverlay = () => {
    //eslint-disable-next-line
    $.showLoginOverlay();
};

const showHAV = () => {
    //eslint-disable-next-line
    $.showHAV();
};

export default {
    showHAV,
    showLoginOverlay,
};
