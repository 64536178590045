import React from 'react';

import standaloneConfig from '../../../../config/standaloneConfig/standaloneConfig';
import SelectMarketSettingsPopup from '../SelectMarketSettingsPopup/SelectMarketSettingsPopup';

const CountrySelectTemplate = (props) => {
    const {countryCodeConfig} = standaloneConfig || {};

    const standaloneLocalization = countryCodeConfig.localization.en;
    const standaloneFlagsConfig = countryCodeConfig.flags;

    const countryCodeOnItemSelect = 'countryCode';
    const countryPopupTitleText = standaloneLocalization.SELECTOR_COUNTRY_HEADER_TEXT;

    return (
        <SelectMarketSettingsPopup
            {...props}
            popupTitleText={countryPopupTitleText}
            codeOnItemSelect={countryCodeOnItemSelect}
            flagsConfig={standaloneFlagsConfig}
        />
    );
};

export default CountrySelectTemplate;
