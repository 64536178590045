import React from 'react';
import {Offline} from 'react-detect-offline';

import standaloneConfig from '../../../config/standaloneConfig/standaloneConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import log from '../../../services/logger/log';
import {setIsNetworkOnline, setNotificationInfo, setNotificationSuccess} from '../../../state/ducks/global';
import {dispatch} from '../../../state/store';

const OfflineDetector = () => {
    const {countryCodeConfig} = standaloneConfig || {};

    const localizedStrings = getLocalizedStrings();
    const standaloneLocalization = countryCodeConfig?.localization.en || {};

    const onChange = (isOnline) => {
        dispatch(setIsNetworkOnline(isOnline));
        if (isOnline) {
            const onlineNotificationText = localizedStrings
                ? localizedStrings[localizationKeys?.GLOBAL_DETECTOR_ONLINE]
                : standaloneLocalization.DETECTOR_ONLINE;

            dispatch(setNotificationSuccess(onlineNotificationText));

            log.info(`OfflineDetector: Internet connection is online`);
        } else {
            const offlineNotificationText = localizedStrings
                ? localizedStrings[localizationKeys?.GLOBAL_DETECTOR_OFFLINE]
                : standaloneLocalization.DETECTOR_OFFLINE;

            dispatch(setNotificationInfo(offlineNotificationText));
            log.info(`OfflineDetector: Internet connection is offline`);
        }
    };

    return (
        <Offline
            polling={{
                interval: 10000,
            }}
            onChange={onChange}
        />
    );
};

export default OfflineDetector;
