import {useEffect} from 'react';

import * as localizationKeys from '../../../consts/app/localizationKeys';
import iotMessageService from '../../../services/iotMessageService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {setNotificationSuccess} from '../../../state/ducks/global';
import {setFWUSuccess} from '../../../state/ducks/iotDevice/actions';
import {dispatch} from '../../../state/store';

const useFWUFinished = (iotMessage, isFirmwareFinished, onFwuFinished) => {
    const isHolderFwuFinished = iotMessageService.isHolderFwuFinished(iotMessage);
    const isDeviceInfoFinished = iotMessageService.isDeviceInfoFinished(iotMessage);
    const isDeviceFwuFinished = isDeviceInfoFinished && isFirmwareFinished; //TODO: check

    const isFWUFinished = isHolderFwuFinished || isDeviceFwuFinished;

    useEffect(() => {
        if (isFWUFinished) {
            dispatch(setNotificationSuccess(getLocalizedStrings()[localizationKeys.FIRMWARE_UPDATE_SUCCESS]));
            onFwuFinished(isHolderFwuFinished);
            dispatch(setFWUSuccess());
        }
    }, [isFWUFinished]);
};

export default useFWUFinished;
