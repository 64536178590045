import * as routeParams from './routeParams';

export const ABOUT_DEVICE = '/about-device';
export const ACTIVATION = '/activation';
export const ACTIVATION_SUCCESS = '/activation-success';
export const AGE_GATE = '/age';
export const AGE_VERIFICATION_FAILED = '/age-verification-failed';
export const APP_INTRO = '/intro';
export const BROWSER_VERIFICATION = '/browser-verification';
export const CLEAN_TUTORIAL = '/clean-tutorial';
export const DEVICE = '/device';
export const DEVICE_SETTINGS = '/device-settings';
export const LEGAL_DISCLAIMER = '/legal-disclaimer';
export const LOGIN = '/login';
export const MY_DEVICES = '/my-devices';
export const NO_DEVICES = '/no-devices';
export const GAM_ERROR = '/gam-error';
export const GAM_ERROR_PARAMETERIZED = `${GAM_ERROR}/:${routeParams.GAM_ERROR_TYPE}?`;
export const NOT_COMPATIBLE_BROWSER = '/not-compatible-browser';
export const NOT_FOUND = '/not-found';
export const PAIRING_ALERT = '/pair-alert';
export const PAIRING_GUIDE = '/pair';
export const PAIRING_GUIDE_PARAMETERIZED = `${PAIRING_GUIDE}/:${routeParams.DEVICE_TYPE}/:${routeParams.BLE_CONNECTION_TYPE}?`;
export const PAIRING_SUCCESS = '/pair-success';
export const REGISTRATION = '/registration';
export const RESET_DEVICE = '/reset';
export const ROOT = '/';
export const SELECT_DEVICE = '/select-device';
export const SELECT_MARKET = '/select-market';
export const TIPS_AND_TRICKS = '/tips';
export const TNC = '/tnc';
export const TNC_DATA_COLLECTION = `${TNC}/data-collection`;
export const TNC_DATA_PREFERENCE = `${TNC}/preference-center`;
export const TNC_DATA_PREFERENCE_SETTINGS = `/preference-center-settings`;
export const TNC_TERMS_OF_USE = `${TNC}/terms-of-use`;
export const TUTORIAL = '/tutorial';
export const TUTORIAL_INTRO = '/tutorial-intro';
export const TUTORIAL_INTRO_PARAMETERIZED = `${TUTORIAL_INTRO}/:${routeParams.TUTORIAL_TYPE}`;
export const TUTORIAL_LIST = '/tutorials';
export const TUTORIAL_PARAMETERIZED = `${TUTORIAL}/:${routeParams.TUTORIAL_TYPE}`;
export const UPDATE_FW = '/fw-update';
export const WELCOME = '/welcome';
export const WELCOME_USER = '/welcome-user';
export const WILDCARD = '*';
