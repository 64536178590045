import React, {forwardRef, useImperativeHandle, useState} from 'react';
import Slider from 'react-slick';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';
import Button from '../Button/Button';
import styles from './Carousel.module.scss';
import CarouselArrowNext from './CarouselArrow/CarouselArrowNext';
import CarouselArrowPrev from './CarouselArrow/CarouselArrowPrev';

const Carousel = forwardRef((props, ref) => {
    const {
        arrowNextTestId,
        arrowPrevTestId,
        children,
        controlArrowClassName,
        dotsClassName,
        isAccessibilityEnabled = true,
        isAdaptiveHeight,
        isArrowVisible = true,
        isCommonNextSlideButtonVisible,
        isControlArrowVisible,
        isDotsVisible = true,
        isDraggable = true,
        isInfinite,
        isSwipeEnabled = true,
        itemsCount,
        lastSlideButton,
        lastSlideRoutePath,
        nextSlideButtonClassName,
        onTransitionEnd,
        options,
        onEdge,
    } = props;
    const [tutorialSlider, setTutorialSlider] = useState(null);
    const [isLastSlide, setIsLastSlide] = useState(false);
    const isLastSlideButtonVisible = isArrowVisible && lastSlideButton && isLastSlide;
    const isNextSlideButtonVisible = isArrowVisible && isLastSlide && !lastSlideButton;
    const routePath = isLastSlide && lastSlideRoutePath ? lastSlideRoutePath : null;

    const onNextBtnClick = () => {
        tutorialSlider.slickNext();
    };

    const setRef = (ref) => {
        setTutorialSlider(ref);
        onSliderInit(ref);
    };

    const afterSlide = (index) => {
        setIsLastSlide(index === itemsCount - 1);
        helpers.runFunction(onTransitionEnd, `${index}`);
    };

    const onSliderInit = (instance) => {
        if (instance) {
            const {props} = instance || {};

            const childrenCount = props?.children.length;

            const isElementSingle = childrenCount === 1;

            if (isElementSingle) {
                setIsLastSlide(true);
            }
        }
    };

    const builtinOptions = {
        adaptiveHeight: isAdaptiveHeight,
        arrows: isControlArrowVisible,
        dots: isDotsVisible,
        dotsClass: `${dotsClassName ? dotsClassName : ''} ${styles.Dots} touch-device slick-dots`,
        infinite: isInfinite,
        nextArrow: (
            <CarouselArrowNext testId={arrowNextTestId} customClassName={controlArrowClassName} to={routePath} />
        ),
        prevArrow: <CarouselArrowPrev customClassName={controlArrowClassName} testId={arrowPrevTestId} />,
        speed: 300,
        waitForAnimate: true,
        focusOnSelect: true,
        accessibility: isAccessibilityEnabled,
        draggable: isDraggable,
        swipe: isSwipeEnabled,
        beforeChange: (current, next) => {
            //TODO: replace with afterChange when fix on 'react-slick' comes out (current version ^ 0.25.2), problem when adaptiveHeight and afterChange are used together
            setTimeout(afterSlide(next), 1);
        },
        onEdge: onEdge,
    };

    const mergedOptions = {...builtinOptions, ...options};

    useImperativeHandle(ref, () => ({
        slide: (index) => tutorialSlider.slickGoTo(index),
        next: () => tutorialSlider.slickNext(),
        prev: () => tutorialSlider.slickPrev(),
        slider: tutorialSlider,
    }));

    const localizedStrings = getLocalizedStrings();

    return (
        <div>
            <Slider {...mergedOptions} ref={(ref) => setRef(ref)}>
                {children}
            </Slider>

            {isLastSlideButtonVisible && lastSlideButton}

            {isNextSlideButtonVisible && (
                <Button
                    className={styles.NextBtn}
                    onClick={!isLastSlide ? onNextBtnClick : null}
                    showArrow
                    to={routePath}
                >
                    {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                </Button>
            )}

            {isCommonNextSlideButtonVisible && !isNextSlideButtonVisible && !isLastSlide && (
                <Button
                    className={`${nextSlideButtonClassName ? nextSlideButtonClassName : ''} ${styles.CommonNextBtn}`}
                    onClick={onNextBtnClick}
                    showArrow
                >
                    {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                </Button>
            )}
        </div>
    );
});

export default Carousel;
