import React from 'react';

import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import UpdateFWPopupTemplate from './UpdateFWPopupTemplate';

const UpdateFWPopup = ({onClose, onStartUpdate, firmwareData, isHolderUpdate, isUpdateFWUpdateError}) => {
    const {device, holder} = firmwareData;
    const {package_available} = (isHolderUpdate ? holder : device) || {};
    const localizedStrings = getLocalizedStrings();
    let popupProps;

    if (isUpdateFWUpdateError) {
        popupProps = {
            title: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_TITLE],
            descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_MESSAGE],
            descriptionPart2: '',
            secondaryBtnText: localizedStrings[localizationKeys.FIRMWARE_SBL_FOUND_POPUP_GOT_IT_BUTTON],
            secondaryBtnAction: onClose,
            primaryBtnText: null,
            primaryBtnAction: onClose,
        };
    } else if (package_available) {
        popupProps = {
            title: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_TITLE],
            descriptionPart1: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_1],
            descriptionPart2: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_DESCRIPTION_PART_2],
            secondaryBtnText: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_SECONDARY],
            secondaryBtnAction: onStartUpdate,
            primaryBtnText: localizedStrings[localizationKeys.ABOUT_DEVICE_FIRMWARE_UPDATE_POPUP_BUTTON_PRIMARY],
            primaryBtnAction: onClose,
        };
    } else {
        popupProps = {
            title: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_TITLE],
            descriptionPart1: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_DESCRIPTION],
            descriptionPart2: '',
            secondaryBtnText: localizedStrings[localizationKeys.FIRMWARE_UPDATE_NOT_NEEDED_CONFIRM_TEXT],
            secondaryBtnAction: onClose,
            primaryBtnText: null,
            primaryBtnAction: onClose,
        };
    }

    return <UpdateFWPopupTemplate onClose={onClose} {...popupProps} />;
};

export default UpdateFWPopup;
