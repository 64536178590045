import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.scss';
import ButtonArrowEnterSVG from './ButtonArrowEnterSVG/ButtonArrowEnterSVG';
import ButtonArrowSVG from './ButtonArrowSVG/ButtonArrowSVG';
import ButtonRaw from './ButtonRaw';

const Button = ({showArrow, showEnterArrow, showUnderline, children, className, ...props}) => {
    const btnClassName = classNames(styles.Button, className, {
        [styles.Underline]: showUnderline,
    });

    return (
        <ButtonRaw {...props} className={btnClassName}>
            {children}
            {showArrow && <ButtonArrowSVG />}
            {showEnterArrow && <ButtonArrowEnterSVG />}
        </ButtonRaw>
    );
};

export default Button;
