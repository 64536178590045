import * as activationStatusTypes from '../../consts/uam/activationStatusTypes';
import {setDeviceActivationStatus} from '../../state/ducks/iotDevice/actions';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {makeSelectAsset, makeSelectAssetByAssetId} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import cmClientService from '../communicationLayer/cmClientService';
import log from '../logger/log';
import yapService from '../yap/yapService';
import uamClientService from './uamClientService';
import uamWebSocketService from './uamWebSocketService';

const isDeviceActivatedInUam = async (deviceSerialNumber, assetId) => {
    let isDeviceActivated = false;
    const state = getState();

    let uamAsset;
    if (assetId) {
        uamAsset = makeSelectAssetByAssetId(assetId)(state);
    } else {
        uamAsset = makeSelectAsset(deviceSerialNumber)(state);
    }

    if (uamAsset?.assetId) {
        isDeviceActivated = uamAsset.yapActivated;
    } else {
        const {device} = makeSelectIotDeviceData()(state);
        const codentify = device?.codentifyOriginal;

        if (codentify) {
            const activated = await uamClientService.getActivationStatus(codentify);
            if (activated == null) throw new Error(`Could not retrieve activation status for ${codentify}`);

            isDeviceActivated = activated.activationStatus?.toLowerCase() === activationStatusTypes.UAM_ACTIVATED;
        }
    }

    if (deviceSerialNumber) {
        log.info(`uamService: isDeviceActivatedInUam: ${isDeviceActivated} for SN: ${deviceSerialNumber}.`);
    } else {
        log.info(`uamService: isDeviceActivatedInUam: ${isDeviceActivated} for assetId: ${assetId}.`);
    }

    dispatch(setDeviceActivationStatus(isDeviceActivated));
    return isDeviceActivated;
};

const syncAndSubscribeOnDevice = async (deviceSerialNumber) => {
    if (!deviceSerialNumber) throw new Error('Device is not initialized');

    const state = getState();
    let uamAsset = makeSelectAsset(deviceSerialNumber)(state);
    const assetId = uamAsset?.assetId;
    if (!assetId) throw new Error('Asset is not initialized');

    await uamWebSocketService.initUamWebSocket(assetId);

    await cmClientService.initCmClient(assetId);
};

const isUamMode = (isP4) => (isP4 ? yapService.isYapEncryptedMode() : false);

export default {
    isDeviceActivatedInUam,
    syncAndSubscribeOnDevice,
    isUamMode,
};
