const parseProvinceData = (acceptedCountryItem, acceptedProvinceCode, provinceCode, countryCode) => {
    const countryProvinces = acceptedCountryItem?.provinces;
    const defaultProvinces = countryProvinces?.sort((a, b) => a.name.localeCompare(b.name))[0];

    return countryProvinces?.find((province) => {
        if (provinceCode && !acceptedProvinceCode && countryCode === countryCode) {
            return province.provinceCode === provinceCode;
        }
        if (acceptedProvinceCode) {
            return province.provinceCode === acceptedProvinceCode;
        }
        return province.name === defaultProvinces.name;
    });
};

const parseLanguageData = (acceptedLanguageCode, acceptedCountryItem, getProvinceData) => {
    const countryLanguages = acceptedCountryItem.languages;

    return countryLanguages.find((language) => {
        if (acceptedLanguageCode) {
            return language.languageCode === acceptedLanguageCode;
        }
        if (getProvinceData) {
            return language.languageCode === getProvinceData.defaultLanguageCode;
        }
        return language.default;
    });
};

const getReadyData = ({acceptedCountryItem, acceptedLanguageCode, acceptedProvinceCode, provinceCode, countryCode}) => {
    const currentCountryName = acceptedCountryItem.name;
    const currentCountryCode = acceptedCountryItem.countryIsoCode;

    const getProvinceData = parseProvinceData(acceptedCountryItem, acceptedProvinceCode, provinceCode, countryCode);

    const getLanguageData = parseLanguageData(acceptedLanguageCode, acceptedCountryItem, getProvinceData);

    const currentLanguageName = getLanguageData?.name;
    const currentLanguageCode = getLanguageData?.languageCode;
    const currentProvinceName = getProvinceData?.name;
    const currentProvinceCode = getProvinceData?.provinceCode;

    return {
        countryName: currentCountryName,
        countryCode: currentCountryCode,
        languageName: currentLanguageName,
        languageCode: currentLanguageCode,
        provinceName: currentProvinceName,
        provinceCode: currentProvinceCode,
    };
};

export default {
    parseProvinceData,
    parseLanguageData,
    getReadyData,
};
