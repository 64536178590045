import React, {useState} from 'react';

import iconFindWhite from '../../../../../../assets/images/icons/find-icon-white.png';
import iconFind2White from '../../../../../../assets/images/icons/find-icon-white@2x.png';
import iconFind3White from '../../../../../../assets/images/icons/find-icon-white@3x.png';
import ButtonDark from '../../../../../components/Button/ButtonDark/ButtonDark';
import VibrationCircles from '../../../../../components/VibrationCircles/VibrationCircles';
import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import useLostDeviceConnection from '../../../../../hooks/effects/connection/useLostDeviceConnection';
import analyticsService from '../../../../../services/analyticsService';
import getDeviceMessageRequestService from '../../../../../services/deviceMessageRequestService';
import deviceConfigService from '../../../../../services/iotDevice/iotDeviceConfigService';
import iotDeviceSettingsService from '../../../../../services/iotDevice/iotDeviceSettingsService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import elementsTesIds from '../../../../../test/consts/elementsTesIds';
import helpers from '../../../../../utils/helpers';
import DeviceImage from '../../../../DeviceImage/DeviceImage';
import DeviceModePopup from '../../DeviceModePopup/DeviceModePopup';
import styles from '../../SettingsOptions/components/DeviceModeComponents/DeviceMode.module.scss';

const FindDevicePopup = (props) => {
    const {onClose, iotDevice, isDeviceReady, layout} = props;
    const {device, holder} = iotDevice;

    const [isVibrationAlarmActive, setIsVibrationAlarmActive] = useState(false);

    const deviceAlarmDuration = deviceConfigService.getDeviceAlarmDuration(iotDevice);

    const startVibrationAlarm = () => {
        analyticsService.pushUsageSettingsFindDeviceEvent();

        iotDeviceSettingsService.setVibrationAlarmTimeout(deviceAlarmDuration, () => {
            setIsVibrationAlarmActive(false);
        });
        getDeviceMessageRequestService().publishVibrationAlarmMessage();
        setIsVibrationAlarmActive(true);
    };

    const stopVibrationAlarm = () => {
        if (isVibrationAlarmActive) {
            iotDeviceSettingsService.clearVibrationAlarmTimeout();
            setIsVibrationAlarmActive(false);

            if (isDeviceReady) {
                getDeviceMessageRequestService().publishStopVibrationAlarmMessage();
            }
        }
    };

    useLostDeviceConnection(!isDeviceReady, stopVibrationAlarm);

    const onFindDevicePopupClose = () => {
        stopVibrationAlarm();
        helpers.runFunction(onClose);
    };

    if (!isDeviceReady) {
        onFindDevicePopupClose();
    }

    const localizedStrings = getLocalizedStrings();

    return (
        <DeviceModePopup
            imgAlt={localizedStrings[localizationKeys.FIND_DEVICE_MODE_TITLE]}
            imgClassName={`${styles.CenterElement}`}
            imgUrls={[iconFindWhite, iconFind2White, iconFind3White]}
            layout={layout}
            onClose={onFindDevicePopupClose}
            subTitle={localizedStrings[localizationKeys.FIND_DEVICE_MODE_VIBRATE_DESCRIPTION]}
            testId={elementsTesIds.LOCATE_DEVICE_POPUP}
            title={localizedStrings[localizationKeys.FIND_DEVICE_MODE_TITLE]}
        >
            <div className={styles.PopupBody}>
                <VibrationCircles circleState={isVibrationAlarmActive}>
                    <DeviceImage device={device} holder={holder} className={styles.ImageContainer} />
                </VibrationCircles>
                {!isVibrationAlarmActive ? (
                    <ButtonDark
                        onClick={startVibrationAlarm}
                        showArrow
                        testId={elementsTesIds.LOCATE_DEVICE_POPUP_BTN_START}
                    >
                        {localizedStrings[localizationKeys.START_FINDING_BUTTON_TEXT]}
                    </ButtonDark>
                ) : (
                    <ButtonDark
                        onClick={stopVibrationAlarm}
                        showArrow
                        testId={elementsTesIds.LOCATE_DEVICE_POPUP_BTN_STOP}
                    >
                        {localizedStrings[localizationKeys.STOP_FINDING_BUTTON_TEXT]}
                    </ButtonDark>
                )}
            </div>
        </DeviceModePopup>
    );
};

export default FindDevicePopup;
