import React from 'react';

import DeviceAnimatedLoader from './DeviceAnimatedLoader/DeviceAnimatedLoader';
import styles from './LoaderScreen.module.scss';

const LoaderScreen = (props) => {
    const {iotDevice, pageLoader, isActive, imgClassName, header, description, headerTestId} = props;

    return (
        <div>
            <h2 className={styles.HeaderTitle} data-testid={headerTestId}>
                {header}
            </h2>
            <DeviceAnimatedLoader
                iotDevice={iotDevice}
                isActive={isActive}
                imgClassName={imgClassName}
                pageLoader={pageLoader}
            />
            <p className={`${styles.Description} ica--txt-regular`}>{description}</p>
        </div>
    );
};

export default LoaderScreen;
