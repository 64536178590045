import React from 'react';

import BackButton from '../../../../components/BackButton/BackButton';
import ButtonSelectedMark from '../../../../components/Button/ButtonSelectedMark/ButtonSelectedMark';
import PopupFullPage from '../../../../components/Popup/PopupFullPage/PopupFullPage';
import TableCell from '../../../../components/TableCell/TableCell';
import layoutService from '../../../../services/layoutService';
import styles from './SelectMarketSettingsPopup.module.scss';

const SelectMarketSettingsPopup = (props) => {
    const {
        transmittedConfig,
        itemCode,
        onClose,
        onItemSelect,
        flagsConfig,
        codeOnItemSelect,
        popupTitleText,
        layout,
    } = props;
    const isMobileLayout = layoutService.isMobileLayout(layout);

    const TableCellCollection = () => {
        return (
            <>
                {transmittedConfig.map((item, index) => {
                    const name = item.name;
                    const code = item.countryIsoCode || item.languageCode || item.provinceCode;
                    const standaloneFlagsConfig = flagsConfig?.find((flag) => flag.code === code.toUpperCase());
                    const flagImage = standaloneFlagsConfig?.imageLink;
                    const isSelected = itemCode === code;

                    return (
                        <TableCell
                            imgUrls={flagImage}
                            key={index.toString()}
                            classNameContainer={styles.TableCellContainer}
                            classNameImageContainer={styles.TableCellImage}
                            name={name}
                            onClick={() => onItemSelect({[codeOnItemSelect]: code})}
                        >
                            {isSelected && <ButtonSelectedMark />}
                        </TableCell>
                    );
                })}
            </>
        );
    };

    return (
        <>
            {isMobileLayout && (
                <PopupFullPage>
                    <div className={styles.MainContainer}>
                        <BackButton onClick={onClose} className={styles.BackButton} />
                        <div className={styles.TitleText}>{popupTitleText}</div>
                        <TableCellCollection />
                    </div>
                </PopupFullPage>
            )}
            {!isMobileLayout && (
                <div className={styles.MainContainer}>
                    <TableCellCollection />
                </div>
            )}
        </>
    );
};

export default SelectMarketSettingsPopup;
