import tutorialTypes from '../../consts/app/tutorialTypes';

const tutorialsOrderP1 = [
    tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS,
];

const tutorialsOrderP4 = [
    tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_POD_GUIDE,
    tutorialTypes.TUTORIAL_TYPE_RESPONSIVE_DRAW_GUIDE,
];

export default {
    tutorialsOrderP1,
    tutorialsOrderP4,
};
