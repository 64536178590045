import authDataStoreService from './auth/authDataStoreService';
import storageService from './storage/storageService';

const setSessionStorageData = () => {
    const spiceToken = authDataStoreService.getSpiceToken();

    if (!spiceToken) {
        storageService.setIsCompatiblePageOpenedToStorage(true);
    }
};

const getIsIntroPageVisible = () => {
    const isCompatiblePageOpened = storageService.getIsCompatiblePageOpenedFromStorage();
    const spiceToken = authDataStoreService.getSpiceToken();

    if (isCompatiblePageOpened && spiceToken) {
        storageService.removeIsCompatiblePageOpenedFromStorage();

        return false;
    } else {
        return true;
    }
};

export default {
    getIsIntroPageVisible,
    setSessionStorageData,
};
