import classnames from 'classnames';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import ProgressiveImage from '../../components/Image/ProgressiveImage/ProgressiveImage';
import {getDeviceTypesData} from '../../consts/device/deviceTypesData';
import deviceAssetService from '../../services/device/deviceAssetService';
import iccConsumerProducts from '../../services/icc/iccConsumerProducts';
import {makeSelectConsumerProductAsset} from '../../state/selectors/consumer';
import styles from './DeviceImage.module.css';

const mapStateToProps = (state, ownProps) => {
    const {mediaId} = ownProps;
    const thumbnailMediaId = deviceAssetService.getThumbnailName(mediaId);

    return createStructuredSelector({
        productAsset: makeSelectConsumerProductAsset(mediaId),
        productThumbnailAsset: makeSelectConsumerProductAsset(thumbnailMediaId),
    });
};
const ProductImage = (props) => {
    const {mediaId, imgClassName, productAsset, productThumbnailAsset, type} = props;
    const isAssetExpired = productAsset?.isExpired();
    const productAssetUrl = productAsset?.url;
    const srcSet = [productAssetUrl];
    const deviceDefaultData = getDeviceTypesData()[type];
    const defaultImageUrl = deviceDefaultData?.defaultImageSrc;
    const thumbnailImageUrl = productThumbnailAsset?.url;

    if (defaultImageUrl) {
        srcSet.push(defaultImageUrl);
    }

    useEffect(() => {
        if (isAssetExpired) {
            iccConsumerProducts.getProductsAssets(mediaId);
        }
    }, [isAssetExpired]);

    const isMediaUrlFetched = mediaId ? productAssetUrl : true;

    return isMediaUrlFetched ? (
        <ProgressiveImage
            imgClassName={classnames(styles.Image, imgClassName)}
            srcSet={srcSet}
            placeholder={thumbnailImageUrl}
        />
    ) : null;
};

export default connect(mapStateToProps)(ProductImage);
