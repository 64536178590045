import React, {useState} from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import helpers from '../../utils/helpers';
import FloatButton from '../Button/FloatButton/FloatButton';
import ActivationSkipPopup from './ActivationSkipPopup';

const ActivationSkipBtn = (props) => {
    const {onClose} = props;
    const [isVisibleActivationSkipPopup, setIsVisibleActivationSkipPopup] = useState(false);

    const onActivationSkipBtnClick = () => {
        setIsVisibleActivationSkipPopup(!isVisibleActivationSkipPopup);
        helpers.runFunction(onClose);
    };

    return (
        <>
            <FloatButton onClick={onActivationSkipBtnClick}>
                {getLocalizedStrings()[localizationKeys.YAP_SKIP_BUTTON_TEXT]}
            </FloatButton>
            {isVisibleActivationSkipPopup && <ActivationSkipPopup onClose={onActivationSkipBtnClick} />}
        </>
    );
};

export default ActivationSkipBtn;
