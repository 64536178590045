import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as uamMethods from '../../consts/uam/uamMethods';
import * as yapActionStatusTypes from '../../consts/yap/yapActionStatusTypes';
import * as yapConnectionStatusTypes from '../../consts/yap/yapConnectionStatusTypes';
import {
    setYapActivationInProgress,
    setYapAssets,
    setYapDeviceConnected,
    setYapLastCheckRequestDate,
    setYapLastRequestId,
    setYapSyncInProgress,
} from '../../state/ducks/yapEncrypted';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import {
    makeSelectAsset,
    makeSelectIsYapActivationInProgress,
    makeSelectYapLastCheckRequestDate,
    makeSelectYapLastRequestId,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import dateTimeUtils from '../../utils/dateTimeUtils';
import helpers from '../../utils/helpers';
import stringUtils from '../../utils/stringUtils';
import cmClientService from '../communicationLayer/cmClientService';
import deviceTypesService from '../device/deviceTypesService';
import iccService from '../icc/iccService';
import log from '../logger/log';
import {uamAssetsMapping} from '../mapping/uamMappings';
import server from '../server/server';
import yapService from '../yap/yapService';
import uamClient from './uamClient';
import uamService from './uamService';

const getAssets = async () => {
    if (!yapService.isYapEncryptedMode()) return;

    const iotDevice = makeSelectIotDeviceData()(getState());
    const iotDeviceType = iotDevice?.device?.type;
    const isP4 = deviceTypesService.isP4(iotDeviceType);
    if (!isP4) return;

    try {
        await iccService.fetchUamToken();
        await callGet(uamMethods.GET_ASSETS, [], setYapAssets, uamAssetsMapping, false, httpErrorsTypes.NOT_FOUND);
    } catch (e) {
        log.info(`uamClientService: getAssets error`);
    }
};

const waitUntilGetUamAsset = async (deviceSerialNumber, timer = appConfig.getUamTimeout()) => {
    const uamAsset = makeSelectAsset(deviceSerialNumber)(getState());
    if (uamAsset) return uamAsset;

    if (timer-- < 0) {
        log.error('uamClientService: Uam asset does not exist for registered device.');
        return null;
    }

    await helpers.timeout(1000);
    return waitUntilGetUamAsset(deviceSerialNumber, timer);
};

const activateDevice = async (deviceSerialNumber) => {
    await tryActivateDeactivateDevice(deviceSerialNumber, true);
};

const deactivateDevice = async (deviceSerialNumber) => {
    await tryActivateDeactivateDevice(deviceSerialNumber, false);
};

let activateDeactivateTimeout = null;

const tryActivateDeactivateDevice = async (deviceSerialNumber, isActivate) => {
    clearTimeout(activateDeactivateTimeout);

    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());
    if (isYapActivationInProgress) return;

    dispatch(setYapActivationInProgress(true));

    try {
        const uamAsset = await waitUntilGetUamAsset(deviceSerialNumber);
        if (uamAsset) {
            try {
                await uamService.syncAndSubscribeOnDevice(deviceSerialNumber);
                return await activateDeactivateDevice(uamAsset, isActivate);
            } catch (e) {
                log.error(`uamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
            }
        }

        await getAssets();
        dispatch(setYapActivationInProgress(false));
    } catch (e) {
        log.error(`uamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
        dispatch(setYapActivationInProgress(false));
    }
};

const activateDeactivateDevice = async (uamAsset, isActivate) => {
    const isConnected = await cmClientService.waitUntilConnected();
    if (isConnected) {
        if (isActivate) {
            log.info('Start activation process.');
        } else {
            log.info('Start deactivation process.');
        }

        const method = isActivate ? uamMethods.ACTIVATE_DEVICE : uamMethods.DEACTIVATE_DEVICE;
        const {requestId} =
            (await callPost(method, uamAsset.assetId).catch(() => {
                log.error(`uamClientService: IsActivate: ${isActivate} failed. Action error`);
                dispatch(setYapActivationInProgress(false));
            })) || {};

        if (requestId) {
            dispatch(setYapLastRequestId(requestId));

            activateDeactivateTimeout = setTimeout(function () {
                dispatch(setYapActivationInProgress(false));
            }, appConfig.getUamTimeout() * 1000);
        } else {
            dispatch(setYapActivationInProgress(false));
        }
    } else {
        dispatch(setYapActivationInProgress(false));
    }
};

const checkRequestStatusBySN = async (deviceSerialNumber) => {
    const state = getState();
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(state);
    if (!isYapActivationInProgress) return;

    const uamAsset = await waitUntilGetUamAsset(deviceSerialNumber);
    if (!uamAsset?.assetId) return;

    const requestId = makeSelectYapLastRequestId()(state);
    if (!requestId) return;

    const timestamp = dateTimeUtils.getTimeNowInMilliseconds();
    const lastCheckDate = makeSelectYapLastCheckRequestDate()(state) + appConfig.getUamCheckRequestInterval() * 1000;

    if (lastCheckDate < timestamp) {
        dispatch(setYapLastCheckRequestDate(timestamp));

        await checkRequestStatus(uamAsset.assetId, requestId, deviceSerialNumber);
    }
};

const checkRequestStatus = async (assetId, requestId, deviceSerialNumber) => {
    const data = await callGet(uamMethods.CHECK_REQUEST_STATUS, [assetId, requestId]);
    const isCompleted = data?.status === yapActionStatusTypes.SUCCESS || data?.status === yapActionStatusTypes.FAILED;
    if (isCompleted) {
        await getAssets();
        await uamService.isDeviceActivatedInUam(deviceSerialNumber, assetId);
        dispatch(setYapActivationInProgress(false));
    }
};

const checkConnectivityStatus = async (assetId) => {
    const isConnected = await cmClientService.isCmClientConnected(assetId);
    if (isConnected) {
        const data = await callGet(uamMethods.CHECK_CONNECTIVITY_STATUS, assetId);
        if (data) {
            if (data.status === yapConnectionStatusTypes.CONNECTED) {
                dispatch(setYapDeviceConnected(true));
            } else if (data.status === yapConnectionStatusTypes.DISCONNECTED) {
                dispatch(setYapDeviceConnected(false));
            }
        }
    }
};

const synchronizeDevice = async (deviceSerialNumber) => {
    const uamAsset = await waitUntilGetUamAsset(deviceSerialNumber);
    if (!uamAsset?.assetId) return;

    dispatch(setYapSyncInProgress(true));
    await uamService.syncAndSubscribeOnDevice(deviceSerialNumber);

    const isConnected = await cmClientService.waitUntilConnected();
    if (isConnected) {
        await callPost(uamMethods.SYNCHRONIZE_DEVICE, uamAsset.assetId);
    } else {
        dispatch(setYapSyncInProgress(false));
    }
};

const connectDevice = (assetId) => callGet(uamMethods.CONNECT_DEVICE, assetId);

const assetSubscribe = (assetId) => callGet(uamMethods.ASSET_SUBSCRIBE, assetId);

// const getDeviceInfo = (assetId) => callGet(uamMethods.GET_DEVICE_INFO, assetId, setUamAsset, uamAssetsMapping);

const getActivationStatus = (codentify) => callGet(uamMethods.ASSET_ACTIVATION_STATUS, codentify, null, null, true);

const callGet = (methodName, args, action, mapper, ignoreErrorCodes) => {
    const params = arrayUtils.toArray(args);
    const methodUrl = stringUtils.formatString(methodName, ...params);
    return callRequest(server.get, methodUrl, action, mapper, ignoreErrorCodes);
};

const callPost = (methodName, args, action) => {
    const params = arrayUtils.toArray(args);
    const methodUrl = stringUtils.formatString(methodName, ...params);
    return callRequest(server.post, methodUrl, action, null);
};

const callRequest = async (httpMethod, methodUrl, action, mapper, ignoreErrorCodes) => {
    try {
        const response = await callUamMethod(httpMethod, methodUrl, ignoreErrorCodes);

        if (!response) return null;

        let {data} = response;

        if (mapper) {
            data = mapper(data);
        }

        if (action) {
            dispatch(action(data));
        } else {
            return data;
        }
    } catch (e) {
        return null;
    }
};

const callUamMethod = async (httpMethod, methodUrl, ignoreErrorCodes) => {
    const url = uamClient.getUamApiUrl(methodUrl);

    try {
        const response = await httpMethod(url, getUamRequestParams());

        log.debug(`uamClientService: request: '${methodUrl}' has successful response`);

        return response;
    } catch (e) {
        return await uamClient.errorCheck(
            e,
            async () => {
                return await httpMethod(methodUrl, getUamRequestParams());
            },
            ignoreErrorCodes
        );
    }
};

const getUamRequestParams = () => {
    const headers = uamClient.getHeaders();
    return {headers};
};

export default {
    getAssets,
    activateDevice,
    deactivateDevice,
    checkRequestStatusBySN,
    checkRequestStatus,
    synchronizeDevice,
    connectDevice,
    assetSubscribe,
    getActivationStatus,
    checkConnectivityStatus,
};
