import * as deepmerge from 'deepmerge';
import uuid from 'uuid';

import arrayUtils from './arrayUtils';

const guid = () => uuid.v4();

const timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const mergeDeep = deepmerge;

const runFunction = (func, arg) => {
    const args = arrayUtils.toArray(arg);
    if (typeof func === 'function') {
        args ? func(...args) : func();
    }
};

export default {
    guid,
    mergeDeep,
    runFunction,
    timeout,
};
