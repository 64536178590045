import {setAuthData, setGamData, setSpiceData, setUamData} from '../../state/ducks/auth';
import {
    makeSelectAccessToken,
    makeSelectAccessTokenData,
    makeSelectAuthUsername,
    makeSelectRefreshToken,
    makeSelectSpiceToken,
} from '../../state/selectors/auth';
import {dispatch, getState} from '../../state/store';
import userSessionService from '../userSessionService';

const getAccessToken = () => makeSelectAccessToken()(getState());
const getAccessTokenData = () => makeSelectAccessTokenData()(getState());
const getAuthUsername = () => makeSelectAuthUsername()(getState());
const getRefreshToken = () => makeSelectRefreshToken()(getState());
const getSpiceToken = () => makeSelectSpiceToken()(getState());
const setUamApiData = (uamData) => dispatch(setUamData(uamData));
const setGamApiData = (gamData) => dispatch(setGamData(gamData));

const setIccTokenData = (data) => {
    dispatch(setAuthData(data));
    userSessionService.setUserSessionTokenData(data);
};

const setSpiceToken = (token, username) => {
    dispatch(setSpiceData(token, username));
    userSessionService.setUserSessionSpiceTokenData(token, username);
};

export default {
    getAccessToken,
    getAccessTokenData,
    getAuthUsername,
    getRefreshToken,
    getSpiceToken,
    setIccTokenData,
    setSpiceToken,
    setUamApiData,
    setGamApiData,
};
