import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import * as localizationKeys from '../../consts/app/localizationKeys';
import {FW_ERRORS} from '../../consts/iot/iotMessageErrorCodes';
import useFWUFinished from '../../hooks/effects/fwu/useFWUFinished';
import useDidUpdate from '../../hooks/effects/useDidUpdate';
import useErrorHandler from '../../hooks/effects/useErrorHandler';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import DeviceFwuClient from '../../services/ble/deviceFwuClient';
import getDeviceMessageRequestService from '../../services/deviceMessageRequestService';
import iotDeviceStateService from '../../services/iotDevice/iotDeviceFWUStateService';
import iotMessageService from '../../services/iotMessageService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {
    makeSelectIotDeviceData,
    makeSelectIotMessage,
    makeSelectIsDeviceReady,
    makeSelectIsFirmwareFinished,
} from '../../state/selectors/iotDevice';
import DeviceFWUProgress from './components/DeviceFWUProgress/DeviceFWUProgress';
import FailedUpdateFWPopup from './components/FailedUpdateFWPopup';
import styles from './UpdateFWPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    iotMessage: makeSelectIotMessage(),
    isFirmwareFinished: makeSelectIsFirmwareFinished(),
    isDeviceReady: makeSelectIsDeviceReady(),
});

const UpdateFWPage = (props) => {
    const {iotDevice, iotMessage, isFirmwareFinished, isDeviceReady} = props;
    const {device, holder} = iotDevice;

    const [errorCode, setErrorCode] = useState(null);
    const [isCheckInProgress, setIsCheckInProgress] = useState(false);

    const isFwuTargetsHolder = iotMessageService.isFwuTargetsHolder(iotMessage);
    const isFirmwareInfoLoaded = iotDeviceStateService.isFirmwareInfoLoadedWithCheck(
        device,
        holder,
        isFwuTargetsHolder
    );

    const [isForcedDisconnected, setIsForcedDisconnected] = useState(false);

    const onFwuFinished = () => {
        appRouterService.forwardToAboutDevicePage();
    };

    useFWUFinished(iotMessage, isFirmwareFinished, onFwuFinished);

    useEffect(() => {
        if (!isDeviceReady && !isFirmwareFinished && !errorCode) {
            setIsForcedDisconnected(true);
        }
    }, [isDeviceReady]);

    useEffect(() => {
        return () => {
            const fwuClient = new DeviceFwuClient();

            fwuClient.stop();
        };
    }, []);

    useDidUpdate(() => {
        if (isFirmwareInfoLoaded && isCheckInProgress) {
            setIsCheckInProgress(false);

            getDeviceMessageRequestService().publishStartUpdateFirmwareMessage(isFwuTargetsHolder);
        }
    }, [isFirmwareInfoLoaded]);

    useErrorHandler({
        iotErrorCodes: FW_ERRORS,
        handler: (error_code) => {
            setErrorCode(error_code);
        },
    });

    const onStartUpdateBtnClick = async () => {
        if (isDeviceReady) {
            setIsForcedDisconnected(false);
            setErrorCode(null);
            const isFwuTargetsHolder = iotMessageService.isFwuTargetsHolder(iotMessage);
            analyticsService.pushUsageSettingsFwUpdateEvent();
            getDeviceMessageRequestService().publishCheckFirmwareMessage(isFwuTargetsHolder);
            setIsCheckInProgress(true);
        } else {
            if (!isForcedDisconnected) setIsForcedDisconnected(true);
        }
    };

    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.UpdateFWContainer}>
            <h2>{localizedStrings[localizationKeys.FIRMWARE_INSTALL_TITLE]}</h2>
            <div className={`ica--txt-regular ${styles.NoteTxt}`}>
                <span>{localizedStrings[localizationKeys.FIRMWARE_INSTALL_DESCRIPTION_PART_1]}</span>
                {localizedStrings[localizationKeys.FIRMWARE_INSTALL_DESCRIPTION_PART_2]}
            </div>
            <DeviceFWUProgress iotDevice={iotDevice} isFirmwareFinished={isFirmwareFinished} isRemainingTimeVisible />
            {(errorCode || isForcedDisconnected) && (
                <FailedUpdateFWPopup
                    errorCode={errorCode}
                    isForcedDisconnected={isForcedDisconnected}
                    onTryAgain={onStartUpdateBtnClick}
                    onClose={appRouterService.forwardToAboutDevicePage}
                />
            )}
        </div>
    );
};

export default connect(mapStateToProps)(UpdateFWPage);
