import helpers from '../utils/helpers';
import uaUtils from '../utils/uaUtils';
import storageService from './storage/storageService';

let _appInstanceId;

const getAppInstanceId = () => {
    if (_appInstanceId) return _appInstanceId;

    let appInstanceId = storageService.getAppInstanceIdFromStorage();

    if (appInstanceId) return appInstanceId;

    const osShortName = uaUtils.getOSShortName();

    appInstanceId = `${osShortName}_${helpers.guid()}`;
    storageService.setAppInstanceIdToStorage(appInstanceId);

    _appInstanceId = appInstanceId;

    return appInstanceId;
};

export default {
    getAppInstanceId,
};
