import appConfig from '../../config/appConfig';
import * as httpErrorsTypes from '../../consts/app/httpErrorsTypes';
import * as gamErrorCodeTypes from '../../consts/gam/gamErrorCodeTypes';
import * as gamErrorPageTypes from '../../consts/gam/gamErrorPageTypes';
import * as gamMethods from '../../consts/gam/gamMethods';
import {makeSelectGamAuthToken} from '../../state/selectors/auth';
import {dispatch, getState} from '../../state/store';
import arrayUtils from '../../utils/arrayUtils';
import helpers from '../../utils/helpers';
import stringUtils from '../../utils/stringUtils';
import urlUtils from '../../utils/urlUtils';
import appRouterService from '../appRouterService';
import authDataStoreService from '../auth/authDataStoreService';
import log from '../logger/log';
import {authGamMapping} from '../mapping/gamMappings';
import server from '../server/server';
import storageService from '../storage/storageService';

let isTokenRefreshInProgress = false;

const getGamApiUrl = (methodName) => {
    const gamApiUrl = appConfig.getGamApiUrl();
    return urlUtils.join(gamApiUrl, methodName);
};

const getHeaders = () => {
    const headers = {
        Accept: 'application/json',
    };

    const state = getState();

    const gamAuthToken = makeSelectGamAuthToken()(state);
    if (gamAuthToken) {
        headers.Authorization = 'Bearer ' + gamAuthToken;
    }

    return headers;
};

const getGamGigyaToken = () => {
    try {
        const userData = storageService.getGamGigyaAccountDataFromStorage();
        if (userData) {
            const gigyaJWT = userData.gigyaJWT;
            if (gigyaJWT) return gigyaJWT;
        }
    } catch (e) {
        log.info(`gamClient: GigyaToken parse error: ${e}`);
    }
    return null;
};

const refreshGigyaToken = async () => {
    let isGigyaTokenRefreshed = null;

    try {
        // eslint-disable-next-line no-undef
        if (typeof gigya === 'undefined' || gigya.accounts === 'undefined' || gigya.accounts.getJWT === 'undefined') {
            log.error(`gamClient: refreshGigyaToken failed. gigya.accounts.getJWT not defined`);
            return false;
        }

        // eslint-disable-next-line no-undef
        gigya.accounts.getJWT({
            callback: async (accountInfo) => {
                if (accountInfo?.id_token && accountInfo.status === 'OK') {
                    let userData = storageService.getGamGigyaAccountDataFromStorage();
                    if (!userData) userData = {};

                    userData.gigyaJWT = accountInfo.id_token;
                    storageService.setGamGigyaAccountDataFromStorage(userData);

                    isGigyaTokenRefreshed = true;
                } else {
                    isGigyaTokenRefreshed = false;
                    log.info(`gamClient: refreshGigyaToken failed. Bad accountInfo from gigya.accounts.getJWT`);
                }
            },
        });
    } catch (e) {
        isGigyaTokenRefreshed = false;
        log.error(`gamClient: refreshGigyaToken failed. error: ${e}`);
    }

    let maxWait = 10;
    while (isGigyaTokenRefreshed === null) {
        maxWait--;
        if (maxWait <= 0) {
            isGigyaTokenRefreshed = false;
            log.error(`gamClient: refreshGigyaToken failed. gigya getJWT callback have not been called after 10 sec`);
        }

        await helpers.timeout(1000);
    }

    return isGigyaTokenRefreshed;
};

const fetchGamToken = async () => {
    const codentify = storageService.getDeviceCodentifyFactoryFromLocalStorage()?.codentify;

    if (!codentify) throw new Error('gamClient: Device codentify does not exist in root element');

    const gigyaJWT = getGamGigyaToken();
    if (!gigyaJWT) throw new Error('gamClient: GigyaToken does not exist in local storage');

    const requestConfig = {
        headers: {Authorization: 'Bearer ' + gigyaJWT},
        args: codentify,
    };

    const response = await callPut({methodName: gamMethods.MANAGE_DEVICE, requestConfig});
    if (response === true) {
        return true;
    }

    if (response) {
        const responseData = authGamMapping(response);

        authDataStoreService.setGamApiData(responseData);
        return true;
    }

    return false;
};

const getMethodUrl = ({methodName, requestConfig}) => {
    const args = requestConfig?.args;
    let methodUrl = methodName;
    if (args) {
        const params = arrayUtils.toArray(args);
        methodUrl = stringUtils.formatString(methodName, ...params);
    }
    return methodUrl;
};

const callGet = (options) => {
    options.methodUrl = getMethodUrl(options);
    options.httpMethod = server.get;
    return callRequest(options);
};

const callPost = (options) => {
    options.methodUrl = getMethodUrl(options);
    options.httpMethod = server.post;
    return callRequest(options);
};

const callPut = (options) => {
    options.methodUrl = getMethodUrl(options);
    options.httpMethod = server.put;
    return callRequest(options);
};

const callRequest = async ({httpMethod, methodUrl, requestConfig, action, mapper, isNeedRedirectForOtherErrors}) => {
    try {
        const response = await callGamMethod(httpMethod, methodUrl, requestConfig, isNeedRedirectForOtherErrors);

        if (!response) return null;

        let {data} = response;

        if (mapper) {
            data = mapper(data);
        }

        if (action) {
            dispatch(action(data));
        } else {
            return data;
        }
    } catch (e) {
        return null;
    }
};

const callGamMethod = async (httpMethod, methodUrl, requestConfig, isNeedRedirectForOtherErrors) => {
    const url = getGamApiUrl(methodUrl);
    const headers = requestConfig?.headers ? requestConfig.headers : getHeaders();
    const data = requestConfig?.data ? requestConfig.data : {};

    try {
        const response = await httpMethod(url, {headers, data});

        log.debug(`gamClient: request: '${methodUrl}' has successful response`);

        return response;
    } catch (e) {
        return await gamErrorCheck(
            e,
            async () => {
                //don't remove next row. It refreshes gam token in headers
                const headers = requestConfig?.headers ? requestConfig.headers : getHeaders();
                return await httpMethod(url, {headers, data});
            },
            isNeedRedirectForOtherErrors,
            !methodUrl.includes(gamMethods.REFRESH_TOKEN_PATH)
        );
    }
};

const gamErrorCheck = async (error, onSuccess, isNeedRedirectForOtherErrors = true, isNeedSuccessCall = true) => {
    const status = error?.response?.status;
    const errorCode = error?.response?.data?.code;

    switch (status) {
        case httpErrorsTypes.NOT_AUTHORIZED: {
            switch (errorCode) {
                case gamErrorCodeTypes.GAM_ASSET_NOTAUTHORIZED:
                    appRouterService.forwardToNoDevicesPage();
                    return null;
                case gamErrorCodeTypes.GAM_ACTION_NOTAUTHORIZED:
                    appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_SYSTEM_ERROR);
                    return null;
                case undefined:
                case null: {
                    return refreshToken(onSuccess, isNeedSuccessCall);
                }
                default:
                    forwardToGamTryAgainErrorPage(isNeedRedirectForOtherErrors);
                    return null;
            }
        }
        case httpErrorsTypes.NOT_FOUND: {
            switch (errorCode) {
                case gamErrorCodeTypes.GAM_CONSUMER_NOTFOUND:
                case gamErrorCodeTypes.GAM_DEVICEID_NOTFOUND:
                case gamErrorCodeTypes.GAM_ASSET_NOTFOUND:
                    appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_SYSTEM_ERROR);
                    break;
                default:
                    forwardToGamTryAgainErrorPage(isNeedRedirectForOtherErrors);
                    break;
            }
            return null;
        }
        default:
            forwardToGamTryAgainErrorPage(isNeedRedirectForOtherErrors);
            return null;
    }
};

const refreshToken = async (onSuccess, isNeedSuccessCall = true) => {
    if (isTokenRefreshInProgress) return;

    isTokenRefreshInProgress = true;
    try {
        let isTokenRefreshed = await refreshGigyaToken();
        if (isTokenRefreshed) {
            isTokenRefreshed = await fetchGamToken();
        }

        isTokenRefreshInProgress = false;

        if (isTokenRefreshed) {
            if (!isNeedSuccessCall) return {data: true};

            return onSuccess ? onSuccess() : null;
        }
    } catch (e) {
        isTokenRefreshInProgress = false;
    }

    appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_SYSTEM_ERROR);
    return null;
};

const forwardToGamTryAgainErrorPage = (isNeedRedirectForOtherErrors) => {
    if (isNeedRedirectForOtherErrors) {
        appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_TEMPORARY_ERROR);
    }
};

export default {
    fetchGamToken,
    callGet,
    callPost,
    callPut,
    refreshToken,
};
