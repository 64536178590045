import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import useDidUpdate from '../../../hooks/effects/useDidUpdate';
import ErrorHandlerService from '../../../services/errorHandlerService';
import {makeSelectIsDeviceSyncInProgress} from '../../../state/selectors/yapEncrypted';

const mapStateToProps = createStructuredSelector({
    isDeviceSyncInProgress: makeSelectIsDeviceSyncInProgress(),
});

const GamPairingChecker = ({isDeviceSyncInProgress}) => {
    useDidUpdate(() => {
        if (!isDeviceSyncInProgress) {
            new ErrorHandlerService().checkGamError();
        }
    }, [isDeviceSyncInProgress]);

    return null;
};

export default connect(mapStateToProps)(GamPairingChecker);
