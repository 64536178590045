import storageService from '../../services/storage/storageService';

export const AUTH_SET_AUTH_DATA = 'app/auth/AUTH_SET_AUTH_DATA';
export const AUTH_SET_SPICE_TOKEN = 'app/auth/AUTH_SET_SPICE_TOKEN';
export const AUTH_SET_UAM_TOKEN = 'app/auth/AUTH_SET_UAM_TOKEN';
export const AUTH_SET_GAM_TOKEN = 'app/auth/AUTH_SET_GAM_TOKEN';

const getInitialData = () => ({
    accessToken: storageService.getAccessTokenFromStorage(),
    expirationDate: storageService.getAccessTokenExpirationFromStorage(),
    refreshToken: storageService.getRefreshTokenFromStorage(),
    spiceToken: storageService.getSpiceTokenFromStorage(),
    username: storageService.getUsernameFromStorage(),
});

export default function reducer(state = getInitialData(), action) {
    switch (action.type) {
        case AUTH_SET_AUTH_DATA: {
            const {accessToken, refreshToken, expirationDate, isAnonymous} = action.payload;
            return {...state, accessToken, refreshToken, expirationDate, isAnonymous};
        }
        case AUTH_SET_SPICE_TOKEN: {
            const {spiceToken, username} = action;
            return {...state, spiceToken, username};
        }
        case AUTH_SET_UAM_TOKEN: {
            const {uamAuthToken, uamIdentityKey, uamApiKey, uamApiUrl} = action;
            return {...state, uamAuthToken, uamIdentityKey, uamApiKey, uamApiUrl};
        }
        case AUTH_SET_GAM_TOKEN: {
            const {gamAuthToken} = action;
            return {...state, gamAuthToken};
        }
        default:
            return state;
    }
}

export const setAuthData = ({accessToken, refreshToken, expirationDate, isAnonymous}) => ({
    type: AUTH_SET_AUTH_DATA,
    payload: {accessToken, refreshToken, expirationDate, isAnonymous},
});

export const setSpiceData = (token, username) => ({
    type: AUTH_SET_SPICE_TOKEN,
    spiceToken: token,
    username: username,
});

export const setUamData = ({authToken, identityKey, uamUrl, uamApiKey}) => {
    const dic = {
        type: AUTH_SET_UAM_TOKEN,
        uamAuthToken: authToken,
        uamApiKey: uamApiKey,
        uamApiUrl: uamUrl,
    };

    if (identityKey) {
        dic.uamIdentityKey = identityKey;
    }

    return dic;
};

export const setGamData = ({authToken, assetId}) => {
    return {
        type: AUTH_SET_GAM_TOKEN,
        gamAuthToken: authToken,
        gamAssetId: assetId,
    };
};

export const removeAuthData = () => ({type: AUTH_SET_AUTH_DATA, payload: null});
