import React from 'react';

import FWUFailedPopup from '../../../components/FWUFailedPopup/FWUFailedPopup';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as iccResponseErrorTypes from '../../../consts/icc/iccResponseErrorTypes';

const RegistrationFailedPopup = (props) => {
    const {httpErrorType} = props;

    let headerKey;
    let descriptionKey;
    let cancelBtnKey;

    switch (httpErrorType) {
        case iccResponseErrorTypes.REGISTERED_PRODUCT_LIMIT: {
            headerKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_TITLE_TEXT;
            descriptionKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_DESCRIPTION_TEXT;
            cancelBtnKey = localizationKeys.MANY_REGISTER_DEVICE_REQUEST_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT;
            break;
        }
        case iccResponseErrorTypes.TOO_MANY_REGISTRATION_REQUESTS: {
            headerKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_TITLE_TEXT;
            descriptionKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_DESCRIPTION_TEXT;
            cancelBtnKey = localizationKeys.REACHED_LIMIT_REGISTERED_DEVICE_ERROR_POPUP_OK_GOT_IT_BUTTON_TEXT;
            break;
        }
    }

    return (
        <FWUFailedPopup
            isClosePopupFeaturesDisable={true}
            headerKey={headerKey}
            descriptionKey={descriptionKey}
            cancelBtnKey={cancelBtnKey}
            {...props}
        />
    );
};

export default RegistrationFailedPopup;
