import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../components/BackButton/BackButton';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import Carousel from '../../components/Carousel/Carousel';
import GuideSliderItem from '../../components/GuideSliderItem/GuideSliderItem';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import iotDeviceResetService from '../../services/iotDevice/iotDeviceResetService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {makeSelectIotDeviceData} from '../../state/selectors/iotDevice';
import styles from './CleaningGuidePage.module.scss';
import {getCleaningGuidePagesData} from './cleaningGuidePagesData';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    layout: makeSelectLayoutType(),
});

const CleaningGuidePage = (props) => {
    const {iotDevice, layout} = props;
    const {device} = iotDevice;
    const deviceType = device?.type;
    const deviceTypeContent = getCleaningGuidePagesData()[deviceType];
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const localizedStrings = getLocalizedStrings();

    const onResetButtonClick = () => {
        iotDeviceResetService.resetDevice();
        appRouterService.forwardToDeviceSettingsPage();
    };

    const lastSlideButton = (
        <ButtonDark showArrow className={styles.LastSlideButton} onClick={onResetButtonClick}>
            {localizedStrings[localizationKeys.CLEAN_INSTRUCTION_RESET_BUTTON_TEXT]}
        </ButtonDark>
    );

    return (
        <div className={styles.Page}>
            <Container isFloatHeight>
                <BackButton className={styles.CleaninGuideBackButton} />
            </Container>
            <Carousel
                isNextSlideButtonVisible
                isCommonNextSlideButtonVisible={!isDesktopLayout}
                isControlArrowVisible
                itemsCount={deviceTypeContent.length}
                lastSlideButton={lastSlideButton}
                nextSlideButtonClassName={styles.LastSlideButton}
                controlArrowClassName={styles.SliderButton}
                dotsClassName={styles.Dots}
            >
                {deviceTypeContent.map((element, index) => {
                    const {header, desc, images} = element;
                    const subTitle = localizedStrings[localizationKeys.CLEAN_INSTRUCTION_TITLE_TEXT];

                    return (
                        <div key={index.toString()} className={styles.ContentContainer}>
                            <GuideSliderItem
                                header={header}
                                description={desc}
                                subTitle={subTitle}
                                element={<Image src={images} />}
                            />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    );
};

export default connect(mapStateToProps)(CleaningGuidePage);
