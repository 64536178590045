import * as localizationKeys from '../../consts/app/localizationKeys';
import {setNotificationFailed} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import {getLocalizedStrings} from '../localization/localizationService';
import log from '../logger/log';
import pairingService from '../pairingService';

const RESPONSE_TIMEOUT_MS = 40 * 1000;

let responsesTimeouts = [];

const setRequest = (requestId) => {
    const responseTimeout = {
        requestId,
        timeout: setTimeout(() => {
            log.error(
                `uiIotMessageResponseChecker: onRequestTimeout, there is no response from SCP cloud for request, requestId: ${requestId}`
            );
            onRequestMessageError();
        }, RESPONSE_TIMEOUT_MS),
    };

    responsesTimeouts.push(responseTimeout);
};

const clearResponseTimeout = (requestId) => {
    const responseTimeout = responsesTimeouts.find((r) => r.requestId === requestId);

    if (responseTimeout) {
        clearTimeout(responseTimeout.timeout);
    }

    responsesTimeouts = responsesTimeouts.filter((r) => r.requestId !== requestId);
};

const clearResponsesTimeouts = () => {
    responsesTimeouts.forEach((request) => clearTimeout(request.timeout));
    responsesTimeouts = [];
};

const onRequestMessageError = () => {
    dispatch(setNotificationFailed(getLocalizedStrings()[localizationKeys.IO_ERROR_NOTIFICATION_TEXT]));
    pairingService.disconnectAndForwardToMyDevicesPage();
};

export default {
    clearResponsesTimeouts,
    clearResponseTimeout,
    onRequestMessageError,
    setRequest,
};
