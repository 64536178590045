import React from 'react';

import styles from '../../MyDevicesPage.module.scss';
import MultipleDevices from './components/MultipleDevice/MultipleDevices';
import SingleDevice from './components/SingleDevice';

const DevicesList = ({customerDeviceList, onSlide, layout}) => {
    const isSingleDevice = customerDeviceList?.length === 1;

    return (
        <div className={styles.DeviceList}>
            {isSingleDevice ? (
                <SingleDevice customerDeviceList={customerDeviceList} />
            ) : (
                <MultipleDevices customerDeviceList={customerDeviceList} onSlide={onSlide} layout={layout} />
            )}
        </div>
    );
};

export default DevicesList;
