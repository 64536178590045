import React from 'react';

import routerService from '../../../services/routerService';
import helpers from '../../../utils/helpers';
import styles from './CarouselArrow.module.scss';

const CarouselArrowPrev = ({testId, className, onClick, to, customClassName}) => {
    const onButtonClick = () => {
        if (to) {
            routerService.forwardTo(to);
        } else {
            helpers.runFunction(onClick);
        }
    };

    return (
        <div
            className={`${styles.Arrow} ${className ? className : ''} ${customClassName ? customClassName : ''}`}
            data-testid={testId}
            onClick={onButtonClick}
        />
    );
};

export default CarouselArrowPrev;
