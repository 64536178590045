import React from 'react';

import styles from './ButtonSelectedMark.module.scss';

const ButtonSelectedMark = () => {
    return (
        <div className={styles.MarkContainer}>
            <ButtonSelectedMarkIcon />
        </div>
    );
};

const ButtonSelectedMarkIcon = () => {
    return (
        <svg width='25' height='25' viewBox='0 0 25 25' className={styles.Mark}>
            <path
                d='M1.707 4.677c-.39-.39-1.024-.39-1.414
                0-.39.39-.39 1.024 0 1.414l3.394 3.394c.39.39
                1.024.39 1.414 0l7.778-7.778c.39-.39.39-1.024
                0-1.414-.39-.39-1.023-.39-1.414 0l-7.07
                7.071-2.688-2.687z'
            />
        </svg>
    );
};

export default ButtonSelectedMark;
