import axios from 'axios';

import log from '../logger/log';
import httpStatusTypesService from './httpStatusTypesService';

const get = (url, requestConfig) => requestApi('get', url, requestConfig);
const post = (url, requestConfig = {}) => requestApi('post', url, requestConfig);
const put = (url, requestConfig = {}) => requestApi('put', url, requestConfig);

const requestDelete = (url, requestConfig = {}) => requestApi('delete', url, requestConfig);

const requestApi = async (method, url, requestConfig = {}) => {
    try {
        let config = {method: method, url: url};

        config = {...config, ...requestConfig};

        return await request(config);
    } catch (e) {
        const logMessage = `requestApi: url: ${url}; requestConfig: ${JSON.stringify(
            requestConfig
        )}, method: ${method}; error: ${e}; response data: ${JSON.stringify(e.response?.data)}`;

        if (httpStatusTypesService.isNotAuthorized(e)) {
            log.info(logMessage);
        } else {
            log.error(logMessage);
        }

        throw e;
    }
};

const request = (requestConfig) => axios.request(requestConfig);

export default {
    get,
    post,
    put,
    requestDelete,
};
