import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import AppLoader from '../../components/Loader/AppLoader/AppLoader';
import {makeLoaders} from '../../state/selectors/global';

const mapStateToProps = createStructuredSelector({
    loaders: makeLoaders(),
});

const LoaderGlobal = ({loaders}) => {
    const loadersLength = loaders.length;
    const {text, isOpaque} = loadersLength && loaders[loadersLength - 1];

    return <AppLoader isVisible={!!loadersLength} loaderText={text} isOpaque={isOpaque} />;
};

export default connect(mapStateToProps)(LoaderGlobal);
