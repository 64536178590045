import appConfig from '../../config/appConfig';
import {logoutClearData} from '../../state/ducks/global';
import {dispatch} from '../../state/store';
import stringUtils from '../../utils/stringUtils';
import appRouterService from '../appRouterService';
import iccService from '../icc/iccService';
import dce1Service from '../market/dce1/dce1Service';
import dcsService from '../market/dcs/dcsService';
import marketTypeService from '../marketTypeService';
import authDataService from './authDataService';
import authDataStoreService from './authDataStoreService';

const login = async () => {
    const {spiceToken, username} = authDataService.getInitialCredentials();
    const isSpiceTokenNullEmpty = stringUtils.isNullOrEmpty(spiceToken);
    const accessProtectCheckResult = accessProtectCheck(spiceToken);

    if (accessProtectCheckResult) {
        if (!isSpiceTokenNullEmpty) {
            authDataStoreService.setSpiceToken(spiceToken, username);
        }

        await iccService.fetchIccToken(isSpiceTokenNullEmpty);
    }
};

const standaloneLogin = async (standaloneCredentials) => {
    const {password, username} = standaloneCredentials;

    authDataStoreService.setSpiceToken(password, username);

    await iccService.fetchIccToken();
};

const logout = () => {
    localStorage.clear();
    dispatch(logoutClearData());

    appRouterService.forwardToSelectMarketPage();
};

const accessProtectCheck = (dataUserToken) => {
    if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
        const isAccessProtected = appConfig.getIsAccessProtected();

        if (isAccessProtected && !dataUserToken) {
            showMarketLogin();

            return false;
        }
    }

    return true;
};

const showMarketLogin = () => {
    const isDCE1 = marketTypeService.isDCE1();

    if (isDCE1) {
        dce1Service.showLogin();
    } else {
        dcsService.showLoginOverlay();
    }
};

export default {
    login,
    logout,
    showMarketLogin,
    standaloneLogin,
};
