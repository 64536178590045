import {config} from '../config';
import configGettersService from './configGettersService';

export default {
    getLinkAndroidStoreChrome: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkAndroidStoreChrome),
    getLinkAndroidStoreIqos: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkAndroidStoreIqos),
    getLinkDesktopApps: () => configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkDesktopApps),
    getLinkDesktopChrome: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkDesktopChrome),
    getLinkIosStoreBluenet: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkIosStoreBluenet),
    getLinkIosStoreIqos: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkIosStoreIqos),
    getLinkMacWidget: () => configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkMacWidget),
    getLinkWindowsWidget: () =>
        configGettersService.getLocalizedPropertyOrDefault(config.data.appLinks.linkWindowsWidget),
};
