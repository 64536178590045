import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect/lib/index';

import tncService from '../../../services/tncService';
import {setTncPopupCode} from '../../../state/ducks/tnc';
import {makeSelectTncLegals} from '../../../state/selectors/consumer';
import styles from './TermsOfUseDescription.module.css';

const mapStateToProps = createStructuredSelector({
    legals: makeSelectTncLegals(),
});

const TermsOfUseDescription = (props) => {
    const {dispatch, innerHtml, className, legals} = props;

    const onReadButtonClick = (e) => {
        const {target} = e;
        const tncCode = tncService.getTncCodeFromTarget(target, styles.TncLink);

        if (tncCode) {
            dispatch(setTncPopupCode(tncCode));
        }
    };

    const textParsed = tncService.getParsedDescription(innerHtml, styles.TncLink, legals);

    return (
        <p
            className={`${className ? className : ''} ica--txt-regular`}
            dangerouslySetInnerHTML={{__html: textParsed}}
            onClick={onReadButtonClick}
        />
    );
};

export default connect(mapStateToProps)(TermsOfUseDescription);
