import classNames from 'classnames';
import React from 'react';

import styles from './Input.module.scss';

const Input = ({type, className, value, maxLength, minLength, onChange, placeholder, testId}) => {
    const inputClassName = classNames(styles.Input, className);

    return (
        <input
            className={inputClassName}
            data-testid={testId}
            maxLength={maxLength}
            minLength={minLength}
            onChange={onChange}
            placeholder={placeholder}
            type={type}
            value={value}
        />
    );
};

export default Input;
