import React from 'react';

import imgAgeGateIcon1 from '../../../../assets/images/age-gate/age-icon.png';
import imgAgeGateIcon2 from '../../../../assets/images/age-gate/age-icon@2x.png';
import imgAgeGateIcon3 from '../../../../assets/images/age-gate/age-icon@3x.png';
import Image from '../../../components/Image/Image';

const AgeGatePageIcon = ({className}) => (
    <div className={className}>
        <Image src={[imgAgeGateIcon1, imgAgeGateIcon2, imgAgeGateIcon3]} />
    </div>
);

export default AgeGatePageIcon;
