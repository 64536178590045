import converter from '../../utils/converter';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import IqosBleClient from './iqosBleClient';

let instance = null;

export default class DeviceCharacteristicClient {
    constructor(createNew = false) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        instance = this;

        this.iqosBleClient = new IqosBleClient();
        this.iqosBleClient.addDeviceStatusCharacteristicListener((value) => {
            const frame = converter.buffer2hex(value);
            mwIotMessageRequestService.publishDeviceCharacteristicEvent(frame);
        });

        this.iqosBleClient.addBatteryInformationCharacteristicListener((value) => {
            const frame = converter.buffer2hex(value);
            mwIotMessageRequestService.publishBatteryCharacteristicEvent(frame);
        });
    }
}
