import React, {useEffect, useState} from 'react';

import imgIconHeart from '../../../../../../../assets/images/icons/heart-icon.png';
import imgIconHeart2 from '../../../../../../../assets/images/icons/heart-icon@2x.png';
import imgIconHeart3 from '../../../../../../../assets/images/icons/heart-icon@3x.png';
import Popup from '../../../../../../components/Popup/Popup';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as iotMessageStatusTypes from '../../../../../../consts/iot/iotMessageStatusTypes';
import * as iotMessageTypes from '../../../../../../consts/iot/iotMessageTypes';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import iotDeviceSettingsService from '../../../../../../services/iotDevice/iotDeviceSettingsService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import {
    hideLoader,
    setNotificationInfo,
    setNotificationSuccess,
    showLoader,
} from '../../../../../../state/ducks/global';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import HolderSBLModePopup from '../../../HolderSBLModePopup/HolderSBLModePopup';
import DeviceDiagnosticResults from './DeviceDiagnosticResults';

const LOADER_NAME = 'LED_MODE_PAGE_LOADER';

const DeviceDiagnostic = (props) => {
    const {dispatch, iotDevice, iotMessage, isConnected, deviceColor, disabled, onDisableClick, testId} = props;
    const {device, holder, errors} = iotDevice;
    const {type, status} = iotMessage;
    const [isVisibleDeviceDiagnosticPopup, setIsVisibleDeviceDiagnosticPopup] = useState(false);
    const isFinished = type === iotMessageTypes.CONNECTION_CARE && status === iotMessageStatusTypes.FINISHED;
    const [isShowMessage, setIsShowMessage] = useState(false);
    const isHolderInSBLMode = iotDeviceSettingsService.isHolderInSBLMode(iotDevice);

    useHideLoader(!isConnected, LOADER_NAME);

    useEffect(() => {
        if (isFinished && isShowMessage) {
            dispatch(hideLoader(LOADER_NAME));
            setIsVisibleDeviceDiagnosticPopup(true);
        }
    }, [isFinished]);

    const onCloseDiagnosticClick = () => {
        setIsVisibleDeviceDiagnosticPopup(false);
        setIsShowMessage(false);
    };

    const onErrorSendMessageClick = (message) => {
        if (message) {
            dispatch(setNotificationInfo(message));
        }
    };

    const onSendMessageClick = (message) => {
        if (message) {
            dispatch(setNotificationSuccess(message));
        }
    };

    const onDiagnosticClick = () => {
        analyticsService.pushUsageSettingsDiagnosticEvent();
        setIsShowMessage(true);
        dispatch(showLoader({name: LOADER_NAME}));
        getDeviceMessageRequestService().publishConnectionCareMessage();
    };

    const getHolderErrors = () => {
        if (errors) {
            return errors.find((item) => item.is_charger === false);
        }
    };

    const isSBLModeRelatedError = isHolderInSBLMode && getHolderErrors();

    return (
        <>
            <DeviceSettingsItem
                deviceColor={deviceColor}
                disabled={disabled}
                imgUrls={[imgIconHeart, imgIconHeart2, imgIconHeart3]}
                onClick={onDiagnosticClick}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                {getLocalizedStrings()[localizationKeys.DEVICE_SETTINGS_DIAGNOSTIC]}
            </DeviceSettingsItem>
            {isVisibleDeviceDiagnosticPopup &&
                isConnected &&
                (isSBLModeRelatedError ? (
                    <HolderSBLModePopup onClose={onCloseDiagnosticClick} />
                ) : (
                    <Popup onClose={onCloseDiagnosticClick}>
                        <DeviceDiagnosticResults
                            device={device}
                            errors={errors}
                            holder={holder}
                            onClose={onCloseDiagnosticClick}
                            onErrorSendMessage={onErrorSendMessageClick}
                            onSendMessage={onSendMessageClick}
                        />
                    </Popup>
                ))}
        </>
    );
};

export default DeviceDiagnostic;
