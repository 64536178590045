import React from 'react';

import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import * as styles from './InstructionStep.module.scss';

const InstructionStep = (props) => {
    const {number, localizationKey} = props;
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.StepElement}>
            <div className={styles.StepElementCircle}>{number}</div>
            <div className={`${styles.StepElementText} ica--txt-regular`}>{localizedStrings[localizationKey]}</div>
        </div>
    );
};

export default InstructionStep;
