import classnames from 'classnames';
import React from 'react';

import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import layoutService from '../../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import OSRelatedDownloadButton from '../../../OSRelatedDownloadButton/OSRelatedDownloadButton';
import styles from './AppIntroPageDownloadSection.module.scss';

const TXT_REGULAR_CLASSNAME = 'ica--txt-regular';

const AppIntroPageDownloadSection = ({buttonData, layout}) => {
    const {link} = buttonData || {};

    if (!link) return null;

    const isMobileLayout = layoutService.isMobileLayout(layout);
    const localizedStrings = getLocalizedStrings();

    const textClassName = classnames(TXT_REGULAR_CLASSNAME, styles.DownloadText);

    return (
        <div className={styles.Container}>
            <p className={textClassName}>
                {
                    localizedStrings[
                        isMobileLayout
                            ? localizationKeys.COMPATIBLE_BROWSER_PAGE_DESCRIPTION_TEXT
                            : localizationKeys.COMPATIBLE_BROWSER_PAGE_TITLE_TEXT_DESKTOP
                    ]
                }
            </p>

            <OSRelatedDownloadButton buttonData={buttonData} />
        </div>
    );
};

export default AppIntroPageDownloadSection;
