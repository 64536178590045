const isFirmwareInfoLoaded = (device) => {
    const deviceFirmware = device?.firmware;
    const isDevicePackageAvailable = deviceFirmware?.package_available;

    return isDevicePackageAvailable !== undefined;
};

const isFirmwareInfoLoadedWithCheck = (device, holder, isTargetsHolder) => {
    const isDevicePackageAvailable = device?.firmware?.package_available;
    const isHolderPackageAvailable = holder?.firmware?.package_available;
    return (
        (isTargetsHolder && isHolderPackageAvailable !== undefined) ||
        (!isTargetsHolder && isDevicePackageAvailable !== undefined)
    );
};

const isFirmwarePackageLoaded = (device, holder) => {
    const deviceFirmware = device?.firmware;
    const holderFirmware = holder?.firmware;
    const isDevicePackageAvailable = deviceFirmware?.package_available;
    const isHolderPackageAvailable = holderFirmware?.package_available;

    return (
        (isDevicePackageAvailable ? deviceFirmware.package_loaded : true) &&
        (isHolderPackageAvailable ? holderFirmware.package_loaded : true)
    );
};

const isFirmwareUpToDate = (device, holder) => {
    const {holder_connected} = device || {};
    const deviceFirmware = device?.firmware;
    const holderFirmware = holder?.firmware;
    const isDevicePackageAvailable = deviceFirmware?.package_available;
    const isHolderPackageAvailable = holderFirmware?.package_available;
    const isDeviceFWUpToDate = deviceFirmware && !isDevicePackageAvailable;
    const isHolderFWUpToDate = holder_connected ? holderFirmware && !isHolderPackageAvailable : true;

    return isDeviceFWUpToDate && isHolderFWUpToDate;
};

export default {
    isFirmwareInfoLoaded,
    isFirmwarePackageLoaded,
    isFirmwareUpToDate,
    isFirmwareInfoLoadedWithCheck,
};
