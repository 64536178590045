import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Button from '../../components/Button/Button';
import ButtonWhite from '../../components/Button/ButtonWhite/ButtonWhite';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketTypeService from '../../services/marketTypeService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectDeviceActivationStatus} from '../../state/selectors/iotDevice';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceImage from '../DeviceImage/DeviceImage';
import styles from './PairingSuccessPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
});

const PairingSuccessPage = (props) => {
    const {iotProduct, isDeviceActivated} = props;
    const {device, holder} = iotProduct || {};
    const {name = ''} = device || {};
    const localizedStrings = getLocalizedStrings();
    const header = localizedStrings.formatString(localizedStrings[localizationKeys.PAIR_DEVICE_PAIRED_TITLE], name);
    const isDeviceNeedsToBeActivated = !isDeviceActivated;
    const isDCE2 = marketTypeService.isDCE2();

    const onLearnHowToUseClick = () => {
        appRouterService.forwardToQuickStartGuidePage();
    };

    useEffect(() => {
        if (isDeviceNeedsToBeActivated) {
            analyticsService.pushActivationPreactivationEvent();
            if (isDCE2) {
                appRouterService.forwardToDeviceActivationPage();
            } else {
                appRouterService.forwardToDeviceRegistrationPage();
            }
        }
    }, [isDeviceNeedsToBeActivated]);

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <h2 className={`${styles.Header} ls--0`} data-testid={elementsTesIds.PAIRING_SUCCESS_TITLE}>
                    {header}
                </h2>
                {isDeviceNeedsToBeActivated && (
                    <p className={`ica--txt-regular ${styles.ActivationDescription}`}>
                        {
                            localizedStrings[
                                localizationKeys.YAP_ACTIVATION_DEVICES_AVAILABLE_ACTIVATION_DESCRIPTION_TEXT
                            ]
                        }
                    </p>
                )}
                <DeviceImage
                    device={device}
                    holder={holder}
                    className={`${styles.ImageContainer} ${isDeviceNeedsToBeActivated ? styles.Activation : ''}`}
                />
                {!isDeviceNeedsToBeActivated && (
                    <>
                        <ButtonWhite showArrow onClick={onLearnHowToUseClick}>
                            {localizedStrings[localizationKeys.LEARN_HOW_TO_USE]}
                        </ButtonWhite>
                        <Button
                            className={styles.ButtonSecondary}
                            showUnderline
                            testId={elementsTesIds.PAIRING_SUCCESS_DEVICE_LIST_BUTTON}
                            to={isDCE2 ? routePaths.DEVICE_SETTINGS : routePaths.MY_DEVICES}
                        >
                            {localizedStrings[localizationKeys.SKIP_LEARN_HOW_TO_USE]}
                        </Button>
                    </>
                )}
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(PairingSuccessPage);
