import React, {useEffect, useState} from 'react';

import server from '../../services/server/server';
import helpers from '../../utils/helpers';

const Video = React.forwardRef(
    ({src, className, autoplay, loop, muted, playsinline, onCanPlay, onLoad, onLoadError, isPreloadEnabled}, ref) => {
        const [isLoaded, setIsLoaded] = useState(!isPreloadEnabled);
        const isVideoRendered = isPreloadEnabled ? isLoaded : true;
        const onCanPlayFunc = () => {
            helpers.runFunction(onCanPlay);
        };

        useEffect(() => {
            if (isPreloadEnabled) {
                server
                    .get(src)
                    .then(() => {
                        helpers.runFunction(onLoad);
                    })
                    .catch(() => {
                        helpers.runFunction(onLoadError);
                    })
                    .finally(() => {
                        setIsLoaded(true);
                    });
            } else {
                helpers.runFunction(onLoad);
            }
        }, []);

        return isVideoRendered ? (
            <video
                onCanPlay={onCanPlayFunc}
                playsInline={playsinline}
                className={className}
                autoPlay={autoplay}
                muted={muted}
                loop={loop}
                ref={ref}
                src={src}
            />
        ) : null;
    }
);

Video.displayName = 'Video';

export default Video;
