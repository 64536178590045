import {createSelector} from 'reselect';

import deviceTypesService from '../../services/device/deviceTypesService';
import productService from '../../services/productService';
import {makeSelectIotDeviceData} from './iotDevice';

const selectProductSettings = (state) => state.productSettings;

const makeSelectProductsToChangeRegistrationStatus = () =>
    createSelector(selectProductSettings, (state) => state.productsToChangeRegistrationStatus);

const makeSelectCurrentProductToChangeRegistrationStatus = () =>
    createSelector(
        makeSelectProductsToChangeRegistrationStatus(),
        makeSelectIotDeviceData(),
        (productsToChangeRegistrationStatus, iotDevice) => {
            const {deviceSerialNumber, holder, device} = iotDevice;
            const {type, codentify} = device || {};
            const isP4Device = deviceTypesService.isP4(type);
            let result;

            if (productsToChangeRegistrationStatus?.length && (deviceSerialNumber || codentify)) {
                const getProductToChangeRegistrationStatus = () =>
                    productService.findProduct({
                        products: productsToChangeRegistrationStatus,
                        isP4: isP4Device,
                        deviceSerialNumber,
                        codentify,
                    });

                result = getProductToChangeRegistrationStatus(deviceSerialNumber, codentify);

                if (!result && holder?.deviceSerialNumber) {
                    result = getProductToChangeRegistrationStatus(holder.deviceSerialNumber);
                }
            }

            result = result ? {...result, isP4: isP4Device} : result;

            return result;
        }
    );

export {makeSelectProductsToChangeRegistrationStatus, makeSelectCurrentProductToChangeRegistrationStatus};
