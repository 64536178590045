import EventEmitter from 'events';

import eventTypes from '../../consts/app/eventTypes';

let instance = null;

export default class DisconnectEventEmitter extends EventEmitter {
    constructor(createNew) {
        if (createNew && instance) {
            instance.removeAllDisconnectListeners();
            instance = null;
        }

        if (instance) {
            return instance;
        }

        super();
        instance = this;
    }

    emitDeviceDisconnect() {
        this.emit(eventTypes.DEVICE_DISCONNECT);
    }

    onDeviceDisconnect(func) {
        this.on(eventTypes.DEVICE_DISCONNECT, func);
    }

    offDeviceDisconnect(func) {
        this.off(eventTypes.DEVICE_DISCONNECT, func);
    }

    removeAllDisconnectListeners() {
        // console.log(`-------------------this.listenerCount(eventTypes.DEVICE_DISCONNECT) - ${this.listenerCount(eventTypes.DEVICE_DISCONNECT)}`)

        this.removeAllListeners(eventTypes.DEVICE_DISCONNECT);
    }
}
