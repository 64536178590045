import React from 'react';

import appConfig from '../../../../config/appConfig';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {ENABLE_BLE_ERROR_DATA_COLLECTION} from '../../../../consts/tnc/aggregatedSettingTypes';
import deviceConfigService from '../../../../services/iotDevice/iotDeviceConfigService';
import iotDeviceSettingsService from '../../../../services/iotDevice/iotDeviceSettingsService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import marketConfigService from '../../../../services/marketConfigService';
import tncService from '../../../../services/tncService';
import {setNotificationInfo} from '../../../../state/ducks/global';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import DeviceCleaningMode from './components/CleaningMode/DeviceCleaningMode';
import DeviceDiagnostic from './components/DeviceDiagnostic/DeviceDiagnostic';
import DeviceLedMode from './components/LedMode/DeviceLedMode';
import ResponsiveDrawMode from './components/ResponsiveDrawMode/ResponsiveDrawMode';
import DeviceVapeCloudSizeMode from './components/VapeCloudSizeMode/DeviceVapeCloudSizeMode';
import DeviceVibrateMode from './components/VibrateMode/DeviceVibrateMode';
import styles from './SettingsOptions.module.scss';

const SettingsOptions = (props) => {
    const {
        dispatch,
        iotDevice,
        iotMessage,
        isDeviceReady,
        isLocked,
        onDisableClick,
        layout,
        isYapUpdateSettingsInProgress,
    } = props;

    const {device_global_features_config, device_management_features_config} = iotDevice;
    if (!device_global_features_config || !device_management_features_config) return null;
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const {is_self_cleaning, is_vibration, is_led_intensity} = device_global_features_config;
    const isSelfCleaning = iotDeviceSettingsService.isSettingAvailable(
        iotDevice,
        is_self_cleaning,
        deviceConfigService.isCleaningEnabled(iotDevice)
    );

    const isVibration = iotDeviceSettingsService.isSettingAvailable(
        iotDevice,
        is_vibration,
        deviceConfigService.isVibrationEnabled(iotDevice)
    );

    const isLedFeatureAvailable =
        deviceConfigService.isDeviceLedModeEnabled(iotDevice) || deviceConfigService.isHolderLedModeEnabled(iotDevice);
    const isLedIntensity = iotDeviceSettingsService.isSettingAvailable(
        iotDevice,
        is_led_intensity,
        isLedFeatureAvailable,
        false
    );

    const isDeviceDiagnosticEnabled = appConfig.getIsDeviceDiagnosticEnabled();
    const isDiagnosticModeEnabled = deviceConfigService.isDiagnosticModeEnabled(iotDevice);
    const isResponsiveDrawModeEnabled = deviceConfigService.isResponsiveDrawModeEnabled(iotDevice);
    const isVapeCloudModeEnabled = deviceConfigService.isVapeCloudModeEnabled(iotDevice);
    const isAggregatedSettingDisabled =
        !tncService.isAggregatedSettingEnabled(ENABLE_BLE_ERROR_DATA_COLLECTION) && isIccJourneyScreensEnabled;
    const localizedStrings = getLocalizedStrings();

    const onAggregatedSettingDisableClick = () => {
        dispatch(setNotificationInfo(localizedStrings[localizationKeys.TROUBLESHOOTING_CONSENT_DESCRIPTION_TEXT]));
    };

    return (
        <div className={styles.SettingsOptionsContainer}>
            <div className={styles.DeviceSettingsContainer}>
                {isSelfCleaning && (
                    <DeviceCleaningMode
                        disabled={!isDeviceReady || isLocked}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        layout={layout}
                        onDisableClick={onDisableClick}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_CLEANING}
                    />
                )}
                {isVibration && (
                    <DeviceVibrateMode
                        disabled={!isDeviceReady || isLocked}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        layout={layout}
                        onDisableClick={onDisableClick}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_VIBRATION}
                    />
                )}
                {isLedIntensity && (
                    <DeviceLedMode
                        disabled={!isDeviceReady || isLocked}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        layout={layout}
                        onDisableClick={onDisableClick}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_LED}
                        isYapUpdateSettingsInProgress={isYapUpdateSettingsInProgress}
                    />
                )}
                {isDiagnosticModeEnabled && isDeviceDiagnosticEnabled && (
                    <DeviceDiagnostic
                        disabled={!isDeviceReady || isLocked || isAggregatedSettingDisabled}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        onDisableClick={
                            isAggregatedSettingDisabled && isDeviceReady
                                ? onAggregatedSettingDisableClick
                                : onDisableClick
                        }
                        layout={layout}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_DIAGNOSTIC}
                    />
                )}
                {isResponsiveDrawModeEnabled && (
                    <ResponsiveDrawMode
                        disabled={!isDeviceReady || isLocked}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        onDisableClick={onDisableClick}
                        layout={layout}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_RESP_DRAW}
                        isYapUpdateSettingsInProgress={isYapUpdateSettingsInProgress}
                    />
                )}
                {isVapeCloudModeEnabled && (
                    <DeviceVapeCloudSizeMode
                        disabled={!isDeviceReady || isLocked}
                        dispatch={dispatch}
                        iotDevice={iotDevice}
                        iotMessage={iotMessage}
                        isConnected={isDeviceReady}
                        onDisableClick={onDisableClick}
                        layout={layout}
                        testId={elementsTesIds.DEVICE_SETTINGS_BTN_VAPE_CLOUD_SIZE}
                        isYapUpdateSettingsInProgress={isYapUpdateSettingsInProgress}
                    />
                )}
            </div>
        </div>
    );
};

export default SettingsOptions;
