import React, {forwardRef} from 'react';

import Carousel from '../../../../components/Carousel/Carousel';
import Container from '../../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import layoutService from '../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import elementsTesIds from '../../../../test/consts/elementsTesIds';
import styles from './PairingGuideCarousel.module.scss';

const PairingGuideCarousel = forwardRef((props, ref) => {
    const {content, onSlide, isNextButtonVisible, layout, isArrowsVisible, arrowNextTestId} = props;

    const isDotsVisible = layoutService.isDesktopLayout(layout) || layoutService.isTabletLayout(layout);

    const localizedStrings = getLocalizedStrings();

    return (
        <section className={styles.Slider}>
            <Carousel
                arrowNextTestId={arrowNextTestId}
                dotsClassName={styles.Dots}
                isAccessibilityEnabled={isArrowsVisible}
                isArrowVisible={false}
                isControlArrowVisible={isArrowsVisible}
                isDotsVisible={isDotsVisible}
                isDraggable={isArrowsVisible}
                isNextSlideButtonVisible={isNextButtonVisible}
                isSwipeEnabled={isArrowsVisible}
                itemsCount={content.length}
                onTransitionEnd={onSlide}
                ref={ref}
            >
                {content.map((element, index) => {
                    const {title, description} = element;

                    return (
                        <div key={index.toString()} className={styles.SliderContent}>
                            <Container>
                                <h2 data-testid={elementsTesIds.PAIRING_SEQUENCE_TITLE} className={styles.SliderHeader}>
                                    {localizedStrings[localizationKeys[title]]}
                                </h2>
                                <p className={`${styles.SliderDescription} ica--txt-regular`}>
                                    {localizedStrings[localizationKeys[description]]}
                                </p>
                            </Container>
                        </div>
                    );
                })}
            </Carousel>
        </section>
    );
});

PairingGuideCarousel.displayName = 'PairingGuideCarousel';

export default PairingGuideCarousel;
