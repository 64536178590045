import classnames from 'classnames';
import React from 'react';

import imgErrorIcon1 from '../../../assets/images/icons/error-icon.png';
import imgErrorIcon2 from '../../../assets/images/icons/error-icon@2x.png';
import imgErrorIcon3 from '../../../assets/images/icons/error-icon@3x.png';
import Image from '../Image/Image';

const ErrorIcon = ({className}) => (
    <Image className={classnames(className)} src={[imgErrorIcon1, imgErrorIcon2, imgErrorIcon3]} />
);

export default ErrorIcon;
