import React, {useEffect, useState} from 'react';

import iconCloudSizeNormal from '../../../../../../../assets/images/settings/vape-cloud-size-normal-icon.png';
import iconCloudSizeNormal2 from '../../../../../../../assets/images/settings/vape-cloud-size-normal-icon@2x.png';
import iconCloudSizeNormal3 from '../../../../../../../assets/images/settings/vape-cloud-size-normal-icon@3x.png';
import iconCloudSizeReduced from '../../../../../../../assets/images/settings/vape-cloud-size-reduced-icon.png';
import iconCloudSizeReduced2 from '../../../../../../../assets/images/settings/vape-cloud-size-reduced-icon@2x.png';
import iconCloudSizeReduced3 from '../../../../../../../assets/images/settings/vape-cloud-size-reduced-icon@3x.png';
import Button from '../../../../../../components/Button/Button';
import Image from '../../../../../../components/Image/Image';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as vapeCloudSizeTypes from '../../../../../../consts/iot/vapeCloudSizeTypes';
import useHideLoader from '../../../../../../hooks/effects/useHideLoader';
import analyticsService from '../../../../../../services/analyticsService';
import backendService from '../../../../../../services/app/backendService';
import getDeviceMessageRequestService from '../../../../../../services/deviceMessageRequestService';
import iotMessageService from '../../../../../../services/iotMessageService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import {showLoader} from '../../../../../../state/ducks/global';
import elementsTesIds from '../../../../../../test/consts/elementsTesIds';
import DeviceModePopup from '../../../DeviceModePopup/DeviceModePopup';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import styles from '../DeviceModeComponents/DeviceMode.module.scss';

const LOADER_NAME = 'VAPE_CLOUD_SIZE_MODE_PAGE_LOADER';

const DeviceVapeCloudSizeMode = (props) => {
    const {
        dispatch,
        iotDevice,
        isConnected,
        disabled,
        iotMessage,
        onDisableClick,
        layout,
        testId,
        isYapUpdateSettingsInProgress,
    } = props;
    const {vapeCloudSizeData} = iotDevice;
    const [isVisibleVapeSizePopup, setIsVisibleVapeSizePopup] = useState(false);
    const onVisibleVapeSizePopup = () => {
        setIsVisibleVapeSizePopup(!isVisibleVapeSizePopup);
    };

    const isGamBackend = backendService.isGamBackend();
    const deviceVapeCloudSize = vapeCloudSizeData?.vape_cloud_size;
    const isResponsiveDrawDataLoaded = deviceVapeCloudSize;
    const isResponsiveDrawStatusFinished = iotMessageService.isVapeCloudSizeStatusFinished(iotMessage);

    useEffect(() => {
        if (!isConnected && !isYapUpdateSettingsInProgress) {
            setIsVisibleVapeSizePopup(false);
        }
    }, [isConnected, isYapUpdateSettingsInProgress]);

    useEffect(() => {
        if (!isResponsiveDrawDataLoaded && isConnected) {
            if (isGamBackend) {
                //todo: uncomment after device info implementation
                //appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_TEMPORARY_ERROR);
            } else {
                dispatch(showLoader({name: LOADER_NAME}));
                getDeviceMessageRequestService().publishGetVapeCloudSizeSettingsMessage();
            }
        }
    }, [isConnected]);

    useHideLoader(isResponsiveDrawStatusFinished || !isConnected, LOADER_NAME);
    const localizedStrings = getLocalizedStrings();

    let vapeCloudSizeModeValue = '';
    let imgSet = [];
    if (isResponsiveDrawDataLoaded) {
        switch (deviceVapeCloudSize) {
            case vapeCloudSizeTypes.NORMAL: {
                vapeCloudSizeModeValue = localizedStrings[localizationKeys.CLOUD_SIZE_NORMAL_BUTTON_TEXT];
                imgSet = [iconCloudSizeNormal, iconCloudSizeNormal2, iconCloudSizeNormal3];
                break;
            }
            case vapeCloudSizeTypes.REDUCED: {
                vapeCloudSizeModeValue = localizedStrings[localizationKeys.CLOUD_SIZE_REDUCED_BUTTON_TEXT];
                imgSet = [iconCloudSizeReduced, iconCloudSizeReduced2, iconCloudSizeReduced3];
                break;
            }
            default:
                break;
        }
    }

    const onVapeCloudSizeSettingClick = (mode) => {
        if (!isGamBackend) {
            dispatch(showLoader({name: LOADER_NAME}));
        }
        analyticsService.pushUsageSettingsVapeCloudSizeModeEvent();
        getDeviceMessageRequestService().publishSetVapeCloudSizeSettingsMessage(mode);
    };

    return (
        <>
            <DeviceSettingsItem
                disabled={disabled}
                imgUrls={imgSet}
                onClick={onVisibleVapeSizePopup}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                {localizedStrings.formatString(
                    localizedStrings[localizationKeys.DEVICE_SETTINGS_CLOUD],
                    vapeCloudSizeModeValue
                )}
            </DeviceSettingsItem>
            {isVisibleVapeSizePopup && (isConnected || isYapUpdateSettingsInProgress) && (
                <DeviceModePopup
                    imgAlt='vape-cloud-size-mode-icon'
                    imgClassName={`${styles.ImageResponsiveDrawHeaderIcon} ${styles.CenterElement}`}
                    imgUrls={imgSet}
                    isClosingBySwipeEnabled
                    layout={layout}
                    onClose={() => setIsVisibleVapeSizePopup(false)}
                    subTitle={localizedStrings[localizationKeys.CLOUD_SIZE_DESCRIPTION_TEXT]}
                    testId={elementsTesIds.VAPE_CLOUD_SIZE_POPUP}
                    title={localizedStrings[localizationKeys.CLOUD_SIZE_TITLE_TEXT]}
                >
                    <div className={`${styles.VapeCloudButtons}`}>
                        <VapeCloudSizeButton
                            imageClassName={styles.ImageVapeSizeNormalIcon}
                            imageSrc={[iconCloudSizeNormal, iconCloudSizeNormal2, iconCloudSizeNormal3]}
                            isActive={deviceVapeCloudSize === vapeCloudSizeTypes.NORMAL}
                            onClick={() => onVapeCloudSizeSettingClick(vapeCloudSizeTypes.NORMAL)}
                            testId={elementsTesIds.VAPE_CLOUD_POPUP_BTN_REGULAR}
                            text={localizedStrings[localizationKeys.CLOUD_SIZE_NORMAL_BUTTON_TEXT]}
                        />
                        <VapeCloudSizeButton
                            imageClassName={styles.ImageVapeSizeReducedIcon}
                            imageSrc={[iconCloudSizeReduced, iconCloudSizeReduced2, iconCloudSizeReduced3]}
                            isActive={deviceVapeCloudSize === vapeCloudSizeTypes.REDUCED}
                            onClick={() => onVapeCloudSizeSettingClick(vapeCloudSizeTypes.REDUCED)}
                            testId={elementsTesIds.VAPE_CLOUD_POPUP_BTN_REDUCED}
                            text={localizedStrings[localizationKeys.CLOUD_SIZE_REDUCED_BUTTON_TEXT]}
                        />
                    </div>
                </DeviceModePopup>
            )}
        </>
    );
};

export default DeviceVapeCloudSizeMode;

const VapeCloudSizeButton = ({text, onClick, isActive, imageSrc, imageClassName, testId}) => {
    return (
        <Button
            className={`${styles.LedModeButton} ${isActive ? styles.Active : ''}`}
            onClick={isActive ? undefined : onClick}
            testId={testId}
        >
            <div className={styles.ShowLedProperty}>
                <span className='txt--11'>{text}</span>
                <Image src={imageSrc} alt={text} className={imageClassName} />
            </div>
        </Button>
    );
};
