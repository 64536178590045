import appConfig from '../../config/appConfig';
import * as gamErrorPageTypes from '../../consts/gam/gamErrorPageTypes';
import * as gamMethods from '../../consts/gam/gamMethods';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import {
    setDeviceDataChangeReceived,
    setSyncLastRequestId,
    setYapActivationInProgress,
    setYapAssets,
    setYapLastRequestId,
    setYapSyncInProgress,
    setYapUpdateSettingsInProgress,
} from '../../state/ducks/yapEncrypted';
import {makeSelectGamAuthToken} from '../../state/selectors/auth';
import {
    makeSelectFirstAsset,
    makeSelectIsDeviceSyncInProgress,
    makeSelectIsYapActivationInProgress,
    makeSelectIsYapUpdateSettingsInProgress,
} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import appErrorService from '../app/appErrorService';
import appRouterService from '../appRouterService';
import iqosBleService from '../ble/iqosBleService';
import cmClientService from '../communicationLayer/cmClientService';
import iccConsumerProducts from '../icc/iccConsumerProducts';
import log from '../logger/log';
import {gamAssetMapping} from '../mapping/gamMappings';
import storageService from '../storage/storageService';
import gamClient from './gamClient';
import gamService from './gamService';
import gamWebSocketService from './gamWebSocketService';

const initGam = async () => {
    try {
        const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();
        const {dataFactory} = deviceDataFromLocalStorage;

        if (dataFactory === undefined) {
            appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_SYSTEM_ERROR);
            return;
        }
        const gamAuthToken = makeSelectGamAuthToken()(getState());
        if (gamAuthToken || (await gamClient.fetchGamToken())) {
            await getGamAssets();
        }
    } catch (e) {
        appRouterService.forwardToGamErrorHandlingPage(gamErrorPageTypes.GAM_SYSTEM_ERROR);
        log.error(`gamService: initGam, error: ${e}`);
    }
};

const getGamAssets = async (isActivationProcess = false) => {
    const options = {
        methodName: gamMethods.GET_ASSETS,
        action: setYapAssets,
        mapper: gamAssetMapping,
        isNeedRedirectForOtherErrors: !isActivationProcess,
    };

    await gamClient.callPost(options);
    const gamAsset = makeSelectFirstAsset()(getState());
    if (gamAsset?.materialId) {
        await iccConsumerProducts.getProductsAssets([gamAsset.materialId]);
    }
};

const connectGamDevice = (assetId) =>
    gamClient.callGet({
        methodName: gamMethods.CONNECT_DEVICE,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

let syncProcessTimeout;
const synchronizeGamDevice = async () => {
    const gamAsset = makeSelectFirstAsset()(getState());
    if (!gamAsset?.assetId) return;

    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(getState());
    if (isDeviceSyncInProgress) return;

    clearTimeout(syncProcessTimeout);
    dispatch(setYapSyncInProgress(true));
    await syncAndSubscribeOnGamDevice();

    const isConnected = await cmClientService.waitUntilConnected();
    if (isConnected) {
        const isDeviceConnected = iqosBleService.isDeviceConnected();
        if (isDeviceConnected) {
            const {requestId} =
                (await gamClient.callPost({
                    methodName: gamMethods.SYNCHRONIZE_DATA,
                    requestConfig: {args: gamAsset.assetId},
                    isNeedRedirectForOtherErrors: false,
                })) || {};

            if (requestId) {
                dispatch(setSyncLastRequestId(requestId));

                syncProcessTimeout = setTimeout(async () => {
                    const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(getState());
                    if (!isDeviceSyncInProgress) return;

                    dispatch(setYapSyncInProgress(false));
                }, appConfig.getGamUpdateSettingTimeout() * 1000);
                return;
            }
        }
    }

    dispatch(setYapSyncInProgress(false));
};

const getGamDeviceInfo = async () => {
    let gamAsset = makeSelectFirstAsset()(getState());
    const assetId = gamAsset?.assetId;

    const deviceInfo = await gamClient.callGet({
        methodName: gamMethods.GET_DEVICE_INFO,
        requestConfig: {args: assetId},
        isNeedRedirectForOtherErrors: false,
    });

    if (!deviceInfo) return false;

    const {deviceSpecific, firmwareVersion} = deviceInfo;
    const {responsiveDrawProfile, vapeCloudSize, parentProtection, ledIntensity, systemStatus, codentify} =
        deviceSpecific || {};
    gamService.updateDeviceSettings(iotMessageTypes.RESPONSIVE_DRAW_PROFILES, responsiveDrawProfile?.hapticPattern);
    gamService.updateDeviceSettings(iotMessageTypes.VAPE_CLOUD_SIZE, vapeCloudSize?.heatingProfile);
    gamService.updateDeviceSettings(iotMessageTypes.PARENT_PROTECTION, parentProtection?.protectionType);
    gamService.updateDeviceSettings(iotMessageTypes.LED, ledIntensity?.intensity);
    gamService.updateIotDevice(firmwareVersion, systemStatus?.batteryState, codentify?.codentify);
    await gamService.updateGamActivationStatus(deviceSpecific?.yapActivationStatus);
    return true;
};

const activateDevice = async () => {
    await tryActivateDeactivateDevice(true);
};

const deactivateDevice = async () => {
    await tryActivateDeactivateDevice(false);
};

let activeProcessTimeout = null;
const tryActivateDeactivateDevice = async (isActivate) => {
    const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());
    if (isYapActivationInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapActivationInProgress(true));
    dispatch(setDeviceDataChangeReceived(false));

    try {
        const gamAsset = makeSelectFirstAsset()(getState());
        if (gamAsset) {
            try {
                await syncAndSubscribeOnGamDevice();
                return await activateDeactivateDevice(gamAsset, isActivate);
            } catch (e) {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
            }
        }

        await getGamAssets(true);
        await gamService.checkIsDeviceActivatedInGam();
        dispatch(setYapActivationInProgress(false));
    } catch (e) {
        log.error(`gamClientService: IsActivate: ${isActivate} failed. error: ${e}`);
        dispatch(setYapActivationInProgress(false));
    }
};

const syncAndSubscribeOnGamDevice = async () => {
    const state = getState();
    let gamAsset = makeSelectFirstAsset()(state);
    const assetId = gamAsset?.assetId;
    if (!assetId) throw new Error('Asset is not initialized');

    await gamWebSocketService.initGamWebSocket(assetId);

    await cmClientService.initCmClient(assetId);
};

const activateDeactivateDevice = async (gamAsset, isActivate) => {
    const isConnected = await cmClientService.waitUntilConnected();
    if (isConnected) {
        if (isActivate) {
            log.info('Start activation process.');
        } else {
            log.info('Start deactivation process.');
        }

        const methodName = isActivate ? gamMethods.ACTIVATE_DEVICE : gamMethods.DEACTIVATE_DEVICE;
        const requestConfig = {args: gamAsset.assetId};
        const {requestId} =
            (await gamClient.callPost({methodName, requestConfig, isNeedRedirectForOtherErrors: false}).catch(() => {
                log.error(`gamClientService: IsActivate: ${isActivate} failed. Action error`);
                dispatch(setYapActivationInProgress(false));
            })) || {};

        if (requestId) {
            dispatch(setYapLastRequestId(requestId));

            activeProcessTimeout = setTimeout(async () => {
                const isYapActivationInProgress = makeSelectIsYapActivationInProgress()(getState());
                if (!isYapActivationInProgress) return;

                await getGamAssets(true);
                await gamService.checkIsDeviceActivatedInGam();
                dispatch(setYapActivationInProgress(false));
            }, appConfig.getGamTimeout() * 1000);
        } else {
            dispatch(setYapActivationInProgress(false));
        }
    } else {
        dispatch(setYapActivationInProgress(false));
    }
};

const updateGamSettings = async (body) => {
    const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());
    if (isYapUpdateSettingsInProgress) return;

    clearTimeout(activeProcessTimeout);

    dispatch(setYapUpdateSettingsInProgress(true));
    dispatch(setDeviceDataChangeReceived(false));

    const gamAsset = makeSelectFirstAsset()(getState());
    if (gamAsset) {
        try {
            await syncAndSubscribeOnGamDevice();
            const isConnected = await cmClientService.waitUntilConnected();
            if (isConnected) {
                log.info('Start updateGamSettings process.');

                const {requestId} =
                    (await gamClient.callPost({
                        methodName: gamMethods.UPDATE_SETTINGS,
                        requestConfig: {args: gamAsset?.assetId, data: body},
                        isNeedRedirectForOtherErrors: false,
                    })) || {};

                if (requestId) {
                    dispatch(setYapLastRequestId(requestId));

                    activeProcessTimeout = setTimeout(() => {
                        const isYapUpdateSettingsInProgress = makeSelectIsYapUpdateSettingsInProgress()(getState());
                        if (!isYapUpdateSettingsInProgress) return;

                        dispatch(setYapUpdateSettingsInProgress(false));
                        appErrorService.showDeviceSettingsGlobalError();
                    }, appConfig.getGamUpdateSettingTimeout() * 1000);
                    return;
                }
            } else {
                appErrorService.showDeviceSettingsGlobalError();
            }
        } catch (e) {
            log.error(`gamClientService: updateGamSettings failed. error: ${e}`);
        }
    }

    dispatch(setYapUpdateSettingsInProgress(false));
};

export default {
    initGam,
    getGamAssets,
    connectGamDevice,
    activateDevice,
    deactivateDevice,
    updateGamSettings,
    synchronizeGamDevice,
    getGamDeviceInfo,
};
