import React, {useEffect} from 'react';

import FWUFailedPopup from '../../../components/FWUFailedPopup/FWUFailedPopup';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import analyticsService from '../../../services/analyticsService';

const ActivationFailedPopup = (props) => {
    useEffect(() => analyticsService.pushActivationFailedError(), []);

    return (
        <FWUFailedPopup
            headerKey={localizationKeys.FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TITLE}
            descriptionKey={localizationKeys.FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_MESSAGE}
            okBtnKey={localizationKeys.FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_TRY_AGAIN_BUTTON}
            cancelBtnKey={localizationKeys.FIRMWARE_DOWNLOADING_FAILED_YAP_POPUP_DO_IT_LATER_BUTTON}
            {...props}
        />
    );
};

export default ActivationFailedPopup;
