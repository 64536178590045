import classNames from 'classnames';
import React from 'react';

import Battery from '../../../../components/Device/Battery/Battery';
import DeviceConnectionStatus from '../../../../components/DeviceConnectionStatus/DeviceConnectionStatus';
import appConfig from '../../../../config/appConfig';
import DeviceImage from '../../../DeviceImage/DeviceImage';
import styles from './DeviceSettingsHeader.module.scss';

const DeviceSettingsHeader = (props) => {
    const {background, isDeviceReady, device, holder, isLocked} = props;
    const imgClassName = classNames(styles.ImageContainer, {
        [styles.DeviceNotConnected]: !isDeviceReady,
    });
    const isDeviceSettingAvailable = appConfig.getIsDeviceSettingAvailable();

    return (
        <div
            className={`${styles.HeaderContainer} ${isDeviceSettingAvailable ? '' : styles.SettingsUnavailable}`}
            style={background}
        >
            <div className={styles.HeaderBackground} style={background} />
            <DeviceImage device={device} holder={holder} className={imgClassName} />
            <div className={styles.DeviceHeaderContainer}>
                <h3 className={styles.DeviceName}>{device?.name}</h3>
                <div className={styles.StatusContainer}>
                    <DeviceConnectionStatus
                        className={styles.Status}
                        isDeviceReady={isDeviceReady}
                        isLocked={isLocked}
                    />
                    {isDeviceReady && <Battery className={styles.Battery} percentage={device?.battery_charge} />}
                </div>
            </div>
        </div>
    );
};

export default DeviceSettingsHeader;
