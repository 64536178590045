import React from 'react';

import Button from '../../../../components/Button/Button';
import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import PopupWarning from '../../../../components/Popup/PopupWarning';
import styles from './UpdateFWPopup.module.scss';

const UpdateFWPopupTemplate = ({
    onClose,
    title,
    descriptionPart1,
    descriptionPart2,
    secondaryBtnText,
    secondaryBtnAction,
    primaryBtnText,
    primaryBtnAction,
}) => {
    return (
        <PopupWarning onClose={onClose} className={styles.UpdateFWPopup}>
            <h2 data-testid='UpdateFWPopupTemplateHeader'>{title}</h2>
            <div className={`ica--txt-regular ${styles.UpdateFWPopupDescription}`}>
                {descriptionPart1}
                <b>{descriptionPart2}</b>
            </div>
            <ButtonDark showArrow onClick={secondaryBtnAction}>
                {secondaryBtnText}
            </ButtonDark>
            {primaryBtnText && (
                <Button showUnderline onClick={primaryBtnAction}>
                    {primaryBtnText}
                </Button>
            )}
        </PopupWarning>
    );
};

export default UpdateFWPopupTemplate;
