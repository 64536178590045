import React from 'react';

import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from './PageLoader.module.scss';

const PageLoader = (props) => {
    const {classNameContainer, className} = props;

    return (
        <div
            data-testid={elementsTesIds.LOADER_CONTROL}
            className={`${styles.PageLoaderContainer} ${classNameContainer}`}
        >
            <div className={`${styles.PageLoader} ${className}`}>
                <span />
            </div>
        </div>
    );
};

export default PageLoader;
