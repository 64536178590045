import iconBubbly from '../../../../../../../assets/images/settings/responsive-draw-bubbly-icon.png';
import iconBubbly2 from '../../../../../../../assets/images/settings/responsive-draw-bubbly-icon@2x.png';
import iconBubbly3 from '../../../../../../../assets/images/settings/responsive-draw-bubbly-icon@3x.png';
import iconCrescendo from '../../../../../../../assets/images/settings/responsive-draw-crescendo-icon.png';
import iconCrescendo2 from '../../../../../../../assets/images/settings/responsive-draw-crescendo-icon@2x.png';
import iconCrescendo3 from '../../../../../../../assets/images/settings/responsive-draw-crescendo-icon@3x.png';
import iconSiren from '../../../../../../../assets/images/settings/responsive-draw-siren-icon.png';
import iconSiren2 from '../../../../../../../assets/images/settings/responsive-draw-siren-icon@2x.png';
import iconSiren3 from '../../../../../../../assets/images/settings/responsive-draw-siren-icon@3x.png';
import iconStaccato from '../../../../../../../assets/images/settings/responsive-draw-staccato-icon.png';
import iconStaccato2 from '../../../../../../../assets/images/settings/responsive-draw-staccato-icon@2x.png';
import iconStaccato3 from '../../../../../../../assets/images/settings/responsive-draw-staccato-icon@3x.png';
import iconVivace from '../../../../../../../assets/images/settings/responsive-draw-vivace-icon.png';
import iconVivace2 from '../../../../../../../assets/images/settings/responsive-draw-vivace-icon@2x.png';
import iconVivace3 from '../../../../../../../assets/images/settings/responsive-draw-vivace-icon@3x.png';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import * as responsiveDrawModeTypes from '../../../../../../consts/iot/responsiveDrawModeTypes';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import elementsTesIds from '../../../../../../test/consts/elementsTesIds';

let data;

const getResponsiveDrawSet = () => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = [
            {
                imgSet: [iconVivace, iconVivace2, iconVivace3],
                mode: responsiveDrawModeTypes.VIVACE,
                popupTitle: localizedStrings[localizationKeys.RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY],
                testId: elementsTesIds.RESP_DRAW_POPUP_BTN_VIVACE,
                title: localizedStrings[localizationKeys.RESPONSIVE_FLOW_ALLEGRO_TITLE_TEXT_KEY],
            },
            {
                imgSet: [iconBubbly, iconBubbly2, iconBubbly3],
                mode: responsiveDrawModeTypes.BUBBLY,
                popupTitle: localizedStrings[localizationKeys.RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY],
                testId: elementsTesIds.RESP_DRAW_POPUP_BTN_BUBBLY,
                title: localizedStrings[localizationKeys.RESPONSIVE_FLOW_BUBBLY_TITLE_TEXT_KEY],
            },
            {
                imgSet: [iconCrescendo, iconCrescendo2, iconCrescendo3],
                mode: responsiveDrawModeTypes.CRESCENDO,
                popupTitle: localizedStrings[localizationKeys.RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY],
                testId: elementsTesIds.RESP_DRAW_POPUP_BTN_CRESCENDO,
                title: localizedStrings[localizationKeys.RESPONSIVE_FLOW_CRESCENDO_TITLE_TEXT_KEY],
            },
            {
                imgSet: [iconStaccato, iconStaccato2, iconStaccato3],
                mode: responsiveDrawModeTypes.STACCATO,
                popupTitle: localizedStrings[localizationKeys.RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY],
                testId: elementsTesIds.RESP_DRAW_POPUP_BTN_STACCATO,
                title: localizedStrings[localizationKeys.RESPONSIVE_FLOW_VIVALDI_TITLE_TEXT_KEY],
            },
            {
                imgSet: [iconSiren, iconSiren2, iconSiren3],
                mode: responsiveDrawModeTypes.SIREN,
                popupTitle: localizedStrings[localizationKeys.RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY],
                testId: elementsTesIds.RESP_DRAW_POPUP_BTN_SIREN,
                title: localizedStrings[localizationKeys.RESPONSIVE_FLOW_SIREN_TITLE_TEXT_KEY],
            },
        ];
    }

    return data;
};

export {getResponsiveDrawSet};
