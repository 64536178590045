import {createSelector} from 'reselect';

const selectAuth = (state) => state.auth;

const makeSelectSpiceToken = () => createSelector(selectAuth, (authState) => authState.spiceToken);

const makeSelectAuthUsername = () => createSelector(selectAuth, (authState) => authState.username);

const makeSelectAccessToken = () => createSelector(selectAuth, (authState) => authState.accessToken);

const makeSelectAccessTokenData = () =>
    createSelector(selectAuth, ({accessToken, expirationDate, isAnonymous}) => ({
        accessToken,
        expirationDate,
        isAnonymous,
    }));

const makeSelectRefreshToken = () => createSelector(selectAuth, (authState) => authState.refreshToken);

const makeSelectUamIdentityKey = () => createSelector(selectAuth, (authState) => authState.uamIdentityKey);

const makeSelectUamAuthToken = () => createSelector(selectAuth, (authState) => authState.uamAuthToken);

const makeSelectUamApiKey = () => createSelector(selectAuth, (authState) => authState.uamApiKey);

const makeSelectUamApiUrl = () => createSelector(selectAuth, (authState) => authState.uamApiUrl);

const makeSelectGamAuthToken = () => createSelector(selectAuth, (authState) => authState.gamAuthToken);

export {
    makeSelectAccessToken,
    makeSelectAccessTokenData,
    makeSelectAuthUsername,
    makeSelectRefreshToken,
    makeSelectSpiceToken,
    makeSelectUamApiKey,
    makeSelectUamApiUrl,
    makeSelectUamAuthToken,
    makeSelectUamIdentityKey,
    makeSelectGamAuthToken,
};
