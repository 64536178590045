import React from 'react';

import styles from './SwitchToggle.module.scss';

const SwitchToggle = ({index, onChange, className, isActive}) => {
    const switchToggleClassName = `${styles.SwitchToggle} ${className ? className : ''}`;
    const toggleClassName = `${styles.Toggle} ${isActive ? styles.ToggleActive : ''}`;

    const onSwitchToggleClick = () => {
        if (onChange) {
            onChange(index, !isActive);
        }
    };

    return (
        <div className={switchToggleClassName} onClick={onSwitchToggleClick}>
            <div className={toggleClassName} />
        </div>
    );
};

export default SwitchToggle;
