import classNames from 'classnames';
import React from 'react';

import imgBackButton1 from '../../../assets/images/icons/back-arrow-icon.png';
import imgBackButton2 from '../../../assets/images/icons/back-arrow-icon@2x.png';
import imgBackButton3 from '../../../assets/images/icons/back-arrow-icon@3x.png';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import routerService from '../../services/routerService';
import Button from '../Button/Button';
import Image from '../Image/Image';
import styles from './BackButton.module.scss';

const BackButton = ({className, onClick = routerService.goBack, ...restProps}) => {
    const localizedStrings = getLocalizedStrings();
    const buttonClassName = classNames(styles.Button, className);

    return (
        <Button className={buttonClassName} onClick={onClick} {...restProps}>
            <Image src={[imgBackButton1, imgBackButton2, imgBackButton3]} />
            <span>{localizedStrings && localizedStrings[localizationKeys.GLOBAL_BACK_BUTTON_TEXT]}</span>
        </Button>
    );
};

export default BackButton;
