import * as commonLayoutTypes from '../consts/layout/commonLayoutTypes';

const isDesktopLayout = (layout) => layout === commonLayoutTypes.DESKTOP;

const isMobileLayout = (layout) => layout === commonLayoutTypes.MOBILE;

const isTabletLayout = (layout) => layout === commonLayoutTypes.TABLET;

export default {
    isDesktopLayout,
    isMobileLayout,
    isTabletLayout,
};
