import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import analyticsService from '../../services/analyticsService';
import yapService from '../../services/yap/yapService';
import {
    makeSelectDeviceActivationStatus,
    makeSelectIotDeviceData,
    makeSelectIsDeviceReady,
} from '../../state/selectors/iotDevice';
import {makeSelectIsYapActivationInProgress} from '../../state/selectors/yapEncrypted';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceActivationConnectedPage from './components/DeviceActivationConnectedPage';
import DeviceActivationNotConnectedPage from './components/DeviceActivationNotConnectedPage';
import DeviceUAMActivationPage from './components/DeviceUAMActivationPage';
import styles from './DeviceActivationPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    isDeviceReady: makeSelectIsDeviceReady(),
    isYapActivationInProgress: makeSelectIsYapActivationInProgress(),
    isDeviceActivated: makeSelectDeviceActivationStatus(),
});

const DeviceActivationPage = (props) => {
    const {iotDevice, isDeviceReady, isYapActivationInProgress, isDeviceActivated} = props;

    useEffect(() => {
        analyticsService.pushActivationDeviceActivatingEvent();
    }, []);

    if (!iotDevice) {
        return null;
    }

    return (
        <section className={styles.Page} data-testid={elementsTesIds.PAIRING_SEQUENCE_ACTIVATION_PAGE}>
            {!isDeviceReady && !isYapActivationInProgress && !isDeviceActivated && (
                <DeviceActivationNotConnectedPage iotDevice={iotDevice} />
            )}
            {isDeviceReady && yapService.isYapParentProtectionMode() && <DeviceActivationConnectedPage />}
            {(isDeviceReady || isYapActivationInProgress || isDeviceActivated) && yapService.isYapEncryptedMode() && (
                <DeviceUAMActivationPage />
            )}
        </section>
    );
};

export default connect(mapStateToProps)(DeviceActivationPage);
