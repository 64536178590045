import classnames from 'classnames';
import React from 'react';

import AppImage from '../AppImage';
import styles from './ProgressiveImage.module.css';

const ProgressiveImage = ({srcSet, placeholder, imgClassName}) => {
    const loader = placeholder ? (
        <AppImage className={classnames(imgClassName, styles.Blured)} src={placeholder} />
    ) : undefined;

    return <AppImage className={imgClassName} src={srcSet} loader={loader} />;
};

export default ProgressiveImage;
