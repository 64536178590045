import React, {useEffect, useState} from 'react';

import ButtonDark from '../../../../components/Button/ButtonDark/ButtonDark';
import Container from '../../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import tncService from '../../../../services/tncService';
import {hideLoader, showLoader} from '../../../../state/ducks/global';
import {dispatch} from '../../../../state/store';
import DCExpansionPanel from './DCExpansionPanel/DCExpansionPanel';
import styles from './PreferenceCenter.module.scss';

const PreferenceCenter = (props) => {
    const [acceptedSettingList, setAcceptedSettingList] = useState([]);
    const {acceptedLegals, legals, onApproved} = props;

    const acceptedLegalsWithSettings = acceptedLegals.filter((al) => al.settings != null);

    useEffect(() => {
        setAcceptedSettingList(acceptedLegalsWithSettings.filter((legal) => !legal.isRevoked).map(({code}) => code));
    }, [acceptedLegals]);

    const legalsSettingsNotNull = [...legals.filter((l) => l.settings != null), ...acceptedLegalsWithSettings];

    const onSettingLegalChange = (code, isAccepted) => {
        if (isAccepted) {
            setAcceptedSettingList([...acceptedSettingList, code]);
        } else {
            const isCodeInList = acceptedSettingList.indexOf(code) !== -1;

            if (isCodeInList) {
                const filteredList = acceptedSettingList.filter((el) => el !== code);

                setAcceptedSettingList(filteredList);
            }
        }
    };

    const onSaveButtonClick = async () => {
        dispatch(showLoader());

        await tncService.setPreferenceCenterSettings(legals, legalsSettingsNotNull, acceptedSettingList, onApproved);

        dispatch(hideLoader());
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <section className={styles.Wrapper}>
            <Container className={styles.Container}>
                <section className={`${styles.PaddingSection} padding--content`}>
                    <h2 className={styles.Title}>{localizedStrings[localizationKeys.PREFERENCE_CENTER_TITLE_TEXT]}</h2>
                    <p
                        className={`${styles.Description} ica--txt-regular`}
                        dangerouslySetInnerHTML={{
                            __html: localizedStrings[localizationKeys.PREFERENCE_CENTER_DESCRIPTION_TEXT],
                        }}
                    />
                    <div className={styles.SettingList}>
                        {legalsSettingsNotNull.map((element) => {
                            const {code, title, text} = element;
                            const isAccepted = acceptedSettingList.includes(code);
                            return (
                                <div key={code}>
                                    <DCExpansionPanel
                                        index={code}
                                        title={title}
                                        innerText={text}
                                        onChange={onSettingLegalChange}
                                        isActive={isAccepted}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <ButtonDark className={styles.ContinueBtn} showArrow onClick={onSaveButtonClick}>
                        {localizedStrings[localizationKeys.PREFERENCE_CENTER_SAVE_AND_CONTINUE_BUTTON_TEXT]}
                    </ButtonDark>
                </section>
            </Container>
        </section>
    );
};

export default PreferenceCenter;
