import classNames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import BackButton from '../../../components/BackButton/BackButton';
import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import Container from '../../../components/Layout/Container/Container';
import Video from '../../../components/Video/Video';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as routeParams from '../../../consts/route/routeParams';
import appRouterService from '../../../services/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import {makeSelectLayoutType} from '../../../state/selectors/global';
import {makeSelectIotDeviceData} from '../../../state/selectors/iotDevice';
import tutorialPageService from '../tutorialPageService';
import styles from './TutorialIntroPage.module.scss';

const mapDispatchToProps = createStructuredSelector({
    iotDevice: makeSelectIotDeviceData(),
    layout: makeSelectLayoutType(),
});

const TutorialIntroPage = (props) => {
    const {
        iotDevice,
        match: {params},
    } = props;
    const {device} = iotDevice;
    const deviceType = device?.type;
    const tutorialType = params[routeParams.TUTORIAL_TYPE];
    const content = tutorialPageService.getTutorialIntroContent(deviceType, tutorialType);
    const {video, header, description, button} = content || {};

    const subtitleClassName = classNames(styles.Subtitle, 'ica--txt-regular');

    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        appRouterService.forwardToTutorialPage(tutorialType);
    };

    return (
        <section className={styles.Page}>
            <Container isFloatHeight>
                <BackButton onClick={appRouterService.forwardToTutorialList} />
            </Container>
            <Container className={styles.Container}>
                <h2 className={styles.Header}>{localizedStrings[localizationKeys[header]]}</h2>
                <div className={subtitleClassName}>{localizedStrings[localizationKeys[description]]}</div>
                <Video className={styles.Video} src={video} playsinline loop autoplay muted />
                <ButtonDark showArrow onClick={onClick} className={styles.Button}>
                    {localizedStrings[localizationKeys[button]]}
                </ButtonDark>
            </Container>
        </section>
    );
};

export default connect(mapDispatchToProps)(TutorialIntroPage);
