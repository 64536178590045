export const ACCESS_TOKEN = 'a_t';
export const ACCESS_TOKEN_EXPIRATION = 'a_t_e';
export const APP_INSTANCE_ID = 'a_i';
export const COMPATIBLE_PAGE = 'c_p';
export const DEVICE_DATA = 'd_d';
export const LANGUAGE_CODE = 'l_c';
export const DEVICE_CODENTIFY_FACTORY = 'd_c_f';
export const MARKET_CODE = 'm_c';
export const REFRESH_TOKEN = 'r_t';
export const SPICE_TOKEN = 's_t';
export const USER_NAME = 'u_n';
export const GAM_GIGYA_ACCOUNT_DATA = '__coresiteAccount';
