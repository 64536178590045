import classNames from 'classnames';
import React from 'react';

import * as localizationKeys from '../../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../../services/localization/localizationService';
import userDeviceService from '../../../../services/userDeviceService';
import styles from '../AppIntroPage.module.scss';

const TXT_REGULAR_CLASSNAME = 'ica--txt-regular';

const AppIntroPageConnectDescription = () => {
    const localizedStrings = getLocalizedStrings();
    const isBluetoothSupported = userDeviceService.isBluetoothSupported();

    const descriptionClassName = classNames(styles.Description, TXT_REGULAR_CLASSNAME, {
        [styles.BTNotSupportedDescription]: !isBluetoothSupported,
    });

    return (
        <p className={descriptionClassName}>
            {localizedStrings[localizationKeys.COMPATIBLE_BROWSER_PAGE_CONNECT_DESCRIPTION_TEXT]}
        </p>
    );
};

export default AppIntroPageConnectDescription;
