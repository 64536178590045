import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import imgIconInfo1 from '../../../assets/images/icons/info-icon-yellow.png';
import imgIconInfo2 from '../../../assets/images/icons/info-icon-yellow@2x.png';
import imgIconInfo3 from '../../../assets/images/icons/info-icon-yellow@3x.png';
import Button from '../../components/Button/Button';
import ButtonDark from '../../components/Button/ButtonDark/ButtonDark';
import Image from '../../components/Image/Image';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketTypeService from '../../services/marketTypeService';
import routerService from '../../services/routerService';
import userDeviceService from '../../services/userDeviceService';
import {makeSelectFirstAsset} from '../../state/selectors/yapEncrypted';
import elementsTesIds from '../../test/consts/elementsTesIds';
import styles from './PairingAlertPage.module.scss';

const mapStateToProps = createStructuredSelector({
    gamAsset: makeSelectFirstAsset(),
});

const PairingAlertPage = ({gamAsset}) => {
    const isIosLessThan_13_5 = userDeviceService.isIosLessThan_13_5();
    const isDCE2 = marketTypeService.isDCE2();

    const onAcceptBtnClick = () => {
        analyticsService.pushPrePairingPairDeviceEvent();
        if (isDCE2) {
            const {type} = gamAsset || {};

            appRouterService.forwardToPairingGuidePage(type);
        } else {
            appRouterService.forwardToSelectDevice();
        }
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <div className={styles.Page}>
            <Container className={styles.Container}>
                <Image src={[imgIconInfo1, imgIconInfo2, imgIconInfo3]} className={styles.Icon} />
                <h2 className={styles.Title}>{localizedStrings[localizationKeys.BEFORE_PAIRING_TITLE_TEXT]}</h2>
                {isIosLessThan_13_5 && (
                    <>
                        <div
                            className={`${styles.Description} ica--txt-regular`}
                            dangerouslySetInnerHTML={{
                                __html: localizedStrings[localizationKeys.BEFORE_PAIRING_NOTIFICATION_TEXT],
                            }}
                        />
                        <hr />
                    </>
                )}
                <div
                    className={`${styles.Description} ica--txt-regular`}
                    dangerouslySetInnerHTML={{
                        __html: localizedStrings[localizationKeys.BEFORE_PAIRING_TEXT],
                    }}
                />
                <ButtonDark
                    testId={elementsTesIds.DEVICE_LIST_BTN_PAIRING_IFO_POPUP_PAIR_DEVICE}
                    showArrow
                    onClick={onAcceptBtnClick}
                >
                    {localizedStrings[localizationKeys.BEFORE_PAIRING_ACCEPT_BUTTON_TEXT]}
                </ButtonDark>
                <Button onClick={() => routerService.goBack()} showUnderline className={styles.ButtonSecondary}>
                    {localizedStrings[localizationKeys.BEFORE_PAIRING_CANCEL_BUTTON_TEXT]}
                </Button>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(PairingAlertPage);
