import ConfigClient from './configClient';
import * as marketNames from './marketNames';

let marketConfigsList;

if (process.env.REACT_APP_IS_BUILD_REF === 'true') {
    marketConfigsList = [
        {
            urls: [
                `https://local.pmi.com/`,
                `https://fieldcoach.qa-pmidce2.com/`,
                `https://www.origin.dev.iqos.com/fr`,
                `https://www.dev.iqos.com/fr`,
                `https://www.dev.veev-vape.com/fr/`,
            ],
            configImport: () => import(/* webpackChunkName: "lazy-chunk.16" */ '../marketConfigs/fr/stg-ref'),
        },
    ];
} else {
    const pnnEnvUrl = 'https://iqos-connect-stg.pnnproducts.com';
    const pnnEnvUrlPublic = 'https://public.iqos-connect-stg.pnnproducts.com';
    const envUrl = 'https://iqos-connect-stg-web.iot.dev-pmidce.com';

    marketConfigsList = [
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.RO}`,
                `${pnnEnvUrlPublic}/${marketNames.RO}`,
                `${envUrl}/${marketNames.RO}`,
            ],
            configImport: () => import('../marketConfigs/ro/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.NZ}`,
                `${pnnEnvUrlPublic}/${marketNames.NZ}`,
                `${envUrl}/${marketNames.NZ}`,
            ],
            configImport: () => import('../marketConfigs/nz/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CZ}`,
                `${pnnEnvUrlPublic}/${marketNames.CZ}`,
                `${envUrl}/${marketNames.CZ}`,
            ],
            configImport: () => import('../marketConfigs/cz/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.FR}`,
                `${pnnEnvUrlPublic}/${marketNames.FR}`,
                `${envUrl}/${marketNames.FR}`,
            ],
            configImport: () => import('../marketConfigs/fr/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.IT}`,
                `${pnnEnvUrlPublic}/${marketNames.IT}`,
                `${envUrl}/${marketNames.IT}`,
            ],
            configImport: () => import('../marketConfigs/it/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.GR}`,
                `${pnnEnvUrlPublic}/${marketNames.GR}`,
                `${envUrl}/${marketNames.GR}`,
            ],
            configImport: () => import('../marketConfigs/gr/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.UK}`,
                `${pnnEnvUrlPublic}/${marketNames.UK}`,
                `${envUrl}/${marketNames.UK}`,
            ],
            configImport: () => import('../marketConfigs/uk/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.CH}`,
                `${pnnEnvUrlPublic}/${marketNames.CH}`,
                `${envUrl}/${marketNames.CH}`,
            ],
            configImport: () => import('../marketConfigs/ch/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.PMI}`,
                `${pnnEnvUrlPublic}/${marketNames.PMI}`,
                `${envUrl}/${marketNames.PMI}`,
            ],
            configImport: () => import('../marketConfigs/pmi/stg-web'),
        },
        {
            urls: [
                `${pnnEnvUrl}/${marketNames.UAT}`,
                `${pnnEnvUrlPublic}/${marketNames.UAT}`,
                `${envUrl}/${marketNames.UAT}`,
            ],
            configImport: () => import('../marketConfigs/uat/stg-web'),
        },
    ];
}

export default class ConfigClientStg extends ConfigClient {
    constructor() {
        super(marketConfigsList);
    }
}
