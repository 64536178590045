import React from 'react';

import * as localizationKeys from '../../consts/app/localizationKeys';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import Button from '../Button/Button';
import ButtonDark from '../Button/ButtonDark/ButtonDark';
import PopupWarning from '../Popup/PopupWarning';
import styles from './ActivationSkipBtn.module.scss';

const ActivationSkipPopup = ({onClose}) => {
    const localizedStrings = getLocalizedStrings();

    return (
        <PopupWarning onClose={onClose}>
            <h2 className={styles.SkipPopupHeader}>
                {localizedStrings[localizationKeys.CANCEL_YAP_PROGRESS_POPUP_TITLE]}
            </h2>
            <p className={`ica--txt-regular ${styles.SkipPopupDescription}`}>
                {localizedStrings[localizationKeys.CANCEL_YAP_PROGRESS_POPUP_MESSAGE]}
            </p>
            <ButtonDark showArrow onClick={onClose} className={styles.Button}>
                {localizedStrings[localizationKeys.CANCEL_YAP_PROGRESS_POPUP_CONTINUE_BUTTON]}
            </ButtonDark>
            <Button showUnderline onClick={() => appRouterService.forwardToMyDevicesPage()} showArrow>
                {localizedStrings[localizationKeys.CANCEL_YAP_PROGRESS_POPUP_CANCEL_BUTTON]}
            </Button>
        </PopupWarning>
    );
};

export default ActivationSkipPopup;
