export const NZ = 'nz';
export const PMI = 'pmi';
export const RO = 'ro';
export const UAT = 'uat';
export const CZ = 'cz';
export const CH = 'ch';
export const FR = 'fr';
export const IT = 'it';
export const UK = 'uk';
export const GR = 'gr';
