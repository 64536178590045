import React from 'react';

import imgIconTipsCharger1 from '../../../../../../assets/images/settings/iqos-charger-icon.png';
import imgIconTipsCharger2 from '../../../../../../assets/images/settings/iqos-charger-icon@2x.png';
import imgIconTipsCharger3 from '../../../../../../assets/images/settings/iqos-charger-icon@3x.png';
import imgIconTipsHolder1 from '../../../../../../assets/images/settings/iqos-holder-icon.png';
import imgIconTipsHolder2 from '../../../../../../assets/images/settings/iqos-holder-icon@2x.png';
import imgIconTipsHolder3 from '../../../../../../assets/images/settings/iqos-holder-icon@3x.png';
import Image from '../../../../../components/Image/Image';
import styles from '../SettingsTable.module.scss';

const SettingsTableChargerHolderIcon = () => {
    const iconSets = {
        charger: [imgIconTipsCharger1, imgIconTipsCharger2, imgIconTipsCharger3],
        holder: [imgIconTipsHolder1, imgIconTipsHolder2, imgIconTipsHolder3],
    };

    return (
        <div className={styles.SettingsTableChargerHolderIcon}>
            <Image src={iconSets.charger} />
            <Image src={iconSets.holder} />
        </div>
    );
};

export default SettingsTableChargerHolderIcon;
