import {createSelector} from 'reselect';

const selectGlobal = (state) => state.global;

const makeIsAppLoading = () => createSelector(selectGlobal, (globalState) => globalState.isAppLoading);

const makeLoaders = () => createSelector(selectGlobal, (globalState) => globalState.loaders);

const makeSelectIsLoaderVisible = (loaderName) =>
    createSelector(makeLoaders(), (loaders) => {
        const loader = loaders?.filter(({name}) => name === loaderName);

        return loader?.length;
    });

const makeNotifications = () => createSelector(selectGlobal, (globalState) => globalState.notifications);

const makeSelectLayoutType = () => createSelector(selectGlobal, (globalState) => globalState.layout);

const makeSelectIsNetworkOnline = () => createSelector(selectGlobal, (globalState) => globalState.isNetworkOnline);

export {
    makeIsAppLoading,
    makeLoaders,
    makeNotifications,
    makeSelectIsLoaderVisible,
    makeSelectLayoutType,
    makeSelectIsNetworkOnline,
};
