import helpers from '../../../utils/helpers';
import useDidUpdate from '../useDidUpdate';

const useLostDeviceConnection = (isDisconnected, handler) => {
    useDidUpdate(() => {
        if (isDisconnected) {
            helpers.runFunction(handler);
        }
    }, [isDisconnected]);
};

export default useLostDeviceConnection;
