import appConfig from '../../config/appConfig';
import {getTipsAndTricksPageData} from './tipsAndTricksPageData';

const getAllowedTATScreens = (isMobileLayout) => {
    const tipsAndTricksAllowedSteps = appConfig.getTipsAndTricksAllowedSteps();
    let allowedScreens = [];

    if (tipsAndTricksAllowedSteps?.length) {
        const pageData = getTipsAndTricksPageData(isMobileLayout);

        allowedScreens = pageData.filter((item, index) => tipsAndTricksAllowedSteps[index]);
    }

    return allowedScreens;
};

export default {
    getAllowedTATScreens,
};
