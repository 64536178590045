const getVariableName = (variableObj) => Object.keys(variableObj)[0];

const getIdentificatorObject = (variableObj) => {
    const identificatorName = getVariableName(variableObj);
    const identificatorValue = variableObj[identificatorName];

    return {
        identificatorName,
        identificatorValue,
    };
};

const getProductIdentificator = (product) => {
    const {codentify, deviceSerialNumber, isP4} = product;
    const identificatorObject = isP4
        ? getIdentificatorObject({codentify})
        : getIdentificatorObject({deviceSerialNumber});

    return identificatorObject;
};

export default {
    getProductIdentificator,
};
