import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import Button from '../../components/Button/Button';
import ButtonWhite from '../../components/Button/ButtonWhite/ButtonWhite';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import analyticsService from '../../services/analyticsService';
import appRouterService from '../../services/appRouterService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceImage from '../DeviceImage/DeviceImage';
import styles from './ActivationSuccessPage.module.scss';

const mapStateToProps = createStructuredSelector({
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
});

// TODO: remove

const ActivationSuccessPage = ({iotProduct}) => {
    const {device} = iotProduct;
    const {name} = device;
    const localizedStrings = getLocalizedStrings();
    const header = localizedStrings.formatString(
        localizedStrings[localizationKeys.YAP_ACTIVATION_STATE_ACTIVATED_TEXT],
        name
    );

    useEffect(() => {
        analyticsService.pushActivationDeviceActivatedSuccessEvent();
    }, []);

    const onTutorialButtonClick = () => appRouterService.forwardToQuickStartGuidePage();

    return (
        <section className={styles.Page}>
            <h2 className={styles.Header} data-testid={elementsTesIds.ACTIVATION_FLOW_SUCCESSFULLY_ACTIVATED_TITLE}>
                {header}
            </h2>
            <p className={`${styles.Description} ica--txt-regular`}>
                {localizedStrings[localizationKeys.YAP_ACTIVATION_STATE_ACTIVATED_DESCRIPTION_TEXT]}
            </p>
            <DeviceImage device={device} className={styles.ImageContainer} />
            <ButtonWhite showArrow onClick={onTutorialButtonClick}>
                {localizedStrings[localizationKeys.YAP_ACTIVATION_STATE_ACTIVATED_ACTION_BUTTON_TEXT]}
            </ButtonWhite>
            <Button
                className={styles.GoToDeviceBtn}
                showArrow
                showUnderline
                testId={elementsTesIds.ACTIVATION_FLOW_SETTINGS_BTN}
                to={routePaths.DEVICE_SETTINGS}
            >
                {localizedStrings[localizationKeys.YAP_GO_TO_DEVICES_TEXT]}
            </Button>
        </section>
    );
};

export default connect(mapStateToProps)(ActivationSuccessPage);
