import classnames from 'classnames';
import PCancelable from 'p-cancelable';
import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import FloatButton from '../../components/Button/FloatButton/FloatButton';
import Container from '../../components/Layout/Container/Container';
import CommonLoaderScreen from '../../components/Loader/CommonLoaderScreen';
import SkipButton from '../../components/SkipButton/SkipButton';
import {dataConfig} from '../../config/dataConfig/dataConfig';
import * as localizationKeys from '../../consts/app/localizationKeys';
import bleConnectionType from '../../consts/ble/bleConnectionType';
import PAIRING_ERRORS from '../../consts/device/pairingErrors';
import {DEVICE_NOT_SUPPORTED_ERROR} from '../../consts/iot/iotMessageErrorCodes';
import * as routeParams from '../../consts/route/routeParams';
import useDeviceDisconnected from '../../hooks/effects/connection/useDeviceDisconnected';
import useHttpRegistrationErrorHandler from '../../hooks/effects/httpErrorsHandlers/useHttpRegistrationErrorHandler';
import useDidUpdate from '../../hooks/effects/useDidUpdate';
import useErrorHandler from '../../hooks/effects/useErrorHandler';
import useGamErrorHandler from '../../hooks/effects/useGamErrorHandler';
import analyticsService from '../../services/analyticsService';
import appErrorService from '../../services/app/appErrorService';
import backendService from '../../services/app/backendService';
import appRouterService from '../../services/appRouterService';
import IqosServiceClient from '../../services/device/iqosServiceClient';
import GetPermittedBuetoothDeviceError from '../../services/errors/getPermittedBuetoothDeviceError';
import gamClientService from '../../services/gam/gamClientService';
import gamService from '../../services/gam/gamService';
import deviceConfigService from '../../services/iotDevice/iotDeviceConfigService';
import iotMessageService from '../../services/iotMessageService';
import layoutService from '../../services/layoutService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import log from '../../services/logger/log';
import pairingService from '../../services/pairingService';
import productService from '../../services/productService';
import iotService from '../../services/scpCloud/iotService';
import {hideLoader, showLoader} from '../../state/ducks/global';
import {makeSelectIotDeviceMergedWithIccProduct} from '../../state/selectors/consumer';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {
    makeSelectIotMessage,
    makeSelectIsDeviceDisconnected,
    makeSelectIsDeviceReady,
} from '../../state/selectors/iotDevice';
import elementsTesIds from '../../test/consts/elementsTesIds';
import RegistrationFailedPopup from '../DeviceRegistrationPage/components/RegistrationFailedPopup';
import PairingSkipPopup from '../PairingSkipPopup/PairingSkipPopup';
import PairingGuideCarousel from './components/PairingGuideCarousel/PairingGuideCarousel';
import PairingGuideErrorPopup from './components/PairingGuideErrorPopup/PairingGuideErrorPopup';
import PairingGuideVideo from './components/PairingGuideVideo';
import styles from './PairingGuidePage.module.scss';
import pairingGuidePageService from './pairingGuidePageService';

const VIDEO_PRELOAD_LOADER_NAME = 'VIDEO_PRELOADING_LOADER';

const mapStateToProps = createStructuredSelector({
    iotMessage: makeSelectIotMessage(),
    iotProduct: makeSelectIotDeviceMergedWithIccProduct(),
    isDeviceDisconnected: makeSelectIsDeviceDisconnected(),
    isDeviceReady: makeSelectIsDeviceReady(),
    layout: makeSelectLayoutType(),
});

const PairingGuidePage = (props) => {
    const {
        dispatch,
        iotMessage,
        iotProduct,
        isDeviceDisconnected,
        isDeviceReady,
        layout,
        match: {params},
    } = props;
    const selectedDeviceType = params[routeParams.DEVICE_TYPE];
    const isNewDevice = params[routeParams.BLE_CONNECTION_TYPE] === bleConnectionType.NEW;
    const deviceTypeData = dataConfig.pairingGuideData[selectedDeviceType];

    if (!deviceTypeData) appRouterService.forwardToMyDevicesPage();

    const slider = useRef();
    const [index, setIndex] = useState(0);
    const [isVisiblePairingLoader, setIsVisiblePairingLoader] = useState(false);
    const [isVisiblePairingSkipPopup, setIsVisiblePairingSkipPopup] = useState(false);
    const [error, setError] = useState(null);
    const errorRef = useRef(error);
    const deviceActivationPromise = useRef();
    errorRef.current = error;

    const {content: deviceTypeContent, video: deviceTypeVideo} = deviceTypeData;

    const isParentProtectionMode = deviceConfigService.isParentProtectionMode(iotProduct);
    const isConnectionFlowFinished = isParentProtectionMode
        ? iotMessageService.isParentModeFlowFinished(iotMessage)
        : iotMessageService.isDeviceInfoFinished(iotMessage);
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const [isNextButtonVisible, setIsNextButtonVisible] = useState(true);
    const [isSliderArrowsVisible, setIsSliderArrowsVisible] = useState(true);
    const localizedStrings = getLocalizedStrings();
    let pairingSuccessTimeout = useRef(0);
    const [isVisibleRegistrationFailedPopup, setIsVisibleRegistrationFailedPopup] = useState(false);
    const [httpErrorType, setHttpErrorType] = useState(null);
    const clearPairingSuccessTimeout = () => clearTimeout(pairingSuccessTimeout.current);
    const isGamBackend = backendService.isGamBackend();
    const iqosService = new IqosServiceClient();

    useEffect(() => {
        dispatch(showLoader({name: VIDEO_PRELOAD_LOADER_NAME}));
        analyticsService.pushPairingTurnOnDeviceEvent();

        if (!isDeviceDisconnected) {
            pairingService.disconnectAndClearState();
        }

        return () => {
            deviceActivationPromise.current?.cancel();
            clearPairingSuccessTimeout();
            iqosService.unmount();
        };
    }, []);

    const onVideoLoad = () => {
        dispatch(hideLoader(VIDEO_PRELOAD_LOADER_NAME));
    };

    const isPairingSuccess = isGamBackend
        ? isDeviceReady && !!iotProduct.device
        : isConnectionFlowFinished && !isVisiblePairingSkipPopup;

    useDidUpdate(async () => {
        if (isPairingSuccess) {
            const lastSlideIndex = deviceTypeContent?.length - 1;
            const {videoStartTime, videoStopTime} = deviceTypeContent[lastSlideIndex];
            const redirectTimeout = (videoStopTime - videoStartTime) * 1000 + 1000;

            const onPairingFinished = () => {
                setIsVisiblePairingLoader(false);

                if (!isVisiblePairingSkipPopup) {
                    const pairingFinishedStepIndex = pairingGuidePageService.getPairingFinishedStepIndex(
                        selectedDeviceType
                    );

                    slider.current.slide(pairingFinishedStepIndex);
                }
            };

            analyticsService.pushPairingSuccessfulEvent();

            onPairingFinished();

            try {
                deviceActivationPromise.current = new PCancelable(async (resolve) => {
                    await productService.checkDeviceActivatedAndRegisterIfNeeded();
                    resolve();
                });
                await deviceActivationPromise.current;
                pairingSuccessTimeout.current = setTimeout(() => {
                    paringSuccessTimeoutAction();
                }, redirectTimeout);
            } catch (e) {
                if (!deviceActivationPromise.current.isCanceled) {
                    appErrorService.showGlobalErrorWithAppReset();
                }
            }
        }
    }, [isPairingSuccess]);

    const paringSuccessTimeoutAction = () => {
        if (!errorRef.current) {
            const pairedDeviceType = iotProduct?.device?.type;

            pairingGuidePageService.makeFinishRedirect(pairedDeviceType);
        }
    };

    useHttpRegistrationErrorHandler({
        handler: (errorType) => {
            setHttpErrorType(errorType);
            clearPairingSuccessTimeout();
            setIsVisibleRegistrationFailedPopup(true);
        },
    });

    useDeviceDisconnected(iotMessage, () => {
        pairingService.onPairingError();
        setError(PAIRING_ERRORS.ERROR_PAIRING);
    });

    useErrorHandler({
        iotErrorCodes: DEVICE_NOT_SUPPORTED_ERROR,
        handler: () => {
            pairingService.onPairingError();
            setError(PAIRING_ERRORS.ERROR_NOT_SUPPORTED_DEVICE);
        },
    });

    useGamErrorHandler({
        handler: () => {
            pairingService.onPairingError();
            setError(PAIRING_ERRORS.ERROR_PAIRING);
        },
    });

    const onSlide = (index) => {
        const currentIndex = Number.parseInt(index);
        const connectionStep = pairingGuidePageService.getConnectionStepIndex(selectedDeviceType);

        if (connectionStep === currentIndex) {
            const pairDevice = async () => {
                analyticsService.pushPairingBluetoothEnabledEvent();
                setIsNextButtonVisible(false);
                setIsSliderArrowsVisible(false);

                try {
                    const onError = (e) => {
                        const isInfoError = e instanceof GetPermittedBuetoothDeviceError;
                        const logMethod = isInfoError ? log.info : log.error;

                        logMethod(`PairingGuidePage, handlePairing error: ${e}`);

                        pairingService.disconnectAndClearState();
                        setIsVisiblePairingLoader(false);
                        const pairingError = PAIRING_ERRORS[e?.code] || PAIRING_ERRORS.ERROR_PAIRING;

                        setError(pairingError);
                    };

                    const onConnect = async () => {
                        if (slider?.current?.slide) {
                            const pairingStep = pairingGuidePageService.getPairingStepIndex(selectedDeviceType);
                            slider.current.slide(pairingStep);
                        }

                        analyticsService.pushPairingDevicePairingEvent();

                        const isDeviceConnected = iqosService.isDeviceConnected();

                        try {
                            if (isDeviceConnected) {
                                setTimeout(() => {
                                    setIsVisiblePairingLoader(true);
                                }, 1800);

                                if (isGamBackend) {
                                    await gamService.initGamData();
                                    await gamClientService.synchronizeGamDevice();
                                } else {
                                    await iotService.initIoT();
                                }
                            }
                        } catch (e) {
                            onError(e);
                        }
                    };

                    await iqosService.connect({
                        isNewDevice,
                        onConnect,
                        onError,
                    });
                } catch (e) {
                    pairingService.disconnectAndClearState();
                    setError(PAIRING_ERRORS.ERROR_CONNECTION_NOT_FOUND);
                }
            };

            pairDevice();
        }

        setIndex(currentIndex);

        if (currentIndex < connectionStep) {
            if (error) {
                setError(null);
                setIsVisiblePairingSkipPopup(false);
            }
        }
    };

    const onPairingSkipBtnClick = () => {
        setIsVisiblePairingSkipPopup(true);
        clearPairingSuccessTimeout();

        if (iqosService.isDeviceConnected()) {
            pairingService.disconnectAndClearState();
        }
    };

    const onSkipPopupTryAgainBtnClick = () => {
        appRouterService.forwardToSelectDevice();
    };

    const onSkipPopupSkipBtnClick = () => {
        pairingService.disconnectAndForwardToMyDevicesPage(isDeviceDisconnected);

        setIsVisiblePairingSkipPopup(false);
    };

    const onCloseRegistrationFailedPopup = () => {
        setIsVisibleRegistrationFailedPopup(true);
        paringSuccessTimeoutAction();
    };

    const onNextButtonClick = () => slider.current.next();

    const pageClassName = classnames(styles.Page, 'page');

    const pageTestId = isVisiblePairingLoader ? elementsTesIds.PAIRING_SEQUENCE_PROGRESS_PAGE : null;

    return (
        <>
            <section className={pageClassName} data-testid={pageTestId}>
                <Container isFloatHeight className={styles.HeaderContainer}>
                    <SkipButton
                        isLeft
                        onClick={onPairingSkipBtnClick}
                        testId={elementsTesIds.PAIRING_SEQUENCE_BTN_BACK}
                    />
                    {isNextButtonVisible && !isDesktopLayout && (
                        <FloatButton onClick={onNextButtonClick} testId={elementsTesIds.PAIRING_SEQUENCE_BTN_NEXT}>
                            {localizedStrings[localizationKeys.QUICK_START_GUIDE_NEXT_BUTTON_TEXT]}
                        </FloatButton>
                    )}
                </Container>
                <PairingGuideCarousel
                    arrowNextTestId={elementsTesIds.PAIRING_SEQUENCE_BTN_NEXT}
                    content={deviceTypeContent}
                    isArrowsVisible={isSliderArrowsVisible}
                    isNextButtonVisible={isNextButtonVisible}
                    layout={layout}
                    onSlide={onSlide}
                    ref={slider}
                />
                <PairingGuideVideo
                    content={deviceTypeContent}
                    index={index}
                    isPreloadEnabled
                    isVideoPaused={isVisiblePairingSkipPopup}
                    onLoad={onVideoLoad}
                    onLoadError={onVideoLoad}
                    src={deviceTypeVideo}
                />
                {isVisiblePairingLoader && <CommonLoaderScreen isBig />}
            </section>
            {error && (
                <PairingGuideErrorPopup
                    deviceType={selectedDeviceType}
                    error={error}
                    setError={setError}
                    setIsVisiblePairingSkipPopup={setIsVisiblePairingSkipPopup}
                />
            )}
            {isVisiblePairingSkipPopup && (
                <PairingSkipPopup onTryAgain={onSkipPopupTryAgainBtnClick} onSkip={onSkipPopupSkipBtnClick} />
            )}
            {isVisibleRegistrationFailedPopup && (
                <RegistrationFailedPopup httpErrorType={httpErrorType} onClose={onCloseRegistrationFailedPopup} />
            )}
        </>
    );
};

export default connect(mapStateToProps)(PairingGuidePage);
