const getMappedData = (mapping, data) => {
    const mappingCopy = mapping;

    let index = 0;

    for (const [key] of Object.entries(mappingCopy)) {
        mapping[key] = data[index];

        index++;
    }

    return mappingCopy;
};

export default {
    getMappedData,
};
