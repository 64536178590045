import {createSelector} from 'reselect';

import marketConfigService from '../../services/marketConfigService';
import {
    makeSelectConsumerProducts,
    makeSelectConsumerProductsMergedInfo,
    makeSelectIotDeviceMergedWithIccProduct,
} from './consumer';
import {makeSelectDeviceConnectionStatus} from './iotDevice';

const makeSelectConsumerProductByIotProduct = () =>
    createSelector(
        makeSelectConsumerProducts(),
        makeSelectIotDeviceMergedWithIccProduct(),
        (consumerProducts, iotProduct) => {
            return consumerProducts?.find((product) => product.deviceSerialNumber === iotProduct.deviceSerialNumber);
        }
    );

const makeSelectCommonProducts = () =>
    createSelector(
        makeSelectConsumerProductsMergedInfo(),
        makeSelectConsumerProductByIotProduct(),
        makeSelectDeviceConnectionStatus(),
        makeSelectIotDeviceMergedWithIccProduct(),
        (consumerProducts, consumerProductByIotProduct, deviceConnectionStatus, iotProduct) => {
            if (consumerProducts && iotProduct.device) {
                const {
                    device,
                    holder,
                    deviceSerialNumber,
                    device_management_features_config,
                    device_global_features_config,
                } = iotProduct;
                const {name, type} = device;
                const status = consumerProductByIotProduct?.status;
                const isDeviceSupported = marketConfigService.isDeviceSupported(type);
                const restConsumerProducts = consumerProducts.filter(
                    (product) => product.deviceSerialNumber !== deviceSerialNumber
                );

                const iotDeviceMapped = {
                    ...device,
                    deviceConnectionStatus,
                    device_management_features_config,
                    device_global_features_config,
                    deviceSerialNumber,
                    isDeviceSupported,
                    isIotProduct: true,
                    productName: name,
                    status,
                };

                if (holder) {
                    iotDeviceMapped.holder = {...holder};
                }

                restConsumerProducts.unshift(iotDeviceMapped);

                return restConsumerProducts;
            } else {
                return consumerProducts;
            }
        }
    );

export {makeSelectCommonProducts};
