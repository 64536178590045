import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import imgBookIcon1 from '../../../../assets/images/icons/book-icon.png';
import imgBookIcon2 from '../../../../assets/images/icons/book-icon@2x.png';
import imgBookIcon3 from '../../../../assets/images/icons/book-icon@3x.png';
import Button from '../../../components/Button/Button';
import ButtonBorder from '../../../components/Button/ButtonBorder/ButtonBorder';
import Image from '../../../components/Image/Image';
import Container from '../../../components/Layout/Container/Container';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import * as tncTypes from '../../../consts/tnc/tncTypes';
import analyticsService from '../../../services/analyticsService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import tncService from '../../../services/tncService';
import {hideLoader, setNotificationInfo, showLoader} from '../../../state/ducks/global';
import {makeSelectAcceptedTncByType, makeSelectTncByType} from '../../../state/selectors/consumer';
import {dispatch} from '../../../state/store';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import TermsOfUseDescription from '../TermsOfUseDescription/TermsOfUseDescription';
import styles from './TermsOfUsePage.module.scss';

const mapStateToProps = createStructuredSelector({
    termsOfUse: makeSelectTncByType(tncTypes.TERMS_OF_US),
    acceptedTermsOfUse: makeSelectAcceptedTncByType(tncTypes.TERMS_OF_US),
});

const TermsOfUsePage = (props) => {
    const {termsOfUse, acceptedTermsOfUse} = props;

    if (!termsOfUse) return null;

    const {description, legals, updateDescription} = termsOfUse;
    if (!legals) return null;

    const onAcceptButtonClick = async () => {
        dispatch(showLoader());

        analyticsService.pushTNCTermsOfUseInteractionEvent(true);
        await tncService.setTnc(legals);

        dispatch(hideLoader());
    };

    const localizedStrings = getLocalizedStrings();

    const onDeclineButtonClick = () => {
        analyticsService.pushTNCTermsOfUseInteractionEvent(false);

        dispatch(setNotificationInfo(localizedStrings[localizationKeys.TERMS_SUMMARY_ERROR_NOTIFICATION_TEXT]));
    };

    const isUpdatedTnc = acceptedTermsOfUse != null;
    const summaryTitleText = isUpdatedTnc
        ? localizationKeys.TERMS_SUMMARY_UPDATE_TITLE_TEXT
        : localizationKeys.TERMS_SUMMARY_TITLE_TEXT;
    const descriptionText = isUpdatedTnc ? updateDescription : description;

    return (
        <div className={styles.Page} data-testid={elementsTesIds.TERMS_OF_USE_PAGE}>
            <Container>
                <div className={styles.Header}>
                    <Image className={styles.HeaderImage} src={[imgBookIcon1, imgBookIcon2, imgBookIcon3]} />
                </div>
                <section>
                    <h2 className={styles.Title}>{localizedStrings[summaryTitleText]}</h2>
                    <TermsOfUseDescription innerHtml={descriptionText} className={styles.TermsOfUsePageDescription} />
                </section>
                <div className={styles.Footer}>
                    <ButtonBorder showArrow onClick={onAcceptButtonClick} className={styles.ButtonAgree}>
                        {localizedStrings[localizationKeys.TERMS_SUMMARY_CONFIRM_BUTTON_TEXT]}
                    </ButtonBorder>
                    <Button className={styles.ButtonDecline} onClick={onDeclineButtonClick} showUnderline>
                        {localizedStrings[localizationKeys.TERMS_SUMMARY_DECLINE_BUTTON_TEXT]}
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default connect(mapStateToProps)(TermsOfUsePage);
