import classNames from 'classnames';
import React from 'react';

import * as localizationKeys from '../../../../../consts/app/localizationKeys';
import layoutService from '../../../../../services/layoutService';
import {getLocalizedStrings} from '../../../../../services/localization/localizationService';
import userDeviceService from '../../../../../services/userDeviceService';
import AppIntroPageBluetoothIcon from '../AppIntroPageBluetoothIcon';
import styles from './AppIntroBTIconText.module.scss';

const TXT_REGULAR_CLASSNAME = 'ica--txt-regular';

const AppIntroBTIconText = ({layout, isDownloadButtonAvailable}) => {
    const isDesktopLayout = layoutService.isDesktopLayout(layout);
    const isMobileLayout = layoutService.isMobileLayout(layout);
    const isBluetoothSupported = userDeviceService.isBluetoothSupported();

    const isVisible =
        isDesktopLayout || (isMobileLayout && isDownloadButtonAvailable) || (isMobileLayout && !isBluetoothSupported);

    if (!isVisible) return null;

    const localizedStrings = getLocalizedStrings();

    const descriptionText = localizedStrings.formatString(
        localizedStrings[
            isBluetoothSupported
                ? localizationKeys.COMPATIBLE_BROWSER_PAGE_DESCRIPTION_ICON_TEXT
                : localizationKeys.COMPATIBLE_BROWSER_PAGE_NOT_COMPATIBLE_DESCRIPTION_ICON_TEXT
        ]
    );

    const descriptionIconClassName = classNames(styles.DescriptionIcon, TXT_REGULAR_CLASSNAME, {
        [styles.BTNotSupported]: !isBluetoothSupported,
        [styles.MarginTopNull]: isDownloadButtonAvailable && isDesktopLayout,
    });

    return (
        <div className={descriptionIconClassName}>
            <span>{descriptionText}</span>
            <AppIntroPageBluetoothIcon layout={layout} />
        </div>
    );
};

export default AppIntroBTIconText;
