import * as cmMessageStatusTypes from '../../consts/uam/cmMessageStatusTypes';
import connectivityService from '../device/connectivityService';
import cmWebSocketService from './cmClientService';

const publishInitializationEvent = () => {
    const message = {
        request_id: null,
        token: null,
        type: cmMessageStatusTypes.CONNECTED,
        communication_interface: getCommunicationInterface(),
    };

    publishMWMessage(message);
};

const publishResponseEvent = (type, request_id, token, frames) => {
    const message = {
        request_id,
        token,
        type,
        communication_interface: getCommunicationInterface(),
        frames,
    };

    publishMWMessage(message);
};

const publishErrorEvent = ({request_id, token, errorCode}) => {
    const message = {
        request_id,
        token,
        type: cmMessageStatusTypes.ERROR,
        communication_interface: getCommunicationInterface(),
        error: errorCode,
    };

    publishMWMessage(message);
};

const publishMWMessage = (message) => {
    cmWebSocketService.publishMessage(message);
};

const getCommunicationInterface = () => (connectivityService.isHid() ? 'usb' : 'ble');

export default {
    publishErrorEvent,
    publishInitializationEvent,
    publishResponseEvent,
};
