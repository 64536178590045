import converter from '../../utils/converter';
import log from '../logger/log';

let instance = null;
const VENDOR_ID = 0x2759;

export default class HIDClient {
    constructor(createNew = false, options) {
        if (createNew && instance) {
            instance = null;
        }

        if (instance) {
            return instance;
        }

        this.options = options;

        instance = this;
    }

    connectDevice = async ({isNewDevice, onConnect}) => {
        try {
            const device = await this.getOpenedDevice(isNewDevice);

            navigator.hid.ondisconnect = () => {
                this.onDisconnected();
                console.log(`HID disconnected: ${device.productName}`);
            };

            this.device = device;

            log.debug(`HIDClient: device is connected, product name: ${device.productName}`);
            onConnect();
        } catch (e) {
            log.debug(`HIDClient: connectDevice failed, error: ${e}`);

            throw e;
        }
    };

    subscribeOnInputReport = (handler) => {
        const REPORT_ID = '3F';

        this.device.oninputreport = (e) => {
            const KEEP_ALIVE_FRAME = '0100';
            const hexResponse = converter.buffer2hex(e.data);
            const isKeepAliveFrame = hexResponse.startsWith(KEEP_ALIVE_FRAME);

            log.debug(`HIDClient: oninputreport: ${hexResponse}${isKeepAliveFrame ? ', isKeepAliveFrame' : ''}`);

            if (!isKeepAliveFrame) {
                let response = REPORT_ID + converter.buffer2hex(e.data);
                handler(response);
            }
        };
    };

    sendReport = (command) => {
        try {
            const REPORT_SIZE = 31;
            const reportId = 63;
            const commandWithoutReportId = command.substr(2);
            const commandBin = converter.hex2binSized(commandWithoutReportId, REPORT_SIZE);

            log.debug(`Try to send command: ${command}`);

            this.device
                .sendReport(reportId, commandBin)
                .then(() => {
                    log.debug(`HIDClient: sendReport success: ${command}`);
                })
                .catch((e) => {
                    log.error(`HIDClient: sendReport failed, error: ${e}`);
                });
        } catch (e) {
            log.error(`HIDClient: sendReport error: ${e}`);
        }
    };

    onDisconnected = (isDisconnectedByUser) => {
        log.debug(`HIDClient: device is disconnected`);
        this.options.onDisconnected(isDisconnectedByUser);
    };

    disconnect = () => {
        if (this.device?.opened) {
            this.device.close();
            this.device = null;
        }
    };

    isDeviceConnected = () => this.device?.opened;

    getOpenedDevice = async (isNewDevice) => {
        let device;

        if (!isNewDevice) {
            device = await this.getPermittedDevice();
        }

        if (!device) {
            device = await this.requestDevice();
        }

        if (!device.opened) {
            await device.open();
        }

        return device;
    };

    getPermittedDevice = async () => {
        const devices = await navigator.hid.getDevices();
        return devices.find((d) => d.vendorId === VENDOR_ID);
    };

    requestDevice = async () => {
        const devices = await navigator.hid.requestDevice({
            filters: [{vendorId: VENDOR_ID}],
        });
        return devices[0];
    };
}
