import React from 'react';

import ButtonDark from '../../../components/Button/ButtonDark/ButtonDark';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import analyticsService from '../../../services/analyticsService';
import appRouterService from '../../../services/appRouterService';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import marketTypeService from '../../../services/marketTypeService';
import routerService from '../../../services/routerService';
import elementsTesIds from '../../../test/consts/elementsTesIds';
import styles from '../NoDevices.module.scss';

const SetupNewDeviceButton = () => {
    const isDCE2 = marketTypeService.isDCE2();

    const onClick = () => {
        if (isDCE2) {
            const dcsRegisterUrl = appConfig.getDcsRegisterUrlFormatted();
            routerService.redirect(dcsRegisterUrl);
        } else {
            analyticsService.pushPrePairingSetupNewIqosEvent();
            appRouterService.forwardToPairingAlertPage();
        }
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <ButtonDark
            className={styles.NewDeviceButton}
            onClick={onClick}
            testId={elementsTesIds.DEVICE_LIST_BTN_SETUP_NEW_DEVICE}
        >
            {localizedStrings[localizationKeys.NO_REGISTERED_DEVICES_CONFIRM_BUTTON_TEXT]}
        </ButtonDark>
    );
};

export default SetupNewDeviceButton;
