import * as iccResponseErrorTypes from '../../../consts/icc/iccResponseErrorTypes';
import useHttpErrorHandler from '../useHttpErrorHandler';

const useHttpRegistrationErrorHandler = ({handler}) => {
    useHttpErrorHandler({
        httpErrorTypes: [
            iccResponseErrorTypes.REGISTERED_PRODUCT_LIMIT,
            iccResponseErrorTypes.TOO_MANY_REGISTRATION_REQUESTS,
        ],
        handler: handler,
    });
};

export default useHttpRegistrationErrorHandler;
