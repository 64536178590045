import {useEffect} from 'react';

const useEventListener = (type, listener) => {
    useEffect(() => {
        document.addEventListener(type, listener);

        return () => document.removeEventListener(type, listener);
    }, []);
};

export default useEventListener;
