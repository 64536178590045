import appConfig from '../../config/appConfig';
import * as iccMethods from '../../consts/icc/iccMethods';
import urlUtils from '../../utils/urlUtils';
import appErrorService from '../app/appErrorService';
import appRouterService from '../appRouterService';
import authDataService from '../auth/authDataService';
import authDataStoreService from '../auth/authDataStoreService';
import {getInitialLanguageCode} from '../localization/localizationService';
import log from '../logger/log';
import {authMapping, authUamMapping, loginTokenMapping} from '../mapping/iccMappings';
import server from '../server/server';
import storageService from '../storage/storageService';
import iccResponseErrorCheckService from './iccResponseErrorCheckService';

let isTokenRefreshInProgress = false;
const getIccApiUrl = (methodName) => urlUtils.join(appConfig.getIccApiUrl(), methodName);

const getHeaders = () => {
    return {
        a: appConfig.getIccApiHeader_a(),
        Accept: 'application/json',
        Authorization: appConfig.getIccApiHeaderAuthorization(),
        'Content-Type': 'application/x-www-form-urlencoded',
        l: getInitialLanguageCode()?.toUpperCase(),
        m: appConfig.getIccApiHeader_m(),
    };
};

const getBody = (isAnonymous) => {
    const authUsername = authDataStoreService.getAuthUsername();

    return {
        grant_type: 'password',
        password: '',
        scope: appConfig.getIccApiBodyScope(),
        username: isAnonymous ? 'anonymous' : authUsername ? authUsername : appConfig.getIccApiHeaderUsername(),
    };
};

const onIccApiMethodResponse = (response, isAnonymous) => {
    const responseData = authMapping(response.data);

    authDataStoreService.setIccTokenData({...responseData, isAnonymous});

    return responseData;
};

const refreshIccTokenIfAllowed = () => {
    if (!isTokenRefreshInProgress) {
        isTokenRefreshInProgress = true;
        return refreshIccToken();
    }
};

const fetchIccToken = async (isAnonymous) => {
    let spiceToken;

    if (isAnonymous) {
        spiceToken = appConfig.getAnonymousToken();
    } else {
        spiceToken = authDataStoreService.getSpiceToken();
    }

    const data = urlUtils.stringify({...getBody(isAnonymous), password: spiceToken});

    try {
        const response = await getToken(data, getHeaders());
        onIccApiMethodResponse(response, isAnonymous);

        log.debug(`iccService: fetchIccToken, request: "${iccMethods.GET_TOKEN}" has successful response`);
    } catch (e) {
        log.error(`iccService: fetchIccToken, request: "${iccMethods.GET_TOKEN}", error: ${e}`);
        throw e;
    }
};

const refreshIccToken = async () => {
    const spiceToken = authDataStoreService.getSpiceToken();
    const refreshToken = authDataStoreService.getRefreshToken();

    if (spiceToken && refreshToken) {
        const data = urlUtils.stringify({
            ...getBody(),
            password: spiceToken,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
        });

        try {
            const response = await getToken(data, getHeaders());

            isTokenRefreshInProgress = false;

            const responseMapped = onIccApiMethodResponse(response);
            log.debug(`iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}" has successful response`);

            return responseMapped;
        } catch (e) {
            isTokenRefreshInProgress = false;

            const isRememberMeEnabled = appConfig.getIsRememberMeEnabled();

            if (isRememberMeEnabled) {
                storageService.removeUserSessionDataFromStorage();
                appRouterService.forwardToSelectMarketPage();
            }

            log.error(`iccService: refreshIccToken, request: "${iccMethods.GET_TOKEN}", error: ${e}`);

            throw e;
        }
    } else {
        log.error(
            `iccService: refreshIccToken, required auth data is empty, spiceToken: ${spiceToken}, refreshToken: ${refreshToken}`
        );
        appErrorService.showGlobalErrorWithAppHardReset();
    }
};

const fetchUamToken = async (isRetry = false) => {
    const authToken = await authDataService.getAuthToken();
    const headers = {headers: {Authorization: 'Bearer ' + authToken}};

    try {
        const response = await getUamToken(headers);
        const responseData = authUamMapping(response.data);

        authDataStoreService.setUamApiData(responseData);

        log.debug(`iccService: fetchUamToken, request: "${iccMethods.GET_UAM_TOKEN}" has successful response`);
    } catch (e) {
        log.error(`iccService: fetchUamToken, request: "${iccMethods.GET_UAM_TOKEN}", error: ${e}`);
        if (isRetry) throw e;

        await iccResponseErrorCheckService(e);
        return fetchUamToken(true);
    }
};

const fetchSSOLink = async (deeplinkId) => {
    const authToken = await authDataService.getAuthToken();
    const headers = {Authorization: 'Bearer ' + authToken};
    const login = authDataStoreService.getAuthUsername();
    const password = authDataStoreService.getSpiceToken();

    if (login) {
        const data = {
            Login: login,
            Password: password,
            AutoLoginLinkName: deeplinkId,
        };

        try {
            const response = await getLoginToken(data, headers);

            const {campaignUrlPersonal} = loginTokenMapping(response.data);

            log.debug(
                `iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}" has successful response`
            );

            return campaignUrlPersonal;
        } catch (e) {
            log.error(`iccService: fetchSSOLink, request: "${iccMethods.GET_CONSUMER_LOGIN_TOKEN}", error: ${e}`);
        }
    } else {
        log.debug(`iccService: fetchSSOLink is canceled, login is empty`);
    }
};

const getToken = (data, headers) => server.post(getIccApiUrl(iccMethods.GET_TOKEN), {data, headers});

const getUamToken = (headers) => server.get(getIccApiUrl(iccMethods.GET_UAM_TOKEN), headers);

const getLoginToken = (data, headers) =>
    server.post(getIccApiUrl(iccMethods.GET_CONSUMER_LOGIN_TOKEN), {data, headers});

export default {
    refreshIccTokenIfAllowed,
    fetchIccToken,
    fetchSSOLink,
    fetchUamToken,
    refreshIccToken,
};
