import React, {useState} from 'react';

import iconCleaning from '../../../../../../../assets/images/icons/cleaning-icon.png';
import iconCleaning2 from '../../../../../../../assets/images/icons/cleaning-icon@2x.png';
import iconCleaning3 from '../../../../../../../assets/images/icons/cleaning-icon@3x.png';
import * as localizationKeys from '../../../../../../consts/app/localizationKeys';
import deviceConfigService from '../../../../../../services/iotDevice/iotDeviceConfigService';
import iotDeviceSettingsService from '../../../../../../services/iotDevice/iotDeviceSettingsService';
import {getLocalizedStrings} from '../../../../../../services/localization/localizationService';
import DeviceSettingsItem from '../../../DeviceSettingsItem';
import styles from './DeviceCleaningMode.module.scss';
import DeviceCleaningModePopup from './DeviceCleaningModePopup';

const DeviceCleaningMode = (props) => {
    const {dispatch, iotDevice, iotMessage, isConnected, disabled, onDisableClick, layout, testId} = props;
    const [isVisibleCleaningPopup, setIsVisibleCleaningPopup] = useState(false);

    const onVisibleCleaningPopup = () => {
        const isCleaningEnabled = deviceConfigService.isCleaningEnabled(iotDevice);

        if (isVisibleCleaningPopup || iotDeviceSettingsService.checkSettingAvailable(iotDevice, isCleaningEnabled)) {
            setIsVisibleCleaningPopup(!isVisibleCleaningPopup);
        }
    };
    const localizedStrings = getLocalizedStrings();

    return (
        <>
            <DeviceSettingsItem
                disabled={disabled}
                imgUrls={[iconCleaning, iconCleaning2, iconCleaning3]}
                onClick={onVisibleCleaningPopup}
                onDisableClick={onDisableClick}
                testId={testId}
            >
                <span className={styles.Title}>{localizedStrings[localizationKeys.DEVICE_SETTINGS_CLEANING_MODE]}</span>
            </DeviceSettingsItem>
            {isVisibleCleaningPopup && isConnected && (
                <DeviceCleaningModePopup
                    dispatch={dispatch}
                    iotDevice={iotDevice}
                    iotMessage={iotMessage}
                    isConnected={isConnected}
                    setIsVisibleCleaningPopup={setIsVisibleCleaningPopup}
                    layout={layout}
                />
            )}
        </>
    );
};

export default DeviceCleaningMode;
