import classnames from 'classnames';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import FloatButton from '../../components/Button/FloatButton/FloatButton';
import Container from '../../components/Layout/Container/Container';
import * as localizationKeys from '../../consts/app/localizationKeys';
import * as routePaths from '../../consts/route/routePaths';
import LogoutButton from '../../containers/LogoutButton/LogoutButton';
import analyticsService from '../../services/analyticsService';
import backendService from '../../services/app/backendService';
import appRouterService from '../../services/appRouterService';
import gamClientService from '../../services/gam/gamClientService';
import iccConsumerProducts from '../../services/icc/iccConsumerProducts';
import iotDeviceConnectionStatusService from '../../services/iotDevice/iotDeviceConnectionStatusService';
import {getLocalizedStrings} from '../../services/localization/localizationService';
import marketConfigService from '../../services/marketConfigService';
import marketTypeService from '../../services/marketTypeService';
import stylesService from '../../services/stylesService';
import {hideLoader, setNotificationInfo, showLoader} from '../../state/ducks/global';
import {makeSelectIotDeviceMergedWithGamProduct} from '../../state/selectors/consumer';
import {makeSelectLayoutType} from '../../state/selectors/global';
import {makeSelectCommonProducts} from '../../state/selectors/products';
import {dispatch} from '../../state/store';
import elementsTesIds from '../../test/consts/elementsTesIds';
import DeviceLock from '../DeviceLock/DeviceLock';
import DevicesList from './components/DevicesList/DevicesList';
import styles from './MyDevicesPage.module.scss';

const mapStateToProps = createStructuredSelector({
    commonProducts: makeSelectCommonProducts(),
    gamProducts: makeSelectIotDeviceMergedWithGamProduct(),
    layout: makeSelectLayoutType(),
});

const LOADER_DATA = {
    name: 'DEVICE_LIST_LOADER',
    isOpaque: true,
};

const MyDevicesPage = (props) => {
    const {commonProducts, layout, gamProducts} = props;

    const isGamBackend = backendService.isGamBackend();
    const isIccJourneyScreensEnabled = marketConfigService.isIccJourneyScreensEnabled();

    const iccProducts = isIccJourneyScreensEnabled ? commonProducts : [];
    const conclusiveProducts = isGamBackend ? gamProducts : iccProducts;

    const [currentProductIndex, setCurrentProductIndex] = useState(0);
    const productExists = !!conclusiveProducts?.length && currentProductIndex < conclusiveProducts.length;
    const product = productExists ? conclusiveProducts[currentProductIndex] : null;
    const {colorHex, deviceConnectionStatus, device_management_features_config, isDeviceActivated} = product || {};
    const isDeviceReady = iotDeviceConnectionStatusService.isDeviceReady(deviceConnectionStatus);
    const backgroundColorStyle = stylesService.getBackgroundColorStyle(colorHex);
    const localizedStrings = getLocalizedStrings();
    const isDCE2 = marketTypeService.isDCE2();

    const onSlide = (productIndex) => {
        setCurrentProductIndex(productIndex);
    };

    const onDisableClick = () => {
        dispatch(setNotificationInfo(localizedStrings[localizationKeys.DEVICE_SETTINGS_CONNECT_NOTIFICATION_TEXT]));
    };

    useEffect(() => {
        analyticsService.pushUsageMyDevicesScreenviewEvent();
        if (isIccJourneyScreensEnabled && !commonProducts) {
            getDetailedProducts();
        } else if (isGamBackend && !gamProducts) {
            getGamAssets();
        }
    }, []);

    useEffect(() => {
        if (conclusiveProducts?.length === 0) {
            appRouterService.forwardToNoDevicesPage();
        }
    }, [conclusiveProducts]);

    const getGamAssets = async () => {
        dispatch(showLoader(LOADER_DATA));

        await gamClientService.initGam();

        dispatch(hideLoader(LOADER_DATA.name));
    };

    const getDetailedProducts = async () => {
        dispatch(showLoader(LOADER_DATA));

        await iccConsumerProducts.getDetailedProducts();

        dispatch(hideLoader(LOADER_DATA.name));
    };

    const plusSymbol = <span className={styles.PlusSymbol}>+</span>;

    const pageClassName = classnames(styles.Page, 'page');

    return (
        <>
            <div className={pageClassName} data-testid={elementsTesIds.DEVICE_LIST_PAGE} style={backgroundColorStyle}>
                <Container isFloatHeight>
                    {!isDCE2 && (
                        <FloatButton
                            className={`${styles.AddDeviceButton} ica--txt-regular`}
                            isLeft
                            testId={elementsTesIds.DEVICE_LIST_BTN_ADD_NEW}
                            to={routePaths.PAIRING_ALERT}
                        >
                            {localizedStrings.formatString(
                                localizedStrings[localizationKeys.DEVICE_LIST_DEVICES_AVAILABLE_ADD_DEVICE_BUTTON_TEXT],
                                plusSymbol
                            )}
                        </FloatButton>
                    )}
                    {!isDeviceActivated && <LogoutButton />}
                </Container>
                <DeviceLock
                    featuresConfig={device_management_features_config}
                    isDeviceReady={isDeviceReady}
                    lockTestId={elementsTesIds.DEVICE_LIST_BTN_UNLOCK}
                    onDisableClick={onDisableClick}
                    product={product}
                    unlockTestId={elementsTesIds.DEVICE_LIST_BTN_LOCK}
                />
                {productExists && (
                    <DevicesList customerDeviceList={conclusiveProducts} onSlide={onSlide} layout={layout} />
                )}
            </div>
        </>
    );
};

export default connect(mapStateToProps)(MyDevicesPage);
