import React from 'react';

import Button from '../../../components/Button/Button';
import appConfig from '../../../config/appConfig';
import * as localizationKeys from '../../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../../services/localization/localizationService';
import routerService from '../../../services/routerService';
import styles from '../NoDevices.module.scss';

const LearnMoreButton = () => {
    const learnMoreUrl = appConfig.getLearnMoreUrlFormatted();

    if (!learnMoreUrl) return null;

    const localizedStrings = getLocalizedStrings();

    const onClick = () => {
        routerService.blankLinkOpen(learnMoreUrl);
    };

    return (
        <Button showArrow className={styles.LearnMoreButton} onClick={onClick}>
            {localizedStrings[localizationKeys.NO_REGISTERED_DEVICES_LEARN_MORE_BUTTON_TEXT]}
        </Button>
    );
};

export default LearnMoreButton;
