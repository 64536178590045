import 'rmc-picker/assets/index.css';

import classnames from 'classnames';
import React, {useState} from 'react';
import MultiPicker from 'rmc-picker/es/MultiPicker';
import Picker from 'rmc-picker/es/Picker';

import arrayUtils from '../../utils/arrayUtils';
import helpers from '../../utils/helpers';
import styles from './WheelPicker.module.scss';
import wheelPickerService from './wheelPickerService';

const WheelPicker = ({data, initialState, onChange, mapping, wrapperClassName}) => {
    const dataArray = arrayUtils.toArray(data);
    const wrapperClass = classnames(wrapperClassName, styles.Wrapper);

    const [value, setValue] = useState(initialState);

    const internalOnChange = (value) => {
        setValue(value);

        const mappedData = wheelPickerService.getMappedData(mapping, value);

        helpers.runFunction(onChange, mappedData);
    };

    return (
        <div className={wrapperClass}>
            <MultiPicker onValueChange={internalOnChange} selectedValue={value}>
                {dataArray.map((section, index) => {
                    return (
                        <Picker key={index}>
                            {section.map((item, index) => {
                                const {value, text} = item || {};

                                return (
                                    <Picker.Item value={value} key={index}>
                                        {text}
                                    </Picker.Item>
                                );
                            })}
                        </Picker>
                    );
                })}
            </MultiPicker>
        </div>
    );
};

export default WheelPicker;
