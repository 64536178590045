import {createSelector} from 'reselect';

const selectTnc = (state) => state.tnc;

const makeSelectTncPopupCodesList = () => createSelector(selectTnc, (state) => state.tncPopupCodesList);

const makeSelectIsTncPopupVisible = () =>
    createSelector(makeSelectTncPopupCodesList(), (tncPopupCodesList) => tncPopupCodesList?.length > 0);

export {makeSelectIsTncPopupVisible, makeSelectTncPopupCodesList};
