import React from 'react';

import imgP1_1 from '../../../../../assets/images/compatible-browser/image-p1-desktop.png';
import imgP1_2 from '../../../../../assets/images/compatible-browser/image-p1-desktop@2x.png';
import imgP1_3 from '../../../../../assets/images/compatible-browser/image-p1-desktop@3x.png';
import imgP4_1 from '../../../../../assets/images/compatible-browser/image-p4-desktop.png';
import imgP4_2 from '../../../../../assets/images/compatible-browser/image-p4-desktop@2x.png';
import imgP4_3 from '../../../../../assets/images/compatible-browser/image-p4-desktop@3x.png';
import Image from '../../../../components/Image/Image';
import appConfig from '../../../../config/appConfig';
import styles from '../AppIntroPage.module.scss';

const AppIntroPageDesktopImage = () => {
    const enableP1Support = appConfig.getEnableP1Support();

    const srcSet = enableP1Support ? [imgP1_1, imgP1_2, imgP1_3] : [imgP4_1, imgP4_2, imgP4_3];

    return <Image src={srcSet} className={styles.DesktopImage} />;
};

export default AppIntroPageDesktopImage;
