import appConfig from '../config/appConfig';
import dceMarketTypes from '../consts/market/dceMarketTypes';

const isDCE1 = () => appConfig.getIsDCE1();
const isDCE2 = () => appConfig.getIsDCE2();
const isDCE1Black = () => appConfig.getDCE1Type() === dceMarketTypes.BLACK;
const isDCE1White = () => appConfig.getDCE1Type() === dceMarketTypes.WHITE;
const isDCS = () => appConfig.getIsDCS();
const isMarketDefined = () => isDCE1() || isDCE2() || isDCS();

export default {
    isDCE1,
    isDCE2,
    isDCE1Black,
    isDCE1White,
    isDCS,
    isMarketDefined,
};
