import UAParser from 'ua-parser-js';

const getOS = () => {
    const uaParser = new UAParser();
    return uaParser.getOS();
};

const getOSShortName = () => {
    const {name} = getOS();

    return name.toLowerCase().substr(0, 3);
};

export default {
    getOS,
    getOSShortName,
};
