import DEVICE_TYPES from '../../consts/device/deviceTypes';
import helpers from '../../utils/helpers';
import appConfig from '../appConfig';

const fetchDataConfig = async () => {
    const enableP1Support = appConfig.getEnableP1Support();
    const enableP4Support = appConfig.getEnableP4Support();

    const p1Data = enableP1Support
        ? (await import(/* webpackChunkName: "lazy-chunk.1" */ './dataConfigP1.json')).default
        : {};
    const p4Data = enableP4Support
        ? (await import(/* webpackChunkName: "lazy-chunk.2" */ './dataConfigP4.json')).default
        : {};
    const data = helpers.mergeDeep(p1Data, p4Data);

    return data;
};

const getDevicesConfigData = (data) => {
    const {Ordinary, Cortez, Duo, Multi, Veev} = data || {};

    return {
        [DEVICE_TYPES.DEV_TYPE_V24P_CHARGER]: Ordinary,
        [DEVICE_TYPES.DEV_TYPE_P1V30_CHARGER]: Cortez,
        [DEVICE_TYPES.DEV_TYPE_P1V31_CHARGER]: Duo,
        [DEVICE_TYPES.DEV_TYPE_P1V30M]: Multi,
        [DEVICE_TYPES.DEV_TYPE_P4_M3_G2]: Veev,
    };
};

const dataConfig = {};

const initDataConfig = async () => {
    const data = await fetchDataConfig();

    dataConfig.batteryGuideData = getDevicesConfigData(data.BatteryGuide);
    dataConfig.cleaningGuideData = getDevicesConfigData(data.CleaningGuide);
    dataConfig.helpfulTipsData = getDevicesConfigData(data.HelpfulTips);
    dataConfig.howToUseGuideData = getDevicesConfigData(data.HowToUseGuide);
    dataConfig.pairingGuideData = getDevicesConfigData(data.PairingGuide);
    dataConfig.podGuideData = getDevicesConfigData(data.PodGuide);
    dataConfig.quickStartGuideData = getDevicesConfigData(data.QuickStartGuide);
    dataConfig.responsiweDrawGuideData = getDevicesConfigData(data.ResponsiveDrawGuide);
};

export {initDataConfig, dataConfig};
