import React, {useEffect} from 'react';

import Carousel from '../../../../../../components/Carousel/Carousel';
import domService from '../../../../../../services/domService';
import layoutService from '../../../../../../services/layoutService';
import DeviceListItem from '../DeviceListItem/DeviceListItem';
import styles from './MultipleDevices.module.scss';

const SLICK_TRACK_CLASSNAME = '.slick-track';
const SLICK_TRACK_CENTERED_CLASSNAME = 'ica-slick-track-centered';

const MultipleDevices = ({customerDeviceList, onSlide, layout}) => {
    const isMobileDevice = layoutService.isMobileLayout(layout);
    const isTabletLayout = layoutService.isTabletLayout(layout);
    const isTwoDevicesAvailable = customerDeviceList?.length === 2;

    const sliderOptions = !isMobileDevice
        ? {
              centerMode: true,
              slidesToShow: isTwoDevicesAvailable ? 2 : 1,
              variableWidth: true,
          }
        : null;

    useEffect(() => {
        if (isTwoDevicesAvailable) {
            const track = domService.getElementBySelector(SLICK_TRACK_CLASSNAME);
            track.classList.add(SLICK_TRACK_CENTERED_CLASSNAME);
        }
    }, [isTwoDevicesAvailable]);

    const onDeviceClick = (index) => {
        if (isTwoDevicesAvailable) {
            const track = domService.getElementBySelector(SLICK_TRACK_CLASSNAME);

            if (index === 0) {
                track.classList.add(SLICK_TRACK_CENTERED_CLASSNAME);
            } else if (index === 1) {
                track.classList.remove(SLICK_TRACK_CENTERED_CLASSNAME);
            }
        }
    };

    return (
        <section className={`${styles.Slider} multiple-devices-slider`}>
            <Carousel
                dotsClassName={styles.MultipleDeviceCarouselDots}
                isAdaptiveHeight
                isArrowVisible={false}
                isControlArrowVisible
                controlArrowClassName={styles.SliderButton}
                isInfinite
                onTransitionEnd={onSlide}
                options={sliderOptions}
            >
                {customerDeviceList?.map((device, i) => (
                    <div key={i}>
                        <DeviceListItem
                            device={device}
                            isTabletLayout={isTabletLayout}
                            onClick={isTwoDevicesAvailable ? () => onDeviceClick(i) : null}
                        />
                    </div>
                ))}
            </Carousel>
        </section>
    );
};

export default MultipleDevices;
