import imgTATBackgroundFirstDesktop from '../../../assets/images/tips-and-tricks/tat-bg-image-first-desktop.png';
import imgTATBackgroundFirstMobile from '../../../assets/images/tips-and-tricks/tat-bg-image-first.png';
import imgTATBackgroundSecondDesktop from '../../../assets/images/tips-and-tricks/tat-bg-image-second-desktop.png';
import imgTATBackgroundSecondMobile from '../../../assets/images/tips-and-tricks/tat-bg-image-second.png';
import imgTATBackgroundThirdDesktop from '../../../assets/images/tips-and-tricks/tat-bg-image-third-desktop.png';
import imgTATBackgroundThirdMobile from '../../../assets/images/tips-and-tricks/tat-bg-image-third.png';
import * as localizationKeys from '../../consts/app/localizationKeys';
import {getLocalizedStrings} from '../../services/localization/localizationService';

let data;

const getTipsAndTricksPageData = (isMobileLayout) => {
    if (!data) {
        const localizedStrings = getLocalizedStrings();

        data = [
            {
                text: localizedStrings[localizationKeys.BENEFITS_FIRST_TEXT],
                image: isMobileLayout ? imgTATBackgroundFirstMobile : imgTATBackgroundFirstDesktop,
            },
            {
                text: localizedStrings[localizationKeys.BENEFITS_THIRD_TEXT],
                image: isMobileLayout ? imgTATBackgroundSecondMobile : imgTATBackgroundSecondDesktop,
            },
            {
                text: localizedStrings[localizationKeys.BENEFITS_FOUR_TEXT],
                image: isMobileLayout ? imgTATBackgroundThirdMobile : imgTATBackgroundThirdDesktop,
            },
        ];
    }

    return data;
};

export {getTipsAndTricksPageData};
