const PRODUCT_COLOR_TYPES = {
    BLUE: 'BLU',
    COPPER: 'COP',
    COPPER_ROSE: 'COR',
    GOLD: 'GOL',
    GREY: 'GRY',
    LUCID_TEAL: 'LUT',
    MARINE_BLUE: 'MAB',
    MOTOR_SPORTS: 'MSR',
    NAVY: 'NAV',
    NIGO: 'CAM',
    PINK: 'PNK',
    RED: 'RED',
    RUBY: 'RUB',
    VELVET_GREY: 'VEG',
    WHITE: 'WHT',
};

export default PRODUCT_COLOR_TYPES;
