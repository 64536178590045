import classnames from 'classnames';
import React, {useState} from 'react';
import {createPortal} from 'react-dom';

import domService from '../../services/domService';
import userDeviceService from '../../services/userDeviceService';
import helpers from '../../utils/helpers';
import Container from '../Layout/Container/Container';
import FixedContainer from '../Layout/FixedContainer/FixedContainer';
import Overlay from '../Layout/Overlay/Overlay';
import styles from './Popup.module.scss';
import PopupCloseButton from './PopupCloseButton/PopupCloseButton';

const Popup = ({children, className, fixedContainerClassName, onClose, isClosingBySwipeEnabled, testId}) => {
    const portalTarget = domService.getPopupSpawnNode();
    const [isPopupClosing, setIsPopupClosing] = useState(false);

    if (!portalTarget) return null;

    const SWIPE_DELTA_TO_START_CLOSING = 30;
    const isTouchSupported = userDeviceService.isTouchSupported();
    const isClosingByTouch = isTouchSupported && isClosingBySwipeEnabled;
    const overlayClassName = isPopupClosing ? styles.OverlayOnPopupClose : '';
    const isCloseFunction = typeof onClose === 'function';
    let touchYStart = 0;
    let touchYEnd = 0;

    const popupClassName = classnames(styles.Popup, className, {[styles.PopupTouchCloseLine]: isClosingByTouch});
    const popupContainerClassName = classnames(styles.PopupContainer, {[styles.PopupOnClose]: isPopupClosing});

    const onTouchStart = (e) => {
        if (isClosingByTouch) {
            touchYStart = e.changedTouches[0].pageY;
        }
    };

    const onTouchMove = (e) => {
        if (isClosingByTouch) {
            touchYEnd = e.changedTouches[0].pageY;

            const delta = touchYEnd - touchYStart;

            if (delta >= SWIPE_DELTA_TO_START_CLOSING && onClose) {
                onPopupClose();
            }
        }
    };

    const onPopupCloseTransitionEnd = () => {
        if (isPopupClosing) {
            helpers.runFunction(onClose);
            setIsPopupClosing(false);
        }
    };

    const onPopupClose = () => {
        if (onClose) {
            setIsPopupClosing(true);
        }
    };

    return createPortal(
        <FixedContainer className={fixedContainerClassName}>
            <Overlay onClick={onPopupClose} className={overlayClassName} />
            <Container className={styles.PopupsContainer}>
                <div
                    className={popupContainerClassName}
                    onTransitionEnd={onPopupCloseTransitionEnd}
                    data-testid={testId}
                >
                    <div className={styles.PopupTopMargin} onClick={onClose} />
                    <div
                        className={popupClassName}
                        onTouchMove={(e) => onTouchMove(e)}
                        onTouchStart={(e) => onTouchStart(e)}
                    >
                        {isCloseFunction && <PopupCloseButton onClick={onPopupClose} />}
                        {children}
                    </div>
                </div>
            </Container>
        </FixedContainer>,
        portalTarget
    );
};

export default Popup;
