import * as localizationKeys from '../../consts/app/localizationKeys';
import * as gamResponsiveDrawModeTypes from '../../consts/gam/responsiveDrawModeTypes';
import * as iotMessageTypes from '../../consts/iot/iotMessageTypes';
import * as responsiveDrawModeTypes from '../../consts/iot/responsiveDrawModeTypes';
import * as activationStatusTypes from '../../consts/uam/activationStatusTypes';
import {setNotificationSuccess} from '../../state/ducks/global';
import {setDeviceActivationStatus, updateIotDeviceData} from '../../state/ducks/iotDevice/actions';
import {setYapAssets} from '../../state/ducks/yapEncrypted';
import {makeSelectFirstAsset, makeSelectIsDeviceSyncInProgress} from '../../state/selectors/yapEncrypted';
import {dispatch, getState} from '../../state/store';
import backendService from '../app/backendService';
import deviceTypesService from '../device/deviceTypesService';
import domService from '../domService';
import {getLocalizedStrings} from '../localization/localizationService';
import log from '../logger/log';
import {deviceInfoMapping, deviceParentModeMapping} from '../mapping/iotMappings';
import storageService from '../storage/storageService';
import yapService from '../yap/yapService';

const initGamData = async () => {
    const asset = makeSelectFirstAsset()(getState());
    const data = {
        device: {
            type: asset.type,
            name: deviceTypesService.getProductNameByType(asset.type),
            mediaId: asset.materialId,
        },
        device_global_features_config: {
            is_holder_check: false,
            is_led_intensity: true,
            is_vibration: false,
            is_self_cleaning: false,
            is_device_alarm: false,
        },
        device_management_features_config: {
            connect_care_mode: false,
            device_led_intensity_mode: true,
            device_parent_protection_mode: true,
            holder_led_intensity_mode: false,
            device_holder_charged_vibration: false,
            device_self_cleaning: 0,
            programmable_vibrations: {setting_supported: false, common_vibration_switcher: false},
            device_alarm: false,
            device_alarm_duration: 30,
            device_vape_cloud_size_mode: true,
            device_responsive_draw_profile_mode: true,
        },
    };

    const mappedData = deviceInfoMapping(data);
    dispatch(updateIotDeviceData(mappedData));
};

const checkIsDeviceActivatedInGam = async () => {
    const isYAPFlowEnabled = yapService.isYapMode();
    const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();
    const {dataFactory} = deviceDataFromLocalStorage;

    if (!isYAPFlowEnabled || dataFactory === false) {
        dispatch(setDeviceActivationStatus(true));
        return;
    }

    let isDeviceActivated = false;
    const state = getState();

    let gamAsset = makeSelectFirstAsset()(state);
    if (gamAsset?.assetId) {
        isDeviceActivated = gamAsset.yapActivated;
    }

    log.info(`gamService: checkIsDeviceActivatedInGam: ${isDeviceActivated}`);
    dispatch(setDeviceActivationStatus(isDeviceActivated));
};

const saveGamRootDataToLocalStorage = () => {
    const isGamBackend = backendService.isGamBackend();

    if (!isGamBackend) return;

    let codentify = domService.getDeviceCodentifyFromRoot();
    let dataFactory = domService.getDeviceFactoryFromRoot();

    if (!codentify && !dataFactory) {
        return;
    }

    let deviceDataFromRoot;

    if (codentify && dataFactory) {
        deviceDataFromRoot = {
            codentify,
            dataFactory,
        };
    } else {
        const deviceDataFromLocalStorage = storageService.getDeviceCodentifyFactoryFromLocalStorage();

        if (!codentify) {
            codentify = deviceDataFromLocalStorage?.codentify;
        }

        if (dataFactory === '') {
            dataFactory = deviceDataFromLocalStorage?.dataFactory;
        }

        deviceDataFromRoot = {
            codentify,
            dataFactory,
        };
    }

    storageService.setDeviceCodentifyFactoryToLocalStorage(deviceDataFromRoot);
};

const updateDeviceSettings = (type, newValue) => {
    if (!newValue) return;

    switch (type) {
        case iotMessageTypes.RESPONSIVE_DRAW_PROFILES:
            if (newValue === gamResponsiveDrawModeTypes.ALLEGRO) {
                newValue = responsiveDrawModeTypes.VIVACE;
            } else if (newValue === gamResponsiveDrawModeTypes.VIVALDI) {
                newValue = responsiveDrawModeTypes.STACCATO;
            }

            const responsiveDrawData = {responsiveDrawData: {responsive_draw_profile: newValue}};

            dispatch(updateIotDeviceData(responsiveDrawData));
            break;
        case iotMessageTypes.VAPE_CLOUD_SIZE:
            const vapeCloudSizeData = {vapeCloudSizeData: {vape_cloud_size: newValue}};

            dispatch(updateIotDeviceData(vapeCloudSizeData));
            break;
        case iotMessageTypes.PARENT_PROTECTION:
            const parentProtectionData = {device: {parent_protection_mode: newValue}};
            const mappedData = deviceParentModeMapping(parentProtectionData);

            dispatch(updateIotDeviceData(mappedData));

            const isDeviceSyncInProgress = makeSelectIsDeviceSyncInProgress()(getState());
            if (isDeviceSyncInProgress) return;

            let message = null;
            if (mappedData.device.parent_protection_mode) {
                message = getLocalizedStrings()[localizationKeys.PARENTAL_CONTROL_DEVICE_LOCKED_MESSAGE];
            } else {
                message = getLocalizedStrings()[localizationKeys.UNLOCK_SUCCESS_NOTIFICATION];
            }

            dispatch(setNotificationSuccess(message));
            break;
        case iotMessageTypes.LED:
            const ledData = {ledData: {device: {intensity: newValue}}};

            dispatch(updateIotDeviceData(ledData));
            break;
    }
};

const updateGamActivationStatus = async (yapActivationStatus) => {
    const state = getState();
    const gamAsset = makeSelectFirstAsset()(state);
    if (yapActivationStatus && gamAsset?.assetId) {
        gamAsset.yapActivated = yapActivationStatus.toLowerCase() === activationStatusTypes.UAM_ACTIVATED;
        dispatch(setYapAssets([gamAsset]));
        await checkIsDeviceActivatedInGam();
    }
};

const updateIotDevice = (version, batteryCharge, codentify) => {
    const data = {
        device: {
            version: version,
            battery_charge: batteryCharge,
            codentify: codentify,
        },
    };
    dispatch(updateIotDeviceData(data));
};

export default {
    checkIsDeviceActivatedInGam,
    initGamData,
    saveGamRootDataToLocalStorage,
    updateDeviceSettings,
    updateGamActivationStatus,
    updateIotDevice,
};
