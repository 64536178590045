import helpers from '../utils/helpers';

let instance = null;

export default class HttpErrorHandlerService {
    constructor() {
        if (instance) {
            return instance;
        }

        instance = this;
        this.errorHandler = null;
        this.httpErrorTypes = [];
    }

    setErrorHandler({httpErrorTypes, handler}) {
        this.errorHandler = handler;
        this.httpErrorTypes = httpErrorTypes;
    }

    removeErrorHandler() {
        this.errorHandler = null;
        this.type = null;
    }

    handleError(httpError) {
        const serviceError = httpError?.response?.data?.serviceError;
        const errorCode = serviceError?.code;
        const errorMessage = serviceError?.message?.value;

        const targetHttpError = this.httpErrorTypes.find(
            (errorType) => errorType.errorCode === errorCode && errorType.errorMessage === errorMessage
        );

        if (targetHttpError) {
            helpers.runFunction(this.errorHandler, targetHttpError);
        }
    }
}
