const unique = (a) => [...new Set(a)];

const equals = (array1, array2) => {
    if (!array1 || !array2) return false;
    if (array1.length !== array2.length) return false;
    for (let i = 0, l = array1.length; i < l; i++) {
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            if (!array1[i].equals(array2[i])) return false;
        } else if (array1[i] !== array2[i]) {
            return false;
        }
    }
    return true;
};

const toArray = (a) => (a ? (Array.isArray(a) ? a : [a]) : []);

const splitArray = (input, itemsPerChunk) => {
    return input.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / itemsPerChunk);

        if (!result[chunkIndex]) {
            result[chunkIndex] = [];
        }

        result[chunkIndex].push(item);

        return result;
    }, []);
};

const complementArray = (initialArray, complementToCount = 4) => {
    const LENGTH_TO_DOUBLE = 2;
    const isArray = Array.isArray(initialArray);
    const arrayLength = initialArray?.length;
    const isValid = isArray && arrayLength < complementToCount;

    if (isValid) {
        if (arrayLength === LENGTH_TO_DOUBLE) {
            const arrayToCheck = [...initialArray];
            const connectedDevice = arrayToCheck.find((element) => element?.isIotProduct);
            const connectedDeviceIndex = arrayToCheck.lastIndexOf(connectedDevice);

            if (connectedDevice) {
                arrayToCheck.splice(connectedDeviceIndex, 1);
            }

            return [...initialArray, ...arrayToCheck];
        } else {
            const arrayLastElement = initialArray[arrayLength - 1];

            return [arrayLastElement, ...initialArray];
        }
    }

    return initialArray;
};

export default {
    complementArray,
    equals,
    splitArray,
    toArray,
    unique,
};
