import {dataConfig} from '../../config/dataConfig/dataConfig';
import tutorialTypes from '../../consts/app/tutorialTypes';
import deviceTypesService from '../../services/device/deviceTypesService';
import tutorialPageData from './tutorialPageData';

const getTutorialContent = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        switch (tutorialType) {
            case tutorialTypes.TUTORIAL_TYPE_QUICK_START_GUIDE:
                return dataConfig.quickStartGuideData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_HOW_TO_USE_GUIDE:
                return dataConfig.howToUseGuideData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_CLEANING_GUIDE:
                return dataConfig.cleaningGuideData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_BATTERY_GUIDE:
                return dataConfig.batteryGuideData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_HELPFUL_TIPS:
                return dataConfig.helpfulTipsData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_RESPONSIVE_DRAW_GUIDE:
                return dataConfig.responsiweDrawGuideData[deviceType];

            case tutorialTypes.TUTORIAL_TYPE_POD_GUIDE:
                return dataConfig.podGuideData[deviceType];

            default:
                return dataConfig.howToUseGuideData[deviceType];
        }
    }
};

const getTutorialIntroContent = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        const content = getTutorialContent(deviceType, tutorialType);

        return content.find((element) => element?.button);
    }
};

const getTutorialBaseContent = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        const content = getTutorialContent(deviceType, tutorialType);

        return content.filter((element) => !element?.button);
    }
};

const getNextTutorialData = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        const nextTutorialType = getNextTutorialType(deviceType, tutorialType);
        const nextTutorialTitle = getNextTutorialTitle(deviceType, tutorialType);

        return {
            nextTutorialType,
            nextTutorialTitle,
        };
    }
};

const getNextTutorialType = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        let orderList;
        let nextTutorialType;
        const isP1Device = deviceTypesService.isP1(deviceType);
        const isP4Device = deviceTypesService.isP4(deviceType);

        if (isP1Device) {
            orderList = tutorialPageData.tutorialsOrderP1;
        } else if (isP4Device) {
            orderList = tutorialPageData.tutorialsOrderP4;
        }

        if (orderList?.length) {
            const currentTutorialIndex = orderList.indexOf(tutorialType);

            nextTutorialType = orderList[currentTutorialIndex + 1];

            if (!nextTutorialType) {
                nextTutorialType = orderList[0];
            }
        }

        return nextTutorialType;
    }
};

const getNextTutorialTitle = (deviceType, tutorialType) => {
    if (deviceType && tutorialType) {
        const nextGuideType = getNextTutorialType(deviceType, tutorialType);

        const introContent = getTutorialIntroContent(deviceType, nextGuideType);

        return introContent?.header;
    }
};

export default {
    getNextTutorialData,
    getTutorialBaseContent,
    getTutorialIntroContent,
};
