import helpers from '../../utils/helpers';
import log from '../logger/log';
import mwIotMessageRequestService from '../scpCloud/mwIotMessageRequestService';
import IqosBLEClient from './iqosBleClient';

export default class FwuDataClient {
    constructor({fwuPackageData, onLastFrame, onFail, lastFrameIndex}) {
        this.fwuPackageData = fwuPackageData;
        this.iqosBLEClient = new IqosBLEClient();
        this.isStopped = true;
        this.lastFrameIndex = lastFrameIndex || fwuPackageData.length - 1; //TODO: check
        this.onFail = onFail;
        this.onLastFrame = onLastFrame;
        this.previousWriteTS = Date.now();
    }

    writeFwuFrames = async (frameIndex) => {
        if (!this.iqosBLEClient.isDeviceConnected() || this.isStopped) return;

        const {iqosBLEClient, fwuPackageData} = this;
        const frame = fwuPackageData[frameIndex];
        const {f, n} = frame;

        try {
            // log.debug(`FwuDataClient: FWU Frame - ${f}: try to writeValue`);
            const WRITE_INTERVAL_MS = 70;
            const interval = Date.now() - this.previousWriteTS;
            const delay = WRITE_INTERVAL_MS - interval;

            if (delay > 0 && delay < WRITE_INTERVAL_MS) {
                await helpers.timeout(delay);
            }
            if (this.isStopped) return;

            this.previousWriteTS = Date.now();
            await iqosBLEClient.writeValueToFwuDataCharacteristic(f, true);

            if (this.isStopped) return;

            log.debug(`FwuDataClient: FWU Frame - ${f}: writeValue success`);

            if (n) {
                mwIotMessageRequestService.publishFwuStatusEvent(n);
            }

            const isLastFrame = frameIndex >= this.lastFrameIndex;

            if (isLastFrame) {
                helpers.runFunction(this.onLastFrame);
            } else {
                this.writeFwuFrames(++frameIndex);
            }
        } catch (e) {
            if (this.isStopped) return;
            log.debug(`FwuDataClient: FWU Frame - ${f}: writeValue error: ${e}`);
            this.onFail();
        }
    };

    start = (frameIndex) => {
        this.isStopped = false;
        this.writeFwuFrames(frameIndex);
    };

    stop = () => {
        this.isStopped = true;
    };
}
