import {useEffect} from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';

import iccMarketService from '../../../services/icc/iccMarketService';
import {makeSelectAggregatedSettings} from '../../../state/selectors/consumer';

const mapStateToProps = createStructuredSelector({
    aggregatedSettings: makeSelectAggregatedSettings(),
});

const DEFAULT_REFRESH_SETTINGS_TIMEOUT = 60;
let interval;

const ConsumerSettingsChecker = ({aggregatedSettings}) => {
    const isAggregatedSettings = !!aggregatedSettings;

    useEffect(() => {
        if (isAggregatedSettings) {
            const {
                allMandatoryTermsApproved,
                allNonMandatoryTermsReviewed,
                settingsRefreshTimeoutSec,
            } = aggregatedSettings;

            if (allMandatoryTermsApproved && allNonMandatoryTermsReviewed) {
                const settingsRefreshTimeout = settingsRefreshTimeoutSec
                    ? settingsRefreshTimeoutSec
                    : DEFAULT_REFRESH_SETTINGS_TIMEOUT;

                clearInterval(interval);
                interval = setInterval(async () => {
                    await iccMarketService.getConsumerAggregatedSettings();
                }, settingsRefreshTimeout * 1000);
            }
        }

        return () => clearInterval(interval);
    }, [isAggregatedSettings]);

    return null;
};

export default connect(mapStateToProps)(ConsumerSettingsChecker);
