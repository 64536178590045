export default {
    ABOUT_DEVICE_BTN_CHARGER: 'aboutMyDeviceBtnChargerSection',
    ABOUT_DEVICE_BTN_FWU_CHECK_CHARGER: 'aboutMyDeviceBtnCheckChargerFirmwareUpdate',
    ABOUT_DEVICE_BTN_FWU_CHECK_HOLDER: 'aboutMyDeviceBtnCheckHolderFirmwareUpdate',
    ABOUT_DEVICE_BTN_HOLDER: 'aboutMyDeviceBtnHolderSection',
    ABOUT_DEVICE_BTN_REGISTER: 'aboutMyDeviceBtnRegisterDevice',
    ABOUT_DEVICE_BTN_UNREGISTER: 'aboutMyDeviceBtnUnregisterDevice',
    ABOUT_DEVICE_PAGE: 'aboutMyDevicePage',
    ABOUT_DEVICE_PAGE_UNREGISTER_POPUP: 'aboutMyDeviceBtnUnregisterPopup',
    ACTIVATION_FLOW_ACTIVATING_TITLE: 'activationFlowTitleActivating',
    ACTIVATION_FLOW_REGISTERING_TITLE: 'activationFlowTitleRegistering',
    ACTIVATION_FLOW_SETTINGS_BTN: 'activationFlowBtnSettings',
    ACTIVATION_FLOW_SUCCESSFULLY_ACTIVATED_TITLE: 'activationFlowTitleSuccessfullyActivated',
    AGE_VERIFICATION_PAGE: 'verifyAgePage',
    BROWSER_COMPATIBLE_PAGE: 'compatibilityBrowserPage',
    CLEANING_MODE_POPUP: 'cleaningPopup',
    COMPATIBILITY_BROWSER_BTN_CONTINUE: 'compatibilityBrowserBtnContinue',
    DATA_COLLECTION_PAGE: 'dataCollectionPage',
    DEVICE_LIST_BTN_ACTIVATE: 'deviceListBtnActivate',
    DEVICE_LIST_BTN_ADD_NEW: 'deviceListBtnAddNew',
    DEVICE_LIST_BTN_LOCK: 'deviceListBtnLock',
    DEVICE_LIST_BTN_PAIR: 'deviceListBtnPairDevice',
    DEVICE_LIST_BTN_PAIRING_IFO_POPUP_PAIR_DEVICE: 'deviceListBtnPairingInfoPopupPairDevice',
    DEVICE_LIST_BTN_SETTINGS: 'deviceListBtnSettings',
    DEVICE_LIST_BTN_SETUP_NEW_DEVICE: 'deviceListBtnSetUpNewDevice',
    DEVICE_LIST_BTN_UNLOCK: 'deviceListBtnUnlock',
    DEVICE_LIST_PAGE: 'deviceListPage',
    DEVICE_SETTINGS_BTN_ABOUT: 'deviceSettingsBtnAboutMyDevice',
    DEVICE_SETTINGS_BTN_BACK: 'deviceSettingsBtnBack',
    DEVICE_SETTINGS_BTN_CLEANING: 'deviceSettingsBtnCleaning',
    DEVICE_SETTINGS_BTN_DIAGNOSTIC: 'deviceSettingsBtnDiagnostic',
    DEVICE_SETTINGS_BTN_LED: 'deviceSettingsBtnLed',
    DEVICE_SETTINGS_BTN_LEGALS: 'deviceSettingsBtnLegalAndPolicy',
    DEVICE_SETTINGS_BTN_LOCATE: 'deviceSettingsBtnLocate',
    DEVICE_SETTINGS_BTN_LOCK: 'deviceSettingsBtnLock',
    DEVICE_SETTINGS_BTN_RENAME: 'deviceSettingsBtnRename',
    DEVICE_SETTINGS_BTN_RESP_DRAW: 'deviceSettingsBtnResponsiveDraw',
    DEVICE_SETTINGS_BTN_TIPS_TRICKS_MORE: 'deviceSettingsBtnTipsAndTutorialsMore',
    DEVICE_SETTINGS_BTN_UNLOCK: 'deviceSettingsBtnUnlock',
    DEVICE_SETTINGS_BTN_UNPAIR: 'deviceSettingsBtnUnpairDevice',
    DEVICE_SETTINGS_BTN_VAPE_CLOUD_SIZE: 'deviceSettingsBtnVapeCloudSize',
    DEVICE_SETTINGS_BTN_VIBRATION: 'deviceSettingsBtnVibration',
    DEVICE_SETTINGS_PAGE: 'deviceSettingsPage',
    LED_MODE_POPUP: 'ledPopup',
    LED_POPUP_BTN_100: 'ledBtn100',
    LED_POPUP_BTN_50: 'ledBtn50',
    LEGAL_DISCLAIMER_BTN_BACKS: 'legalDisclaimerBtnBack',
    LEGAL_DISCLAIMER_BTN_CONTINUE: 'legalDisclaimerBtnContinue',
    LEGAL_DISCLAIMER_PAGE: 'legalDisclaimerPage',
    LOADER_CONTROL: 'LoaderControl',
    LOCATE_DEVICE_POPUP: 'locateMyDevicePopup',
    LOCATE_DEVICE_POPUP_BTN_START: 'locateMyDeviceBtnStartLocate',
    LOCATE_DEVICE_POPUP_BTN_STOP: 'locateMyDeviceBtnStopLocate',
    LOCK_POPUP: 'lockPopup',
    LOCK_POPUP_BTN_CANCEL: 'lockBtnCancel',
    LOCK_POPUP_BTN_LOCK: 'lockBtnLock',
    LOGIN_BTN_BACK: 'loginBtnBack',
    LOGIN_BTN_LOGIN: 'loginBtnLogin',
    LOGIN_INPUT_PASSWORD: 'loginInputPassword',
    LOGIN_INPUT_USERNAME: 'loginInputUsername',
    LOGIN_PAGE: 'LoginPage',
    MARKET_BTN_CONFIRM: 'marketSelectBtnConfirm',
    MARKET_DDL_LANGUAGE: 'marketSelectDDLLanguage',
    MARKET_DDL_MARKET: 'marketSelectDDLMarket',
    MARKET_SELECT_PAGE: 'marketSelectPage',
    PAIRING_SEQUENCE_ACTIVATION_PAGE: 'pairingSequenceActivationPage',
    PAIRING_SEQUENCE_BTN_BACK: 'pairingSequenceBtnBack',
    PAIRING_SEQUENCE_BTN_NEXT: 'PairingSequenceBtnNext',
    PAIRING_SEQUENCE_BTN_SELECT_VEEV: 'pairingSequenceBtnSelectVeev',
    PAIRING_SEQUENCE_DEVICE_SELECT_PAGE: 'pairingSequenceDeviceSelectPage',
    PAIRING_SEQUENCE_PROGRESS_PAGE: 'pairingSequenceProgressPage',
    PAIRING_SEQUENCE_REGISTERING_PAGE: 'pairingSequenceRegisteringPage',
    PAIRING_SEQUENCE_SKIP_PAGE: 'pairingSequenceSkipPage',
    PAIRING_SEQUENCE_TITLE: 'pairingSequenceTitle',
    PAIRING_SUCCESS_DEVICE_LIST_BUTTON: 'pairingSequenceBtnDeviceList',
    PAIRING_SUCCESS_TITLE: 'pairingSequenceTitleSuccessfullyPaired',
    POPUP_COMMON_BTN_CLOSE: 'popupBtnClose',
    PREFERENCE_CENTER_PAGE: 'preferenceCenterPage',
    QSG_PAGE: 'qsgPage',
    QSG_PAGE_BTN_BACK: 'qsgBtnBack',
    QSG_PAGE_BTN_NEXT: 'qsgBtnNext',
    QSG_PAGE_BTN_PREV: 'qsgBtnPrevious',
    RENAME_DEVICE_POPUP: 'renameMyDevicePopup',
    RENAME_DEVICE_POPUP_BTN_CANCEL: 'renameMyDeviceBtnCancel',
    RENAME_DEVICE_POPUP_BTN_CLEAR_INPUT: 'renameMyDeviceBtnClearInput',
    RENAME_DEVICE_POPUP_BTN_OK: 'renameMyDeviceBtnOk',
    RENAME_DEVICE_POPUP_INPUT: 'renameMyDeviceInputDeviceName',
    RESP_DRAW_POPUP_BTN_BUBBLY: 'responsiveDrawBtnBubbly',
    RESP_DRAW_POPUP_BTN_CRESCENDO: 'responsiveDrawBtnCrescendo',
    RESP_DRAW_POPUP_BTN_SIREN: 'responsiveDrawBtnSiren',
    RESP_DRAW_POPUP_BTN_STACCATO: 'responsiveDrawBtnStaccato',
    RESP_DRAW_POPUP_BTN_VIVACE: 'responsiveDrawBtnVivace',
    RESPONSIVE_DRAW_POPUP: 'responsiveDrawPopup',
    SETUP_NEW_IQOS_PAGE: 'setUpNewIqosPopup',
    TERMS_OF_USE_PAGE: 'termOfUsePage',
    TIPS_BTN_GET_STARTED: 'tipsBtnGetStarted',
    TIPS_BTN_PAGER_LAST: 'tipsBtnPagerLast', //TODO: add
    TIPS_TRICKS_PAGE: 'tipsPage',
    TIPS_TUTORIALS_PAGE: 'tipsAndTutorialsPage',
    UNLOCK_POPUP: 'unlockPopup',
    UNLOCK_POPUP_BTN_CANCEL: 'unlockBtnCancel',
    UNLOCK_POPUP_BTN_UNLOCK: 'unlockBtnUnlock',
    VAPE_CLOUD_POPUP_BTN_REDUCED: 'VapeCloudSizeBtnReduced',
    VAPE_CLOUD_POPUP_BTN_REGULAR: 'vapeCloudSizeBtnRegular',
    VAPE_CLOUD_SIZE_POPUP: 'vapeCloudSizePopup',
    VERIFY_AGE_BTN_BACK: 'verifyAgeBtnBack',
    VERIFY_AGE_BTN_CONFIRM: 'verifyAgeBtnConfirm',
    VERIFY_AGE_INPUT_DAY: 'verifyAgeInputDay',
    VERIFY_AGE_INPUT_MONTH: 'verifyAgeInputMonth',
    VERIFY_AGE_INPUT_YEAR: 'verifyAgeInputYear',
    VIBRATION_MODE_POPUP: 'vibrationPopup',
    WELCOME_LABEL_WELCOME: 'welcomeTextWelcome',
    WELCOME_PAGE: 'welcomePage',
};
